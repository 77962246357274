import { useLocation, useNavigate } from "react-router-dom";
import { Container, Typography as Typo } from "@mui/material";
import { useSelector } from "react-redux";
const ErrorPage = () => {
  return (
    <>
      <Container>
        <Typo align="center" variant="h6">
          Pagina non trovata.
        </Typo>
      </Container>
    </>
  );
};

export default ErrorPage;
