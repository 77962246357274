import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";
import { AutocompleteCustom } from "../../../../UI/AutoComplete/AutoComplete";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useEffect } from "react";
import PannelloOspiti from "./componentsPrenotazioneOspiti/PannelloOspiti";
import { setOspitiPrenotazioneInfo } from "../../../../../redux/reducers/prenotazioneStepper-reducer";
import { useDispatch, useSelector } from "react-redux";

function PrenotazioniOspiti({
  open,
  handleClose,
  selectOptions,
  selectedOption = [],
}) {
  const [textFieldValue, setTextFieldValue] = useState("");
  const [selectedUser, setSelectedUser] = useState({});
  const [cleanSelected, setCleanSelected] = useState(false);
  const stateDati = useSelector((state) => state.prenotazioneStepper);
  const userDate = useSelector((state) => state.userDate);
  const dispatch = useDispatch();

  const {fullName, firstName, lastName} = userDate
  const { ospitiPrenotazioneInfo } = stateDati;

  const showSelect = selectOptions.length > 1;

  const handleValueOspiti = (selectedValue) => {
    setSelectedUser(selectedValue);
  };


function addOspite() {
let utente = showSelect ? selectedUser : {idUtente: null, nominativo: fullName, lastName: lastName, firstName: firstName, immagineAvatar: null}
let nominativo =  showSelect ? selectedUser.nominativo : fullName

    let ospiteDaInserire = {
      utente: utente,
      nominativoSingolo: textFieldValue,
      nominativo: textFieldValue + " (" +  nominativo + ")",
    };
    dispatch(setOspitiPrenotazioneInfo(ospiteDaInserire));
    setSelectedUser({});
    setTextFieldValue("");
    setCleanSelected(!cleanSelected);
  }

  function handleCloseInternal() {
    handleClose(false)
    setTextFieldValue("");
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Ospiti</DialogTitle>
      <DialogContent>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <PannelloOspiti
              ospiti={ospitiPrenotazioneInfo}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              label="Nominativo Ospite"
              id="nominativoOspite"
              variant="standard"
              fullWidth
              value={textFieldValue}
              onChange={(event) => setTextFieldValue(event.target.value)}
            />
          </Grid>
          <Grid item xs={5} sx={{ marginTop: "3px" }}>
            {showSelect && (
              <AutocompleteCustom
                label="Utente di riferimento"
                field={["nominativo"]}
                checkField={"idUtente"}
                fullWidth={false}
                keyId={"idUtente"}
                openOnFocus={true}
                optionsAutoComplete={selectOptions}
                handleValueReturned={handleValueOspiti}
                startValueAutoComplete={selectedUser}
                clean={true}
                cleanAlways={cleanSelected}
                multiple={false}
              />
            )}
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              onClick={() => {
                addOspite();
              }}
              disabled={!textFieldValue || !selectedUser}
            >
              <AddIcon />
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleCloseInternal();
          }}
        >
          indietro
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PrenotazioniOspiti;
