export const defaultScalePlanimetria = 1;

export const handlerScale = 0.1


export function handlerZoom(event, saveStateFunction) {
  event.preventDefault();
  if (event.deltaY > 0) {
    saveStateFunction((zoomeValue) => {
      return zoomeValue < 0.5 ? zoomeValue : zoomeValue - handlerScale;
    });
  } else {
    saveStateFunction((zoomeValue) => {
      return zoomeValue > 3 ? zoomeValue : zoomeValue + handlerScale;
    });
  }
}

let countClick = 0;
export function handlerMobileZoom(e, params) {
  e.preventDefault();
  const { saveStateFunction, refContainer} = params;


  if(countClick == 0) {
    saveStateFunction(1)
    refContainer.current.scrollTop = refContainer.current.scrollTop;
    refContainer.current.scrollLeft = refContainer.current.scrollLeft;
  } else if (countClick == 1) {
    saveStateFunction(1.5)
    refContainer.current.scrollTop = refContainer.current.scrollTop;
    refContainer.current.scrollLeft = refContainer.current.scrollLeft
  } else {
    saveStateFunction(0.5)
    refContainer.current.scrollTop = refContainer.current.scrollTop;
    refContainer.current.scrollLeft = refContainer.current.scrollLeft
    countClick = 0;
    return;
  }
  countClick++;
 }



 export function calcolaStato(scaleZoom) {

   let value = defaultScalePlanimetria - scaleZoom;
   value = Math.round(value * 10) / 10

   return -(value)

 }