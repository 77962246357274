import React, { useState, useEffect } from "react";
import axios from "axios";
import { dialogPageTypes } from "../../const/dialogPageTypes";
import { Grid, Divider, Typography } from "@mui/material";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import TableToolbar from "../UI/TableToolbar/TableToolbar";
import { styled } from "@mui/material/styles";
import { CDCard, CDCardContent, CDCardHeader } from "../../const/CDCardStyles";
import CheckValidSortModel from "../../functions/CheckValidSortModel";
import {
  BuildingHeader,
  BuildingHeaderMobile,
} from "../../const/DatatableData";
import {
  getBuildingPaginated,
  exportUsers,
  getUsers,
  exportBuildings,
} from "../../const/urlAction";
import { useSelector, useDispatch } from "react-redux";
import {
  closeDialog,
  openEmptyDialog,
  setPageType,
} from "../../redux/reducers/DialogGeneral";
import BuildingDialog from "../Dialog/BuildingDialog/BuildingDialog";
import handlerError from "../../functions/handlerError";
import { notificationOpen } from "../../redux/reducers/notification-reducer";
import { loadUsersState } from "../../redux/reducers/usersSlice-reducer";
import Notification from "../UI/Notification/Notification";
import { removeDataGridHeader } from "../../functions/handlerDataGrid";
import useCheckDisabilitazioniSuPostazioni from "../../Hooks/useCheckDisabilitazioniSuPostazioni";
import { TitleDatable } from "../TitleDatable/TitleDataTable";
import BuildingDialogRefactored from "../Dialog/BuildingDialog/BuildingDialogRefactored";
import { multiDialogAction } from "../../const/Dialog/MultiDialogAction";
import { closeDialogBuilding } from "../../redux/reducers/DialogBuilding-reducer";

const typePage = "Edifici";
const urlBase = process.env.REACT_APP_URL;
const columns = BuildingHeader.headers;

const Building = () => {
  const dispatch = useDispatch();
  const dialogState = useSelector((state) => state.dialogBuilding);
  const state = useSelector((state) => state.userDate.policies);
  const reloading = useSelector((state) => state.dataTableBuildings);
  const [searchFilter, setSearchFilter] = useState("");
  const [clicked, setClicked] = useState({ type: "", count: 0, rowInfo: [] });
  const [elementoToDialog, setElementoToDialog] = useState({});
  const [searchStatus, setSearchStatus] = useState(false);
  const [page, setPage] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const viewState = useSelector((state) => state.viewState);
  const [pageSize, setPageSize] = useState(10);
  const [rows, setRows] = useState([]);
  const [rowsCount, setRowsCount] = useState(rows.length);
  const [loading, setLoading] = useState(false);
  const [sortModel, setSortModel] = useState([
    {
      field: BuildingHeader.initialFieldName,
      sort: BuildingHeader.initialFieldOrder,
    },
  ]);

  const StyledDataGrid = removeDataGridHeader(DataGrid, viewState);

  const handleSearchFilter = (searchText) => {
    setSearchFilter(searchText);
    setSearchStatus(!searchStatus);
  };


  useEffect(() => {
    let isMounted = true;
    const { sortField, sortOrder } = CheckValidSortModel(
      sortModel,
      BuildingHeader.initialFieldName
    );

    (async () => {
      setLoading(true);

      const { action, method } = getBuildingPaginated;
      let url = urlBase + action;

      const requestBody = {
        pageIndex: page,
        pageSize: pageSize,
        sortColumnName: sortField,
        sortDirection: sortOrder,
        applyPaginationAndSort: true,
        searchFilter: searchFilter,
      };
      try {        
        const response = await axios({
          method: method,
          url,
          data: requestBody,
        });
        if (isMounted) {
          const newRows = response.data.result.result;
          const newRowsCount = response.data.result.totalFiltered;
          setRows(newRows);
          setRowsCount(newRowsCount);

          if (!response.data.ok) {
            dispatch(
              notificationOpen({
                message: response.data.message,
                status: "success",
              })
            );
          }
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        const notificationError = handlerError(error);
        dispatch(notificationOpen(notificationError));
        setLoading(false);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [page, pageSize, sortModel, searchStatus, searchFilter, reloading]);

  useEffect(() => {
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  });

  const handleResizeWindow = () => {
    if (window.innerWidth > 600) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  };

  //gestisce lo stato della dialog dal contexto perchè il BoxButton non ha l'accesso diretto
  useEffect(() => {
    if (dialogState.count > 0) {
      setClicked((old) => {
        return { type: dialogState.type, count: old.count + 1 };
      });
      setElementoToDialog(dialogState.rowInfo);
    }
  }, [dialogState]);

    //si assicura che all'apertura del componente la Dialog sia chiusa.
useEffect(() => {
  return () => {
    dispatch(closeDialogBuilding())
  }
},[])

  return (
    <>
      {isMobile || (!isMobile && viewState) ? (
        <>
          <Grid container>
            <Grid item component={CDCard} xs={12} sm={12}>
              <CDCardHeader
                className="CDCardHeader-Padding"
                title={
                  <>
                    <TitleDatable nameDataTable="Strutture sanitarie" />
                  </>
                }
              />
              <Divider />
              <CDCardContent>
                <Grid container item>
                  <StyledDataGrid
                    rows={rows}
                    rowCount={rowsCount}
                    getRowId={(row) => row.sigla}
                    columns={BuildingHeaderMobile.headers}
                    page={page}
                    pageSize={pageSize}
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    onSortModelChange={(newSortModel) =>
                      setSortModel(newSortModel)
                    }
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    disableSelectionOnClick
                    sortModel={sortModel}
                    sortingMode="server"
                    sortingOrder={["asc", "desc"]}
                    paginationMode="server"
                    loading={loading}
                    sx={{ border: 0 }}
                    disableColumnMenu
                    rowHeight={120}
                    disableColumnFilter
                    disableColumnSelector
                    autoHeight
                    autoWidth
                    components={{
                      Toolbar: () => (
                        <TableToolbar
                          typePage={typePage}
                          enableAdd={true}
                          isSalaRiunione={false}
                          enableSearch={true}
                          enableExport={true}
                          handleAdd={() => {
                            setClicked((old) => {
                              return {
                                type: multiDialogAction.add,
                                count: old.count + 1,
                              };
                            });
                          }}
                          initialSearchText={searchFilter}
                          startSearch={handleSearchFilter}
                        />
                      ),
                      NoRowsOverlay: () => (
                        <GridOverlay>
                          <Typography>Nessun elemento</Typography>
                        </GridOverlay>
                      ),
                      NoResultsOverlay: () => (
                        <GridOverlay>
                          <Typography>Nessun elemento</Typography>
                        </GridOverlay>
                      ),
                    }}
                  />
                </Grid>
              </CDCardContent>
            </Grid>
          </Grid>
          <Notification />
        </>
      ) : (
        <>
          <Grid container>
            <Grid item component={CDCard} xs={12} sm={12}>
              <CDCardHeader
                className="CDCardHeader-Padding"
                title={
                  <>
                    <TitleDatable nameDataTable="Strutture Sanitarie" />
                  </>
                }
              />
              <Divider />
              <CDCardContent>
                <Grid container item>
                  <StyledDataGrid
                    rows={rows}
                    rowCount={rowsCount}
                    getRowId={(row) => row.id}
                    columns={BuildingHeader.headers}
                    page={page}
                    pageSize={pageSize}
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    onSortModelChange={(newSortModel) =>
                      setSortModel(newSortModel)
                    }
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    disableSelectionOnClick
                    sortModel={sortModel}
                    sortingMode="server"
                    sortingOrder={["asc", "desc"]}
                    paginationMode="server"
                    loading={loading}
                    sx={{ border: 0 }}
                    disableColumnMenu
                    disableColumnFilter
                    disableColumnSelector
                    autoHeight
                    autoWidth
                    components={{
                      Toolbar: () => (
                        <TableToolbar
                          typePage={typePage}
                          enableAdd={true}
                          isSalaRiunione={false}
                          enableSearch={true}
                          enableExport={true}
                          handleAdd={() => {
                            setClicked((old) => {
                              return {
                                type: multiDialogAction.add,
                                count: old.count + 1,
                              };
                            });
                          }}
                          initialSearchText={searchFilter}
                          startSearch={handleSearchFilter}
                        />
                      ),
                      NoRowsOverlay: () => (
                        <GridOverlay>
                          <Typography>Nessun elemento</Typography>
                        </GridOverlay>
                      ),
                      NoResultsOverlay: () => (
                        <GridOverlay>
                          <Typography>Nessun elemento</Typography>
                        </GridOverlay>
                      ),
                    }}
                  />
                </Grid>
              </CDCardContent>
            </Grid>
          </Grid>
          <Notification />
        </>
      )}
      {clicked.count > 0 && (
        <BuildingDialogRefactored
          clicked={clicked}
          rowInfo={elementoToDialog}
        />
      )}
    </>
  );
};

export default Building;
