import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import React from "react";

export default function PannelloRiepilogativo({ infoPannelloRiepilogativo }) {
  
  const { dataFinePrenotazione, dataInizioPrenotazione } = infoPannelloRiepilogativo;
  return (
    <React.Fragment>
      <Grid item xs={12} sm={12}>
        <Box className="boxElementi-Planimetria flex-center-center" sx={{ width: "100%" }}>
          <Typography>
            <i>Fascia oraria{" "}
            <b>{moment(dataInizioPrenotazione).format("HH:mm")}</b>{" - "}
            <b>{moment(dataFinePrenotazione).format("HH:mm")}</b> del{" "}
            <b> {moment(dataInizioPrenotazione).format("DD/MM/YYYY")}{" "}</b></i>
          </Typography>
        </Box>
      </Grid>
    </React.Fragment>
  );
}
