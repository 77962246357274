import "../panoramica.css";
import React from "react";
import { Card, Grid, Popover, Tooltip, Typography } from "@mui/material";
import ViewRowDisponibilita from "./ViewRowDisponibilita";
import { useEffect } from "react";
import { useState } from "react";
import {
  GetAllElelementsWithPrenotazioniCount,
  GetSchedulerPrenotazioni,
} from "../../../const/urlAction";
import axios from "axios";
import handlerError from "../../../functions/handlerError";
import { useDispatch, useSelector } from "react-redux";
import { notificationOpen } from "../../../redux/reducers/notification-reducer";
import PathViewDisponibilita from "./PathViewDisponibilita/PathViewDisponibilita";
import SetActionOnDisponibilita from "../../../functions/disponibilita/setActionOnDisponibilita";
import { Tab, Tabs } from "@mui/material";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import moment from "moment";
import { openDialog } from "../../../redux/reducers/DialogGeneral";
import InputSearchDisponibilita from "./InputSearchDisponibilita";
import { setIsSalaRiunione, isSalaRiunione} from "../../../redux/reducers/filterInitial-reducer"


const urlBase = process.env.REACT_APP_URL;
export default function CardViewDisponibilita({
  sendData,
  dateSelected = "",
  timeSelected = "",
  activeBackStepPath,
  handlerStateDialogMappaDisponibilita = () => null,
  returnElementToDialogMostraMappaDisponibilita = () => null,
  isSideEffectCompleted = () => null,
  setPrenotazioneModalOpen = () => null,
  elementClickedMaps,
  changeClickMaps,
  setElementClickedMaps = () => null
}) {
  const [element, setElement] = useState(null);
  const [elClicked, setElClicked] = useState(undefined);
  const [lengthArrElementPath, setLengthArrElementPath] = useState(0);
  const [isGoodChoice, setisGoodChoice] = useState(false);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const isAdmin = false;
  const [isResponsabileEdificio, setisResponsabileEdificio] = useState(false);
  const state = useSelector((state) => state.userDate);
  const [lastTimeStart, setTimeStart] = useState(null);
  const [lastTimeEnd, setTimeEnd] = useState(null);
  const [lastDate, setDate] = useState(null);
  const [resurces, setResurces] = useState(null);
  const [events, setEvents] = useState(null);
  const [idEdificio, setIdEdificio] = useState(null);
  const [idZona, setIdZona] = useState(null);
  const [isCliccato, setisCliccato] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popupTitle, setPopupTitle] = useState(null);
  const [popupText, setPopuoText] = useState(null);
  const [popupTime, setPopupTime] = useState(null);
  const isSubmenuOpen = Boolean(anchorEl);
  const [searchFilter, setSearchFilter] = useState(null);
  const isSalaRiunione = useSelector((state) => state.filter.isSalaRiunione);


  //se avviene un click su un marker della mappa imposto l elemento cliccato
  useEffect(() => {    
    setElClicked(elementClickedMaps)
  }, [changeClickMaps])
  

  useEffect(() => {
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  });

  const handleResizeWindow = () => {
    if (window.innerWidth > 600) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  };



  const handleClick = (eventInfo) => {
    setAnchorEl(eventInfo.jsEvent.target);
    setPopuoText(document.querySelector("[data-resource-id='"+eventInfo?.event?._def?.resourceIds[0] +"']").querySelector(".fc-datagrid-cell-main").textContent)
    setPopupTitle(eventInfo?.event?.title)
    var start = moment(eventInfo?.event._instance.range.start)
    var end = moment(eventInfo?.event._instance.range.end)
    setPopupTime(start.utcOffset(-7200000).format("HH:mm") +"-"+ end.utcOffset(-7200000).format("HH:mm"))
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  //Chiamata per il recupero degli elementi
  useEffect(() => {
    (async () => {
      const { action, method } = GetAllElelementsWithPrenotazioniCount;
      isSideEffectCompleted(false);
      let url = urlBase + action;
      if (timeSelected.length > 0) {
        const body = SetActionOnDisponibilita(
          dateSelected,
          timeSelected,
          elClicked?.subElementType,
          elClicked?.id,
          searchFilter,
          isSalaRiunione
        );
          // console.log(body)
        try {
          const response = await axios({
            method: method,
            url,
            data: body,
            headers: {
              "Content-Type": "application/json",
            },
          });
          // console.log(elClicked)
          if (body.IdStanza) {
            setIdEdificio(null)
            dispatch(openDialog("Homepage"));
            // setPrenotazioneModalOpen(true)
            //per mostrare planimetria
            // returnElementToDialogMostraMappaDisponibilita(response.data.result);
          } else {
            if(response.data.result.listDeskBookingElement.length>0){
              setElement(response.data.result);
            }
            else{
              setElement(null)
            }
            setisGoodChoice(true);
            if (
              lastDate != dateSelected ||
              lastTimeStart != timeSelected[0] ||
              lastTimeEnd != timeSelected[1]
            ) {
              setDate(dateSelected);
              setTimeEnd(timeSelected[1]);
              setTimeStart(timeSelected[0]);
            } else {
              changeTab(null, 0);
            }

            if (elClicked != null || elClicked != undefined) {
              if (elClicked.subElementType === 1) {
                setIdEdificio(elClicked.id);
                setIdZona(null)
              }
              if (elClicked.subElementType === 2) {
                setIdZona(elClicked.id);
              }
            }

            if (isCliccato === true && activeTab === 1) {
              GetResultScheduler(
                dateSelected,
                timeSelected,
                idEdificio,
                idZona
              );
            }
          }

          sendData(response.data.result);
        } catch (error) {
          console.error(error);
          const notificationError = handlerError(error);
          dispatch(notificationOpen(notificationError));
          setisGoodChoice(false);
        }
        isSideEffectCompleted(true);
      }
    })();
  }, [elClicked, dateSelected, timeSelected, searchFilter]);

  //chiamata per popolamento dello scheduler
  function GetResultScheduler(dateSelected, timeSelected, idEdificio, idZona) {
    (async () => {
      const { action, method } = GetSchedulerPrenotazioni;
      let url = urlBase + action;
      if (timeSelected.length > 0) {
        const body = {
          GiornoInizio: dateSelected,
          OraInizio: timeSelected[0].toString(),
          OraFine: timeSelected[1].toString(),
          IdEdificio: idEdificio,
          IdZona: idZona,
        };
        try {
          const response = await axios({
            method: method,
            url,
            data: body,
            headers: {
              "Content-Type": "application/json",
            },
          });
          let evetsScheduler = [];
          response.data.result.events.forEach((e) => {
            let arr = [];
            arr.resourceId = e.id;
            arr.title = e.utentePrenotazione;
            arr.start = e.dataInizio.replace("T", " ");
            arr.end = e.dataFine.replace("T", " ");
            if (e.isDisabilitazione === true) {
              arr.title = "Non disponibile";
              arr.backgroundColor = "gray";
              arr.borderColor = "gray";
            } else {
              arr.backgroundColor = "#026D82";
            }
            evetsScheduler.push(arr);
          });
          setEvents(evetsScheduler);
          let resourcesScheduler = [];
          response.data.result.resources.forEach((e) => {
            let arr = [];
            arr.id = e.id;
            arr.stanza = e.stanza;
            arr.title = e.postazione;
            resourcesScheduler.push(arr);
          });
          setResurces(resourcesScheduler);
        } catch (error) {
          console.error(error);
          const notificationError = handlerError(error);
          dispatch(notificationOpen(notificationError));
        }
      }
    })();
  }

  function changeTab(event, value) {
    setActiveTab(value);
    if (value === 1) {
      GetResultScheduler(dateSelected, timeSelected, idEdificio, idZona);
      setisCliccato(true);
    }
  }

  function a11yProps(index) {
    /*props necessarie alle tab */
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function RenderTab(isAdmin) {
    setisResponsabileEdificio(false);
    if (isAdmin.state.roles !== null || isAdmin.state.roles !== undefined) {
      if (
        isAdmin.state.roles.length > 0 ||
        isAdmin.state.responsabileEdificio.length > 0
      )
        if (state.roles[0] === "Amministratore") {
          isAdmin = true;
        } else {
          isAdmin = false;
        }
      if (state.responsabileEdificio.length > 0) {
        state.responsabileEdificio.forEach((x) => {
          if (x.idEdificio == idEdificio) {
            setisResponsabileEdificio(true);
          }
        });
      } else {
        setisResponsabileEdificio(false);
      }
    } else {
      isAdmin = false;
    }
    if ((isAdmin === true || isResponsabileEdificio) && !isMobile) {
      return (
        <>
          <Tabs item xs={2} value={activeTab} onChange={changeTab} centered>
            <Tab
              label="PANORAMICA"
              {...a11yProps(0)}
              style={{ width: "50%" }}
            />
            <Tab
              label="DETTAGLIO"
              disabled={isMobile}
              {...a11yProps(1)}
              style={{ width: "50%" }}
            />
            {/*<Tab disabled={isToday} label={!isToday && "torna a oggi"} onClick={resetToday} />}
              //rimettere questo reset sotto forma di button altrove nel template, farsi dire dove*/}
          </Tabs>
        </>
      );
    } else {
      return <></>;
    }
  }

  return (
    <>
      <InputSearchDisponibilita setSearchFilter={setSearchFilter}></InputSearchDisponibilita>
      {element && (
        <div
          style={{ width: "100%", height: "auto" }}
          className="cardDisponibilita"
        >
          {/* mostra la tab se l'utente è admin */}
          {element.listDeskBookingElement[0].subElementType == 3 && (
            <Grid>
              <RenderTab isAdmin={isAdmin} state={state} />
            </Grid>
          )}
          <PathViewDisponibilita
            el={isGoodChoice ? elClicked : null}
            returnNewPath={setElClicked}
            returnLenghtArrElementPath={setLengthArrElementPath}
            activeBackStepPath={activeBackStepPath}
            handlerStateDialogMappaDisponibilita={
              handlerStateDialogMappaDisponibilita
            }
            elementClickedMaps={elementClickedMaps}
            setElementClickedMaps={setElementClickedMaps}
          />

          {element.listDeskBookingElement.filter((ed) => ed.subElementType !== 3 || ed.isSalaRiunioni === isSalaRiunione).map((ed, index) => {
            return (
              <React.Fragment key={index}>
                {activeTab === 0 && (
                  <ViewRowDisponibilita
                    element={ed}
                    percentStatusConfiguration={
                      element.percentStatusConfiguration
                    }
                    returnIdElementClicked={setElClicked}
                    lengthArrElementPath={lengthArrElementPath}
                  />
                )}
              </React.Fragment>
            );
          })}
        </div>
      )}
      {activeTab === 1 && (
        <Card
          style={{ width: "100%", marginLeft: "10px", marginRight: "10px" }}
        >
          <FullCalendar
            schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
            headerToolbar={{
              left: "  ",
              center: "  ",
              right: "  ",
            }}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              resourceTimelinePlugin,
            ]}
            initialView="resourceTimelineDay"
            resourceOrder={"stanza"}
            resourceGroupField="stanza"
            resourceAreaHeaderContent=" "
            height="500px"
            slotMaxTime={timeSelected[1] + ":00"}
            slotMinTime={timeSelected[0] + ":00"}
            validRange={{
              start: dateSelected,
              end: dateSelected,
            }}
            eventColor={"#026D82"}
            slotLabelFormat={{
              hour: "numeric",
              minute: "2-digit",
              omitZeroMinute: false,
              meridiem: false,
              hour12: false,
            }}
            resources={resurces}
            events={events}
            resourceAreaWidth="15%"
            eventClick={
              (eventInfo) => handleClick(eventInfo)
            }
          />
        </Card>
      )}

          <Popover
           open={isSubmenuOpen}
           anchorEl={anchorEl}
           onClose={handleClose}
           anchorOrigin={{
             vertical: "bottom",
             horizontal: "left"
           }}>
            <Grid container>
              <div style={{padding:"0 0px 0px 0px", width:'15rem'}}>
                <Grid item xs={12} sx={{ backgroundColor: '#026D82', color: '#F9F9FA', paddingLeft:'5px'}}  className="flex-center-left">
                  <Typography className="header-submenu-planimetriaInfo">
                  <b>{popupText}</b>
                  </Typography>
                </Grid>
                <Typography>
                  <Grid container item xs={12} className="containerSubmenu" sx={{ p: 0.5 }}>
                    {popupTitle}
                  </Grid>
                  <Grid container item xs={12} className="containerSubmenu" sx={{ p: 0.5 }}>
                    {popupTime}
                  </Grid>
                </Typography>
            </div> 
          </Grid>
        </Popover> 
    </>
  );
}
