import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Switch,
  TextField,
  Typography,
  FormControlLabel,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { dialogButtonRiepilogo } from "../../../const/DialogData";
import { saveUser } from "../../../redux/reducers/user-reducer";
import {
  UpdateAlertPrenotazioniEsaurite,
  UpdateSingleBookingConfirmationEmailEnabled,
} from "../../../const/urlAction";
import axios from "axios";
import { notificationOpen } from "../../../redux/reducers/notification-reducer";
import { styled } from "@mui/material/styles";
import AvatarImage from "../../Avatar/AvatarImage";
import { useTheme } from "@emotion/react";
import "../Dialog.css";
import { Box } from "@mui/system";

const urlBase = process.env.REACT_APP_URL;

export default function DettaglioUtenteDialog({
  dialogState,
  returnCloseDialog,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const infoUser = useSelector((state) => state.userDate);
  const backButtonState = useSelector((state) => state.dialogBackButton);
  const dispatch = useDispatch();

  const {
    email,
    firstName,
    lastName,
    phoneNumber,
    roles,
    fullName,
    utenteResponsabileUtenti,
    responsabileEdificio,
    alertPrenotazioniEsaurite,
    singleBookingConfirmationEmailEnabled,
  } = infoUser;

  const [checkedPrenotazioniEsaurite, setCheckedPrenotazioniEsaurite] =
    useState(alertPrenotazioniEsaurite);
  const [
    checkedSingleBookingConfirmationEmailEnabled,
    setCheckedSingleBookingConfirmationEmailEnabled,
  ] = useState(singleBookingConfirmationEmailEnabled);

  function closeDialogState() {
    returnCloseDialog(false);
  }

  const MaterialUISwitch = styled(Switch)(({ theme, checked = false }) => ({
    width: 62,
    height: 34,
    padding: 7,
    "& .MuiSwitch-switchBase": {
      margin: 1,
      padding: 0,
      transform: "translateX(6px)",
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(22px)",
        "& .MuiSwitch-thumb:before": {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path fill="${encodeURIComponent(
            "#ffffff"
          )}" d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0l57.4-43c23.9-59.8 79.7-103.3 146.3-109.8l13.9-10.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176V384c0 35.3 28.7 64 64 64H360.2C335.1 417.6 320 378.5 320 336c0-5.6 .3-11.1 .8-16.6l-26.4 19.8zM640 336a144 144 0 1 0 -288 0 144 144 0 1 0 288 0zm-76.7-43.3c6.2 6.2 6.2 16.4 0 22.6l-72 72c-6.2 6.2-16.4 6.2-22.6 0l-40-40c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L480 353.4l60.7-60.7c6.2-6.2 16.4-6.2 22.6 0z"/></svg>')`,
        },
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#026D82" : "#026D82",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: theme.palette.mode === "dark" ? "#026D82" : "#026D82",
      width: 32,
      height: 32,
      "&:before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: checked ? '2px': 0,
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path fill="${encodeURIComponent(
          "#ffffff"
        )}" d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg>')`,
      },
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      borderRadius: 20 / 2,
    },
  }));

  const changeAlertPrenotazioniEsaurite = async (event) => {
    const { action, method } = UpdateAlertPrenotazioniEsaurite;
    let url = urlBase + action;

    try {
      const response = await axios({
        method: method,
        url,
        headers: {
          "Content-Type": "application/json",
        },
      });
      setCheckedPrenotazioniEsaurite(!event.target.checked);
      dispatch(saveUser(infoUser));
    } catch (error) {
      console.error(error);
      setCheckedPrenotazioniEsaurite(!event.target.checked);
      if (error.response) {
        dispatch(
          notificationOpen({
            message: `${error.response.data.message}`,
            status: "error",
          })
        );
      } else {
        dispatch(
          notificationOpen({
            message: "Operazione fallita: Server non raggiungibile.",
            status: "error",
          })
        );
      }
    }
  };

  const changeSingleBookingConfirmationEmailEnabled = async (event) => {
    const { action, method } = UpdateSingleBookingConfirmationEmailEnabled;
    let url = urlBase + action;

    try {
      const response = await axios({
        method: method,
        url,
        headers: {
          "Content-Type": "application/json",
        },
      });
      setCheckedSingleBookingConfirmationEmailEnabled(!event.target.checked);
      dispatch(saveUser(infoUser));
    } catch (error) {
      console.error(error);
      setCheckedSingleBookingConfirmationEmailEnabled(!event.target.checked);
      if (error.response) {
        dispatch(
          notificationOpen({
            message: `${error.response.data.message}`,
            status: "error",
          })
        );
      } else {
        dispatch(
          notificationOpen({
            message: "Operazione fallita: Server non raggiungibile.",
            status: "error",
          })
        );
      }
    }
  };

  //si occupa di gestire la chiusura della dialog attraverso il backButton
  useEffect(() => {
    closeDialogState("V");
  }, [backButtonState]);

  return (
    <>
      <Dialog
        open={dialogState}
        fullScreen={fullScreen}
        fullWidth={true}
        // aria-labelledby="alert-dialog-title"
        // aria-describedby="alert-dialog-description"
        //maxWidth={false}
        maxWidth="sm"
      >
        <DialogTitle>Account personale</DialogTitle>
        <DialogContent className="border-Content-dialog">
          <Grid container rowSpacing={1} columnSpacing={2}>
            <AvatarImage
              size={100}
              name={infoUser.firstName + " " + infoUser.lastName}
              isInputDisabled={false}
              isCentered={true}
              isUserLoggedImage={true}
             />
            <Grid item xs={12} sm={12}>
              <TextField
                margin="dense"
                id="Username"
                label="Email"
                type="text"
                fullWidth
                variant="standard"
                defaultValue={email}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                id="FirstName"
                label="Nominativo"
                type="text"
                fullWidth
                variant="standard"
                defaultValue={fullName}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                id="PhoneNumber"
                label="Telefono"
                type="text"
                fullWidth
                variant="standard"
                defaultValue={phoneNumber}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                margin="dense"
                id="roles"
                label="Ruolo"
                type="text"
                fullWidth
                variant="standard"
                defaultValue={roles.join(", ")}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            {responsabileEdificio.length > 0 && (
              <Grid item xs={12} sm={12}>
                <TextField
                  margin="dense"
                  id="roles"
                  label="Strutture sanitarie associate"
                  type="text"
                  fullWidth
                  variant="standard"
                  defaultValue={responsabileEdificio
                    .map((x) => x.nominativoEdificio)
                    .join(", ")}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
            )}
            {utenteResponsabileUtenti.length > 0 && (
              <Grid item xs={12} sm={12}>
                <TextField
                  margin="dense"
                  id="roles"
                  label="Utenti associati"
                  type="text"
                  fullWidth
                  variant="standard"
                  defaultValue={utenteResponsabileUtenti
                    .map((x) => x.nominativo)
                    .join(", ")}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
            )}
          </Grid>

          <Typography
            style={{
              backgroundColor: "none",
              paddingLeft: "0px",
              padding: "16px 0px",
              fontSize: "1.25rem",
              fontWeight: "bold",
              color: "#026D82",
              backgroundColor: "none",
            }}
          >
            Preferenze
          </Typography>
          <Grid container rowSpacing={3} columnSpacing={2}>
            <Grid item xs={12} sm={12}>
              <FormControlLabel
                control={
                  <MaterialUISwitch
                    checked={checkedPrenotazioniEsaurite}
                    onChange={(event) => changeAlertPrenotazioniEsaurite(event)}
                  />
                }
                label={
                  <Typography>
                    Avvisami quando sto per terminare le prenotazioni
                  </Typography>
                }
              ></FormControlLabel>
            </Grid>

            <Grid item xs={12} sm={12}>
              <FormControlLabel
                control={
                  <MaterialUISwitch
                    checked={checkedSingleBookingConfirmationEmailEnabled}
                    onChange={(event) =>
                      changeSingleBookingConfirmationEmailEnabled(event)
                    }
                  />
                }
                label={
                  <Typography>
                    Abilita notifica email per le tue prenotazioni singole
                  </Typography>
                }
              ></FormControlLabel>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => closeDialogState()}>
            {dialogButtonRiepilogo.Chiudi}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
