import React from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Autocomplete, Checkbox, Grid } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import TextField from "@mui/material/TextField";
import { useEffect, useState, Fragment } from "react";
import AvatarImage from "../../Avatar/AvatarImage";

export function AutocompleteCustom({
  label = "Default Label",
  field = ["id"],
  checkField = "id",
  keyId = "id",
  showAvatar = true,
  optionsAutoComplete = [],
  multiple = false,
  handleValueReturned = () => null,
  isDisabled = false,
  disableCloseOnSelect,
  fullWidth = true,
  startValueAutoComplete = multiple ? [] : {},
  errorMsg = "",
  clean = false,
  cleanAlways, //permette il reload 
}) {
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(multiple ? [] : {});

  useEffect(() => {
    handleValueReturned(selectedValue);
  }, [selectedValue]);


  useEffect(() => {
    setOptions(optionsAutoComplete);
    setSelectedValue(startValueAutoComplete);
  }, [optionsAutoComplete]);

  const handleChangeObjSelect = (event, value) => {
    setSelectedValue(value);
  };

  useEffect(() => {
    if (clean) setSelectedValue(null);
  }, [clean,cleanAlways]);

  return (
    <React.Fragment>
      <Autocomplete
        disabled={isDisabled}
        multiple={multiple}
        id="roles"
        size="small"
        disableCloseOnSelect={disableCloseOnSelect ?? multiple}
        options={options}
        value={selectedValue}
        limitTags={1}
        fullWidth={fullWidth}
        isOptionEqualToValue={(option, value) =>
          option[checkField] === value[checkField]
        }
        getOptionLabel={(option) =>
          field
            .map((field, index) => {
              const optionField =
                field === "sigla" ? "(" + option[field] + ")" : option[field]; //da Dinamicizzare
              return option[field] === undefined ? "" : optionField;
            })
            .join(" ")
        }
        onChange={handleChangeObjSelect}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option[keyId]}>
            <div>{options[keyId]?.firstName}</div>
            <Checkbox
              key={option[keyId]}
              icon={
                <CheckBoxOutlineBlankIcon
                  key={option[keyId]}
                  fontSize="small"
                />
              }
              checkedIcon={
                <CheckBoxIcon key={option[keyId]} fontSize="small" />
              }
              style={{ marginRight: 8 }}
              checked={selected}
            />
            <div style={{ marginRight: "10px" }}>
              { showAvatar && <AvatarImage
                size={33}
                isInputDisabled={true}
                isCentered={false}
                isUserLoggedImage={false}
                ImmagineAvatar={option.immagineAvatar}
                name={option.firstName + " " + option.lastName}
              />}
            </div>
            {field.map((field, index) => {
              const optionField =
                field === "sigla" ? "(" + option[field] + ")" : option[field]; //da Dinamicizzare
              return <Fragment key={index}>{optionField} </Fragment>;
            })}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            error={errorMsg === "" ? false : true}
            helperText={errorMsg}
            {...params}
            label={label}
            variant="standard"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>{params.InputProps.endAdornment}</Fragment>
              ),
            }}
          />
        )}
      />
    </React.Fragment>
  );
}
