import ZoneDatatable from "../../components/ZoneDatatable/ZoneDatatable";
const ZonePanel = () => {

return (
    <>
    <ZoneDatatable></ZoneDatatable>
    </>
)

}

export default ZonePanel