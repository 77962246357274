import { createSlice } from '@reduxjs/toolkit';

const initialState = {isOpen:false};

const dialogCambioPassword = createSlice({
    name:'dialogDispositivo',
    initialState,
    reducers: {
         openDialogCambioPassword:(state) => {
             return {...state, isOpen:true}
         },
        closeDialog: (state) => { 
            return {...state, isOpen: false};
         }
    }
})

export const { openDialogCambioPassword, closeDialog  } = dialogCambioPassword.actions;

const { reducer } = dialogCambioPassword;

export default reducer;