import { createSlice } from '@reduxjs/toolkit';

const initialState = { loading: false };

const appLoaderSlice = createSlice({
    name: 'appLoader',
    initialState,
    reducers: {
        showLoader: (state) => {
            return { ...state, loading: true };
        },
        hideLoader: (state) => {
            return { ...state, loading: false };
        },
    }
});

export const { showLoader, hideLoader } = appLoaderSlice.actions;

const { reducer } = appLoaderSlice;

export default reducer;