import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, Navigate } from "react-router-dom";
import { validationRegistrationRequestOtp } from "../../../../const/urlAction";
import { hideLoader, showLoader } from "../../../../redux/reducers/appLoader-reducer";
import ResponseConvalidaRichiestaCredenziali from "../../ConvalidaRichiestaCredenziali/ConvalidaRichiestaCredenzialiResponse";


const urlBase = process.env.REACT_APP_URL;
export default function ConvalidaOtpRichiestaCredenziali() {
    const [statoConvalidaOtp, setStatoConvalidaOtp] = useState(null);
    const search = useLocation().search;
    const richiesta = new URLSearchParams(search).get('r')
    const otp = new URLSearchParams(search).get('o')
    const dispatch = useDispatch();

    const userDate = localStorage.getItem("deskbooking");
    const objUserDate = JSON.parse(userDate);

useEffect(() => {
if(richiesta && otp && objUserDate == null) {
    (async () => {
        let body = {
            r:richiesta,
            o:otp
        };
    dispatch(showLoader());
      const { action, method } = validationRegistrationRequestOtp;
      let url = urlBase + action+"?r=" + richiesta+"&o=" + otp;
      try {
        const response = await axios({
          method: method,
          url,
          data: body,
        });
        if (response.data.ok) {
            setStatoConvalidaOtp(response.data.ok)
        }
      } catch (error) {
        console.error(error);
        setStatoConvalidaOtp(false)
      }
      dispatch(hideLoader());
    }
    )();
   }
  }, [richiesta, otp]);


  return objUserDate != null && Object.entries(objUserDate).length !== 0 ? (
    <Navigate to="/prenotazioni" />
  ) : (
      <ResponseConvalidaRichiestaCredenziali outCome={statoConvalidaOtp} />
  )
}