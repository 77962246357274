import { Typography } from "@mui/material";

export const roleWarning = "L'associazione di almeno un ruolo consentirà all'utente di effettuare l'accesso all'applicazione";
export const credenzialiDiDominioWarning = "Le credenziali devono essere specificate come nel formato di esempio. Es. m.rossi";

export const UserDialogTextActiveDirectory =<>
Nella sincronizzazione con <b>credenziali di dominio</b> verranno importati da Active Directory gli utenti provvisti di una email e verranno aggiornate le loro informazioni anagrafiche.<br/>
E' possibile specificare le <b>unità organizzative (OU)</b> nelle quali applicare la ricerca.<br/>
Tutti gli utenti recuperati da Active Directory potranno, al termine della sincronizzazione, effettuare l'accesso con le proprie credenziali di dominio.<br/>
Nel caso di variazione di tipologia di autenticazione tali utenti verranno informati tramite una breve mail riepilogativa.<br/><br/>
Cliccando su conferma tale sincronizzazione sarà eseguita in background e non sarà possibile effettuare una nuova sincronizzazione fino al termine dell'operazione.</>;

export const UserDialogTextLocalDatabase = <>Nella sincronizzazione con <b>Email e Password</b> gli utenti che attualmente utilizzano le proprie credenziali di dominio potranno, al termine della sincronizzazione, accedere all'applicazione tramite Email e Password. <br/>
Tali utenti verranno informati tramite una breve mail riepilogativa contenente l'email e la nuova password generata da utilizzare.<br/><br/>
Cliccando su conferma tale sincronizzazione sarà eseguita in background e non sarà possibile effettuare una nuova sincronizzazione fino al termine dell'operazione.</>; 

export default function AvvisoFormMessage({msg="default message"}) {

    return(
        <>
        <Typography sx={{fontSize:"12px", color:"#026D82"}}>{msg}</Typography>
        </>
    )
};