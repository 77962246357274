import { useState } from "react";
import { useEffect } from "react";
import { listaPostazioneIcon } from "../../../../const/Planimetria/IconPlanimetria";
import setDateWithHourseAndMinutes from "../../../../functions/setDateWithHoursAndMinutes";
import { planimetriaUrlParamsForBackgroundPlanimetriaMostraInfo } from "../../../../functions/UrlParams";
import InfoSubMenu from "../../../Planimetria/InfoSubMenu/InfoSubMenu";
import MostraMappa from "../../../Planimetria/mostraMappa/MostraMappa";

export default function PlanimetriaDisponibilita({
  dati = [],
  dettaglioSfondoPlanimetria = {},
  idStanza = -1,
  dataSelected,
  rangeTime
}) {

    const dates = setDateWithHourseAndMinutes(dataSelected,rangeTime[0], rangeTime[1])

  let infoPannelloRiepilogativo = {dataInizioPrenotazione:dates[0], dataFinePrenotazione:dates[1]}  
  let imageBackground =
  planimetriaUrlParamsForBackgroundPlanimetriaMostraInfo(idStanza);

  function actionOnClickIcon(event, id, index, status) {
    return index //ritorna l'indice dell'elemento selezionato
}
  return (
    <>
      <MostraMappa
        dettaglioSfondoPlanimetria={dettaglioSfondoPlanimetria}
        infoPannelloRiepilogativo={infoPannelloRiepilogativo}
        elementiDalDb={dati}
        imageBackground={imageBackground}
        IconsToCreate={listaPostazioneIcon}
        actionOnClickButton={actionOnClickIcon}
        SubMenu={InfoSubMenu}
        dataSelected={dataSelected}
      />
    </>
  );
}
