import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  useMediaQuery,
} from "@mui/material";
import CustomStepper from "../CustomStepper";
import { useTheme } from "@emotion/react";
import React from "react";
export default function DialogStepper({ isOpen = false, saltaAllUltimoStep}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Dialog
        open={isOpen}
        fullScreen={fullScreen}
        fullWidth={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        //maxWidth={false}
        maxWidth="md"
      >
        <Grid>
          <Grid item></Grid>
          <Grid item>
            <DialogTitle>Prenotazione</DialogTitle>
            <DialogContent sx={{ paddingBottom: "10px" }} >
              <CustomStepper saltaAllUltimoStep={saltaAllUltimoStep}/>
            </DialogContent>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}
