



 const aggiustamentiPlanimetriaGestione = {

    aggiustamentoPosizioneTopSuPlanimetriaGestione: 104,
    aggiustamentoPosizioneLeftSuPlanimetriaGestione: 20,
    aggiustamentoDimensioniPlanimetriaSuSchermo: 35,
    aggiustamentoVariazionePosizioneClientSuPlanimetriaGestione: 85,
}

const aggiustamentiMenuContestualePlanimetriaGestione = {

    aggiustamentoPosizioneTopSuMenuContestualePlanimetriaGestione: - 25,
    aggiustamentoPosizioneLeftSuMenuContestualePlanimetriaGestione: 20,
}

const  aggiustamentiPlanimetriaPrenotazione = {
    aggiustamentoPosizioneTopSuPlanimetriaPrenotazione: 256,
    aggiustamentoPosizioneLeftSuPlanimetriaPrenotazione: 245
}

const aggiustamentiMenuContestualePlanimetriaPrenotazione = {

    aggiustamentoPosizioneTopSuMenuContestualePlanimetriaPrenotazione: 0,
    aggiustamentoPosizioneLeftSuMenuContestualePlanimetriaPrenotazione: 50,
}

export {aggiustamentiMenuContestualePlanimetriaGestione, aggiustamentiPlanimetriaPrenotazione, aggiustamentiMenuContestualePlanimetriaPrenotazione, aggiustamentiPlanimetriaGestione};