import ButtonBoxUsers from "../components/UsersDatatable/ButtonBoxUsers";
import ButtonBoxZone from "../components/ZoneDatatable/ButtonBoxZone";
import ButtonBoxStanze from "../components/StanzeDatatable/ButtonBoxStanze";
import ButtonBoxPostazioni from "../components/PostazioniDatatable/ButtonBoxPostazioni";
import { Grid } from "@mui/material";
import moment from "moment";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import './DatatableData.css'
import { maxValueText } from "./DialogRiepilogoText";
import DoneIcon from '@mui/icons-material/Done';
import ButtonBoxBuilding from '../components/BuildingDataTable/ButtonBoxBuilding';
import CreateHeaderHomePageHeader from "../components/Datatable/UtilsDataTable/CreateHeaderHomePageHeader";
import ButtonBoxRichiesteCredenziali from "../components/RichiesteAccesso/RichiestaCredenzialiAccesso/RichiesteAccessoComponent/ButtonBoxRichiesteCredenziali";
import { faBed, faBriefcaseMedical, faDoorOpen, faHospital, faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const minWidthColumn = 160;

const StyledDataGrid = styled(DataGrid)(() => ({
  "& .MuiDataGrid-columnHeader:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-columnHeader:focus-within": {
    outline: "none",
  },
  "& .MuiDataGrid-root": {
    outline: "none !important",
  },
  "& .MuiDataGrid-cell:focus-within": {
    outline: "none !important",
  },
}));

export const HomepageHeader = {
  initialFieldName: "idPostazione",
  initialFieldOrder: "asc",
  headers: [
    {
      field: "idPostazione",
      headerName: "Postazione",
      flex: 1,
      resizable: false,
      sortable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
    },
    {
      field: "dataInizioPrenotazione",
      headerName: "Data Inizio Prenotazione",
      flex: 1,
      resizable: false,
      sortable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
    },
    {
      field: "dataFinePrenotazione",
      headerName: "Data Fine Prenotazione",
      flex: 1,
      resizable: false,
      sortable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
    }
  ],
};



export const HomepageHeaderMobile = {
  
  initialFieldName: "idPostazione",
  initialFieldOrder: "asc",
  headers: [
    {
      field: "userActionsMobile",
      headerName: "",
      type: "actions",
      flex: 1,
      
      resizable: false,
      sortable: false,
      minWidth: minWidthColumn,
      renderCell: (x) => {

       const {isSalaRiunione, utentePrenotato} = x.row;
       const componentToShow = utentePrenotato ? CreateHeaderHomePageHeader(utentePrenotato): <></>;

        return (
          <Grid
           container item
         
          xs={12}
          sm={9}
          md={6}
          lg={5}
          xl={4}
          
          >
            <Grid item xs={6} sm={8} md={8}
            > 
              <div style={{
                    alignItems: "start",
                    justifyContent: "start",
                    marginTop:"-6px"
                   
              }}>
              {componentToShow}
              <p style={{ fontSize: "16.9px", fontStyle: "italic" , margin: "0 0 5px 0" , color : "#424242"}}>
              <FontAwesomeIcon icon={faHospital} className="sm-Icon" /> {x.row.edificioSigla} 
              <FontAwesomeIcon icon={faLayerGroup}  className="sm-Icon sm-icon-3"/> {x.row.zonaSigla}   
              {!isSalaRiunione ? <FontAwesomeIcon icon={faDoorOpen}  className="sm-Icon sm-icon-2"/>
                               : <FontAwesomeIcon icon={faBriefcaseMedical}  className="sm-Icon sm-icon-4"/>}
               {x.row.stanzaSigla}
               {!isSalaRiunione ? <><FontAwesomeIcon icon={faBed} className="sm-Icon sm-icon-2"/>{x.row.postazionePosto}</>
                               : <></>}
              </p>
             
              <p
                style={{
                  fontSize: "16px",
                  fontStyle: "italic",
                  margin: "0 0 4px 0",
                  marginLeft : "0px"
                }}
              >
              <CalendarMonthIcon style={{marginLeft:"-5px", marginBottom:"-3px", color : "#026D82", height : "18px", width :"27px" }}/> {moment(x.row.dataInizioPrenotazione).format("DD/MM/YYYY HH:mm")} - {moment(x.row.dataFinePrenotazione).format("HH:mm")}
               </p>

              </div> 
              
            </Grid>
            </Grid>
     
        );
      },
      headerAlign: "left",
      align: "left",
     
    },
  ],
};

export const HomepageHeaderDisabilitazioni = {

  initialFieldName: "idPostazione",
  initialFieldOrder: "asc",
  headers: [
    {
      field: "userActionsMobile",
      headerName: "",
      type: "actions",
      flex: 1,
      
      resizable: false,
      sortable: false,
      minWidth: minWidthColumn,
      renderCell: (x) => {
        const {isSalaRiunione, utentePrenotato} = x.row;
        return (

          <Grid
           container item
         
          xs={12}
          sm={9}
          md={6}
          lg={5}
          xl={4}
          
          >
            <Grid item xs={6} sm={8} md={8}
            > 
              <div style={{
                    alignItems: "start",
                    justifyContent: "start",
                    marginTop:"-6px"
                   
              }}>
              {/* <p style={{ fontSize: "29px", fontStyle: "regular", fontWeight: "bolder" , margin: "0 0 4px 0" , color : "#424242"}}>
              <DesktopMacIcon style={{marginLeft:"-0.3px",marginBottom:"-13.9px", color : "#026D82", height : "49px", width :"27px" }}/> {x.row.postazionePosto}
               </p> */}
              <p style={{ fontSize: "16.9px", fontStyle: "italic" , margin: "0 0 5px 0" , color : "#424242"}}>
              <FontAwesomeIcon icon={faHospital} className="sm-Icon"/> {x.row.edificioSigla} 
              <FontAwesomeIcon icon={faLayerGroup}  className="sm-Icon"/> {x.row.zonaSigla}   
              {!isSalaRiunione ? <FontAwesomeIcon icon={faDoorOpen}  className="sm-Icon"/>
                               : <FontAwesomeIcon icon={faBriefcaseMedical}  className="sm-Icon sm-icon-2"/>}
               {x.row.stanzaSigla}
               {!isSalaRiunione ? <><FontAwesomeIcon icon={faBed} className="sm-Icon sm-icon-2"/>{x.row.postazionePosto}</>
                               : <></>}
              </p>
             
              <p
                style={{
                  fontSize: "16px",
                  fontStyle: "italic",
                  margin: "0 0 4px 0",
                  
                  marginLeft : "0px"
                }}
              >
              <CalendarMonthIcon style={{marginLeft:"-5px", marginBottom:"-3px", color : "#026D82", height : "18px", width :"27px" }}/>
               { 
               moment(x.row.dataInizioPrenotazione).format("DD/MM/YYYY") ===  moment(x.row.dataFinePrenotazione).format("DD/MM/YYYY") 
               ? moment(x.row.dataInizioPrenotazione).format("DD/MM/YYYY HH:mm") + " - " + moment(x.row.dataFinePrenotazione).format("HH:mm")
               : x.row.isMaxValue 
                  ? moment(x.row.dataInizioPrenotazione).format("DD/MM/YYYY HH:mm") + " - " + maxValueText 
                  :  moment(x.row.dataInizioPrenotazione).format("DD/MM/YYYY HH:mm") + " - " + moment(x.row.dataFinePrenotazione).format("DD/MM/YYYY HH:mm")
                   }
               </p>
              </div> 
            
            </Grid>
            </Grid>
     
        );
      },
      headerAlign: "left",
      align: "left",
     
    },
  ],
};

/*
autenticationType
dateRequest
email
*/

export const RichiesteAccessoHeader = {
  initialFieldName: "dateRequest",
  initialFieldOrder: "asc",
  headers: [
    {
      field: "dateRequest",
      headerName: "Data richiesta",
      flex: 1,
      resizable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
    },
    {
      field: "username",
      headerName: "Email/Username",
      flex: 1,
      resizable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
    },
    {
      field: "lastName",
      headerName: "Cognome",
      flex: 1,
      resizable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
    },
    {
      field: "firstName",
      headerName: "Nome",
      flex: 1,
      resizable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      resizable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
    },
    {
      field: "userAction",
      headerName: "",
      type: "actions",
      flex: 1,
      resizable: false,
      sortable: false,
      minWidth: minWidthColumn,
      getActions: (cell) => {
        return [<ButtonBoxRichiesteCredenziali activeRow={cell.id} rowInfo={cell.row} />];
      },
      headerAlign: "center",
      align: "right",
    },
  ],
};

export const UsersHeader = {
  initialFieldName: "username",
  initialFieldOrder: "asc",
  headers: [
    {
      field: "username",
      headerName: "Username",
      flex: 1,
      resizable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
    },
    {
      field: "lastName",
      headerName: "Cognome",
      flex: 1,
      resizable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
    },
    {
      field: "firstName",
      headerName: "Nome",
      flex: 1,
      resizable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      resizable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
    },
    {
      field: "roles",
      headerName: "Ruoli",
      flex: 1,
      resizable: false,
      sortable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
      valueGetter: (cell) => {
        let arrayRoles = cell.row.roles.map((x) => {
          let word = x.description.split(/(?=[A-Z])/);
          return word.join(" ");
        });
        return arrayRoles.join(", ");
      },
    },
    {
      field: "userAction",
      headerName: "",
      type: "actions",
      flex: 1,
      resizable: false,
      sortable: false,
      minWidth: minWidthColumn,
      getActions: (cell) => {
        return [<ButtonBoxUsers activeRow={cell.id} rowInfo={cell.row} />];
      },
      headerAlign: "center",
      align: "right",
    },
  ],
};

export const UsersHeaderMobile = {
  initialFieldName: "username",
  initialFieldOrder: "asc",
  headers: [
    {
      field: "userActionsMobile",
      headerName: "",
      type: "actions",
      flex: 1,
      resizable: false,
      sortable: false,
      minWidth: minWidthColumn,
      renderCell: (x) => {
        return (
          <Grid container item
          xs={12}
          sm={9}
          md={6}
          lg={5}
          xl={4}
          >
            {/* <Grid  item xs={1}  style={{ display: "flex", justifyContent: "center" }}> */}
            <Grid
              container
              item
              alignItems="left"
              justifyContent="start"
              xs={2}
              sm={1}
              md={1}
            >
              <div
                style={{
                  background: "#026D82",
                  marginTop:"12px",
                  width: "27px",
                  height: "27px",
                  borderRadius: "26px",
                }}
              >
                <div
                  style={{
                    fontSize: "13px",
                    color: "white",
                    fontStyle: "regular",
                  }}
                >
                  <div
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      marginTop: "4px",
                    }}
                  >
                    {x.row.firstName.charAt(0).toUpperCase()}
                    {x.row.lastName.charAt(0).toUpperCase()}
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={10} sm={11} md={11}
            >
            <p className="firstRow-HeaderMobile">
            {x.row.fullName}
              </p>
              <p className="secondRow-HeaderMobile">
              {x.row.username}{x.row.email != x.row.username && (<> - {x.row.email}</>)}
              </p>
              <p className="thirdRow-HeaderMobile">
              <ButtonBoxUsers activeRow={x.id} rowInfo={x.row} />
              </p>  
            </Grid>
          </Grid>
        );
      },
      headerAlign: "left",
      align: "left",
    },
  ],
};

export const ZoneHeader = {
  initialFieldName: "Sigla",
  initialFieldOrder: "asc",
  headers: [
    {
      field: "sigla",
      headerName: "Sigla",
      flex: 1,
      resizable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
    },
    {
      field: "denominazione",
      headerName: "Denominazione",
      flex: 1,
      resizable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
    },
    {
      field: "planimetria",
      type: "actions",
      headerName: "Planimetria",
      minWidth: minWidthColumn,
      flex: 1,
      resizable: false,
      editable: false,
      getActions: (x) => {
        const {idSfondoPlanimetria } = x.row
        //tabIndex permette di identificare quale Row è stata chiamata nel dataTable
        return [<>{idSfondoPlanimetria ? <DoneIcon /> : "" }</>];
      },
      headerAlign: "center",
      align: "center",
    },
    {
      field: "zoneAction",
      type: "actions",
      headerName: "",
      minWidth: minWidthColumn,
      flex: 1,
      resizable: false,
      editable: false,
      getActions: (x) => {
        //tabIndex permette di identificare quale Row è stata chiamata nel dataTable
        return [<ButtonBoxZone activeRow={x.id} rowInfo={x.row} />];
      },
      headerAlign: "center",
      align: "right",
    },
  ],
};

export const ZoneHeaderMobile = {
  initialFieldName: "username",
  initialFieldOrder: "asc",
  headers: [
    {
      field: "zoneActionsMobile",
      headerName: "",
      type: "actions",
      flex: 1,
      resizable: false,
      sortable: false,
      minWidth: minWidthColumn,
      renderCell: (x) => {
        return (
          <Grid container item
          xs={12}
          sm={9}
          md={6}
          lg={5}
          xl={4}
          >
            {/* <Grid  item xs={1}  style={{ display: "flex", justifyContent: "center" }}> */}
            <Grid
              container
              item
              alignItems="left"
              justifyContent="start"
              xs={2}
              sm={2}
              md={1}
            >
               <div
                style={{
                  background: "#026D82",
                  marginTop:"12px",
                  width: "27px",
                  height: "27px",
                  borderRadius: "26px",
                }}
              >
                <div
                  style={{
                    fontSize: "13px",
                    color: "white",
                    fontStyle: "regular",
                  }}
                >
                  <div
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      marginTop: "4px",
                    }}
                  >
                    {x.row.sigla.toUpperCase()}
                   
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={10} sm={10} md={11}>
  
              <p className="firstRow-HeaderMobile">
                {x.row.denominazione}
              </p>
              <p className="secondRow-HeaderMobile">
                Planimetria: {x.row.idSfondoPlanimetria ? "sì" :"no"}
               </p>
              <p className="thirdRow-HeaderMobile">
                <ButtonBoxZone activeRow={x.id} rowInfo={x.row} />
              </p>
            </Grid>
          </Grid>
        );
      },
      headerAlign: "left",
      align: "left",
    },
  ],
};

export const StanzeHeader = {
  initialFieldName: "Sigla",
  initialFieldOrder: "asc",
  headers: [
    {
      field: "sigla",
      headerName: "Sigla",
      flex: 1,
      resizable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
    },
    {
      field: "denominazione",
      headerName: "Denominazione",
      flex: 1,
      resizable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
    },
    {
      field: "planimetria",
      type: "actions",
      headerName: "Planimetria",
      minWidth: minWidthColumn,
      flex: 1,
      resizable: false,
      editable: false,
      getActions: (x) => {
        const {idSfondoPlanimetria } = x.row
        //tabIndex permette di identificare quale Row è stata chiamata nel dataTable
        return [<>{ idSfondoPlanimetria ? <DoneIcon /> : "" }</>];
      },
      headerAlign: "center",
      align: "center",
    },
    {
      field: "salaRiunioni",
      type: "actions",
      headerName: "Servizio",
      minWidth: minWidthColumn,
      flex: 1,
      resizable: false,
      editable: false,
      getActions: (x) => {
        const {isSalaRiunione } = x.row
        //tabIndex permette di identificare quale Row è stata chiamata nel dataTable
        return [<>{ isSalaRiunione ? <DoneIcon /> : "" }</>];
      },
      headerAlign: "center",
      align: "center",
    },
    {
      field: "stanzeActions",
      type: "actions",
      headerName: "",
      minWidth: minWidthColumn,
      flex: 1,
      resizable: false,
      editable: false,
      getActions: (x) => {
        //tabIndex permette di identificare quale Row è stata chiamata nel dataTable
        return [<ButtonBoxStanze activeRow={x.id} rowInfo={x.row} />];
      },
      headerAlign: "center",
      align: "right",
    },
  ],
};

export const StanzeHeaderMobile = {
  initialFieldName: "username",
  initialFieldOrder: "asc",
  headers: [
    {
      field: "stanzeActionsMobile",
      headerName: "",
      type: "actions",
      flex: 1,
      resizable: false,
      sortable: false,
      minWidth: minWidthColumn,
      renderCell: (x) => {
        return (
          <Grid container item
          xs={12}
          sm={9}
          md={6}
          lg={5}
          xl={4}
          >
            <Grid
              container
              item
              alignItems="left"
              justifyContent="start"
              xs={2}
              sm={2}
              md={1}
            >
              <div
                style={{
                  background: "#026D82",
                  marginTop:"12px",
                  width: "27px",
                  height: "27px",
                  borderRadius: "26px",
                }}
              >
                <div
                  style={{
                    fontSize: "13px",
                    color: "white",
                    fontStyle: "regular",
                  }}
                >
                  <div
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      marginTop: "4px",
                    }}
                  >
                    {x.row.sigla.toUpperCase()}
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={10} sm={10} md={11}>
            <p className="firstRow-HeaderMobile">
                {x.row.denominazione}
              </p>
              <p className="secondRow-HeaderMobile">
              {x.row.isSalaRiunione ?
              "Servizio" :
              "Stanza"}
              </p>
              <p className="thirdRow-HeaderMobile">
                <ButtonBoxStanze activeRow={x.id} rowInfo={x.row} />
              </p>
            </Grid>
          </Grid>
        );
      },
      headerAlign: "left",
      align: "left",
    },
  ],
};


export const PostazioniHeader = {
  initialFieldName: "posto",
  initialFieldOrder: "asc",
  headers: [

    {
      field: "posto",
      headerName: "Postazione",
      flex: 1,
      resizable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
      renderCell: (x) => {
        return (
          <Grid container
          item
          xs={12}
          sm={9}
          md={6}
          lg={5}
          xl={4}
          >
           
            <Grid item xs={8} sm={10} md={11}>
              <p style={{ fontSize: "15px", margin: "0" }}>
                 Postazione  {x.row.posto}
              </p>
              {/*<p style={{ margin: "10px 0 -5px 0" }}>
                <ButtonBoxPostazioni activeRow={x.id} rowInfo={x.row} />
          </p>*/}
            </Grid>
          </Grid>
        );
      },
      headerAlign: "left",
      align: "left",
    },
    {
      field: "riservata",
      headerName: "Riservata a",
      type: "actions",
      flex: 1,
      resizable: false,
      sortable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
      align:'left',
      getActions: (cell) => {
        return [
          <>
            {
              cell.row.utentiPostazione
              .map(x => x.nomeUtente)
                  .join(', ')
            }
          </>
          ];
        }
      },
    {
      field: "postazioniActions",
      type: "actions",
      headerName: "",
      minWidth: minWidthColumn,
      flex: 1,
      resizable: false,
      editable: false,
      getActions: (x) => {
        //tabIndex permette di identificare quale Row è stata chiamata nel dataTable
        return [<ButtonBoxPostazioni activeRow={x.id} rowInfo={x.row} isSalaRiunione={x.row.isSalaRiunione} />];
      },
      headerAlign: "center",
      align: "right",
    },
  ],
};

export const PostazioniHeaderMobile = {
  initialFieldName: "posto",
  initialFieldOrder: "asc",
  headers: [
    {
      field: "postazioniActionsMobile",
      headerName: "",
      type: "actions",
      flex: 1,
      resizable: false,
      sortable: false,
      minWidth: minWidthColumn,
      renderCell: (x) => {
        return (
          <Grid container
          item
          xs={12}
          sm={12}
          md={12}
          >
            <Grid item xs={12} sm={12} md={12} >
              <p className="firstRow-HeaderMobile">
                Postazione  {x.row.posto}
              </p>              
              <p className="secondRow-HeaderMobile textOverflowEllipsis">
              {x.row.utentiPostazione.length > 0 ? x.row.utentiPostazione
              .map(x => x.nomeUtente)
                  .join(', ') : "Non riservata"}
              </p>
              <p className="thirdRow-HeaderMobile">
                <ButtonBoxPostazioni activeRow={x.id} rowInfo={x.row} />
              </p>
            </Grid>
          </Grid>
        );
      },
      headerAlign: "left",
      align: "left",
    },
  ],
};



export const BuildingHeader = {
  initialFieldName: "denominazione",
  initialFieldOrder: "asc",
  headers: [
    {
      field: "sigla",
      headerName: "Sigla",
      flex: 1,
      resizable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
    },
    {
      field: "denominazione",
      headerName: "Denominazione",
      flex: 1,
      resizable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
    },
    {
      field: "Responsabili",
      headerName: "Responsabili",
      type: "actions",
      flex: 1,
      resizable: false,
      sortable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
      align:'left',
      getActions: (cell) => {
        return [
          <>
            {
              cell.row.utentiResponsabiliPrenotazioni
              .map(x => x.nominativo)
                  .join(', ')
            }
          </>
          ];
        }
      },
    {
      field: "buildingsActions",
      headerName: "",
      type: "actions",
      flex: 1,
      resizable: false,
      sortable: false,
      minWidth: minWidthColumn,
      getActions: (cell) => {
        return [
          <ButtonBoxBuilding
            activeRow={cell.id}
            rowInfo={cell.row}
          />,
        ];
      },
      headerAlign: "right",
      align: "right",
    },
  ],
};

export const BuildingHeaderMobile = {
  headers: [
    {
      field: "buildingActionsMobile",
      headerName: "",
      type: "actions",
      flex: 1,
      resizable: false,
      sortable: false,
      minWidth: minWidthColumn,
      renderCell: (x) => {
        return (
          <Grid container
          item
          xs={12}
          sm={9}
          md={6}
          lg={5}
          xl={4}
          >
            <Grid
              container
              item
              alignItems="left"
              justifyContent="start"
              xs={2}
              sm={2}
              md={1}
            >
              <div
                style={{
                  background: "#026D82",
                  marginTop:"12px",
                  width: "27px",
                  height: "27px",
                  borderRadius: "26px",
                }}
              >
                <div
                  style={{
                    fontSize: "13px",
                    color: "white",
                    fontStyle: "regular",
                  }}
                >
                  <div
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      marginTop: "4px",
                    }}
                  >
                    {x.row.sigla.toUpperCase()}
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={10} sm={10} md={11}>
              <p className="firstRow-HeaderMobile">
                {x.row.denominazione}
              </p>
              <p className="secondRow-HeaderMobile textOverflowEllipsis">
              {x.row.utentiResponsabiliPrenotazioni
              .map(x => x.nominativo)
                  .join(', ')}
              </p>
              <p className="thirdRow-HeaderMobile">
                <ButtonBoxBuilding
                  activeRow={x.id}
                  rowInfo={x.row}
                />
              </p>
            </Grid>
          </Grid>
        );
      },
      headerAlign: "left",
      align: "left",
    },
  ],
};
