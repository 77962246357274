import { createSlice } from '@reduxjs/toolkit';

const initialState = {homepageDefault: null, backHomeDefault:false};

const handlerBackToDefault = createSlice({
    name:'handlerBackToDefault',
    initialState,
    reducers: {
        changeViewHomeToDefault: (state) => { 
            return {...state, backHomeDefault:!state.backHomeDefault };
         }
    }
})

export const { changeViewHomeToDefault } = handlerBackToDefault.actions;

const { reducer } = handlerBackToDefault;

export default reducer;