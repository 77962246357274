import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { urlCheckDisabilitazioni } from "../functions/UrlParams";
import { reloadingDataTableUsers } from "../redux/reducers/dataTableUsers-reducer";
import { notificationOpen } from "../redux/reducers/notification-reducer";

const urlBase = process.env.REACT_APP_URL;

export default function useCheckDisabilitazioniSuPostazioni() {
    const params = useParams();
    const reloading = useSelector((state) => state.dataTableUsers);
    const dispatch = useDispatch();
    let [result, setResult] = useState(false);
    const {entita, action, method} = urlCheckDisabilitazioni(params);

    useEffect(() => {
      let isMounted = true;
          (async () => {
            let url = urlBase + action + entita;
            try {
                const response = await axios({
                    method: method,
                    url,
                  });
                  if(isMounted) {
                    if (response.data.ok) {
                      setResult(response.data.result);
                    }
                  }
            } catch (error) {
              console.error(error);
              dispatch(notificationOpen({ message: error.response.data.message, status: "error" }))
            }
          })();
          return () => {
            isMounted = false;
          }
      }, [reloading]);

    return result
}