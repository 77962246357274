import {
  Button,
  Grid,
} from "@mui/material";
import {
  dialogButtonQrCode,
} from "../../../../../const/DialogData";
import CreateLogoPostazioneScannedQrCode, {
} from "../../../../../functions/createLogoPostazioneScannedQrCode";
import "../../../../../index.css";
import TitleScannedQrCode from "../GeneralComponentQrCode/titleScannedQrCode";
import IconResum from "./ComponentPostazioneScannedQrCode/iconResume";
import { useEffect, useState } from "react";
import usePrenotazioneQrCode from "../../../../../Hooks/usePrenotazioneQrCode";
import {
  recuperaOraEMinutiDaDefault,
} from "../../../../../functions/recuperaOraEMinutiDaStringa";
import recuperaMaxTimeQrCodePrenotazione from "../../../../../functions/recuperaMaxTimeQrCodePrenotazione";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DISPONIBILE } from "../../../../../const/Planimetria/StatoPostazione";
import GeneralCustomDateTimePicker from "../../../../UI/TimePicker/GeneralCustomDateTimePicker";
import BodyComponentPostazioneOccupata from "./ComponentPostazioneScannedQrCode/BodyComponentPostazioneOccupata";
import BodyComponentPostazioneCheckin from "./ComponentPostazioneScannedQrCode/BodyComponentPostazioneCheckin";
import { useSelector } from "react-redux";
import { useRef } from "react";
import useAdaptiveCssFromRef from "../../../../../Hooks/Adaptive/useAdaptiveCssFromRef";
import adaptiveCssFromRef from "../../../../../Hooks/Adaptive/useAdaptiveCssFromRef";

export default function PostazioneScannedQrCode({
  postazioneScanned,
  returnBackComponentView,
  closeGeneralDialog,
  returnComponentState
}) {
  const [checkInButton, setcheckInButton] = useState(0);
  const [adaptiveHeightComponent, setAdaptiveHeightComponent] = useState(0);
  const buttonsBox = useRef(undefined);


  const {
    postazione,
    prenotazioniDelGiorno,
    statoAlMomentoDelloScan,
    prenotazioneSuggerita,
    postazioneOccupata,
    hasPrenotazioniAttiveNelGiorno,
    prenotazioneCheckIn,
  } = postazioneScanned;
  const { dataFinePrenotazione, id } = prenotazioneSuggerita;
  const dataFineDefault = useSelector((state) => state.prenotazioneStepper.dataFine); //recupera l'orario di default dalla configurazione della stepper

  const defaultFinePrenotazione = recuperaOraEMinutiDaDefault(dataFineDefault);

  //Gestisce quale data passare come suggerimento all'utente durante la prenotazione.
  let dataFinePrenotazioneRecuperata =
    dataFinePrenotazione === null
      ? defaultFinePrenotazione
      : dataFinePrenotazione;

  //Gestisce il limite massimo di estensione della prenotazione.
  const maxTime = recuperaMaxTimeQrCodePrenotazione(
    dataFinePrenotazione,
    dataFinePrenotazioneRecuperata
  );

  const [valueDataFine, setValueDataFine] = useState(
    dataFinePrenotazione || defaultFinePrenotazione
  );
  const [prenota, setPrenota] = useState(0);

  let iconStatoPostazione = CreateLogoPostazioneScannedQrCode(
    statoAlMomentoDelloScan,
    postazione
  );

  usePrenotazioneQrCode(
    prenota,
    // dataFinePrenotazioneRecuperata,
    valueDataFine,
    id,
    closeGeneralDialog,
    returnBackComponentView
  );

  function closeAll() {
    returnBackComponentView(false);
    closeGeneralDialog(false);
  }
useEffect(()  => {
  setAdaptiveHeightComponent(adaptiveCssFromRef(buttonsBox))
},[buttonsBox])


  return (
    <>
      <div style={{ minHeight:`${adaptiveHeightComponent}px` }}>
        <Grid container>
          <Grid item className="flex-bottom-left">
            <Button onClick={() => returnBackComponentView(false)}>
              <ArrowBackIcon color="primary" fontSize="large" />
            </Button>
          </Grid>
          <Grid item>
            <TitleScannedQrCode title={"Prenotazione rapida"} />
          </Grid>
        </Grid>
        <IconResum informazioniPostazione={postazione} />
        <Grid container className="flex-center-center">
          {iconStatoPostazione}
        </Grid>
        {
          !prenotazioneCheckIn 
         ? <BodyComponentPostazioneOccupata statoAlMomentoDelloScan={statoAlMomentoDelloScan} postazioneOccupata={postazioneOccupata} setValueDataFine={setValueDataFine} dataFinePrenotazione={dataFinePrenotazione} valueDataFine={valueDataFine} hasPrenotazioniAttiveNelGiorno={hasPrenotazioniAttiveNelGiorno} />
         : <BodyComponentPostazioneCheckin prenotazioneCheckIn={prenotazioneCheckIn} checkInDependency={checkInButton} returnComponentState={returnComponentState} />
        }
        </div>
      <Grid container sx={{ padding: "8px" }} ref={buttonsBox}>
        <Grid item sm={6} xs={6} md={12}>
          <Button onClick={() => closeAll()}>
            {dialogButtonQrCode.Annulla}
          </Button>
        </Grid>
        {!(statoAlMomentoDelloScan != 1) && (
          <Grid item sm={6} xs={6} md={12} sx={{ float: "right" }}>
            <Button
              sx={{ float: "right" }}
              variant="contained"
              onClick={() => setPrenota((old) => old + 1)}
            >
              {dialogButtonQrCode.Prenota}
            </Button>
          </Grid>
        )}
        {
          prenotazioneCheckIn && (
            <Grid item sm={6} xs={6} md={12} sx={{ float: "right" }}>
            <Button
              sx={{ float: "right" }}
              variant="contained"
              onClick={() => setcheckInButton(old => old + 1)}
            >
              {dialogButtonQrCode.Conferma}
            </Button>
          </Grid>
          )
        }
      </Grid>
    </>
  );
}
