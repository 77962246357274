import axios from "axios";
import { useEffect, useState } from "react"
import { EdificioZona } from "../const/Stepper/constStepper"
import { validazionePrenotazionePreInserimento } from "../const/urlAction";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import handlerError from "../functions/handlerError";
import { notificationOpen } from "../redux/reducers/notification-reducer";
import { hideLoader, showLoader } from "../redux/reducers/appLoader-reducer";
import { radioButtonPrenotazioneStepper } from "../const/RadioButton/RadioButtonListRadio";

const urlBase = process.env.REACT_APP_URL

export function useCheckPrenotazioneUserExist(activeCheckPrenotazioneUserExist, activeStep) {
  const stateDati = useSelector((state) => state.prenotazioneStepper);
  const [result, setResult] = useState({isPrenotazioneExist:false, reloading:false});
  const dispatch = useDispatch();

  const { ripetizioneGiorni, dataInizio, dataGiorno, dataFine, ripetizioneDataFine, utentePrenotazione, postazioneId, radioButtonPrenotazionePerAltri, ospitiPrenotazioneInfo } = stateDati;

  useEffect (() => {
    if(activeStep === EdificioZona && ripetizioneGiorni.length === 0 && utentePrenotazione.length < 2 && ospitiPrenotazioneInfo.length === 0 && radioButtonPrenotazionePerAltri !==  radioButtonPrenotazioneStepper[2].value   ) {
      (async() => {
        const { action, method } = validazionePrenotazionePreInserimento;
        let url = urlBase + action;
        dispatch(showLoader());

        const requestBody = {
          idUtentePrenotazione: utentePrenotazione.length > 0 ? utentePrenotazione[0].utente : null,
          idPostazione: postazioneId,
          disabilitazione: false,
          dataPrenotazione: moment(dataGiorno).format(),
          oraInizioPrenotazione: dataInizio,
          oraFinePrenotazione: dataFine,
          dataFineRipetizioni: moment(ripetizioneDataFine).format(),
          giorniRipetizione: ripetizioneGiorni 
        }

        try{
            const response = await axios({
                method: method,
                url,
                data: requestBody
            });
            setResult({isPrenotazioneExist:false,reloading:!result.reloading})
        } catch(error){
            console.error(error);
           const notificationError = handlerError(error);
           setResult({isPrenotazioneExist:true,reloading:!result.reloading})
             dispatch(notificationOpen(notificationError));
          } 
          dispatch(hideLoader());
    })();
      }
  }, [activeCheckPrenotazioneUserExist])

  return result;
}