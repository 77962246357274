
export const checkPolicy = (statePolicyContext, actionPolicy, typePage) => {
 let result = false
let policy = typePage+"."+actionPolicy
      
    let isPresent = statePolicyContext.indexOf(policy);
    result = isPresent === -1 ? false : true;
  
  return result;
};
