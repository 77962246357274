import { createSlice } from '@reduxjs/toolkit';

const initialState = {isOpenDialogPostazione:false , dialogType:"", dialogPageType:"", rowInfo:-1, body:{}};

const postazioneSlice = createSlice({
    name:'dialogPostazione',
    initialState,
    reducers: {
         openEmptyDialogPostazione: (state, payload) => {
            return {...state, isOpenDialogPostazione: true, dialogType:payload.payload.dialogType, body:payload.payload.body?.body };
         },
         setPageTypePostazione: (state, payload) => { 
            return {...state, dialogPageType:payload.payload};
         },
         openDialogPostazione:(state,payload) => {
             return {...state, isOpenDialogPostazione:true, dialogType:payload.payload.dialogType, rowInfo:payload.payload.rowInfo}
         },
         closeDialogPostazione: (state) => {
            return {...state, isOpenDialogPostazione: false};
         }
    }
});

export const { openDialogPostazione, closeDialogPostazione, openEmptyDialogPostazione, setPageTypePostazione } = postazioneSlice.actions;

const { reducer } = postazioneSlice;

export default reducer;