import { Autocomplete, Button, Checkbox, Typography } from "@mui/material";
import { dialogPageTypes } from "../../const/dialogPageTypes"
//import { TimePicker } from "@mui/lab";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect, useState, Fragment } from "react";
import { addPostazione, editAccount, editBuilding, deletePostazione, getPostazioneById, editUtentiAssociati, getUsersFiltered, getUsersAssociati } from "../../const/urlAction";
import axios from "axios";
import { useFormik } from "formik";
import * as yup from "yup";
import { dialogAddUser, dialogEditPostazione, dialogEditUtenti, dialogButtonGeneral, dialogButtonConfirm, changeStatusBuildingTextDialog, changeStatusBuildingTitleDialog, dialogAddPostazioni, changeStatusPostazioneTitleDialog } from "../../const/DialogData";
import { notificationOpen } from "../../redux/reducers/notification-reducer";
import { reloadingDataTableBuildings } from "../../redux/reducers/dataTableBuildings-reducer";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useParams } from "react-router-dom";
import { loadPostazioniState } from "../../redux/reducers/postazioniSlice-reducer";
import handlerError from "../../functions/handlerError";
import { reloadingDataTableUsers } from "../../redux/reducers/dataTableUsers-reducer";
import { BoxDialogDisabilita } from "./DisableDialog/DialogDisabilita";
import { closeDialog } from "../../redux/reducers/DialogGeneral";
import DisabilitaUnaPostazione from "./PostazioneSingola/DisabilitaUnaPostazione";
import RipristinaUnaPostazione from "./PostazioneSingola/RipristinaUnaPostazione";
import { loadUsersState } from "../../redux/reducers/usersSlice-reducer";
import convertPositionToInt from "../../functions/convertPositionToInt";
import { hideLoader, showLoader } from "../../redux/reducers/appLoader-reducer";

const urlBase = process.env.REACT_APP_URL;

const PostazioniDialog = () => {
  const backButtonState = useSelector((state) => state.dialogBackButton);
const [users, setUsers] = useState();
  const [isRenderingDialog, setIsRenderingDialog] = useState(false);
  const [changedRow, setchangedRow] = useState();
  const dialogState = useSelector((state) => state.dialogGeneral);
  const [selectedUsers, setSelectedUsers] = useState([]);


  const [inputUserSelect, setInputUserSelect] = useState('');
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [inputDeviceSelect, setInputDeviceSelect] = useState('');
  const dispatch = useDispatch();
  const { rowInfo, dialogType, dialogPageType, isOpen } = dialogState;
  const params = useParams();
  const {idStanza} = params;

  let validationSchema;

  const appLoadingState = useSelector((state) => state.appLoader.loading);
  
  if (dialogType === "edit") {
    validationSchema = yup.object({
      Posto: yup
        .number()
        .integer("Il Posto dev'essere un numero")
        .required("Campo Obbligatorio."),
      /*UtentiResponsabiliPrenotazioni: yup.array()
      .of(
        yup.object().shape({
         idUtente: yup.string().required("Campo Obbligatorio."),
         nominativo: yup.string().required("Campo Obbligatorio.")
        })
      .required("Campo Obbligatorio.")
    )*/


    });


  } else if (dialogType === "add") {
    validationSchema = yup.object({
      Posto: yup
        .number()
        .integer("Il Posto dev'essere un numero")
        .required("Campo Obbligatorio."),

    });
  }

  let initialValues = {};
  // const {
  //   id,
  //   posto: Posto,
  //   deleted,
  //   UtentiPostazione

  // } = rowInfo;

  if (dialogType === "edit") {
    initialValues = {
      id: rowInfo?.id,
      Posto: rowInfo?.posto,
      UtentiPostazione: rowInfo?.UtentiPostazione

    };
  } else if (dialogType === "add") {
    initialValues = {
      IdStanza: 0,
      Posto: 0,

    };
  } else if (dialogType === "delete") {
    initialValues = {
      //id: id,
      Posto: rowInfo?.posto
    }
  }

  if (dialogType === "editUtenti") {
    initialValues = {
      id: rowInfo?.id,
      Posto: rowInfo?.posto,
      UtentiPostazione: rowInfo?.UtentiPostazione

    };
  }

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {

      if (dialogType === "edit") {
        setchangedRow({
          id: values.id,
          Posto: values.Posto,
          UtentiPostazione: selectedUsers


        });
      } else if (dialogType === "add") {
        setchangedRow({
          IdStanza: 0,
          Posto: values.Posto,
          PosX:convertPositionToInt(dialogState?.body?.posX) || 0,
          PosY:convertPositionToInt(dialogState?.body?.posY) || 0
        });
      } if (dialogType === "editUtenti") {
        setchangedRow({
          id: values.id,
          UtentiPostazione: selectedUsers


        });
      }

      if (dialogType === "delete") {
        setchangedRow({
          id: values.id

        });
      }
    }
  });

  const handleChangeUserSelect = (event, value) => {
    setSelectedUsers(value);
  };

  const handleInputChangeUserSelect = (event, value) => {
    setInputUserSelect(value);
  };

  const handleChangeDeviceSelect = (event, value) => {
    setSelectedDevices(value);
  };

  const handleInputChangeDeviceSelect = (event, value) => {
    setInputDeviceSelect(value);
  };

    useEffect(() => {
      if(isOpen && dialogType === 'editUtenti') {
    (async() => {
      const { action, method } = getUsersAssociati
      let url = urlBase + action;
      const requestBody = {}
      try{
        const response = await axios({
          method: method,
          url,
          data: requestBody
        });
         
        if(response.data.ok){
          dispatch(loadUsersState(response.data.result));
        }     
      } catch(error){
        console.error(error);
        const notificationError = handlerError(error);
        dispatch(notificationOpen(notificationError));
      } 
    })();
    }
  }, [isOpen]);


  useEffect(() => {
    let isMounted = true;
    (async () => {

      const { action, method } = getUsersFiltered;
      let url = urlBase + action;

      try {
        const response = await axios({
          method: method,
          url,
        });
        if(isMounted) {
        setUsers(response.data.result);
        }
      } catch (error) {
        console.error(error);
        const notificationError = handlerError(error);
        dispatch(notificationOpen(notificationError));
      }

    })();
    return () => {
      isMounted = false;
      }
  }, []);

  //Gestisce il cambio di Datatable con la Dialog Aperta, la chiude e il dialogPageType impedisce di far partire la chiamata
  useEffect(() => {
    dispatch(closeDialog());
  }, [window.onhashchange])

  useEffect(() => {
    let isMounted = true;
    if (isOpen && dialogType === "editUtenti" && dialogPageType === dialogPageTypes.postazioni) {
      (async () => {
        setSelectedUsers([]);
        setSelectedDevices([]);
        setIsRenderingDialog(true);

        const { id } = rowInfo;
        const { action, method } = getPostazioneById;
        let url = urlBase + action + id;

        try {

          const response = await axios({
            method: method,
            url
          });
          if(isMounted) {
          if (response.data.ok) {
            dispatch(reloadingDataTableBuildings());
              const listnomeUtente = response.data.result['utentiPostazione'].map(x => {return {idUtente: x.idUtente, idPostazione: x.idPostazione,nominativo:x.nomeUtente}});
 
            //formik.setFieldValue(`utentiPostazione`, setSelectedUsers(response.data.result['utentiPostazione']), true);
            formik.setFieldValue(`utentiPostazione`, setSelectedUsers(listnomeUtente), true);

            setIsRenderingDialog(false);

          } else {
            dispatch(notificationOpen({ message: response.data.message, status: "success" }));
          }
          }
        } catch (error) {
          console.error(error);
          if (error.response) {
            dispatch(notificationOpen({ message: `${error.response.data.message}`, status: "error" }));
          } else {
            dispatch(notificationOpen({ message: "Operazione fallita: Server non raggiungibile.", status: "error" }));
          }
        }
        setIsRenderingDialog(false);
      })();
    }
    return () => {
      isMounted = false;
      }
  }, [isOpen]);


  const closeForm = () => {
    formik.resetForm(initialValues)
    dispatch(closeDialog());
  }


  useEffect(() => {
    let isMounted = true;
    if (changedRow != null) {
      (async () => {

        let formData, action, method, url;/*userData */

        if (dialogType === "add") {
          const{PosX,PosY}=changedRow;
          formData = { ...changedRow, PosX:convertPositionToInt(PosX), PosY:convertPositionToInt(PosY), IdStanza : idStanza };
          action = addPostazione.action;
          method = addPostazione.method;
        }
        else if (dialogType === "editUtenti") {

          var utentiPostazioni = selectedUsers.map(item => item.idUtente);
          formData = { ...changedRow, UtentiPostazione: utentiPostazioni, idPostazione: rowInfo?.id };

          action = editUtentiAssociati.action;
          method = editUtentiAssociati.method;
        }

        url = urlBase + action;
        dispatch(showLoader())
        try {
          const response = await axios({
            method: method,
            url,
            data: formData
          });
          if(isMounted) {
          if (response.data.ok) {
            formik.resetForm(initialValues)
            dispatch(reloadingDataTableUsers())
            dispatch(loadPostazioniState());
            dispatch(closeDialog());
          }

          dispatch(notificationOpen({ message: response.data.message, status: "success" }))
         }
        } catch (error) {
          console.error(error);

          if (error?.response?.data?.errors != undefined) {
            let keys = Object.keys(error?.response?.data?.errors)
            keys.map(key => {
              formik.setFieldError(key, error?.response?.data?.errors[key])
            })
          }
          if (error.response.data.message == "") {

            dispatch(notificationOpen({ message: "", status: "error" }));

          }  
          if (error.response.data.message == undefined) {
            dispatch(closeDialog());
            dispatch(notificationOpen({ message: 'Operazione fallita.', status: "error" }));
            
          }
        }
        dispatch(hideLoader())
      })();
    }
    return () => {
      isMounted = false;
      }
  }, [changedRow]);



  const deleteRestore = (rowInfo) => {
    let isMounted = true;
    if (dialogType === "delete") {
      (async () => {
        setIsRenderingDialog(true);

        const { id } = rowInfo;
        const { action, method } = deletePostazione;
        let url = urlBase + action;
        dispatch(showLoader())
        try {
          const response = await axios({
            method: method,
            url,
            data: id,
            headers: {
              'Content-Type': 'application/json'
            }
          });
          if(isMounted) {
          if (response.data.ok) {
            dispatch(reloadingDataTableUsers())
            dispatch(loadPostazioniState());
            setIsRenderingDialog(false);
          }
          dispatch(notificationOpen({ message: response.data.message, status: "success" }));
          }
        }
        catch (error) {
          console.error(error);
          if (error.response) {
            dispatch(notificationOpen({ message: `${error.response.data.message}`, status: "error" }));
          } else {
            dispatch(notificationOpen({ message: "Operazione fallita: Server non raggiungibile.", status: "error" }));
          }
          setIsRenderingDialog(false);
        }
        dispatch(hideLoader())
      })();

      dispatch(closeDialog());
      
      return () => {
        isMounted = false;
        }
    }
  };

  useEffect(() => {
    setSelectedUsers([]);
    setSelectedDevices([]);
  }, [isOpen]);

  useEffect(() => {
    dispatch(closeDialog())
  },[backButtonState])


  return (
    <>
      {dialogType === "delete" && (
        <>
          <Dialog
            open={isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth={'sm'}
          >
            <DialogTitle>
              {changeStatusPostazioneTitleDialog(rowInfo?.deleted)}
            </DialogTitle>
              <DialogContent className="border-Content-dialog">
                <DialogContentText id="alert-dialog-description">
                  {changeStatusBuildingTextDialog(rowInfo?.deleted)} la <b>Postazione {rowInfo?.posto}</b>?
                </DialogContentText>
            </DialogContent>
               <DialogActions>
                <Button onClick={() => dispatch(closeDialog(1))}>
                  {dialogButtonConfirm.Cancel}
                </Button>
                <Button variant="contained" onClick={() => deleteRestore(rowInfo)} >
                  {dialogButtonConfirm.Confirm}
                </Button>
              </DialogActions>
          </Dialog>
        </>
      )}
      {/*Dialog di tipo editaUtente apposta per la modifica degli utenti quindi crea uno useEffect a parte */}
      {(dialogType === "add" ) && !isRenderingDialog && (
        <>
          <Dialog
            open={isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth={'sm'}
          >
            <DialogTitle >{dialogAddPostazioni.DialogTitle}</DialogTitle>
              <DialogContent className="border-Content-dialog">
                <DialogContentText id="alert-dialog-description">
                    {dialogAddPostazioni.DialogText}
                    </DialogContentText>
            </DialogContent>
                <DialogActions >
                  <Button onClick={() => closeForm()} >
                    {dialogButtonGeneral.Cancel}
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={() => formik.handleSubmit()}
                  >
                    {dialogButtonGeneral.Confirm}
                  </Button>
                </DialogActions>
          </Dialog>
        </>
      )}
            {(dialogType === "edit") && !isRenderingDialog && (
        <>
          <Dialog
            open={isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth={'sm'}
          >
            <DialogTitle >{dialogType === "edit" ? dialogEditPostazione.DialogTitle : dialogAddPostazioni.DialogTitle}</DialogTitle>
            <DialogContent className="border-Content-dialog">
                <FormControl fullWidth>
                  {dialogType === "edit" && (
                    <TextField
                      margin="dense"
                      id="id"
                      label="ID Postazione"
                      type="text"
                      fullWidth
                      variant="standard"
                      value={formik.values.id}
                      sx={{ display: 'none' }}
                    />
                  )}

                  <Grid container rowSpacing={1} columnSpacing={2}>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        margin="dense"
                        id="Posto"
                        label="Numero di postazioni"
                        type="number"
                        fullWidth

                        variant="standard"
                        value={formik.values.Posto}
                        onChange={formik.handleChange("Posto")}
                        error={
                          formik.touched.Posto &&
                          Boolean(formik.errors.Posto)
                        }
                        helperText={
                          formik.touched.Posto &&
                          formik.errors.Posto
                        }
                      />
                    </Grid>
                  </Grid>
                </FormControl>
                  </DialogContent>
                <DialogActions sx={{ mt: 3 }}>
                  <Button onClick={() => closeForm()} >
                    {dialogButtonGeneral.Cancel}
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={() => formik.handleSubmit}
                  >
                    {dialogButtonGeneral.Confirm}
                  </Button>
                </DialogActions>
          </Dialog>
        </>
      )}
      {(dialogType === "editUtenti") && !isRenderingDialog && (
        <>
          <Dialog
            open={isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth={'sm'}
          >
            <DialogTitle >{dialogType === "editUtenti" ? dialogEditUtenti.DialogTitle : dialogAddPostazioni.DialogTitle}</DialogTitle>
            <DialogContent className="border-Content-dialog">
                <FormControl fullWidth>
                  {dialogType === "editUtenti" && (
                    <TextField

                      margin="dense"
                      id="id"
                      label="ID Postazione"
                      type="text"
                      fullWidth
                      variant="standard"
                      value={formik.values.id}
                      sx={{ display: 'none' }}
                    />
                  )}
                  <Grid item xs={12} sm={6}>
                  </Grid>
                  <Grid container rowSpacing={1} columnSpacing={2}>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        margin="dense"
                        id="Posto"
                        label="Postazione"
                        type="text"
                        fullWidth
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="standard"

                        value={`Postazione ${formik.values.Posto}`}
                        onChange={formik.handleChange("Posto")}
                        error={
                          formik.touched.Posto &&
                          Boolean(formik.errors.Posto)
                        }
                        helperText={
                          formik.touched.Posto &&
                          formik.errors.Posto
                        }

                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Autocomplete
                        multiple
                        id="utentiPostazione"
                        size="small"
                        options={users} //tutti ma sotto forma di json selectedUsers idUtente Nominativo
                        value={selectedUsers}
                        disableCloseOnSelect
                        limitTags={1}
                        isOptionEqualToValue={(option, value) => option.idUtente === value.idUtente}
                        getOptionLabel={(option) => option.nominativo || ""}
                        onChange={handleChangeUserSelect}
                        onInputChange={handleInputChangeUserSelect}

                        renderOption={(props, option, { selected }) => {
                          return (<li  key={option?.idUtente} {...props} >
                            {option.nominativo}
                          </li>)
                        }
                      }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Postazione riservata a" variant="standard" InputProps={
                              {
                                ...params.InputProps,
                                endAdornment: (
                                  <Fragment>
                                    {params.InputProps.endAdornment}
                                  </Fragment>
                                )
                              }}
                          />)

                        }
                      />
                    </Grid>
                    <br></br>
                    <br></br>

                  </Grid>
                </FormControl>
            </DialogContent>
                <DialogActions >
                  <Button onClick={() => closeForm()} >
                    {dialogButtonGeneral.Cancel}
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={appLoadingState}
                    onClick={() => formik.handleSubmit()}
                  >
                    {dialogButtonGeneral.Confirm}
                  </Button>
                </DialogActions>

          </Dialog>
        </>
      )}
        { (dialogState.dialogType === "disableRangeData" || dialogState.dialogType === "ripristinaAll") && (
              <BoxDialogDisabilita isOpen={isOpen} dialogType={dialogType} rowInfo={rowInfo}  closeForm={closeForm}/>
      )}
      { dialogType === "disableOne" && (
              <DisabilitaUnaPostazione isOpen={isOpen} dialogType={dialogType} rowInfo={rowInfo}  closeForm={closeForm}/>
      )}
      { dialogType === "restoreOne" && (
              <RipristinaUnaPostazione isOpen={isOpen} dialogType={dialogType} rowInfo={rowInfo}  closeForm={closeForm}/>
      )}
    </>
  );
};

export default PostazioniDialog;