import React, { useEffect, useLayoutEffect, useState } from "react";
import Switch from "@mui/material/Switch";
import { FormControlLabel, Grid, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useDispatch, useSelector } from "react-redux";
import itLocale from "date-fns/locale/it";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import DayRipetizioniBox from "./DayRipetizioniBox/DayRipetizioniBox";
import { ControlloRipetizioneStatus, returnRipetizioneDataFine } from "../../../../redux/reducers/prenotazioneStepper-reducer";
import GeneralCustomDateTimePicker from "../../../UI/TimePicker/GeneralCustomDateTimePicker";

const now = new Date(Date.now());
const nowPiuUno = new Date(new Date().setDate(new Date().getDate() + 1)).toString()

export default function RipetizioniBox({ maxday, minDayValue }) {
  const dispatch = useDispatch();
  const [valueDataFineRipetizioni, setValueDataFineRipetizioni] = useState(nowPiuUno);
  const label = { inputProps: { "aria-label": "Switch Ripetizioni" } };
  const state = useSelector((state) => state.prenotazioneStepper);
  const { ripetizioneStatus, ripetizioneDataFine } = state;

  //se lo stato dello switch cambia, svuota l'array
  useLayoutEffect(() => {
    setValueDataFineRipetizioni(nowPiuUno)
  }, [ripetizioneStatus])

  const handleChangeData = (newValue) => {
    if (newValue !== null) {
      dispatch(returnRipetizioneDataFine(newValue.toString()));
      setValueDataFineRipetizioni(newValue);
    } else {
      dispatch(returnRipetizioneDataFine("null"));
      setValueDataFineRipetizioni("null");
    }
  };

useEffect(() => {
  if(minDayValue) {
    let dateSaved = new Date(valueDataFineRipetizioni)
    let newDate = new Date(minDayValue)
    if(newDate.getTime() > dateSaved.getTime()) {
      setValueDataFineRipetizioni(minDayValue)
      dispatch(returnRipetizioneDataFine(minDayValue.toString()));
    }
  }
}, [minDayValue, ripetizioneStatus])


  useLayoutEffect(() => {
    ripetizioneStatus ? setValueDataFineRipetizioni(ripetizioneDataFine) : setValueDataFineRipetizioni(nowPiuUno);
  }, [ripetizioneStatus])

  return (
    <React.Fragment>
      <Grid container rowSpacing={1} columnSpacing={5}>
        <Grid item xs={12} >
          <FormControlLabel control={
            <Switch
              onChange={() => { dispatch(ControlloRipetizioneStatus(!ripetizioneStatus)) }}
              checked={ripetizioneStatus}
            />
          } label="Ripeti prenotazione" />
        </Grid>
        {ripetizioneStatus && <><Grid item xs={12} sm={6} md={4} lg={4} >
          <DayRipetizioniBox />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={itLocale}>
            <GeneralCustomDateTimePicker
              TypeTimePicker={5}
              label="Ripeti prenotazione fino al"
              valueStart={valueDataFineRipetizioni}
              handleChange={handleChangeData}
              placeholder="gg/mm/aaaa"
              minDate={minDayValue}
              maxDate={maxday}
              disabled={!ripetizioneStatus}
            />
          </LocalizationProvider>
        </Grid></>}
      </Grid>
    </React.Fragment>
  );
}
