export default function useHandlerBackButton(
  param,
  backButtonLogic = () => {},
  dispatch = null
) {
  let oldstate = window.history.state;
  window.history.pushState(oldstate, "");
  window.onpopstate = (event) => {
     if(event) backButtonLogic(param,dispatch);
  };
}

