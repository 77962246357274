import { ListItem, ListItemText, Typography, useTheme } from "@mui/material";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Avatar from "@mui/material/Avatar";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import List from "@mui/material/List";
import axios from "axios";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import { useDispatch } from "react-redux";
import { notificationOpen } from "../../../../../redux/reducers/notification-reducer";
import handlerError from "../../../../../functions/handlerError";
import { getDettaglioPrenotazioneById } from "../../../../../const/urlAction";
import { maxValueText } from "../../../../../const/DialogRiepilogoText";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { enumCheckInStatus } from "../../../../../const/prenotazioni/enumCheckInStatus";
import { faBed, faBriefcaseMedical, faDoorOpen, faHospital, faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const urlBase = process.env.REACT_APP_URL;
export default function Riepilogo({ id }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);

  const theme = useTheme();

  useEffect(() => {
    // if (isOpenRiepilogo) {
    (async () => {
      setIsLoading(true);

      const { action, method } = getDettaglioPrenotazioneById;
      let url = urlBase + action + id;

      try {
        const response = await axios({
          method: method,
          url,
        });

        if (response.data.ok) {
          setRows(response.data.result);
        } else {
          dispatch(
            notificationOpen({
              message: response.data.message,
              status: "success",
            })
          );
        }
      } catch (error) {
        console.error(error);
        const notificationError = handlerError(error);
        dispatch(notificationOpen(notificationError));
      }

      setIsLoading(false);
    })();
    // }
    return () => {
      //   isOpenRiepilogo = false;
      setRows([]);
    };
    //   }, [isOpenRiepilogo]);
  }, []);


  //gestisce se mostrare la data fine originale
  function appendDataFineOriginale(valueOriginal, value) {
      const time1 = new Date(valueOriginal)
      const time2 = new Date(value)
     return time1.getTime() === time2.getTime() ? "" : <Typography sx={{display:"inline", color:"grey"}}>({moment(time1).format("HH:mm")})</Typography> ;
  }

  function handlerColorCheckInAndText() {
    switch ( rows.checkInStatus) {
      case enumCheckInStatus.future:
          return
      case enumCheckInStatus.waiting:
        return theme.palette.warning.main;
      case enumCheckInStatus.completed:
          return theme.palette.success.main;
      case enumCheckInStatus.notChecked:
          return theme.palette.error.main;
    }
  }

  return (
    <>
      {!isLoading && (
        <List sx={{ pt: 0 }}>
          {!rows.disabilitato && (
            <ListItem key={rows.id}>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: "#026D82", color: "white" }}>
                  <AssignmentIndIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={rows.ospite ? <> {rows.nominativoOspite} <Typography sx={{display:"inline", color:"grey"}}>{" (" + rows.utentePrenotato + ")"}</Typography></> : rows.utentePrenotato} />
            </ListItem>
          )}
          <ListItem>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: "#026D82", color: "white" }}>
                <CalendarMonthIcon />
              </Avatar>
            </ListItemAvatar>
            {rows.disabilitato ? (
              rows.isMaxValue === true ? (
                <ListItemText
                  primary={
                    <Typography>
                      {moment(rows.dataInizioPrenotazione).format(
                        "DD/MM/YYYY HH:mm"
                      )}{" "}
                      - {maxValueText}{" "}
                    </Typography>
                  }
                />
              ) : moment(rows.dataInizioPrenotazione).format("DD/MM/YYYY") ===
                moment(rows.dataFinePrenotazione).format("DD/MM/YYYY") ? (
                <ListItemText
                  primary={`${moment(rows.dataInizioPrenotazione).format(
                    "DD/MM/YYYY HH:mm"
                  )} - ${moment(rows.dataFinePrenotazione).format("HH:mm")}`}
                />
              ) : (
                <ListItemText
                  primary={`${moment(rows.dataInizioPrenotazione).format(
                    "DD/MM/YYYY HH:mm"
                  )} - ${moment(rows.dataFinePrenotazione).format(
                    "DD/MM/YYYY HH:mm"
                  )}`}
                />
              )
            ) : (
              <ListItemText
                primary={<>{moment(rows.dataInizioPrenotazione).format(
                  "DD/MM/YYYY HH:mm"
                )} - {moment(rows.dataFinePrenotazione).format("HH:mm")} {appendDataFineOriginale(rows.dataOraFinePrenotazioneOriginale,rows.dataFinePrenotazione)}</>}
              />
            )}
          </ListItem>
          {/* nascosto demo*/}
          {/* {rows.enableCheckIn && rows.checkInStatus !== enumCheckInStatus.future && (
            <ListItem>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: `${handlerColorCheckInAndText()}`, color: "white" }} >
                {
                rows.checkInStatus === enumCheckInStatus.waiting ? (
                  <AccessTimeFilledIcon />
                ) : (
                  rows.checkInStatus === enumCheckInStatus.notChecked ?  <CloseIcon /> :  <CheckIcon />
                )
                }
                </Avatar>
              </ListItemAvatar>
              {
                rows.checkInStatus === enumCheckInStatus.waiting ? (
                  <Typography>In attesa di check-in</Typography>
                ) : (
                  rows.checkInStatus === enumCheckInStatus.notChecked ?  <Typography>Check-in scaduto</Typography> : <ListItemText>Orario check-in: {moment(rows.dataCheckIn).format("DD/MM/YYYY HH:mm")}</ListItemText>
                )
                }
            </ListItem>
          )} */}
          <ListItem key={rows.edificioSigla}>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: "#026D82", color: "white" }}>
                <FontAwesomeIcon icon={faHospital}/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={`${rows.edificioDenominazione} (${rows.edificioSigla})`}
            />
          </ListItem>
          <ListItem key={rows.zonaSigla}>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: "#026D82", color: "white" }}>
                <FontAwesomeIcon icon={faLayerGroup} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={`${rows.zonaDenominazione} (${rows.zonaSigla})`}
            />
          </ListItem>
          <ListItem key={rows.stanzaSigla}>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: "#026D82", color: "white" }}>
                {!rows.isSalaRiunione ? (
                  <FontAwesomeIcon icon={faDoorOpen} />
                ) : (
                  <>
                    <FontAwesomeIcon icon={faBriefcaseMedical} />
                  </>
                )}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={`${rows.stanzaDenominazione} (${rows.stanzaSigla})`}
            />
          </ListItem>
          {!rows.isSalaRiunione && (
            <ListItem key={rows.postazionePosto}>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: "#026D82", color: "white" }}>
                  <FontAwesomeIcon icon={faBed} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={`Letto ${rows.postazionePosto}`} />
            </ListItem>
          )}
          <ListItem key={rows.utenteInserimento}>
            <ListItemAvatar>
              <Avatar sx={{ color: "white" }} />
            </ListItemAvatar>
            <ListItemText primary={rows.utenteInserimento} />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <CalendarMonthIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={moment(rows.dataIns).format("DD/MM/YYYY HH:mm")}
            />
          </ListItem>
        </List>
      )}
    </>
  );
}
