export function initialValuesBuildingEdit(rowInfo) {
    return {id: rowInfo?.id,
    Denominazione: rowInfo?.denominazione,
    Sigla: rowInfo?.sigla,
    UtentiResponsabiliPrenotazioni : rowInfo?.utentiResponsabiliPrenotazioni,
    Indirizzo: rowInfo?.indirizzo,
    Coordinate: rowInfo?.coordinate
    }
  };

  export function initialValuesBuildingAdd() {
        return {id: "",
        Denominazione: "",
        Sigla: "",
        UtentiResponsabiliPrenotazioni: [],
        Indirizzo: "",
        Coordinate: "",
      }
  }


  export function initialValuesBuildingDelete(rowInfo) {
    return {id: rowInfo?.id,
    Denominazione: rowInfo?.denominazione,
    Sigla: rowInfo?.sigla,}
  }