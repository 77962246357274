import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Checkbox } from '@mui/material';
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

export default function SelectCustom({optionsAutoComplete = [], label="field", field=[], handleValueReturned, startValueAutoComplete, isDisabled = false}) {
  const [selectedValue, setSelectedValue] = React.useState({});

React.useEffect(() => {
  if (Object.keys(startValueAutoComplete).length > 0) setSelectedValue(startValueAutoComplete);
},[startValueAutoComplete])

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  React.useEffect(() => {
    if(typeof handleValueReturned == typeof(new Function)) {
        handleValueReturned(selectedValue)
    }
}, [selectedValue]);

  return (
    <div>
      <FormControl variant="standard" sx={{ m: 1, minWidth: "100%" }}>
        <InputLabel >{label}</InputLabel>
         <Select
          value={selectedValue}
          onChange={handleChange}
          label={label}
          disabled={isDisabled}
        >
          {optionsAutoComplete.map(el => 
          <MenuItem key={el.id} value={el}>
            {field.map((fl,index) => 
                { 
                  const optionField = fl === "sigla" ?  " ("+ el[fl] +")" : el[fl]  //da Dinamicizzare 
                  return optionField
                }
            )}
              </MenuItem> 
              )}
        </Select>
      </FormControl>
      </div>
  );
}