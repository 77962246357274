import { List, ListItem, ListItemAvatar } from "@mui/material";
import { Box } from "@mui/system";

export function TabPanelComponentToShow({ componentTab, withPadding = true }) {
  return (
    <div>
      <Box sx={withPadding ? { p: 3 } : {}}>{componentTab}</Box>
    </div>
  );
}
