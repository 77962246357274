import { AcceptRichiestaCredenziali, RefuseRichiestaCredenziali } from "../../../../const/urlAction";

export const dialogListSignIn = {
  approved: "Approved",
  refuse: "Refuse",
};


export default function createDialogConfirmActionInDialogSignIn(dialog, body, info = {}) {

    const { approved , refuse } = dialogListSignIn;

    let object = {};

    switch (true) {
      case dialog === approved:
        object = { noteField:false,viewComponent:{textPrincipal:<>Approvare la richiesta di credenziali di accesso a favore di <b>{info?.lastName} {info?.firstName} ({info?.username})</b>?</>, textTitle:"Accetta richiesta credenziali"}, actionApi: AcceptRichiestaCredenziali, body: body };
        break;
      case dialog === refuse:
        object = { noteField:true, actionApi:RefuseRichiestaCredenziali, viewComponent:{textPrincipal:<>Rifiutare la richiesta di credenziali di accesso a favore di <b>{info?.lastName} {info?.firstName} ({info?.username})</b>?</>, textTitle:"Rifiuta richiesta credenziali"} , body: body };
        break;
    }
    return object;
}
