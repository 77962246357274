import axios from "axios";
import { GetUserFromActiveDirectory } from "../../const/urlAction";
import { hideLoader, showLoader } from "../../redux/reducers/appLoader-reducer";
import { notificationOpen } from "../../redux/reducers/notification-reducer";
import handlerError from "../handlerError";

const urlBase = process.env.REACT_APP_URL;

export default function autoCompleteFormFromActiveDirectory(
  formik,
  resetForm = false,
  dispatch = () => null
) {
  (async () => {
    dispatch(showLoader());
    let username = formik.values.username;
    const { action, method } = GetUserFromActiveDirectory;
    let url = urlBase + action + "?username=" + username;
    try {
      const response = await axios({
        method: method,
        url,
      });
      let keys = Object.keys(response.data.result);
      if (resetForm) {
        formik.resetForm();
        formik.setFieldValue("username", username);
      } else {
        formik.setFieldValue("email", "");
      }
      keys.map((key) => {
        if (response.data.result[key])
          formik.setFieldValue(key, response.data.result[key]);
      });
      if (!response.data.result["email"])
        dispatch(
          notificationOpen({
            status: "warning",
            message:
              "L'utente è stato trovato su Active Directory ma non dispone di una email, per tanto non sarà possibile salvare le modifiche con questa tipologia di autenticazione.",
          })
        );
    } catch (error) {
      console.error(error);
      const notificationError = handlerError(error);
      dispatch(notificationOpen(notificationError));
    }
    dispatch(hideLoader());
  })();
}
