import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import { useDispatch } from "react-redux";
import { GridActionsCellItem } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";
import { policies as permessi } from "../../const/policies";
import DeleteIcon from "@mui/icons-material/Delete";
import QrCodeIcon from "@mui/icons-material/QrCode";
import PeopleIcon from "@mui/icons-material/People";
import RotateRightIcon from "@mui/icons-material/RotateRight";

import { dialogPageTypes } from "../../const/dialogPageTypes";
import { openDialog, setPageType } from "../../redux/reducers/DialogGeneral";
import { useState } from "react";
import useGenerateQrCode from "../../Hooks/useGenerateQrCode";
import SpinnerCustom from "../../components/UI/spinner/spinner";
import useMobileDetection from "../../Hooks/useMobileDetection";
import { GenerateQrCodePostazione } from "../../const/urlAction";
import handlerConfiguration from "../../functions/general/handlerConfiguration";


const ButtonBoxPostazioni = ({ rowId, rowInfo, isSalaRiunione }) => {
  const configurations = useSelector((state) => state.configurations);
  const {enableQrCodeScanner : available} = handlerConfiguration(configurations)
  const [qrCodeToGenerateBody, setQrCodeToGenerateBody] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const state = useSelector((state) => state.userDate);
  const { policies, responsabileEdificio } = state;
  const dispatch = useDispatch();

  const handleDialogToEdit = ({ dialogType, rowInfo }) => {
    dispatch(setPageType(dialogPageTypes.postazioni));
    dispatch(openDialog({ dialogType, rowInfo }));
  };

  function handleDownloadQrCode(rowInfo) {
    const { id,qrCodePostazione } = rowInfo;
    setQrCodeToGenerateBody({id,qrCodePostazione});
  }

  useGenerateQrCode(qrCodeToGenerateBody, setIsLoading, GenerateQrCodePostazione);

  const isMobile = useMobileDetection();

  return (
    <>
      {policies.indexOf(permessi.postazioniInserimentoModifica) != -1 && (
        <GridActionsCellItem
          icon={
            <Tooltip title="Associa utenti al posto letto">
              <PeopleIcon style={{ fontSize: 30 }} color="primary" />
            </Tooltip>
          }
          label="Utenti"
          onClick={() =>
            handleDialogToEdit({ dialogType: "editUtenti", rowInfo })
          }
        />
      )}
      {(policies.indexOf(permessi.postazioniEliminazioneRipristino) != -1 ||
        responsabileEdificio.length > 0) && (
        <GridActionsCellItem
          icon={
            <Tooltip title="Disabilita il posto letto">
              <DoDisturbIcon style={{ fontSize: 30 }} color="primary" />
            </Tooltip>
          }
          label="Utenti"
          onClick={() =>
            handleDialogToEdit({ dialogType: "disableOne", rowInfo })
          }
        />
      )}
      {(policies.indexOf(permessi.postazioniEliminazioneRipristino) != -1 ||
        responsabileEdificio.length > 0) && (
        <GridActionsCellItem
          icon={
            <Tooltip title="Riabilita il posto letto">
              <RotateRightIcon style={{ fontSize: 30 }} color="primary" />
            </Tooltip>
          }
          label="Utenti"
          onClick={() =>
            handleDialogToEdit({ dialogType: "restoreOne", rowInfo })
          }
        />
      )}
      {(available)  &&
        !isMobile &&
        (policies.indexOf(permessi.postazioniDownloadQRCode) != -1 ||
          responsabileEdificio.length > 0) && (
          <GridActionsCellItem
            icon={
              <Tooltip title="Download QR Code">
                {<QrCodeIcon color="primary" style={{ fontSize: 30 }} />}
              </Tooltip>
            }
            label="Cancella"
            onClick={() => handleDownloadQrCode(rowInfo)}
          />
        )}
      {!isSalaRiunione &&
        policies.indexOf(permessi.postazioniEliminazioneRipristino) != -1 && (
          <GridActionsCellItem
            icon={
              <Tooltip title="Elimina">
                {<DeleteIcon color="primary" style={{ fontSize: 30 }} />}
              </Tooltip>
            }
            label="Cancella"
            onClick={() =>
              handleDialogToEdit({ dialogType: "delete", rowInfo })
            }
          />
        )}
    </>
  );
};

export default ButtonBoxPostazioni;
