import { Button, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";

export default function TipsZoomMobile({ handleCloseTips, nameTips }) {

  return (
    <>
      <Grid container sx={{ maxWidth: "500px" }}>
        <Grid item xs={12}>
          <DialogTitle>Guida alla prenotazione</DialogTitle>
        </Grid>
        <Grid item xs={12}>
        <DialogContent>
          <Typography>Seleziona una stanza toccando l'icona corrispondente su mappa,
             dopodichè seleziona il posto letto. 
             Usa il doppio tocco su mappa per regolare lo zoom.</Typography>
             </DialogContent>
        </Grid>
        <Grid item xs={12}>
        <DialogActions >
          <Button color="inherit" sx={{float:"right", color:"#026D82"}} onClick={() => handleCloseTips(nameTips)}>HO CAPITO</Button>
          </DialogActions >
        </Grid>
      </Grid>
    </>
  );
}
