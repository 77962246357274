import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import logo from "../assests/logoBase.png"
import letto from "../assests/letto.png"
import servizio from "../assests/servizio.png"
import { Typography, Grid } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { setIsSalaRiunione} from "../redux/reducers/filterInitial-reducer"
import { useDispatch } from "react-redux";

export default function FiltroTipoPrenotazioni({
}) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
  useEffect(() => {

  }, []);

  const handleClick = (isSalaRiunioni) =>{
    sessionStorage.setItem('isSalaRiunioni', String(isSalaRiunioni));
    navigate("/disponibilita")
    dispatch(setIsSalaRiunione(isSalaRiunioni));
  }


  return (
    <>
    <Grid container className="filterContainer">
      <Grid item xs={12} sm={5} className="filterElementContainer letto" onClick={() => handleClick(false)}>
        <div className="filterElement">
          <h3 className="lettoText">
            PRENOTA LETTO
          </h3>
          <img src={letto} className="logoFilterImage" alt="Letto"></img>
          <Typography>
            Verifica la disponibilità per prenotare<br/> un ricovero ospedaliero.
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} sm={2} className="filterImageElement">
        <div className="filterElement">
          <img src={logo} className="logoFilterImage" alt="Logo"></img>
        </div>
      </Grid>
      <Grid item xs={12} sm={5} className="filterElementContainer servizio" onClick={() => handleClick(true)}>
        <div className="filterElement">
          <h3 className="servizioText">
            PRENOTA SERVIZIO
          </h3>
          <img src={servizio} className="logoFilterImage" alt="Servizio"></img>
          <Typography>
            Verifica la disponibilità per prenotare<br/> una visita medica o un esame diagnostico.
          </Typography>
        </div>
      </Grid>
    </Grid>

    </>
  );
}
