import axios from "axios";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { addPrenotazioni } from "../const/urlAction";
import { notificationOpen } from "../redux/reducers/notification-reducer";
import handlerError from "../functions/handlerError";
import { loadprenotazioniTableState } from "../redux/reducers/prenotazioneTable-reducer";
import { useState } from "react";

const urlBase = process.env.REACT_APP_URL;

let now = new Date(Date.now());
export default function usePrenotazioneQrCode(
  prenota,
  valueDataFine,
  idPostazione,
  closeGeneralDialog,
  returnBackComponentView,
  setIsLoading = () => null,
) {
  const dispatch = useDispatch();

  
  useEffect(() => {
    let isMounted = true;
    if (prenota > 0) {
      
      (async () => {
        setIsLoading(true) 

        let arrPrenotazioni = []
        arrPrenotazioni.push({Utente: null,IdPosto: idPostazione})

        const { action, method } = addPrenotazioni;
        let url = urlBase + action;
        
        let body = {
          prenotazioni: arrPrenotazioni,
          OraInizioPrenotazione: "00:00", // viene sempre sovrrascritto nel BE
          OraFinePrenotazione: moment(valueDataFine).format("HH:mm"),
          IsQrCodePrenotazione: true,
          DataPrenotazione:now
        };

        try {
          const response = await axios({
            method: method,
            url,
            data: body,
            headers: {
              "Content-Type": "application/json",
            },
          });
          if (response.data.ok && isMounted) {
            dispatch(
              notificationOpen({
                message: response.data.message,
                status: "success",
              }))
              dispatch(loadprenotazioniTableState());
              returnBackComponentView(false)
              closeGeneralDialog(false);
              setIsLoading(false)
          }
        } catch (error) {
          console.error(error);
           const notificationError = handlerError(error);
           dispatch(notificationOpen(notificationError));
           setIsLoading(false)
        }
      })();
    }
    return () => {
      isMounted = false;
      setIsLoading(false)
    };
  }, [prenota]);

  return null;
}
