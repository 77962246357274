import { useState, useEffect, useRef } from 'react';
import Navbar from "./navbar/Navbar";
import Sidebar from "./sidebar/Sidebar";
import { Grid, Box, Toolbar } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { closeSidebar } from '../redux/reducers/sidebar-reducer';
import { useNavigate } from 'react-router-dom';
import useMobileDetection from '../Hooks/useMobileDetection';
import SpinnerCustom from '../components/UI/spinner/spinner';

const Layout = ({ children }) => {
  const state = useSelector((state) => state.userDate);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stateSidebar = useSelector((state) => state);

  const location = useLocation();
  const Url = location.pathname;
  Url.substring(0, Url.lastIndexOf("/"))

  useEffect(() => {
    dispatch(closeSidebar())
  }, [navigate])

  let xsSize = useMobileDetection();

  return (
    <>
      <Box sx={{ display: 'flex' }} >
        {state.token !== undefined && Url !== '/filtro' && <Navbar initialState={stateSidebar} xsSize={xsSize} />}
        {state.token !== undefined && Url !== '/filtro' && <Sidebar initialState={stateSidebar} xsSize={xsSize} />}
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: xsSize ? '100%':'100vh',
            overflow: 'auto'
          }}
        >
          {Url !== '/' && <Toolbar className="containerAdaptiveHook" />}
          <Grid className="layout-main-grid " sx={Url === '/' ? { padding: "0px!important" } : {}} container rowSpacing={2} columnSpacing={2}>
            {children}
          </Grid>
        </Box>
      </Box>
      <SpinnerCustom />
    </>
  );
};

export default Layout;
