import CheckIcon from "@mui/icons-material/Check";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import {
  DISABILITATA,
  DISPONIBILE,
  OCCUPATA,
  RISERVATA,
} from "./StatoPostazione";
import { faBed, faBriefcaseMedical, faDoorOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const iconsZone = [
  <FontAwesomeIcon icon={faDoorOpen} style={{ color: "gray" }} fontSize="medium" />,
];
export const iconsStanze = [
  <FontAwesomeIcon icon={faBed}  style={{ color: "gray" }} fontSize="medium" />,
];

//General
export const iconSelectedElement = <CheckIcon />;

//Stanza
export const iconStanzaDefault = <FontAwesomeIcon icon={faDoorOpen} />;

//Postazione Riunione
export const iconPostazioneRiunione = <FontAwesomeIcon icon={faBriefcaseMedical} />;

//Stanza Riunione

export const iconStanzaRiunione = <FontAwesomeIcon icon={faBriefcaseMedical} />;

//Postazione
export const iconPostazioneDefault = <FontAwesomeIcon icon={faBed}  />;
export const iconDisablePostazione = <DoNotDisturbIcon />;
export const iconBusyPostazione = <FontAwesomeIcon icon={faBed}  />;
export const iconReservedPostazione = <DoNotDisturbIcon />;

//Postazione SalaRiunioni
export const iconBusySalaRiunionePostazione = <FontAwesomeIcon icon={faBriefcaseMedical} />;

export const listaPostazioneIcon = {
  general: iconPostazioneDefault,
  disable: iconDisablePostazione,
  busy: iconBusyPostazione,
  reserved: iconReservedPostazione,
};

export const listaPostazioneSalaRiunioniIcon = {
  general: iconPostazioneRiunione,
  disable: iconDisablePostazione,
  busy: iconBusySalaRiunionePostazione,
  reserved: iconReservedPostazione,
};

//vengono esportati nel metodo assegnaIconeInPlanimetriaGestione
export const stanzaIcon = { general: iconStanzaDefault };
export const stanzaRiunioneIcon = { general: iconStanzaRiunione };
export const postazioneInSalaRiunione = { general: iconPostazioneRiunione };
export const postazioneIconSemplice = { general: iconPostazioneDefault };

export const iconsSalaRiunioni = {
  postazione: iconPostazioneRiunione,
  stanza: iconStanzaRiunione,
};

const planimetriaPrenotazione = "planimetriaPrenotazione";

//lo style fa riferimento alle classi presenti nel css 'PlanimetriaIcons.css'
export function statusToIcon(
  Icon,
  element,
  iconWithStyle = false,
  userPointOfView = null
) {
  let icon = Icon.general;
  let style = "generalElement";

  if (element?.utentiPostazione) {
    let utentiPostazione = element.utentiPostazione.map(x => x.idUtente)
    if(utentiPostazione.includes(userPointOfView?.utente)) {
      element?.prenotazioni.length > 0 
      ? element.status = OCCUPATA
      : element.status = DISPONIBILE;
    }
  }
  
  if (iconWithStyle) {
    if (element.postazioniDisponibili === false)
    return { icon: icon, style: "PostazioneDisabled" };

    switch (true) {
      case element.status === OCCUPATA:
        icon = Icon.busy;
        style = "PostazioneBusy";
        break;
        case element.status === DISABILITATA:
          icon = Icon.disable;
          style = "PostazioneDisabled";
          break;
          case element.status === RISERVATA:
            icon = Icon.reserved;
            style = "Postazionereserved";
        break;
    }
  }

  return { icon, style };
}

export function statusToIconStanzaSalaRiunione(
  Icon,
  element,
  iconWithStyle = false,
  userPointOfView = null,
) {
  let icon = Icon.general;
  let style = "generalElement";


  if (element?.utentiPostazione) {
    let utentiPostazione = element.utentiPostazione.map(x => x.idUtente)
    if(utentiPostazione.includes(userPointOfView?.utente)) {
      element?.prenotazioni.length > 0 
      ? element.status = OCCUPATA
      : element.status = DISPONIBILE;
    }
  }

  if (true) {
    switch (true) {
      case element.status === OCCUPATA:
        icon = Icon.busy;
        style = "PostazioneBusy";
        break;
      case element.status === DISABILITATA:
        icon = Icon.disable;
        style = "PostazioneDisabled";
        break;
      case element.status === RISERVATA:
        icon = Icon.reserved;
        style = "Postazionereserved";
        break;
    }
  }
  return { icon, style };
}

export function statusToIconRiepilogoPrenotazione(
  Icon,
  element,
  utentePrincipale = undefined
) {
  let icon = Icon.general;
  let style = "generalElement";

  const {
    status,
    postazioniDisponibili,
    idPostazione,
    disabilitato,
    prenotazioni,
  } = element;

  const listUtentePrenotato = prenotazioni.map((x) => x.utentePrenotato);

  if (utentePrincipale?.idPostazione == element?.id)
    return { icon: iconSelectedElement, style: "PostazioneUtentePrincipale" };
  if (prenotazioni.length == 0)
    return { icon: Icon.general, style: "generalElement" };
  if (prenotazioni.every((x) => x.disabilitato === true))
    return { icon: Icon.disable, style: "PostazioneDisabled" };
  if (prenotazioni.length > 0)
    return { icon: Icon.busy, style: "PostazioneBusy" };

  return { icon, style };
}
