import { createSlice } from '@reduxjs/toolkit';

const initialState = false;

const changeView = createSlice({
    name:'changeView',
    initialState,
    reducers: {
        changeViewState: (state) => { 
            return !state;
         }
    }
})

export const { changeViewState } = changeView.actions;

const { reducer } = changeView;

export default reducer;