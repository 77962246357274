import { Grid } from "@mui/material";
import { padding } from "@mui/system";
import { useEffect, useState } from "react";

export default function ContainerPills({
  params = undefined,
  element = [],
}) {
  const [reload, setReload] = useState(false);
  if (!params) console.error("Component non implementato");
const {Component,handleDelete} = params;
useEffect(() => {
  setReload(!reload);
}, [element])

  return (
    <>
      <Grid container>
        <Grid item className="boxElementi-SuggerimentoPrenotazione" sx={{minHeight:"100px", display: "block"}}>
          {element.map((el, index) => {
            return <div key={index} style={{margin:"3px", display:"inline-block"}}><Component label={el} index={index} handleDelete={handleDelete} /></div>;
          })}
        </Grid>
      </Grid>
    </>
  );
}
