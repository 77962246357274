import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import EditIcon from '@mui/icons-material/Edit';
import LoopOutlinedIcon from '@mui/icons-material/LoopOutlined';
import { useDispatch } from "react-redux";
import { GridActionsCellItem } from "@mui/x-data-grid";
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from 'react-redux';
import {policies as permessi} from "../../const/policies"
import DeleteIcon from '@mui/icons-material/Delete';
import QrCodeIcon from "@mui/icons-material/QrCode";
import { useNavigate } from 'react-router-dom';
import { openDialog, setPageType } from '../../redux/reducers/DialogGeneral';
import { dialogPageTypes } from "../../const/dialogPageTypes"
import useMobileDetection from '../../Hooks/useMobileDetection';
import { useState } from 'react';
import useGenerateQrCode from '../../Hooks/useGenerateQrCode';
import { GenerateQrCodeStanza } from '../../const/urlAction';
import handlerConfiguration from '../../functions/general/handlerConfiguration';
import HotelIcon from '@mui/icons-material/Hotel';

const ButtonBoxStanze = ({ rowId, rowInfo }) => {
  const configurations = useSelector((state) => state.configurations);
  const {enableQrCodeScanner : available} = handlerConfiguration(configurations)
  const state = useSelector((state) => state.userDate);
  const { policies, responsabileEdificio } = state;
  const [isLoading, setIsLoading] = useState(false);
  const [idQrCodeToGenerate, setIdQrCodeToGenerate] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const handleDialogToEdit = ({dialogType, rowInfo}) => {
    dispatch(setPageType(dialogPageTypes.stanze))
    dispatch(openDialog({dialogType, rowInfo}))
  };

  const idStanza = rowInfo.id
  const isMobile = useMobileDetection();

  function handleDownloadQrCode(rowInfo) {
    const { id } = rowInfo;
    setIdQrCodeToGenerate(id);
  }

  useGenerateQrCode(idQrCodeToGenerate, setIsLoading, GenerateQrCodeStanza);

  return (
    <>
      {policies.indexOf(permessi.zoneInserimentoModifica) != -1 && <GridActionsCellItem
        icon={
          <Tooltip title="Modifica" >
            <EditIcon sx={{fontSize: 30 }}
            color="primary" />
          </Tooltip>
        }
        label="Modifica"
        onClick={() => handleDialogToEdit({dialogType:"edit", rowInfo})}
      />}
        {policies.indexOf(permessi.stanzeVisualizzazione) != -1 &&<GridActionsCellItem
        icon={
          <Tooltip title="Gestisci Posti letto" >
              <HotelIcon sx={{ fontSize: 30 }} color="primary" />
          </Tooltip>
        }
        label="Posti letto"
        onClick={() => navigate("" + idStanza)}
      />}
            {(available)  &&
        !isMobile &&
        (policies.indexOf(permessi.postazioniDownloadQRCode) != -1 ||
          responsabileEdificio.length > 0) && (
          <GridActionsCellItem
            icon={
              <Tooltip title="Download QR Code">
                {<QrCodeIcon color="primary" style={{ fontSize: 30 }} />}
              </Tooltip>
            }
            label="Cancella"
            onClick={() => handleDownloadQrCode(rowInfo)}
          />
        )}
        {policies.indexOf(permessi.stanzeEliminazioneRipristino) != -1 &&<GridActionsCellItem
        icon={
          <Tooltip title={!rowInfo.dataDel ? "Cancella" : "Abilita"}>
           { rowInfo.dispositivoAbilitato ? <DoDisturbIcon sx={{fontSize: 30 }} color="primary" /> : <DeleteIcon sx={{fontSize: 30 }} color="primary" />}
          </Tooltip> 
        }
        label="Cancella"
        onClick={() => handleDialogToEdit({dialogType:"delete", rowInfo})}
      />}
      </>
  );
};

export default ButtonBoxStanze;
