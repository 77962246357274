

    export function initialValuesUserEdit(rowInfo) {
      return {id: rowInfo.id,
      firstName: "",
      email:"",
      lastName: "",
      username: "",
      phoneNumber: "",
      roles: [],
      users: [],}
    };

    export function initialValuesUserAdd() {
      return {id: "",
      firstName: "",
      lastName: "",
      username: "",
      email: "",
      phoneNumber: "",
      roles: [],
      users: [],
      Password: "",
      ConfirmPassword: "",}
    };

    export function initialValuesUserSignIn() {
      return {id: "",
      firstName: "",
      lastName: "",
      username: "",
      email: "",
      phoneNumber: "",
    };
  }

export function initialValuesUserReset(rowInfo){
      return {id: rowInfo.id,
        FirstName: rowInfo.FirstName,
        LastName: rowInfo.LastName,
        Username: rowInfo.Username,}
    };

export function initialValuesUserDelete(rowInfo) {
return {id: rowInfo.id,
    FirstName: rowInfo.FirstName,
    LastName: rowInfo.LastName,
    Username: rowInfo.Username,}
    };