import { createSlice } from '@reduxjs/toolkit';

const initialState = {isOpenRemove:false , dialogType:"", dialogPageType:"", rowInfo:-1, id : 0};

const dialogRemoveSlice = createSlice({
    name:'dialogRemove',
    initialState,
    reducers: {
        openEmptyDialogRemove: (state, payload) => { 
            return {...state, isOpenRemove: true, dialogType:payload.payload};
         },
         setPageType: (state, payload) => { 
            return {...state, dialogPageType:payload.payload};
         },
         openDialogRemove:(state,payload) => {
           
             return {...state, id:payload.payload, isOpenRemove:true, rowInfo:payload.payload.rowInfo}
         },
         closeDialogRemove: (state) => {
            return {...state, isOpenRemove: false};
         }
    }
});

export const { openDialogRemove, closeDialogRemove, openEmptyDialogRemove, setPageType } = dialogRemoveSlice.actions;

const { reducer } = dialogRemoveSlice;

export default reducer;