import {
  deletePrenotazione as deletePrenotazioneAction,
  checkOutPrenotazione as checkOutPrenotazioneAction,
  checkInPrenotazione as checkInPrenotazioneAction
} from "../const/urlAction";

export const dialogList = {
  checkOutPrenotazione: "CHECKOUT",
  deletePrenotazione: "DELETE",
  checkInPrenotazione: "CHECKIN",
};

export default function createDialogConfirmActionInDialogRiepilogo(
  dialog,
  body
) {
  const { deletePrenotazione, checkOutPrenotazione, checkInPrenotazione } = dialogList;

  let object = {};

  switch (true) {
    case dialog === deletePrenotazione:
      object = {
        noteField: true,
        viewComponent: {
          textPrincipal: "Vuoi confermare l'eliminazione della prenotazione?",
          textTitle: "Elimina prenotazione",
        },
        actionApi: deletePrenotazioneAction,
        body: body,
      };
      break;
    case dialog === checkOutPrenotazione:
      object = {
        noteField: false,
        actionApi: checkOutPrenotazioneAction,
        viewComponent: {
          textPrincipal: "Vuoi confermare il checkout della prenotazione?",
          textTitle: "Checkout prenotazione",
        },
        body: body,
      };
      break;
    case dialog === checkInPrenotazione:
      object = {
        noteField: false,
        actionApi: checkInPrenotazioneAction,
        viewComponent: {
          textPrincipal: "Vuoi confermare il check-in della prenotazione?",
          textTitle: "Check-in prenotazione",
        },
        body: body,
      };
      break;
  }
  return object;
}
