export default function CalcPercenteProgressBar(
  percentStatusConfiguration,
  occupate,
  disponibili
) {
  let percent = (occupate / disponibili) * 100;
  percent = Math.round(percent)
  
  if(isNaN(percent)) percent = 0;

  return progressBarStatus(percentStatusConfiguration, percent);
}

function progressBarStatus(configuration, valueProgressBar) {
  const {lowStatus, mediumStatus} = configuration;
  let colorProgressbar;

  //i colori sono configurati a livello di Thema di mui.
  switch (true) {
    case mediumStatus < valueProgressBar:
        colorProgressbar = "customError";
        break;
    case mediumStatus >= valueProgressBar && valueProgressBar > lowStatus:
        colorProgressbar = "customWarning";
      break;
    case valueProgressBar <= lowStatus:
        colorProgressbar = "customSuccess";
      break;
  }
  return {valueProgressBar,colorProgressbar }
}
