import { Grid, Popover } from "@mui/material";
import React, { useState } from "react";
import ShowSubMenuInfo from "./ShowSubMenuInfo/ShowSubMenuInfo";

export default function InfoSubMenu({open = false, anchorEl={}, info = null, dataSelected}) {
    return (
    <React.Fragment>
        { info !== null && 
        <div>
           <Popover 
           open={open}
           anchorEl={anchorEl}
           anchorOrigin={{
             vertical: 'center',
             horizontal: 'right',
           }}
         >
          <Grid container>
          <div style={{padding:"0 0px 0px 0px", width:'20.5rem'}}>
            <ShowSubMenuInfo info={info} dataSelected={dataSelected}/>
        </div> 
        </Grid>
        </Popover> 
        </div>
        }
    </React.Fragment>
    );
}
