import { Grid, TextField } from "@mui/material";
import { DesktopDatePicker, TimePicker, LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import React, { useEffect } from "react";
import itLocale from "date-fns/locale/it";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { checkRangeDatePrenotazioniFilter } from "../../../functions/checkRangeDate";
import GeneralCustomDateTimePicker from "../../UI/TimePicker/GeneralCustomDateTimePicker";

const now = new Date(Date.now());
const maxday = new Date(new Date(Date.now()).setDate(now.getDate() + 31));

export function DatapickerBoxDisabilita({ disableButton,valueInizio, valueFine, expanded, isActiveRadioButtonUser}) {
  const [valueDataGiornoInizio, setValueDataGiornoInizio] = React.useState(null);
  const [valueDataGiornoFine, setValueDataGiornoFine] = React.useState(null);

  const { isCorrectRangeData} = checkRangeDatePrenotazioniFilter(valueDataGiornoInizio,valueDataGiornoFine)

  useEffect(() => {
    disableButton(isCorrectRangeData);
  },[valueDataGiornoInizio,valueDataGiornoFine])


  const handleChangeDataInizio = (newValue) => {
    if (newValue !== null) {
        valueInizio(newValue)
      setValueDataGiornoInizio(newValue);
    } else {
      setValueDataGiornoInizio(null);
    }
  };

  const handleChangeDataFine = (newValue) => {
    if (newValue !== null) {
        valueFine(newValue)
      setValueDataGiornoFine(newValue);
    } else {
      ;
      setValueDataGiornoFine(null);
    }
  };

  useEffect(()=> {
    setValueDataGiornoInizio(null);
    setValueDataGiornoFine(null);

  },[expanded,isActiveRadioButtonUser])

  return (
    <React.Fragment>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={itLocale}>
          <Grid container columnSpacing={3} rowSpacing={5}>
        <Grid item xs={12} lg={6}>
        <GeneralCustomDateTimePicker
              TypeTimePicker={7}
              label="Data Inizio"
              valueStart={valueDataGiornoInizio}
              handleChange={handleChangeDataInizio}
              placeholder= "gg/mm/aaaa hh:mm"
            />
        </Grid>
        <Grid item xs={12} lg={6}>
        <GeneralCustomDateTimePicker
              TypeTimePicker={7}
              label="Data Fine"
              valueStart={valueDataGiornoFine}
              handleChange={handleChangeDataFine}
              placeholder= "gg/mm/aaaa hh:mm"
            />
        </Grid>
        </Grid>
      </LocalizationProvider>
    </React.Fragment>
  );
}
