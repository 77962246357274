export function recuperaOraEMinutiDaStringa(string) {
    const data = new Date(string)
    let ora = data.getHours();
    let minuti = data.getMinutes()

    return {ora,minuti};
}

export function recuperaOraEMinutiDaDefault(orario) {
if(orario === undefined) return
    let oraFine = orario.substring(0, 2);
    let minutiFine = orario.substring(3, 5);

    let now = new Date(Date.now());

   return  now > new Date(new Date(new Date(Date.now()).setHours(oraFine)).setMinutes(minutiFine))
        ? null
        : new Date(new Date(new Date(Date.now()).setHours(oraFine)).setMinutes(minutiFine));

}