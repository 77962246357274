import { useEffect } from "react";
import { handlerZoom } from "../functions/handlerZoom";

export default function useNoPassiveEventListener(ref, event, func) {
  useEffect(() => {
    if (ref.current)
      ref.current.addEventListener(
        event,
        (e) => {
          handlerZoom(e, func);
        },
        { passive: false }
      );
  }, [ref.current]);
}
