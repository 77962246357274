


export    const noHeaderStyle = {
        '& .MuiDataGrid-columnHeader:focus': {
          outline: 'none'
        }, 
        '& .MuiDataGrid-columnHeader:focus-within': {
          outline: 'none'
        },
        '& .MuiDataGrid-root': {
            outline: 'none !important'
        },
        '& .MuiDataGrid-cell:focus-within':{
            outline: 'none !important'
        },
        '& .MuiDataGrid-virtualScroller' :{
          marginTop: '0 !important'
        },
        '& .css-f3jnds-MuiDataGrid-columnHeaders':{
          display: 'none !important'
        },
        '& .css-1nitb7c': {
          display: 'none !important'
        }
      }

   export  const withHeaderStyle = {
            '& .MuiDataGrid-columnHeader:focus': {
              outline: 'none'
            }, 
            '& .MuiDataGrid-columnHeader:focus-within': {
              outline: 'none'
            },
            '& .MuiDataGrid-root': {
                outline: 'none !important'
            },
            '& .MuiDataGrid-cell:focus-within':{
                outline: 'none !important'
            }
      }


      export const smallSize = {
        '& .css-1yyanpp-MuiFormControlLabel-root': {
          fontSize: '13px !important'
        },
        '& .MuiFormControlLabel-label': {
          fontSize: '13px !important'
        }
      }
      export const NormalSize = {

       }