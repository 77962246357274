import { createSlice } from '@reduxjs/toolkit';

const initialState = {idEdificio:0, idZona:0, idStanza:0, Edificio: {}, Zona: {}, Stanza: {}, Postazione: {}};

const infoPrenotazione = createSlice({
    name:'infoPrenotazione',
    initialState,
    reducers: {
         saveEdificioInPrenotazioneStepper: (state,payload) => {
            return {...state, idEdificio: payload.payload};
         },
         resetEdificioInPrenotazioneStepper: (state,payload) => {
            return {...state, idEdificio: payload.payload};
         },
        saveZonaInPrenotazioneStepper: (state,payload) => {
            return {...state, idZona: payload.payload};
         },
         resetZonaInPrenotazioneStepper: (state) => {
            return {...state, idZona: 0};
         },
         saveStanzaInPrenotazioneStepper: (state,payload) => {
            return {...state, idStanza: payload.payload};
         },
         resetStanzaInPrenotazioneStepper: (state) => {
            return {...state, idStanza: 0};
         },
         saveEdificioCompletoInPrenotazioneStepper: (state,payload) => {
            return {...state, Edificio: payload.payload};
         },
         saveZonaCompletoInPrenotazioneStepper: (state,payload) => {
            return {...state, Zona: payload.payload};
         },
         saveStanzaCompletoInPrenotazioneStepper: (state,payload) => {
            return {...state, Stanza: payload.payload};
         },
         savePostazioneCompletoInPrenotazioneStepper: (state,payload) => {
            return {...state, Postazione: payload.payload};
         },
         resetStatePrenotazioneStepper: (state,payload) => {
            return initialState;
         },
    }
});

export const gestisciDatiPrenotazionePlanimetria = ( payload, state) => (dispatch) => {
   dispatch(saveEdificioInPrenotazioneStepper(payload[0].id))
   dispatch(saveZonaInPrenotazioneStepper(payload[1].id))
   dispatch(saveStanzaInPrenotazioneStepper(payload[2].id))
   dispatch(saveEdificioCompletoInPrenotazioneStepper(payload[0]))
   dispatch(saveZonaCompletoInPrenotazioneStepper(payload[1]))
   dispatch(saveStanzaCompletoInPrenotazioneStepper(payload[2]))
        // console.log(payload)
 }
 

export const { saveZonaInPrenotazioneStepper, saveEdificioInPrenotazioneStepper , savePostazioneCompletoInPrenotazioneStepper, saveEdificioCompletoInPrenotazioneStepper, saveZonaCompletoInPrenotazioneStepper, saveStanzaCompletoInPrenotazioneStepper ,resetEdificioInPrenotazioneStepper, saveStanzaInPrenotazioneStepper, resetZonaInPrenotazioneStepper, resetStanzaInPrenotazioneStepper,  resetStatePrenotazioneStepper} = infoPrenotazione.actions;

const { reducer } = infoPrenotazione;

export default reducer;