import { Divider, Grid, Typography } from "@mui/material";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { useState } from "react";
import { removeDataGridHeader } from "../../../functions/handlerDataGrid";
import {
  CDCardContent,
  CDCard,
  CDCardHeader,
} from "../../../const/CDCardStyles";
import { TitleDatable } from "../../TitleDatable/TitleDataTable";
import Notification from "../Notification/Notification";
import { useEffect } from "react";
import DialogConferma from "../Dialog/DialogConferma";
import { useSelector } from "react-redux";
import CheckValidSortModel from "../../../functions/CheckValidSortModel";


/* il searchField va passato nel Toolbar e poi riunito nel RequestBody del chiamante di CustomStyledDataGrid */
export default function CustomStyledDataGrid({
  viewState = false,
  idRowsfield = "id",
  headers = [],
  rows = [],
  rowsCount = 0,
  nameDataGrid="NoName",
  pageSizeDataGrid = 10,
  rowHeight = 120,
  setRequestBody = () => null,
  reloadRow = () => null,
  setSateSpinner = false,
}) {
  const reloading = useSelector((state) => state.dataTableUsers);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(pageSizeDataGrid);
  const StyledDataGrid = removeDataGridHeader(DataGrid, viewState);
  const [loading, setLoading] = useState(false);
  const [sortModelInternal, setSortModelInternal] = useState([
    {
      field: headers.initialFieldName,
      sort: headers.initialFieldOrder,
    },
  ],);

  
  useEffect(() => {

    const { sortField, sortOrder } = CheckValidSortModel(
      sortModelInternal,
      headers.initialFieldName
    );

    setRequestBody({
      pageIndex: page,
      pageSize: pageSize,
      sortColumnName: sortField,
      sortDirection: sortOrder,
      applyPaginationAndSort: true,
    })

    reloadRow(loading)
    setLoading(!loading)
  },[reloading,sortModelInternal, page, pageSize,])
  

  return (
    <>
      <Grid container>
        <Grid item component={CDCard} xs={12} sm={12}>
          <CDCardHeader
            className="CDCardHeader-Padding"
            title={
              <>
                <TitleDatable nameDataTable={nameDataGrid} />
              </>
            }
          />
          <Divider />
          <CDCardContent>
            <Grid container item>
              <StyledDataGrid
                rows={rows}
                rowCount={rowsCount}
                getRowId={(row) => row[`${idRowsfield}`]}
                columns={headers.headers}
                page={page}
                pageSize={pageSize}
                onPageChange={(newPage) => setPage(newPage)}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                onSortModelChange={(newSortModel) => setSortModelInternal(newSortModel)}
                rowsPerPageOptions={[10, 25, 50, 100]}
                disableSelectionOnClick
                sortModel={sortModelInternal}
                sortingMode="server"
                sortingOrder={["asc", "desc"]}
                paginationMode="server"
                sx={{ border: 0 }}
                loading={setSateSpinner}
                disableColumnMenu
                disableColumnFilter
                disableColumnSelector
                autoHeight
                rowHeight={rowHeight}
                autoWidth
                components={{
                  // Toolbar: () => (
                  //   <TableToolbar
                  //     typePage={typePage}
                  //     enableAdd={true}
                  //     enableSearch={true}
                  //     isSalaRiunione={false}
                  //     enableExport={true}
                  //     handleAdd={() => {
                  //       dispatch(setPageType(dialogPageTypes.user));
                  //       dispatch(openEmptyDialogUser("add"));
                  //     }}
                  //     initialSearchText={searchFilter}
                  //     startSearch={handleSearchFilter}
                  //   />
                  // ),
                  NoRowsOverlay: () => (
                    <GridOverlay>
                      <Typography>Nessun elemento</Typography>
                    </GridOverlay>
                  ),
                  NoResultsOverlay: () => (
                    <GridOverlay>
                      <Typography>Nessun elemento</Typography>
                    </GridOverlay>
                  ),
                }}
              />
            </Grid>
          </CDCardContent>
        </Grid>
      </Grid>
      <Notification />
    </>
  );
}
