import { checkDisabilitazionePostazioniToEdificio, checkDisabilitazionePostazioniToStanza, checkDisabilitazionePostazioniToZona, disableAndRestorePostazioniDaStanza, disableAndRestorePostazioniDaZona, disablePostazioniDaEdificio, disablePostazioniDaStanza, disablePostazioniDaZona, edificioAndDetail, editPostazione, editStanza, editStanzaPosizione, getBackgroundPlanimetriaStanzaByIdStanza, getBackgroundPlanimetriaZonaByIdZona, getStanzeByIdZona, restorePostazioniDaEdificio, RestorePostazioniDaStanza, restorePostazioniDaZona, stanzaAndDetail, zonaAndDetail } from "../const/urlAction";
import { getPostazioniByIdStanza } from "../const/urlAction";

const urlBase = process.env.REACT_APP_URL;

/* Tramite UrlParams che richiama il component 'Planimetria' il metodo capisce che tipo di planimetria e con quali dati va effettuata la chiamata.*/ 
export function planimetriaUrlParamsForElements(UrlParams) {

    let id = UrlParams.idStanza === undefined ? UrlParams.idZona : UrlParams.idStanza
    let type = UrlParams.idStanza === undefined ? 'zona' : 'stanza'
    let { action, method } = UrlParams.idStanza === undefined ? getStanzeByIdZona : getPostazioniByIdStanza

    return {id, type, action, method}

}

export function planimetriaUrlParamsForBackground(UrlParams) {
    let id = UrlParams.idStanza === undefined || UrlParams.idStanza ===  0 ? UrlParams.idZona : UrlParams.idStanza
    let { action, method } = UrlParams.idStanza === undefined || UrlParams.idStanza ===  0 ? getBackgroundPlanimetriaZonaByIdZona : getBackgroundPlanimetriaStanzaByIdStanza
    return {id, action, method}

}

export function planimetriaUrlParamsForBackgroundPlanimetriaMostraInfo(idStanza) {
    let id = idStanza
    let { action, method } = getBackgroundPlanimetriaStanzaByIdStanza
    return urlBase + action + id

}

export const typeEntityPlanimetria = { stanza: 0 , postazione : 1}

export function planimetriaUrlParamsForElementsEdit(UrlParams) {

    let id = UrlParams.idStanza === undefined ? UrlParams.idZona : UrlParams.idStanza
    let { action, method } = UrlParams.idStanza === undefined ? editStanzaPosizione : editPostazione
    let type = UrlParams.idStanza === undefined ? typeEntityPlanimetria.stanza : typeEntityPlanimetria.postazione
    return {id, action, method, type}

}

export function urlDettaglioEntita(UrlParams) {
const {idZona, idStanza, idEdificio } = UrlParams;
let entita; let action; let method;

    switch (true) {
        case idStanza !== undefined:
            entita = Number(idStanza);
            action = stanzaAndDetail.action;
            method = stanzaAndDetail.method;
            break;
        case idZona !== undefined:
            entita = Number(idZona);
            action = zonaAndDetail.action;
            method = zonaAndDetail.method;
            break;
        case idEdificio !== undefined:
            entita = Number(idEdificio);
            action = edificioAndDetail.action;
            method = edificioAndDetail.method;
            break;
    }

    return {entita, action, method}
}

export function urlDisabilitaEntita(UrlParams) {
    const {idZona, idStanza, idEdificio } = UrlParams;
    let entita; let action; let method;
    
        switch (true) {
            case idStanza !== undefined:
                entita = Number(idStanza);
                action = disablePostazioniDaStanza.action;
                method = disablePostazioniDaStanza.method;
                break;
            case idZona !== undefined:
                entita = Number(idZona);
                action = disablePostazioniDaZona.action;
                method = disablePostazioniDaZona.method;
                break;
            case idEdificio !== undefined:
                entita = Number(idEdificio);
                action = disablePostazioniDaEdificio.action;
                method = disablePostazioniDaEdificio.method;
                break;
        }
    
        return {entita, action, method}
    }

    export function urlAbilitaEntita(UrlParams) {
        const {idZona, idStanza, idEdificio } = UrlParams;
        let entita; let action; let method;
        
            switch (true) {
                case idStanza !== undefined:
                    entita = Number(idStanza);
                    action = RestorePostazioniDaStanza.action;
                    method = RestorePostazioniDaStanza.method;
                    break;
                case idZona !== undefined:
                    entita = Number(idZona);
                    action = restorePostazioniDaZona.action;
                    method = restorePostazioniDaZona.method;
                    break;
                case idEdificio !== undefined:
                    entita = Number(idEdificio);
                    action = restorePostazioniDaEdificio.action;
                    method = restorePostazioniDaEdificio.method;
                    break;
            }
        
            return {entita, action, method}
        }


        export function urlCheckDisabilitazioni(UrlParams) {
            const {idZona, idStanza, idEdificio } = UrlParams;
            let entita; let action; let method;
            
                switch (true) {
                    case idStanza !== undefined:
                        entita = Number(idStanza);
                        action = checkDisabilitazionePostazioniToStanza.action;
                        method = checkDisabilitazionePostazioniToStanza.method;
                        break;
                    case idZona !== undefined:
                        entita = Number(idZona);
                        action = checkDisabilitazionePostazioniToZona.action;
                        method = checkDisabilitazionePostazioniToZona.method;
                        break;
                    case idEdificio !== undefined:
                        entita = Number(idEdificio);
                        action = checkDisabilitazionePostazioniToEdificio.action;
                        method = checkDisabilitazionePostazioniToEdificio.method;
                        break;
                }
                return {entita, action, method}
            }