import { useSelector } from "react-redux";
import AvvisoFormMessage from "../../../UI/Form/AvvisoFormMessage";

export default function MessagePasswordRequired() {
    const configurations = useSelector((state) => state.configurations.configurazioniAutenticazione.configurazionePasswordRequisiti);

 function createMessagePasswordRequired() {
    if(!configurations) return <></>

    let text = "La password deve contenere: "
    if(configurations.requireLength)  text +=  "8 caratteri"
    if(configurations.requireDigit)  text +=  ", un valore numerico"
    if(configurations.requireLowercase)  text +=  ", una minuscola"
    if(configurations.requireNonAlphanumeric)  text +=  ", un carattere speciale"
    if(configurations.requireUppercase)  text +=  ", una maiuscola"
    
    return text
 }

    return (
        <AvvisoFormMessage msg={createMessagePasswordRequired()} />
    )
}