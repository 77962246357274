import StanzeDatatable from "../../components/StanzeDatatable/StanzeDatatable";
const StanzePanel = () => {

return (
    <>
    <StanzeDatatable></StanzeDatatable>
    </>
)

}

export default StanzePanel