import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button } from "@mui/material";

//serve per cambiare il modo in cui si presenta un component, simulando un finto tasto di back
export function BackButtonChangeView({changeView = () => null}) {

  return (
    <>
      {changeView && (
        <Button
          sx={{
            borderRadius: "500px",
            backgroundColor: "#a0b3d36b",
            padding: "0",
            minHeight: "35px",
            minWidth: "35px",
          }}
          onClick={() => {changeView()}}
        >
          <ArrowBackIcon sx={{ mr: 0, my: 0 }} />
        </Button>
      )}
    </>
  );
}
