import { createSlice } from '@reduxjs/toolkit';

const initialState = {isOpenRiepilogo:false , dialogType:"", dialogPageType:"", rowInfo:-1, id : 0};

const dialogRiepilogoSlice = createSlice({
    name:'dialogRiepilogo',
    initialState,
    reducers: {
         openEmptyDialogRiepilogo: (state, payload) => { 
            return {...state, isOpenRiepilogo: true, dialogType:payload.payload};
         },
         setPageType: (state, payload) => { 
            return {...state, dialogPageType:payload.payload};
         },
         openDialogRiepilogo:(state,payload) => {
           
             return {...state, id:payload.payload, isOpenRiepilogo:true, rowInfo:payload.payload.rowInfo}
         },
         closeDialogRiepilogo: (state) => {
            return {...state, isOpenRiepilogo: false};
         }
    }
});

export const { openDialogRiepilogo, closeDialogRiepilogo, openEmptyDialogRiepilogo, setPageType } = dialogRiepilogoSlice.actions;

const { reducer } = dialogRiepilogoSlice;

export default reducer;