export const marks = [
    { value: 0, label: "GEN" },
    { value: 1, label: "FEB" },
    { value: 2, label: "MAR" },
    { value: 3, label: "APR" },
    { value: 4, label: "MAG" },
    { value: 5, label: "GIU" },
    { value: 6, label: "LUG" },
    { value: 7, label: "AGO" },
    { value: 8, label: "SET" },
    { value: 9, label: "OTT" },
    { value: 10, label: "NOV" },
    { value: 11, label: "DIC" },
  ];