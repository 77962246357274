import { createSlice } from '@reduxjs/toolkit';

const initialState = true;

const dialogBackButton = createSlice({
    name:'dialogBackButton',
    initialState,
    reducers: {
         closeDialogWithBackButton: (state) => {
            return !state;
         }
    }
});

export const { closeDialogWithBackButton } = dialogBackButton.actions;

const { reducer } = dialogBackButton;

export default reducer;