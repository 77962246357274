export const initialState = {
  utente: null,
  DataOraInizio: null,
  DataOraFine: null,
  Postazione: null,
  Stanza: null,
  Zona: null,
  Edificio: null,
  RadioButtonUser: true,
  MostraDisabilitazioni: false,
  PrenotazioniPassate: false,
  OrderByDateAsc: true,
};

const filterHistoryDatatableReducer = (state, action) => {
  switch (action.type) {
    case "ADD_UTENTE":
      state = { ...state, utente: action.payload };
      break;
    case "ADD_POSTAZIONE":
      state = { ...state, Postazione: action.payload };
      break;
    case "SET_RADIOBUTTON_USER":
      state = { ...state, RadioButtonUser: action.payload };
      break;
    case "ADD_STANZA":
      state = { ...state, Stanza: action.payload, Postazione: null };
      break;
    case "ADD_ZONA":
      state = { ...state, Zona: action.payload, Stanza: null, Postazione: null };
      break;
    case "ADD_EDIFICIO":
      state = { ...state, Edificio: action.payload, Zona: null, Stanza:null, Postazione:null };
      break;
    case "ADD_DATAINIZIO":
      state = { ...state, DataInizio: action.payload };
      break;
    case "ADD_DATAFINE":
      state = { ...state, DataFine: action.payload };
      break;
    case "ADD_ORAINIZIO":
      state = { ...state, OrarioInizio: action.payload };
      break;
    case "ADD_ORAFINE":
      state = { ...state, OrarioFine: action.payload };
      break;
    case "ADD_PRENOTAZIONESTATUS":
      state = { ...state, MostraDisabilitazioni: action.payload };
      break;
    case "ADD_PRENOTAZIONIPASSATE":
      state = { ...state, PrenotazioniPassate: action.payload };
      break;
    case "ORDER_DATE_ASC":
      state = { ...state, OrderByDateAsc: action.payload };
      break;
    case "RESET":
      state = initialState;
      break;
  }
  return state;
};

export default filterHistoryDatatableReducer;
