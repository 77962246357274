import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetTaskSincronizzazioneUtenti } from "../../const/urlAction";
import { utentiSincronizzazioneSetTask } from "../../redux/reducers/TaskAsync";

const urlBase = process.env.REACT_APP_URL;
export default function useTaskAsyncRun() {
    const taskState = useSelector((state) => state.taskAsync);
    const [reloadTask, setReloadTask] = useState(false);
    const [task, setTask] = useState(null);
    const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const { action, method } = GetTaskSincronizzazioneUtenti;
      let url = urlBase + action;
      try {
        const response = await axios({
          method: method,
          url,
        });
        if (response.data.ok) {
          dispatch(utentiSincronizzazioneSetTask(response.data.result));
          setTask(response.data.result)
        }
      } catch (error) {
        console.error(error);
      }
    })();
    return () => {
      if (task !== null) {
        setTimeout(function () {
          setReloadTask(!reloadTask);
        }, 1000);
      }
    };
  }, [taskState.utentiSincronizzazione.stateTask,task,reloadTask]);

}