import React from "react";

const IconaPlanimetria = ({ icon }) => {

  return (
    <React.Fragment>
          {icon}
    </React.Fragment>
  );
};

export default IconaPlanimetria;
