import { createSlice } from '@reduxjs/toolkit';

const initialState = false;

const dataTableUsers = createSlice({
    name:'dataTableUsers',
    initialState,
    reducers: {
        reloadingDataTableUsers: (state) => {
            return !state;
         }
    }
})

export const { reloadingDataTableUsers  } = dataTableUsers.actions;

const { reducer } = dataTableUsers;

export default reducer;