import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const userSlice = createSlice({
    name:'users',
    initialState,
    reducers: {
        loadUsersState: (state, payload) => { 
            return payload.payload
         }
    }
})

export const { loadUsersState } = userSlice.actions;

const { reducer } = userSlice;

export default reducer;