import Riepilogo from "../../../../components/Dialog/DialogRiepilogo/tabsDialogRiepilogo/singleTab/Riepilogo";
import Riunione from "../../../../components/Dialog/DialogRiepilogo/tabsDialogRiepilogo/singleTab/Riunione";
import PlanimetriaMostraRiepilogoPrenotazione from "../../../../components/Planimetria/PlanimetriaMostraRiepilogoPrenotazione/PlanimetriaMostraRiepilogoPrenotazione";

export default function ListTabsDialogoRiepilogo(id, returnSizePlanimetria, rows) {
    return [
      { componentTab: <Riepilogo id={id} />,
        label: "riepilogo" },
      {
        componentTab: (
          <PlanimetriaMostraRiepilogoPrenotazione
            returnSizePlanimetria={returnSizePlanimetria}
            dati={rows}
          />
        ),
        label: "planimetria",
      },
       // nascosto demo
      //  (rows.isSalaRiunione ? { componentTab: <Riunione id={id} titoloRiunione={rows.titoloRiunione}/>, label: "riunione"} : null) 

      ,
    ];
  }