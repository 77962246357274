import ButtonBoxPostazioni from "../components/PostazioniDatatable/ButtonBoxPostazioni";
import ButtonBoxStanze from "../components/StanzeDatatable/ButtonBoxStanze";
import { getPostazioneById, getStanzaById } from "../const/urlAction";

export function setComponentBoxButtonInSubMenu(type){
    let boxMenu = null;
    switch(true) {
        case type === 'zona':
            boxMenu = ButtonBoxStanze
          break;
         case type === 'stanza':
            boxMenu = ButtonBoxPostazioni
          break;
      }

return boxMenu;
}

export function setCallBoxButtonInSubMenuForAction(type){
  let callType = {};

  switch(true) {
      case type === 'zona':
        callType = getStanzaById
        break;
       case type === 'stanza':
        callType = getPostazioneById
        break;
    }

return callType;
}
