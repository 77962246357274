export function calcolaPercentualeDiMarginDaScaleImage(initialScale, Scale, idSfondoPlanimetria, customPlanimetria, defaultPlanimetria) {
    let num = Scale - initialScale
    let denominatore = definisciDenominatoreCalcoloPercentualePlanimetria(num)

    let isCustomImgPlanimetria = idSfondoPlanimetria ? true : false;

    let height = isCustomImgPlanimetria ? customPlanimetria.altezzaCustomImg : defaultPlanimetria.altezzaDefault;
    let width = isCustomImgPlanimetria ? customPlanimetria.larghezzaCustomImg : defaultPlanimetria.larghezzaDefault;
  
    let deltaMarginLeft = (width / denominatore) / 2 
    let detlaMarginTop = ( height /denominatore) / 2


    return {height, width, marginLeft:deltaMarginLeft, marginTop: detlaMarginTop}
}


export function definisciDenominatoreCalcoloPercentualePlanimetria(num){
  return calcolaScallingPercentualePlanimetria(num)
}

export function moltiplicaPercentualeScalling(num) {
  return calcolaScallingPercentualePlanimetria(num)
}


function calcolaScallingPercentualePlanimetria(
  num
) {
  num = Math.abs(num)
  let str = "" + num;
  let arrayStr = str.split("");

  const arrayReversed = arrayStr.reverse();

  let arrayFiltered = arrayReversed.filter((x) => x != ".");

  arrayFiltered[0] = 1;
  let number = arrayFiltered.join("");
  
  return parseInt(number);
}
