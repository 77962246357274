import * as yup from "yup";

/* ## Building ## */

export const validationSchemaBuildingEdit = yup.object({
        Denominazione: yup
          .string()
          .required("Campo Obbligatorio."),
        Sigla: yup
          .string()
          .required("Campo Obbligatorio."),
        UtentiResponsabiliPrenotazioni: yup.array()
        .of(
          yup.object().shape({
           idUtente: yup.string().required("Campo Obbligatorio."),
           nominativo: yup.string().required("Campo Obbligatorio.")
          })
        .required("Campo Obbligatorio.")
      )
      });

      export const validationSchemaBuildingAdd = yup.object({
        Denominazione: yup
          .string()
          .required("Campo Obbligatorio."),
        Sigla: yup
          .string()
          .required("Campo Obbligatorio."),
        UtentiResponsabiliPrenotazioni: yup.array()
        .of(
          yup.object().shape({
           idUtente: yup.string().required("Campo Obbligatorio."),
           nominativo: yup.string().required("Campo Obbligatorio.")
          })
        .required("Campo Obbligatorio.")
      )
  
      });