import {
  Button,
  Checkbox,
  DialogContentText,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { dialogPageTypes } from "../../const/dialogPageTypes";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect, useState, Fragment } from "react";
import {
  addStanza,
  editStanza,
  getStanzaById,
  deleteStanza,
} from "../../const/urlAction";
import axios from "axios";
import { reloadingDataTableUsers } from "../../redux/reducers/dataTableUsers-reducer";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  dialogButtonGeneral,
  dialogButtonConfirm,
  changeStatusStanzaTitleDialog,
  changeStatusStanzaTextDialog,
  dialogEditStanze,
  dialogAddStanze,
} from "../../const/DialogData";

import { notificationOpen } from "../../redux/reducers/notification-reducer";
import handlerError from "../../functions/handlerError";
import { useParams } from "react-router-dom";
import { BoxDialogDisabilita } from "./DisableDialog/DialogDisabilita";
import { closeDialog } from "../../redux/reducers/DialogGeneral";
import { ShowImgPlanimetria } from "../ShowImgPlanimetria/ShowImgPlanimetria";
import convertPositionToInt from "../../functions/convertPositionToInt";
import { hideLoader, showLoader } from "../../redux/reducers/appLoader-reducer";

const urlBase = process.env.REACT_APP_URL;

const StanzeDialog = () => {
  const [isRenderingDialog, setIsRenderingDialog] = useState(false);
  const [changedRow, setchangedRow] = useState(null);
  const backButtonState = useSelector((state) => state.dialogBackButton);
  const [radioButtonPlanimetria, setRadioButtonPlanimetria] =
    useState("mantieni");
  const dialogState = useSelector((state) => state.dialogGeneral);
  const dispatch = useDispatch();
  const { rowInfo, dialogType, dialogPageType, isOpen, body } =
    dialogState;
  const params = useParams();
  let validationSchema;

  if (dialogType === "edit") {
    validationSchema = yup.object({
      denominazione: yup.string().required("Campo Obbligatorio."),
      Sigla: yup.string().required("Campo Obbligatorio."),
    });
  } else if (dialogType === "add") {
    validationSchema = yup.object({
      denominazione: yup.string().required("Campo Obbligatorio."),
      Sigla: yup.string().required("Campo Obbligatorio."),
    });
  }

  const appLoadingState = useSelector((state) => state.appLoader.loading);

  let initialValues = {};

  // const {
  //   id,
  //   denominazione,
  //   sigla: Sigla,
  //   idZona,
  //   dataDel,
  //   idSfondoPlanimetria,
  //   nomeImagineSfondoPlanimetria,
  //   isSalaRiunione,
  // } = rowInfo;

  if (dialogType === "edit") {
    initialValues = {
      id:rowInfo?.id,
      denominazione:rowInfo?.denominazione,
      Sigla:rowInfo?.sigla,
      idZona:rowInfo?.idZona,
      nomeImagineSfondoPlanimetria:rowInfo?.nomeImagineSfondoPlanimetria,
      idSfondoPlanimetria:rowInfo?.idSfondoPlanimetria,
    };
  } else if (dialogType === "add") {
    initialValues = {
      denominazione: "",
      Sigla: "",
      idZona: "",
      nomeImagineSfondoPlanimetria: "",
      idSfondoPlanimetria: "",
      isSalaRiunione: []
    };
  } else if (dialogType === "delete") {
    initialValues = {
      denominazione:rowInfo?.denominazione,
      Sigla:rowInfo?.sigla,
      idZona:rowInfo?.idZona,
    };
  }

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true, // rinizializza il valore che gli viene passato con rowInfo
    onSubmit: (values) => {
      if (dialogType === "edit") {
        if (radioButtonPlanimetria === "modifica" && values.file == null) {
          formik.setFieldError(
            "file",
            "Per modificare la planimetria, seleziona un'immagine."
          );
        }
        setchangedRow({
          id: values.id,
          denominazione: values.denominazione,
          Sigla: values.Sigla,
          idZona: rowInfo?.idZona,
          PosX: convertPositionToInt(dialogState?.rowInfo?.posX) || 0,
          PosY: convertPositionToInt(dialogState?.rowInfo?.posY) || 0,
          Image: values.file,
          RadioImageHandler:
            radioButtonPlanimetria === "cancella" ? true : false,
        });
      } else if (dialogType === "add") {
        setchangedRow({
          denominazione: values.denominazione,
          Sigla: values.Sigla,
          idZona: params.idZona,
          PosX: convertPositionToInt(dialogState?.body?.posX) || 0,
          PosY: convertPositionToInt(dialogState?.body?.posY) || 0,
          Image: values.file,
          isSalaRiunione: values?.isSalaRiunione.length > 0 ? true : false,
        });
     }
    },
  });
  //va in conflitto con la stepper
  // //Gestisce il cambio di Datatable con la Dialog Aperta, la chiude e il dialogPageType impedisce di far partire la chiamata
  // useEffect(() => {
  //   dispatch(closeDialog());
  // }, [window.onhashchange]);

  useEffect(() => {
    let isMounted = true;
    if (
      dialogState.DialogPostazione &&
      dialogType === "edit" &&
      dialogPageType === dialogPageTypes.stanze
    ) {
      (async () => {
        //setIsRenderingDialog(true);

        const { action, method } = getStanzaById;
        let url = urlBase + action + rowInfo?.id;

        try {
          const response = await axios({
            method: method,
            url,
          });
          if(isMounted) {
          if (response.data.ok) {
            dispatch(reloadingDataTableUsers());

            Object.keys(response.data.result).map((key) => {
              formik.setFieldValue(
                `${[key]}`,
                `${response.data.result[key]}`,
                true
              );
            });
            setIsRenderingDialog(false);
          } else {
            dispatch(
              notificationOpen({
                message: response.data.message,
                status: "success",
              })
            );
          }
        }
        } catch (error) {
          console.error(error);
          if (error.response) {
            dispatch(
              notificationOpen({
                message: `${error.response.data.message}`,
                status: "error",
              })
            );
          } else {
            dispatch(
              notificationOpen({
                message: "Operazione fallita: Server non raggiungibile.",
                status: "error",
              })
            );
          }
          setIsRenderingDialog(false);
        }
      })();
    }
    return () => {
      isMounted = false;
      }
  }, [dialogState.isOpenDialogPostazione]);

  // const change = (rowInfo) => {
  //   if (
  //     dialogState.dialogType === "delete" &&
  //     dialogPageType === dialogPageTypes.stanze
  //   ) {
  //     const { id } = rowInfo;
  //     // setchangedRow({
  //     //   userId:id
  //     // });
  //     dispatch(closeDialog());
  //   }
  //   return changedRow;
  // };

  useEffect(() => {
    let isMounted = true;
    if (changedRow != null && formik.isValid) {
      (async () => {
        let action, method, url;
        let formData = new FormData();

        if (dialogType === "add" || dialogType === "edit") {
          Object.keys(changedRow).map((key) => {
            formData.append(`${key}`, changedRow[key]);
          });

          if (dialogType === "edit") {
            action = editStanza.action;
            method = editStanza.method;
          } else {
            action = addStanza.action;
            method = addStanza.method;
          }

          url = urlBase + action;
        }

        dispatch(showLoader())
        try {
          const response = await axios({
            method: method,
            url,
            data: formData,
          });
          if(isMounted) {
          if (response.data.ok) {
            formik.resetForm(initialValues);
            dispatch(reloadingDataTableUsers());
            dispatch(closeDialog());
            setRadioButtonPlanimetria("mantieni");
          }
          dispatch(
            notificationOpen({
              message: response.data.message,
              status: "success",
            })
          );
        }
        } catch (error) {
          console.error(error);
          const notificationError = handlerError(error);
          dispatch(notificationOpen(notificationError));

          if (error?.response?.data?.errors != undefined) {
            let keys = Object.keys(error?.response?.data?.errors);
            keys.map((key) => {
              formik.setFieldError(key, error?.response?.data?.errors[key]);
            });
          }
        }
        dispatch(hideLoader())
      })();
    }
    return () => {
      isMounted = false;
      }
  }, [changedRow]);

  const deleteRestore = (rowInfo) => {
    let isMounted = true;
    if (dialogState.dialogType === "delete") {
      (async () => {
        setIsRenderingDialog(true);

        const { id } = rowInfo;
        const { action, method } = deleteStanza;
        let url = urlBase + action;
        dispatch(showLoader())
        try {
          const response = await axios({
            method: method,
            url,
            data: id,
            headers: {
              "Content-Type": "application/json",
            },
          });
        if(isMounted) {
          if (response.data.ok) {
            formik.resetForm(initialValues);
            dispatch(reloadingDataTableUsers());
          }
          
          dispatch(
            notificationOpen({
              message: response.data.message,
              status: "success",
            })
            );
            setIsRenderingDialog(false);
        }
        } catch (error) {
          console.error(error);
          if (error.response) {
            dispatch(
              notificationOpen({
                message: `${error.response.data.message}`,
                status: "error",
              })
            );
          } else {
            dispatch(
              notificationOpen({
                message: "Operazione fallita: Server non raggiungibile.",
                status: "error",
              })
            );
          }
          setIsRenderingDialog(false);
        }
        dispatch(hideLoader())
      })();
      dispatch(closeDialog());

      return () => {
        isMounted = false;
        }
    }
  };

  const closeForm = () => {
    setRadioButtonPlanimetria("mantieni");
    formik.resetForm(initialValues);
    dispatch(closeDialog());
  };

  /* React Mui non ha il textField di tipo input,
  forzandolo, il tasto input trigghera i comportamenti di tutti gli altri tasti,
  per impedirglielo viene fatto il controllo sull'evento  */
  function handleChangeFile(event) {
    if (event?.currentTarget?.files[0]?.type?.startsWith("image")) {
      formik.setFieldValue("file", event.currentTarget.files[0]);
    } else {
      formik.setFieldError(
        "file",
        "Il tipo di file non è consentito. Caricare un Immagine."
      );
    }
  }

  const handlePlanimetriaChange = (event) => {
    setRadioButtonPlanimetria(event.target.value);
  };

  useEffect(() => {
    dispatch(closeDialog());
  },[backButtonState])

  return (
    <>
      {dialogState.dialogType === "delete" && (
        <>
          <Dialog
            open={isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth={'sm'}
          >
            <DialogTitle>{changeStatusStanzaTitleDialog(rowInfo?.dataDel)}</DialogTitle>
            <DialogContent className="border-Content-dialog">
              <DialogContentText id="alert-dialog-description">
                {changeStatusStanzaTextDialog(rowInfo?.dataDel)} <b>{rowInfo?.denominazione}</b> (<b>{rowInfo?.sigla}</b>)?
              </DialogContentText>
              </DialogContent>
            <DialogActions>
              <Button onClick={() => dispatch(closeDialog())}>
                {dialogButtonConfirm.Cancel}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => deleteRestore(rowInfo)}
              >
                {dialogButtonConfirm.Confirm}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
      {(dialogState.dialogType === "add" ||
        dialogState.dialogType === "edit") &&
        !isRenderingDialog && (
          <>
            <Dialog
            open={isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth={'sm'}
            >
              <DialogTitle>
                {dialogState.dialogType === "edit"
                  ? dialogEditStanze.DialogTitle
                  : dialogAddStanze.DialogTitle}
              </DialogTitle>
                <DialogContent className="border-Content-dialog">
                  <FormControl fullWidth>
                    <Grid container rowSpacing={1} columnSpacing={2}></Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        margin="dense"
                        inputProps={{
                          maxLength: 3,
                        }}
                        id="Sigla"
                        label="Sigla"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={formik.values.Sigla}
                        onChange={formik.handleChange("Sigla")}
                        error={
                          formik.touched.Sigla && Boolean(formik.errors.Sigla)
                        }
                        helperText={formik.touched.Sigla && formik.errors.Sigla}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        margin="dense"
                        id="denominazione"
                        label="Denominazione"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={formik.values.denominazione}
                        onChange={formik.handleChange("denominazione")}
                        error={
                          formik.touched.denominazione &&
                          Boolean(formik.errors.denominazione)
                        }
                        helperText={
                          formik.touched.denominazione &&
                          formik.errors.denominazione
                        }
                      />
                    </Grid>
                    {(dialogState.dialogType === "add" || (dialogState.dialogType === "edit" && 
                    rowInfo?.isSalaRiunione )) &&
                      <>
                     <Grid item xs={12} sm={12}
                     sx={{ margin: "15px 0 0px 0" }}>
                      { dialogState.dialogType === "add" ?
                              <FormControlLabel
                            control={<Checkbox size="small" />}
                            label={<Typography>Servizio</Typography>}
                            labelPlacement="end"
                            onChange={formik.handleChange("isSalaRiunione")}
                          /> :
                          <FormControlLabel
                          control={<Checkbox size="small" />}
                          label={<Typography>Servizio</Typography>}
                          labelPlacement="end"
                          checked={true}
                        /> 
                          }
                    </Grid>
                    <Grid item xs={12} sm={12}
                        sx={{ margin: "0px 0 4px 0" }}
                        >
                    <Typography fontSize={12} color="#026D82">
                       La stanza verrà creta in modalità "Servizio". 
                       La prenotazione di un servizio non interferisce con la prenotazione di un posto letto.
                      </Typography>
                    </Grid>
                    </> }
                    {formik.values.nomeImagineSfondoPlanimetria !== null &&
                      formik.values.nomeImagineSfondoPlanimetria !== undefined &&
                        formik.values.nomeImagineSfondoPlanimetria !== "null" &&
                      formik.values.nomeImagineSfondoPlanimetria !== "" && (
                        <ShowImgPlanimetria idStanza={rowInfo?.id} nomeImgPlanimetria={rowInfo?.nomeImagineSfondoPlanimetria} />
                      )}
                    {formik.values.idSfondoPlanimetria !== null &&
                      formik.values.idSfondoPlanimetria !== "" &&
                      formik.values.idSfondoPlanimetria !== undefined && (
                        <Grid
                          sx={{ margin: "20px 0 2px 0" }}
                          item
                          xs={12}
                          sm={12}
                        >
                          <FormLabel component="legend">
                            <b>Gestione planimetria</b>
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-label="recoveryEnabled"
                            name="row-radio-group-enabled"
                            onChange={(event) => {
                              handlePlanimetriaChange(event);
                            }}
                            defaultValue="mantieni"
                          >
                            <FormControlLabel
                              value="mantieni"
                              control={<Radio />}
                              label="Mantieni"
                            />
                            <FormControlLabel
                              value="cancella"
                              control={<Radio />}
                              label="Cancella"
                            />
                            <FormControlLabel
                              value="modifica"
                              control={<Radio />}
                              label="Modifica"
                            />
                          </RadioGroup>
                        </Grid>
                      )}
                      {(radioButtonPlanimetria === "modifica" ||
                        formik.values.idSfondoPlanimetria === null ||
                        formik.values.idSfondoPlanimetria === "" ||
                        formik.values.idSfondoPlanimetria === undefined) && (
                      <Grid
                        sx={{ margin: "15px 0 2px 0" }}
                        item
                        xs={12}
                        sm={12}
                      >
                        <Grid item xs={12} sm={12}>
                          <label>
                            <Typography sx={{}}> Planimetria stanza</Typography>
                          </label>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            margin="dense"
                            id="file"
                            label=""
                            type="file"
                            fullWidth
                            variant="standard"
                            onChange={(event) => handleChangeFile(event)}
                            error={Boolean(formik.errors.file)}
                            helperText={formik.errors.file}
                          />
                        </Grid>
                        {radioButtonPlanimetria === "modifica" && (
                          <Grid item xs={12} sm={12}>
                            <Typography
                              fontSize={12}
                              sx={{ paddingTop: "5px", position:"relative", top: "5px"}}
                              color="#026D82"
                            >
                              Attenzione: se la nuova planimetria ha dimensioni
                              differenti rispetto alla precedente, gli oggetti
                              precedentemente disegnati subiranno un reset della
                              posizione.
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    )}
                  </FormControl>
                </DialogContent>
                  <DialogActions>
                    <Button onClick={() => closeForm()}>
                      {dialogButtonGeneral.Cancel}
                    </Button>
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={appLoadingState}
                      onClick={() => formik.handleSubmit()}
                    >
                      {dialogButtonGeneral.Confirm}
                    </Button>
                  </DialogActions>

            </Dialog>
          </>
        )}
        { (dialogState.dialogType === "disableRangeData" || dialogState.dialogType === "ripristinaAll") && (
              <BoxDialogDisabilita isOpen={dialogState.isOpen} dialogType={dialogState.dialogType} rowInfo={rowInfo} closeForm={closeForm}/>
      )}
    </>
  );
};

export default StanzeDialog;
