import { AutocompleteBoxHome } from "../../components/Dashboard/Stepper/stepperComponent/AutocompleteBoxHome";
import { DataPickerBoxHome } from "../../components/Dashboard/Stepper/stepperComponent/DataPickerBoxHome";
import PostazionePlanimetriaBoxHome from "../../components/Dashboard/Stepper/stepperComponent/PostazionePlanimetriaBoxHome";
import { StanzaPlanimetriaBoxHome } from "../../components/Dashboard/Stepper/stepperComponent/StanzaPlanimetriaBoxHome";


export const DataOra = 0
export const EdificioZona = 1
export const Stanza = 2
export const Postazione = 3
  
  export const frames = [
    {
      label: "Dati prenotazione",
      body: DataPickerBoxHome,
      id: DataOra
    },
    {
      label: "Struttura sanitaria e reparto",
      body: AutocompleteBoxHome  ,
      id: EdificioZona
    },
    {
      label: "Stanza",
      body: StanzaPlanimetriaBoxHome ,
      id: Stanza
    },
    {
      label: "Elemento",
      body: PostazionePlanimetriaBoxHome  ,
      id: Postazione
    },
  ];

  //serve per poter tornare alla label originale nella Stepper
  export const originalFrames = [
    {
      label: "Dati prenotazione",
      body: DataPickerBoxHome  ,
      id: DataOra
    },
    {
      label: "Struttura sanitaria e reparto",
      body: AutocompleteBoxHome  ,
      id: EdificioZona
    },
    {
      label: "Stanza",
      body: StanzaPlanimetriaBoxHome ,
      id: Stanza
    },
    {
      label: "Elemento",
      body: PostazionePlanimetriaBoxHome  ,
      id: Postazione
    },
  ];
