import { DialogTitle, Grid, Typography } from "@mui/material";

export default function TitleScannedQrCode({title = "Nessun titolo"}) {
  return (
    <>
      <Grid container className="title-DialogQrCodeScan">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <DialogTitle >{title}</DialogTitle >
        </Grid>
      </Grid>
    </>
  );
}
