import { Typography } from "@mui/material"

//add
export const dialogAddUser = { DialogTitle: "Inserisci nuovo utente" }
export const dialogAddZone = { DialogTitle:"Inserisci nuovo reparto" }
export const dialogAddBuilding = {DialogTitle : "Inserisci nuova struttura sanitaria"}
export const dialogAddStanze = { DialogTitle:"Inserisci nuova stanza" }
export const dialogAddPostazioni = {DialogTitle : "Aggiungi posto letto", DialogText : "Desideri aggiungere un posto letto?"}

//Edit
export const dialogEditUser = { DialogTitle: "Modifica utente" }
export const dialogEditZone = { DialogTitle:"Modifica reparto" }
export const dialogEditBuilding = { DialogTitle: "Modifica struttura sanitaria" }
export const dialogEditPostazione= { DialogTitle: "Modifica posto letto" }
export const dialogEditUtenti = {DialogTitle : "Associazione Utenti "}
export const dialogEditStanze = { DialogTitle:"Modifica stanza" }

//RegistrazioneUtente
export const dialogSignInUser = { DialogTitle: "Richiesta credenziali di accesso" }
//Cambio Password
export const dialogCambioPassword = { DialogTitle: "Cambio password" }

//Sincronizzazione
export const dialogSincronizzazioneUsers = { DialogTitle: "Sincronizzazione Utenti" }

//Function tag

export const changeStatusUserTitleDialog = (userState) => {
   const textUserState = userState === null ? "Disabilita" : "Abilita";
    return `${textUserState} utente`;
}

export const changeStatusUserTextDialog = (userState) => {
    const textUserState = userState === null ? "disabilitare": "abilitare" ;
     return `Vuoi ${textUserState}`;
 } 

export const changeStatusBuildingTitleDialog = (userState) => {
    const textUserState = userState === null ? "abilita" : "Elimina";
     return `${textUserState} edificio`;
}

export const changeStatusPostazioneTitleDialog = (userState) => {
    const textUserState = userState === null ? "abilita" : "Elimina";
     return `${textUserState} postazione`;
}

export const changeStatusBuildingTextDialog = (userState) => {
    const textUserState = userState === null ? "abilitare" : "eliminare" ;
     return `Vuoi ${textUserState}`;
 } 

 export const changeStatusStanzaTitleDialog = (userState) => {
    const textUserState = userState === null ? "Elimina" : "Abilita";
     return `${textUserState} stanza`;
 } 

export const changeStatusStanzaTextDialog = (userState) => {
    const textUserState = userState === null ? "eliminare" : "abilitare";
     return `Vuoi ${textUserState} la stanza`;
 } 

 export const changeStatusZonaTitleDialog = (userState) => {
    const textUserState = userState === null ? "Elimina" : "Abilita";
     return `${textUserState} zona`;
 } 

 export const changeStatusZonaTextDialog = (userState) => {
    const textUserState = userState === null ? "eliminare" : "abilitare";
     return `Vuoi ${textUserState} la zona`;
 } 

 export const dialogTextDeletePostazione = (Posto) => {

     return <>Vuoi eliminare la Postazione <b>{Posto}</b></> 
 }

//Generic
export const dialogButtonGeneral = { Confirm:"Salva", Cancel:"Annulla" }

export const dialogButtonConfirm = { Confirm:"Conferma", Cancel:"Annulla" }


export const dialogButtonRiepilogo = { Confirm:"Conferma", Chiudi:"Chiudi", Elimina:"Elimina" , Disabilita:"Disabilita", Indietro:"Indietro"}

export const dialogButtonQrCode = { Prenota:"Prenota", Annulla:"Annulla", Elimina:"Elimina" , Disabilita:"Disabilita", Indietro:"Indietro", Conferma:"Conferma"}

export const switchAlertPrenotazioniEsaurite = { Label: "Avvisami quando sto per terminare le prenotazioni"} 
//text

export const dialogTextTitleDeletePostazione = "Eliminare posto letto"


export const convalidaOtpRichiestaCredenzialiButtonText = { Indietro:"Torna alla login" }