import { createSlice } from '@reduxjs/toolkit';

const initialState = {isOpen:false , dialogType:"", dialogPageType:"", rowInfo:-1};

const userSlice = createSlice({
    name:'dialogUser',
    initialState,
    reducers: {
         openEmptyDialogUser: (state, payload) => { 
            return {...state, isOpen: true, dialogType:payload.payload};
         },
         setPageType: (state, payload) => { 
            return {...state, dialogPageType:payload.payload};
         },
         openDialogUser:(state,payload) => {
             return {...state, isOpen:true, dialogType:payload.payload.dialogType, rowInfo:payload.payload.rowInfo}
         },
         closeDialogUser: (state) => {
            return {...state, isOpen: false};
         }
    }
});

export const { openDialogUser, closeDialogUser, openEmptyDialogUser, setPageType } = userSlice.actions;

const { reducer } = userSlice;

export default reducer;