import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import EditIcon from "@mui/icons-material/Edit";
import LoopOutlinedIcon from "@mui/icons-material/LoopOutlined";
import {
  openDialogUser,
  setPageType,
} from "../../redux/reducers/dialogUser-reducer";
import { useDispatch } from "react-redux";
import { GridActionsCellItem } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";
import { policies as permessi } from "../../const/policies";
import LockResetIcon from "@mui/icons-material/LockReset";
import { dialogPageTypes } from "../../const/dialogPageTypes";

const ButtonBoxUsers = ({ rowId, rowInfo }) => {
  const state = useSelector((state) => state.userDate);
  const { policies } = state;
  const dispatch = useDispatch();

  const handleDialogToEdit = ({ dialogType, rowInfo }) => {
    dispatch(setPageType(dialogPageTypes.user));
    dispatch(openDialogUser({ dialogType, rowInfo }));
  };

  return (
    <>
      {policies.indexOf(permessi.utentiInserimentoModifica) != -1 && (
        <GridActionsCellItem
          icon={
            <Tooltip title="Modifica">
              <EditIcon sx={{ fontSize: 30 }} color="primary" />
            </Tooltip>
          }
          label="Modifica"
          onClick={() => handleDialogToEdit({ dialogType: "edit", rowInfo })}
        />
      )}

      {policies.indexOf(permessi.utentiCancellazione) != -1 && (
        <GridActionsCellItem
          icon={
            <Tooltip title={!rowInfo.dataDel ? "Disabilita" : "Abilita"}>
              {!rowInfo.dataDel ? (
                <DoDisturbIcon sx={{ fontSize: 30 }} color="primary" />
              ) : (
                <LoopOutlinedIcon sx={{ fontSize: 30 }} color="primary" />
              )}
            </Tooltip>
          }
          label="Cancella"
          onClick={() => handleDialogToEdit({ dialogType: "delete", rowInfo })}
        />
      )}
      {(policies.indexOf(permessi.utentiResetPassword) != -1 && rowInfo.hasPassword ) ? 
      (
          <GridActionsCellItem
            icon={
              <Tooltip title="Reset Password">
                <LockResetIcon sx={{ fontSize: 30 }} color="primary" />
              </Tooltip>
            }
            label="Reset"
            onClick={() => handleDialogToEdit({ dialogType: "reset", rowInfo })}
          />
        ) :
        <GridActionsCellItem
        sx={{visibility:"hidden"}}
            icon={
              <Tooltip title="Reset Password">
                <LockResetIcon sx={{ fontSize: 30 }} color="primary" />
              </Tooltip>
            }
            label="Reset"
          />
        }
    </>
  );
};

export default ButtonBoxUsers;
