import { Button, Chip, Grid, Paper, Typography } from "@mui/material";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import { BookingTimelineSegment } from "../../../../UI/BookingTimeline/BookingTimeline";
import {
  DesktopMac,
} from "@mui/icons-material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { CalendarViewMode } from "../../../../../const/calendarViewMode";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import "../../Prenotazioni.styles.css";
import "./RowPrenotazione.styles.css";
import {
  getDayofMonth,
  getTime,
  getDayOfWeek,
  formatDateRegularNoTime,
  dayofMonth,
  DayofWeekandMonthVisibile,
  month,
} from "../../../../../functions/formatDate";
import { openDialogRiepilogo } from "../../../../../redux/reducers/DialogRiepilogo-reducer";
import { useDispatch } from "react-redux";
import moment from "moment";
import { enumViewModePrenotazioni } from "../../../../../const/prenotazioni/enumViewMode";
import CheckInStatusPill from "../CheckInStatusPill/CheckInStatusPill";
import useMobileDetection from "../../../../../Hooks/useMobileDetection";
import createStatusRowSide from "../../../../../functions/prenotazioni/createStatusRowSide";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBed, faBriefcaseMedical, faDoorOpen, faHospital, faLayerGroup } from "@fortawesome/free-solid-svg-icons";

/**renderizza una card prenotazione in versione desktop munita di indicatore di timeline */
export function RowPrenotazioneConTimeline({
  prenotazioni,
  index,
  isSectionHeader,
  viewMode,
  viewOnlyMine = true,
  isOpenAdvancedSearch,
}) {
  return (
    <>
      <Grid className="row-timeline-container">
        <BookingTimelineSegment
          isOpenAdvancedSearch={isOpenAdvancedSearch}
          prenotazioni={prenotazioni}
          index={index}
          showDateLabel={isSectionHeader}
          viewMode={viewMode}
        />
        <RowPrenotazione
          viewOnlyMine={viewOnlyMine}
          prenotazione={prenotazioni[index]}
        />
      </Grid>
    </>
  );
}

/**Component della card con dati di prenotazione (formato esteso per desktop)*/
export function RowPrenotazione({ prenotazione, viewOnlyMine }) {
  const {
    dataInizioPrenotazione,
    dataFinePrenotazione,
    isSalaRiunione,
    zonaDenominazione,
    stanzaDenominazione,
    edificioDenominazione,
    postazionePosto,
    disabilitato,
    utentePrenotato,
    isMaxValue,
    id,
    ospite,
    nominativoOspite,
  } = { ...prenotazione };

  const dispatch = useDispatch();
let isShowNomeOspiteConUtente = viewOnlyMine;
    if(ospite) viewOnlyMine = false; // ridefinisce il modo di vedere la card se è una prenotazione per un ospite
  
  return (
    <>
      <Paper
        onClick={() => {
          dispatch(openDialogRiepilogo(id));
        }}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className="row-prenotazione box-shadow-light "
      >
        <Grid container item>
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            lg={2}
            xl={2} /* orario prenotazione */
          >
            <div className="booking-card-left-section flex-center-center">
              <Typography
                sx={{
                  color: "#026D82",
                  fontSize: "15px",
                  fontWeight: 650,
                  padding: "0",
                }}
              >
                {getTime(dataInizioPrenotazione) +
                  " - " +
                  getTime(dataFinePrenotazione)}
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            xs={7}
            sm={7}
            md={7}
            lg={8}
            xl={8}
            sx={{ padding: "5px 0 5px 20px" }} /*location postazione e user*/
          >
            <div style={{ marginTop: "5px" }}>
              {viewOnlyMine === false && (
                <p
                  /*riga 1: utente prenotato */ className="prenotaz-14pt italic"
                >
                  <AssignmentIndIcon className="sm-Icon" />{" "}
                  <b>{ ospite ? isShowNomeOspiteConUtente ? nominativoOspite : <>{nominativoOspite}<Typography sx={{display:"inline", color:"grey"}}>{" (" + utentePrenotato + ")" } </Typography></> : utentePrenotato}</b>
                </p>
              )}
              <p /*riga 2: sala e postazione*/ className="prenotaz-14pt">
                {isSalaRiunione ? (
                  <>
                    <FontAwesomeIcon icon={faBriefcaseMedical} className="sm-Icon sm-icon-4" />
                    <>{stanzaDenominazione}</>
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faDoorOpen} className="sm-Icon sm-icon-4" />
                    <i>{stanzaDenominazione}</i>
                  </>
                )}
                {!isSalaRiunione && (
                  <>
                    <FontAwesomeIcon icon={faBed} className="sm-Icon sm-icon-4" />
                    <i>Letto {postazionePosto}</i>
                  </>
                )}
              </p>

              <p /*riga 3: edificio e zona */ className="prenotaz-14pt italic">
                <FontAwesomeIcon icon={faHospital} className="sm-Icon" />{" "}
                <span>{edificioDenominazione}</span>
                <FontAwesomeIcon icon={faLayerGroup} className="sm-Icon sm-icon-3" />
                {zonaDenominazione}
              </p>
              {disabilitato === true && (
                <p
                  /*riga 3: data disabilitazione */ className="prenotaz-14pt italic"
                >
                  <CalendarMonthIcon className="sm-Icon" />{" "}
                  {formatDateRegularNoTime(dataInizioPrenotazione)} -{" "}
                  {isMaxValue
                    ? "n.d"
                    : formatDateRegularNoTime(dataFinePrenotazione)}
                </p>
              )}
            </div>
          </Grid>
          <Grid
            item
            xs={2}
            sm={2}
            md={2}
            lg={2}
            xl={2}
            className="flex-center-end"
            sx={{ fontSize: "12px" }}
          >
            <Grid container className="prenotazioni-utente-align">

              <Grid item xs={9}>
              <Grid item xs={12}>
              </Grid>
              {/* Nascosto demo */}
              {/* <Grid item xs={12}>
                <CheckInStatusPill status={prenotazione.checkInStatus} /> 
              </Grid> */}
            </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

/**Component della card con dati di prenotazione (formato compatto per mobile) */
export function RowPrenotazioneCompact({
  prenotazione,
  viewMode,
  viewOnlyMine,
}) {
  const {
    dataInizioPrenotazione,
    dataFinePrenotazione,
    isSalaRiunione,
    zonaSigla,
    stanzaSigla,
    edificioSigla,
    postazionePosto,
    utentePrenotato,
    disabilitato,
    id,
    ospite,
    nominativoOspite,
  } = { ...prenotazione };

  const dispatch = useDispatch();

  const isMobile = useMobileDetection(950);
  let colorStatusCheckIn = createStatusRowSide(prenotazione.checkInStatus)

  if(ospite) viewOnlyMine = false; // ridefinisce il modo di vedere la card se è una prenotazione per un ospite

  return (
    <>
      <Paper
      sx={{borderRight:`${colorStatusCheckIn?.color} solid 5px`}}
        onClick={() => {
          dispatch(openDialogRiepilogo(id));
        }}
        className={colorStatusCheckIn?.color === "#dc3545" ? "box-shadow-light margin-row-lista row-lista-effect" : "box-shadow-light margin-row-lista" }
      >
        <Grid container>
          <Grid item xs={1} /*spazio icona postazione */>
            <div className="booking-card-left-section bk-icon-mob flex-center-center">
              {isSalaRiunione ? (
                <FontAwesomeIcon icon={faBriefcaseMedical}
                  className={
                    disabilitato
                      ? "bk-icon tl-icon-fill-disabled"
                      : "bk-icon bk-icon-light"
                  }
                />
              ) : (
                <FontAwesomeIcon icon={faBed}
                  className={
                    disabilitato
                      ? "bk-icon tl-icon-fill-disabled"
                      : "bk-icon bk-icon-fill"
                  }
                />
              )}
            </div>
          </Grid>
          <Grid
            item
            xs={9}
            sx={{
              padding: "5px 0 5px 5px",
              marginTop: "5px",
              // flexGrow: 1,
            }} /*location postazione e user*/
          >
            {viewOnlyMine === false && (
              <p className="prenotaz-15pt italic">
                <AssignmentIndIcon className="sm-Icon" />{" "}
                <b>{ ospite ? viewOnlyMine ? nominativoOspite : <>{nominativoOspite}<Typography sx={{display:"inline", color:"grey"}}>{" (" + utentePrenotato + ")" }</Typography></> : utentePrenotato}</b>
              </p>
            )}
            <p className="prenotaz-14pt italic">
              <FontAwesomeIcon icon={faHospital} className="sm-Icon" /> <span>{edificioSigla}</span>
              <FontAwesomeIcon icon={faLayerGroup} className="sm-Icon sm-icon-3" />
              {zonaSigla}
              {isSalaRiunione ? (
                <>
                  <FontAwesomeIcon icon={faBriefcaseMedical} className="sm-Icon sm-icon-2" />
                  {stanzaSigla}
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faDoorOpen} className="sm-Icon sm-icon-2" />
                  <>{stanzaSigla}</>
                </>
              )}
              {!isSalaRiunione && (
                <>
                  <FontAwesomeIcon icon={faBed} className="sm-Icon sm-icon-2" />
                  {postazionePosto}
                </>
              )}
            </p>

            <p className="prenotaz-14pt italic">
              {" "}
              <CalendarMonthIcon className="sm-Icon" />
              {getTime(dataInizioPrenotazione) +
                " - " +
                getTime(dataFinePrenotazione)}
            </p>
          </Grid>
          

          {/*giorno prenotazione*/}
          <Grid xs={2} item className="flex-center-center">
          <Grid xs={12} item className="flex-center-center">
            <Typography
              className="bk-date-mobile"
              sx={{ fontWeight: 500, fontSize: "14px" }}
            >
              {viewMode === enumViewModePrenotazioni.AdvancedFilter && (
                </*modalità mensile, giorno del mese/settimana */>
                  <span style={{ fontSize: "20px" }}>
                    {" "}
                    {dayofMonth(dataInizioPrenotazione)}{" "}
                  </span>
                  <br />
                  {month(dataInizioPrenotazione)}
                  {/*aggiungere anno in questo slot per visualizzazione tutte prenotazioni*/}
                </>
              )}
              {viewMode === enumViewModePrenotazioni.Month && (
                </*modalità mensile, giorno del mese/settimana */>
                  <span style={{ fontSize: "20px" }}>
                    {" "}
                    {dayofMonth(dataInizioPrenotazione)}{" "}
                  </span>
                  <br />
                  {getDayOfWeek(dataFinePrenotazione)}
                  {/*aggiungere anno in questo slot per visualizzazione tutte prenotazioni*/}
                </>
              )}
            </Typography>
          </Grid>
          {/* <Grid xs={12} item className="flex-center-center">
          {!isMobile && <CheckInStatusPill status={prenotazione.checkInStatus} isMobile={isMobile} />}
          </Grid> */}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
