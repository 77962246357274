import { createSlice } from '@reduxjs/toolkit';

const initialState = false;

const forceAppToReload = createSlice({
    name: 'forceAppToReload',
    initialState,
    reducers: {
        forceApp: (state) => {
            return !state;
        }
    }
});

export const { forceApp } = forceAppToReload.actions;

const { reducer } = forceAppToReload;

export default reducer;