import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { dialogAddUser } from "../../../../../const/DialogData";
import { labelArrayDialogUserAddEdit } from "../../../../../const/RadioButton/RadioButtonListRadio";
import {ListTabsDialogoUserAdd } from "../../../../../const/Tabs/Dialog/DialogUser/DialogUserAdd/ListTabsDialogUserAdd";
import MessageNoProvider from "../../../../UI/MessageNoProvider/MessageNoProvider";
import RadioButtonWithTabs from "../../../../UI/RadiobuttonCustom.js/RadioButtonWithTabs/RadioButtonWithTabs";

export default function DialogAddUser({
  isOpen,
  lastTabsSelected,
  setLastTabsSelected,
}) {
  return (
    <>
      <Dialog
        open={isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"sm"}
      >
        <DialogContent className="border-Content-dialog">
          <DialogTitle sx={{paddingLeft:"0"}}>{dialogAddUser.DialogTitle}</DialogTitle>
          <RadioButtonWithTabs
          GroupName={"Tipo di autenticazione"}
          tabPanel={ListTabsDialogoUserAdd()}
          labelList={labelArrayDialogUserAddEdit}
          startValueReset={labelArrayDialogUserAddEdit[0]}
          noTabsComponent={<MessageNoProvider />}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
