import { Divider, Typography } from "@mui/material";
import React from "react";
import InfoStanza from "./infoDettagli/infoStanza";
import InfoZona from "./infoDettagli/infoZona";

export default function HeadSubMenu({infoElement = {},typeComponent = ""}) {

    return (
        <React.Fragment>
            {typeComponent == "stanza" ? <InfoStanza infoElement={infoElement} sx={{paddingLeft:'0px'}}/> : <InfoZona infoElement={infoElement}/> }
        </React.Fragment>
    )
}