import { Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useRef } from "react";
import { TabPanelComponentToShow } from "./TabPanelComponentToShow";

const planimetriaPosition = 1;
export default function CustomMainTabsComponent({ tabPanel, lastTabsSelected, setLastTabsSelected, noTabsComponent = <>Nessuna Tab selezionata</> }) {
  const actionRef = useRef();
  const [valueTabSelected, setValueTabSelected] = React.useState(lastTabsSelected);



  const handleChange = (event, newValue) => {
    if(newValue > 1) {
      setValueTabSelected(newValue)
      setLastTabsSelected(0);
    } else {
      setValueTabSelected(newValue)
      setLastTabsSelected(newValue);
    }
    
  };

  // gestisce il problema sull'alineamento della linea sotto alla tabs selezionata.
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      actionRef.current.updateIndicator();
    }, 200);

    return () => {
      clearTimeout(timeout);
    };
  }, [valueTabSelected]);


  return (
    <>
      <Box>
        <Tabs value={valueTabSelected} onChange={handleChange} action={actionRef}>
          {tabPanel.map((tab, index) => {
            return <Tab key={index} label={tab.label} />;
          })}
        </Tabs>
      </Box>
      {
      tabPanel.length > 0 ? 
      tabPanel.map((tab, index) => {
        if (index == valueTabSelected) {
          return (
            <TabPanelComponentToShow
              key={index}
              componentTab={tab.componentTab}
              index={index}
              withPadding={false}
            />
          );
        }
      })
    : noTabsComponent
    }
    </>
  );
}
