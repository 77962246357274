import {
  iconsStanze,
  iconsZone,
  listaPostazioneIcon,
  listaPostazioneSalaRiunioniIcon,
  postazioneIconSemplice,
  postazioneInSalaRiunione,
  stanzaIcon,
  stanzaRiunioneIcon,
} from "../const/Planimetria/IconPlanimetria";

//metodo di assegnazione icona di default per la creazione degli elementi nella toolbar
export function assegnaIconeInPlanimetriaGestioneToolbar(
  idStanza,
) {

  let IconsToToolBar = idStanza === undefined ? iconsZone : iconsStanze;
  
  return  IconsToToolBar;
}

//metodo di assegnazione icona di default per la creazione degli elementi nella planimetria
export function assegnaIconeInPlanimetriaGestione(
  idStanza,
  isSalaRiunione
) {

  let IconsToCreate = isSalaRiunione
    ? idStanza === undefined 
      ? stanzaRiunioneIcon 
      : listaPostazioneSalaRiunioniIcon
    : idStanza === undefined
      ? stanzaIcon
      : listaPostazioneIcon;

  return IconsToCreate ;
}
