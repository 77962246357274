
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDoorOpen, faHospital, faLayerGroup } from "@fortawesome/free-solid-svg-icons";

const edificio = "edificioDetail"
const zona = "zonaDetail"
const stanza = "stanzaDetail"

export default function creazionePathTextEntita(entitaDetail = {}) {
let body;

if(!entitaDetail) return <></>

if(Object.entries(entitaDetail).length !== 0) {

    const keys = Reflect.ownKeys(entitaDetail).filter(x => x.toLowerCase().includes("detail"));
    
 
if(keys.indexOf(edificio) !== -1) body = <div style={{display:"flex", alignItems:"center", justifyContent:"center", fontSize:"12px", paddingRight:"5px"}}> <FontAwesomeIcon icon={faHospital} fontSize="small"/> {entitaDetail[edificio].sigla.toUpperCase()}</div>
if(keys.indexOf(zona) !== -1) body = <div style={{display:"flex", alignItems:"center", justifyContent:"center", fontSize:"12px", paddingRight:"5px"}}> {body} <FontAwesomeIcon icon={faLayerGroup} fontSize="small"/> {entitaDetail[zona].sigla.toUpperCase()}</div>
if(keys.indexOf(stanza) !== -1) body = <div style={{display:"flex", alignItems:"center", justifyContent:"center", fontSize:"12px", paddingRight:"5px"}}> {body} <FontAwesomeIcon icon={faDoorOpen} fontSize="small"/> {entitaDetail[stanza].sigla.toUpperCase()}</div>


   body = <div style={{display:"flex", alignItems:"center", justifyContent:"center", fontSize:"12px"}}> {body} {assegnazioneIcona(keys)} {entitaDetail.sigla.toUpperCase()}</div>
 

 return <div style={{float:"right"}}>{body}</div>
}
}

function assegnazioneIcona(keys) {
const elementNumber = keys.length

let icon = "";
switch (true) {
    case elementNumber === 0:
        icon = <FontAwesomeIcon icon={faHospital} fontSize="small" style={{paddingRight:"3px"}}/>
        break;
    case elementNumber === 1:
        icon = <FontAwesomeIcon icon={faLayerGroup} fontSize="small" style={{paddingRight:"3px"}}/>
        break;
    case elementNumber === 2:
        icon = <FontAwesomeIcon icon={faDoorOpen} fontSize="small" style={{paddingRight:"3px"}}/>
        break;
}
    return icon
}