import { createSlice } from '@reduxjs/toolkit';

const initialState = {isOpenDialogStanza:false , dialogType:"", dialogPageType:"", rowInfo:-1, body:{}};

const stanzaSlice = createSlice({
    name:'dialogStanza',
    initialState,
    reducers: {
         openEmptyDialogStanza: (state, payload) => {
            return {...state, isOpenDialogStanza: true, dialogType:payload.payload.dialogPageType, body:payload.payload.body};
         },
         setPageTypeStanza: (state, payload) => { 
            return {...state, dialogPageType:payload.payload};
         },
         openDialogStanza:(state,payload) => {
             return {...state, isOpenDialogStanza:true, dialogType:payload.payload.dialogType, rowInfo:payload.payload.rowInfo}
         },
         closeDialogStanza: (state) => {
            return {...state, isOpenDialogStanza: false};
         }
    }
});

export const { openDialogStanza, closeDialogStanza, openEmptyDialogStanza, setPageTypeStanza } = stanzaSlice.actions;

const { reducer } = stanzaSlice;

export default reducer;