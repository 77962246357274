//Background Image

import axios from "axios";
import { useEffect, useState } from "react";
import { planimetriaUrlParamsForBackground } from "../functions/UrlParams";

const urlBase = process.env.REACT_APP_URL;

export default function useBackGroundPlanimetria(params) {
  
  const {id, action, method} = planimetriaUrlParamsForBackground(params);
  
      return urlBase + action + id ;
}