import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const riepilogoDialogAfterPrenotazione = createSlice({
  name: 'RiepilogoDialogAfterPrenotazione',
  initialState,
  reducers: {
    saveDataForRiepilogo: (state, action) => {
      return state = action.payload;
    },
    resetInitialState: (state) => {
        return state = initialState;
    }
  }
});

export const { saveDataForRiepilogo, resetInitialState } = riepilogoDialogAfterPrenotazione.actions;
export default riepilogoDialogAfterPrenotazione.reducer;



