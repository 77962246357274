import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from "react-router-dom";
import Layout from "./layout/Layout";
import Settings from "./pages/Settings";
import ErrorPage from "./pages/ErrorPage";
import Logout from "./pages/Logout";
import Login from "./pages/Login/Login";
import { useSelector, useDispatch } from "react-redux";
import { policies } from "./const/policies"
import Interceptor from "./helpers/Interceptor";
import { refreshToken } from "./redux/reducers/user-reducer";
import UsersPanel from "./pages/UserPanels/UsersPanel";
import ZonePanel from "./pages/ZonePanel/ZonePanel";
import Building from "./pages/BuildingPanel/Building";
import PostazioniPanel from "./pages/PostazioniPanel/PostazioniPanel";
import StanzePanel from "./pages/StanzePanels/StanzePanel";
import useGetConfiguration from "./Hooks/Configuration/useGetConfigurationAuth";
import RichiesteAccesso from "./pages/RichiesteAccesso/RichiesteAccesso"
import PlannerComponent from "./components/Planner/PlannerComponent";
import Prenotazioni from "./pages/Prenotazioni/Prenotazioni";
import DisponibilitaPage from "./pages/Disponibilita/Disponibilita";
import { useState } from "react";
import { useEffect } from "react";
import ConvalidaRichiestaCredenziali from "./pages/ConvalidaRichiestaCredenziali/ConvalidaRichiestaCredenziali";
import FiltroTipoPrenotazioni from "./pages/FiltroTipoPrenotazioni";
import { setIsSalaRiunione, isSalaRiunione} from "./redux/reducers/filterInitial-reducer"

let permessi = [];

export const pathConfermaCredenzialiRichiesta = "confermaregistrazione";

function App() {
const reloadingApp = useSelector((state) => state.forceAppToReload)
const location = useLocation();
const [lessState,setLessState] = useState(false) //serve a renderizzare l'intera applicazione 

const userDate = localStorage.getItem('deskbooking');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state) => state.userDate);

  //recupera dal localstorage e popola il context(redux) con i dati degli utenti.
  if (JSON.stringify(state) === '{}' && location.pathname !== '/') {
    const objUserDate = JSON.parse(userDate)
    dispatch(refreshToken(objUserDate, navigate));
  }

  state.policies !== undefined ? permessi = state.policies : permessi = [];

  Interceptor(dispatch);

  const isConfigured = useGetConfiguration()

//serve a renderizzare l'intera applicazione 
  useEffect(() => {
    setLessState(!lessState)
  },[reloadingApp])

  useEffect(() => {
    dispatch(setIsSalaRiunione(sessionStorage.getItem('isSalaRiunioni') === "true"))
  }, )
  
  
  return (
    <>{
      isConfigured 
      ?
      <>
        <Layout>
          <Routes>
            {/* #### aggiungi i path anche nella pageError'pageListAvailable' #### */}
            {state.token !== undefined && <Route path="/settings" element={<Settings />} />}
            {permessi.indexOf(policies.utentiVisualizzazione) != -1 && <Route path="/utenti" element={<UsersPanel />} />}
            {permessi.indexOf(policies.edificiVisualizzazione) != -1 && <Route path="/edifici" element={<Building />} />}
            {permessi.indexOf(policies.zoneVisualizzazione) != -1 && <Route path="/edifici/:idEdificio" element={<ZonePanel />} />}
            {permessi.indexOf(policies.stanzeVisualizzazione) != -1 && <Route path="/edifici/:idEdificio/:idZona" element={<StanzePanel />} />}
            {permessi.indexOf(policies.postazioniVisualizzazione) != -1 && <Route path="/edifici/:idEdificio/:idZona/:idStanza" element={<PostazioniPanel />} />}
            {permessi.indexOf(policies.gestioneRichiestaCredenziali) != -1 &&  <Route path="/richiesteAccesso" element={<RichiesteAccesso />} />} 
            {state.token !== undefined && <Route path="/disponibilita" element={<DisponibilitaPage />} />}
            {state.token !== undefined && <Route path="/planner" element={<PlannerComponent />} />}
            {state.token !== undefined && <Route path="/prenotazioni" element={<Prenotazioni />} />}
            {state.token !== undefined && <Route path="/logout" element={<Logout />} />}
            {state.token !== undefined && <Route path="/filtro" element={<FiltroTipoPrenotazioni />} />}
            <Route path={`${pathConfermaCredenzialiRichiesta}/*`} element={<ConvalidaRichiestaCredenziali />} />
            <Route path="/" element={<Login />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </Layout>
        </>
      : 
        <Layout></Layout>
    }
    </>
  );
}

export default App;
