import WorkIcon from "@mui/icons-material/Work";
import MailIcon from '@mui/icons-material/Mail';
import { Chip } from "@mui/material";


export default function SincronizzazioneUserPills({
    label= undefined,
    index = undefined,
    handleDelete = () => null,
}) {
    if(!label) console.error(" la label nel component chip non è definita")
    if(index == undefined) console.error("l'ordinamento non è definito nel component chip")

  return (
    <Chip
      variant="outlined"
      color="primary"
      size="small"
      label={label}
      onDelete={() => handleDelete(index)}
      icon={<WorkIcon />}
    />
  );
}
