import { createSlice } from '@reduxjs/toolkit';

const initialState = {isOpen:false , dialogType:"",lastOpen:0 , dialogPageType:"", rowInfo:-1};

const dialogGeneralSlice = createSlice({
    name:'dialog',
    initialState,
    reducers: {
         openEmptyDialog: (state, payload) => { 
            return {...state, isOpen: true, dialogType:payload.payload};
         },
         setPageType: (state, payload) => { 
            return {...state, dialogPageType:payload.payload};
         },
         openDialog:(state,payload) => {
             return {...state, isOpen:true,dialogType:payload.payload.dialogType, rowInfo:payload.payload.rowInfo}
         },
         closeDialog: (state) => {
            return {...state, isOpen: false };
         },
         openDialogWithBody: (state,payload) => {
            return {...state, body: payload.payload.body.body, dialogType:payload.payload.dialogType, isOpen:true };
         }
    }
});

export const { openDialog, closeDialog,openDialogWithBody, openEmptyDialog, setPageType } = dialogGeneralSlice.actions;

const { reducer } = dialogGeneralSlice;

export default reducer;