import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const userRolesSlice = createSlice({
    name:'userRoles',
    initialState,
    reducers: {
        loadUserRolesState: (state, payload) => { 
            return payload.payload
         }
    }
})

export const { loadUserRolesState } = userRolesSlice.actions;

const { reducer } = userRolesSlice;

export default reducer;