import { Card, Divider, Popover, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { RISERVATA } from "../../../const/Planimetria/StatoPostazione";
import {
  setCallBoxButtonInSubMenuForAction,
  setComponentBoxButtonInSubMenu,
} from "../../../functions/setBoxButtonInSubMenu";
import DescrizioneStatusSubMenu from "./DescrizioneStatusSubMenu";
import HeadSubMenu from "./HeadSubMenu";
const urlBase = process.env.REACT_APP_URL;

const ElementSubMenu = ({
  open = false,
  mostraTasti = true,
  typeComponent = "",
  infoPrenotazioniToSubmenu = [],
  isSalaRiunione = false,
  anchorEl = {},
  infoPositionSubMenuAndId = {
    id: 0,
    x: 0,
    y: 0,
    status: 0,
    descrizioneStatus: "Nessuna Descrizione Disponibile.",
  },
}) => {
  const [infoElement, setInfoElement] = useState(null);
  const [show, setShow] = useState(false);
  const { id, x, y, descrizioneStatus, status } = infoPositionSubMenuAndId;

  open = typeComponent === "" ? false : open;
  /* metodo valutazione component*/
  let iconElement = setComponentBoxButtonInSubMenu(typeComponent);
  useEffect(() => {
    (async () => {
      if (id !== 0 && id !== undefined && status !== RISERVATA) {
        setShow(false);
        const { action, method } =
          setCallBoxButtonInSubMenuForAction(typeComponent);
        let url = urlBase + action + id;

        try {
          const response = await axios({
            method: method,
            url,
            headers: {
              "Content-Type": "application/json",
            },
          });
          if (response.data.ok) {
            setInfoElement(response.data.result);
            setShow(true);
          } else {
          }
        } catch (error) {
          console.error(error);
        }
      }
    })();
  }, [open, infoPositionSubMenuAndId]);


  useEffect(() => {  // riporta il setInfoElement allo stato iniziale per impedire aperture del menu non volute.
    setInfoElement(null)
  }, [infoPositionSubMenuAndId]);

  return (
    <React.Fragment>
      {infoElement !== null && (
        <div>
          {show && (
            <Popover
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "center",
                horizontal: "right",
              }}
            >
              <div style={{ padding: "0px 0px 0px 0px" , width:'20.5rem'}}>
                <HeadSubMenu
                  infoElement={infoElement}
                  typeComponent={typeComponent}
                />
                {mostraTasti ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    {React.createElement(iconElement, {
                      rowInfo: infoElement,
                      isSalaRiunione: isSalaRiunione,
                    })}
                  </div>
                ) : (
                  <DescrizioneStatusSubMenu
                    stato={status}
                    prenotazioni={infoPrenotazioniToSubmenu}
                  />
                )}
              </div>
            </Popover>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default ElementSubMenu;
