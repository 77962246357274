export default function setDateWithHourseAndMinutes(day,start, end) {
    let startHour = parseInt(start)
    let EndHour = parseInt(end)

    let dateStartRangeTime = new Date(new Date(day).setHours(startHour))
    let dateEndRangeTime = new Date(new Date(day).setHours(EndHour))
    dateStartRangeTime.setMinutes(0)
    dateEndRangeTime.setMinutes(0)

    return [dateStartRangeTime, dateEndRangeTime]
}