let flag;
export function addDoubleClickEvent(
  event,
  singleClickFunction = { function: null, params: null },
  doubleClickFunction = { function: null, params: null },
  optionSettings = { ableToDesktop: true, isMobile: null, timer: null }
) {
  const { function: singleFunction, params: singleParams } =
    singleClickFunction;
  const { function: doubleFunction, params: doubleParams } =
    doubleClickFunction;
  const { ableToDesktop, isMobile, timer } = optionSettings;

  let isAble = isMobile;
  if (ableToDesktop || isMobile === null ) isAble = true;
  let ft = timer < 200 || timer == null ? 200 : timer ;

  const myTimeout = setTimeout(function () {
    if (flag && isAble) {
      doubleFunction(event, doubleParams);
      flag = null;
    } else {
      singleFunction(event, singleParams);
      flag = null;
    }
  }, ft);

  if (flag === false) {
    clearTimeout(myTimeout);
    flag = true;
  } else {
    flag = false;
  }
}


