import { Card } from "@mui/material";
import React from "react";
import BoxIconaToolbar from "./BoxIconaToolBar";

const ElementiInseribili = ({
  allowDrop,
  drop,
  dragStart,
  icons = [],
  changeIcons,
}) => {

  return (
    <React.Fragment>
      <div style={{ backgroundColor: "rgba(242, 242, 242, 0.30)", border:"solid thin #eae6e6" }}>
        {icons.map((el, index) => {
          return (
            <div
              style={{ width: "50px", height: "50px", display: "inline", display:"flex", justifyContent:"center", alignItems:"center" }}
              className="cursorGrabbing"
              draggable
              key={index}
              onDragOver={(event) => allowDrop(event)}
              onDrop={(event) => drop(event, index)}
              onMouseOver={() => {
                changeIcons(el);
              }}
              onDragStart={(event) => dragStart(event)}
            >
             <BoxIconaToolbar icon={el} />
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};


export default ElementiInseribili;
