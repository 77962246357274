import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AutocompleteCustom } from "../../../UI/AutoComplete/AutoComplete";

export default function SelectOrganizationalUnits({
  returnValueFromSelect = () => null,
  OrganizationalUnits = [],
}) {
const [listOuForAutoComplete, setListOuForAutoComplete] = useState([]);

  useEffect(() => {
    let list = []
    OrganizationalUnits.map((value, index) => {
      list.push({ index: index, denominazione: value });
    });
    setListOuForAutoComplete(list)
  },[OrganizationalUnits])

  const handleValueOu = (selectedValue) => {
    returnValueFromSelect(selectedValue);
  };


  return (
    <>
      <AutocompleteCustom
        label="Unità organizzative"
        field={["denominazione"]}
        checkField={"index"}
        keyId={"index"}
        multiple={true}
        optionsAutoComplete={listOuForAutoComplete}
        handleValueReturned={handleValueOu}
      />
    </>
  );
}
