import { Checkbox, FormControlLabel } from "@mui/material";

export default function CustomCheckBox({returnValue, label, oldValue=false}) {
    function onChange(e) {
        returnValue(e.target.checked)
    }
  return (
    <>
      <FormControlLabel
        control={<Checkbox />}
        label={label}
        labelPlacement="end"
        onChange={(e) => onChange(e)}
        checked={oldValue}
      />
    </>
  );
}
