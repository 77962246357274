import Building from "../../components/BuildingDataTable/BuildingDataTable";

const BuildingPanel = () => {

    
    return (
        <>
        <Building></Building>
        </>
    )
}

export default BuildingPanel;