const CheckValidSortModel = (sortModel, defaultColumnSort) => {
  let sortField, sortOrder;

  if (sortModel.length !== 0) {
    const { field, sort } = sortModel[0];
    sortField = field;
    sortOrder = sort === "asc" ? 1 : 0;     
  } else {
    sortField = defaultColumnSort;
    sortOrder = 1;
  }

  return { sortField, sortOrder };
};

export default CheckValidSortModel;
