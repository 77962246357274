import { Autocomplete, Grid, TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import {
  setIndirizzo,
  setCoordinate,
} from "../../../../../../redux/reducers/addressEdificio-reducer";

const apiKey = process.env.REACT_APP_MAPS_APIKEY;
const debounce = process.env.REACT_APP_MAPS_DEBOUNCE;
const country = process.env.REACT_APP_MAPS_COUNTRY;
const fields = process.env.REACT_APP_MAPS_FIELDS;

export default function AutocompleteAddressField({ formik }) {
  const state = useSelector((state) => state.addressEdificio);
  const { coordinate, indirizzo } = state;
  const { placesService, placePredictions, getPlacePredictions } = useGoogle({
    apiKey: apiKey,
    debounce: debounce,
    options: {
      componentRestrictions: { country: country },
    },
  });

  //Viene chiamato dall Autocomplete(MAPS) quando selezioniamo un luogo
  const saveSelected = (description) => {
    if (placePredictions.length && description)
      placesService?.getDetails(
        {
          placeId: placePredictions.find(x => x.description === description).place_id,
          fields: fields.split(" "),
        },
        (placeDetails) => {
          var coordinate = JSON.stringify(placeDetails.geometry.location)
            .replace('"lng":', "")
            .replace("}", "")
            .substring(7);
          dispatch(setIndirizzo({ indirizzo: placeDetails.formatted_address }));
          dispatch(setCoordinate({ coordinate: coordinate }));
        }
      );
  };

  useEffect(() => {
    dispatch(setIndirizzo({ indirizzo: formik.values.Indirizzo }));
    dispatch(setCoordinate({ coordinate: formik.values.Coordinate }));
  }, []);

  const dispatch = useDispatch();

  return (
    <>
      <Grid item xs={12} sm={12}>
        <Autocomplete
          id="googleAutocomplete"
          options={placePredictions.map(function (item) {
            return item["description"];
          })}
          //quando Seleziono un risultato scatta questo evento
          onChange={(event, newValue,) => {
             saveSelected(newValue);
          }}
          renderInput={(params) => (
            <TextField
              variant="standard"
              {...params}
              label="Digita e seleziona un indirizzo..."
              onChange={(evt) => {
                getPlacePredictions({ input: evt.target.value });
              }}
              value={params?.description}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          InputProps={{ readOnly: true }}
          margin="dense"
          id="Indirizzo"
          label="Indirizzo"
          type="text"
          fullWidth
          variant="standard"
          value={indirizzo || ""}
          onChange={(e) =>
            dispatch(setIndirizzo({ indirizzo: e.target.value }))
          }
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          InputProps={{ readOnly: true }}
          margin="dense"
          id="Coordinate"
          label="Coordinate"
          type="text"
          fullWidth
          variant="standard"
          value={coordinate || ""}
          onChange={(e) =>
            dispatch(setCoordinate({ coordinate: e.target.value }))
          }
        />
      </Grid>
    </>
  );
}
