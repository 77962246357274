import { Button, Grid, TextField } from "@mui/material";
import {
  DesktopDatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  returnDateFine,
  returnDateInizio,
  returnDataGiorno,
  cambiaRadioButtonPrenotazionePerAltri,
  resetUtentePrenotazione,
  handlerSelectUtenti,
  handlerOspitiPrenotazione,
  returnUtentePrenotazione,
} from "../../../../redux/reducers/prenotazioneStepper-reducer";
import itLocale from "date-fns/locale/it";
import { AutocompleteCustom } from "../../../UI/AutoComplete/AutoComplete";
import { getUsersFiltered } from "../../../../const/urlAction";
import axios from "axios";
import handlerError from "../../../../functions/handlerError";
import { notificationOpen } from "../../../../redux/reducers/notification-reducer";
import PannelloSuggerimentiPrenotazione from "../../PannelloSuggerimentoPrenotazione/PannelloSuggerimentiPrenotazione";
import RipetizioniBox from "./RipetizioniBox";
import GeneralCustomDateTimePicker from "../../../UI/TimePicker/GeneralCustomDateTimePicker";
import RadioButtonCustom from "../../../UI/RadiobuttonCustom.js/RadioButtonCustom.js";
import { radioButtonPrenotazioneStepper, radioButtonPrenotazioneStepperOnlyMe,  } from "../../../../const/RadioButton/RadioButtonListRadio";
import handlerConfiguration from "../../../../functions/general/handlerConfiguration";
import moment from "moment";
import PrenotazioniOspiti from "./PrenotazioneOspiti/PrenotazioniOspiti";
import PannelloOspiti from "./PrenotazioneOspiti/componentsPrenotazioneOspiti/PannelloOspiti";
import useGetAvatarUserLogged from "../../../../Hooks/UserDate/useGetAvatarUserLogged";
import userLoggedToPrenotazioneObj from "../../../../functions/general/userLoggedToPrenotazioneObj";

const minDate = "1970-01-01T";
const now = new Date(Date.now());


const urlBase = process.env.REACT_APP_URL;

export function DataPickerBoxHome({
  setStepNoAutoSkipped,
  framesIndex,
  disabled = false,
}) {
  const configurations = useSelector((state) => state.configurations);
  const userDate = useSelector((state) => state.userDate);

  const {
    orarioInizioDefault: OraInizio,
    orarioFineDefault: OraFine,
    maxGiorniRipetizione,
  } = handlerConfiguration(configurations);

  const maxday = new Date(
    new Date(Date.now()).setDate(now.getDate() + maxGiorniRipetizione)
  );

  const prenotazioneDati = useSelector((state) => state.prenotazioneStepper);
  const [usersRows, setUsersRows] = React.useState([]);
  const [dialogOspitiState, setDialogOspitiState] = React.useState(false);
  const [reload, setReload] = React.useState(false);
  const [isFirst, setIsFirst] = useState(true);
  let avatarUserLogged = useGetAvatarUserLogged();
  const [radioTipoPrenotazione, setRadioTipoPrenotazione] = React.useState(
    radioButtonPrenotazioneStepper[0].value
  );
  const dispatch = useDispatch();
  const [valueDataGiorno, setValueDataGiorno] = React.useState(null);
  const [valueDataInizio, setValueDataInizio] = React.useState(
    new Date(minDate + OraInizio)
  );

  const [valueDataFine, setValueDataFine] = React.useState(
    new Date(minDate + OraFine)
  );

  const handleChangeData = (newValue) => {
    if (newValue !== null) {
      dispatch(returnDataGiorno(newValue.toString()));
      setValueDataGiorno(newValue);
    } else {
      dispatch(returnDataGiorno("null"));
      setValueDataGiorno("null");
    }
  };
  const {
    dataGiorno,
    dataInizio,
    dataFine,
    utentePrenotazione,
    radioButtonPrenotazionePerAltri,
    ospitiPrenotazioneInfo,
  } = prenotazioneDati;

  useEffect(() => {
    setValueDataGiorno(dataGiorno);
    setValueDataInizio(new Date(minDate + dataInizio));
    setValueDataFine(new Date(minDate + dataFine));
    setRadioTipoPrenotazione(radioButtonPrenotazionePerAltri.toString());
  }, []);

  const handleChangeOrarioInizio = (newValue) => {
    if (newValue !== null) {
      let newDate = staticDateWithTimeValue(newValue);
      dispatch(returnDateInizio(newDate));
      setValueDataInizio(new Date(minDate + newDate));
    } else {
      dispatch(returnDateInizio("null"));
      setValueDataInizio("null");
    }
  };

  const handleChangeOrarioFine = (newValue) => {
    if (newValue !== null) {
      let newDate = staticDateWithTimeValue(newValue);
      dispatch(returnDateFine(newDate.toString()));
      setValueDataFine(new Date(minDate + newDate));
    } else {
      dispatch(returnDateFine("null"));
      setValueDataFine("null");
    }
  };

  const handleValueUsers = (selectedValue) => {
    selectedValue = selectedValue.map((x) => ({
      nominativo: x.nominativo,
      idStanza: null,
      idPosto: null,
      posto: null,
      immagineAvatar: x.immagineAvatar,
      ospite: x.ospite || false,
      utente: x.idUtente || x.utente,
    }));

    const value = selectedValue.filter((item, index, self) => {
      return !self
        .slice(index + 1)
        .some(
          (other) =>
            other.utente === item.utente &&
            other.nominativo === item.nominativo &&
            other.ospite === item.ospite
        );
    });

    dispatch(handlerSelectUtenti(value));
  };

  useEffect(() => {
    (async () => {
      const { action, method } = getUsersFiltered;
      let url = urlBase + action;
      try {
        const response = await axios({
          method: method,
          url,
        });
        setUsersRows(response.data.result);
        setStepNoAutoSkipped((old) => {
          if (old.indexOf(framesIndex) === -1) return [...old, framesIndex];
          return old;
        });
      } catch (error) {
        console.error(error);
        const notificationError = handlerError(error);
        dispatch(notificationOpen(notificationError));
      }
    })();
  }, []);

  function returnRadioButtonValue(value) {
    let valueRadio = value;
    setRadioTipoPrenotazione(valueRadio);
    dispatch(cambiaRadioButtonPrenotazionePerAltri(valueRadio));
    dispatch(resetUtentePrenotazione());
  }

  //se seleziono me stesso e ho degli ospiti, devo creare la prenotazione per me stesso.
  useEffect(() => {
    if (!isFirst) {
      let prenotazioneUtenteLoggato = utentePrenotazione.some(
        (x) => x.utente === null
      ); //la prenotazione dell'utente loggato viene inseria a null nell'elenco prenotazione;
      if (
        ospitiPrenotazioneInfo.length > 0 &&
        radioTipoPrenotazione === radioButtonPrenotazioneStepper[0].value
      ) {
        if (!prenotazioneUtenteLoggato) {
          let prenotazioneUserLogged = userLoggedToPrenotazioneObj(
            userDate,
            avatarUserLogged
          );
          setReload(!reload);
          dispatch(
            returnUtentePrenotazione([
              ...utentePrenotazione,
              prenotazioneUserLogged,
            ])
          );
        }
      } else if (prenotazioneUtenteLoggato) {
        let prenotazioniWithOutUserLogged = utentePrenotazione.filter((x) => {
          if (x.utente !== null) return x;
        });
        setReload(!reload);
        dispatch(returnUtentePrenotazione(prenotazioniWithOutUserLogged));
      }
    } else {
      setIsFirst(false);
    }
  }, [radioTipoPrenotazione, ospitiPrenotazioneInfo]);

  return (
    <React.Fragment>
      <Grid
        container
        rowSpacing={5}
        columnSpacing={5}
        sx={{ paddingBottom: "40px", paddingTop: "40px" }}
      >
          <>
            {/* <Grid item xs={9} md={9} className="stepper-grid">
              <RadioButtonCustom
                GroupName="Prenota"
                startValueReset={radioButtonPrenotazionePerAltri}
                labelList={ radioButtonPrenotazioneStepperOnlyForMe}
                handleChange={returnRadioButtonValue}
              />
            </Grid> */}
            {/* <Grid item xs={3} md={3}>
              <Button
                variant="outlined"
                onClick={() => {
                  setDialogOspitiState(!dialogOspitiState);
                }}
                sx={{ float: "right" }}
              >
                Ospiti
              </Button>
            </Grid> */}
            {/* {radioTipoPrenotazione ===
              radioButtonPrenotazioneStepper[1].value && (
              <Grid item xs={12} md={6} className="stepper-grid">
                <AutocompleteCustom
                  label="Prenota per conto di..."
                  field={["nominativo"]}
                  checkField={"nominativo"}
                  fullWidth={false}
                  keyId={"nominativo"}
                  openOnFocus={true}
                  optionsAutoComplete={usersRows}
                  handleValueReturned={handleValueUsers}
                  startValueAutoComplete={utentePrenotazione.filter((x) => {
                    if (x.ospite === false) return x;
                  })}
                  multiple={true}
                />
              </Grid>
            )}
            <Grid item xs={12} md={12} className="stepper-grid">
              <PannelloOspiti ospiti={[]} />
            </Grid> */}
          </>
        <Grid rowSpacing={5} columnSpacing={5} container item xs={12}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={itLocale}>
            <Grid item xs={12} sm={4} className="stepper-grid">
              <GeneralCustomDateTimePicker
                TypeTimePicker={5}
                label="Data prenotazione"
                valueStart={valueDataGiorno}
                handleChange={handleChangeData}
                minDate={now}
                maxDate={maxday}
                placeholder={"gg/mm/aaaa"}
              />
            </Grid>
            <Grid item xs={6} sm={4} className="stepper-grid">
              <GeneralCustomDateTimePicker
                TypeTimePicker={1}
                label="Orario di inizio"
                valueStart={valueDataInizio}
                handleChange={handleChangeOrarioInizio}
              />
            </Grid>
            <Grid item xs={6} sm={4} className="stepper-grid">
              <GeneralCustomDateTimePicker
                TypeTimePicker={1}
                label="Orario di fine"
                valueStart={valueDataFine}
                handleChange={handleChangeOrarioFine}
              />
            </Grid>
          </LocalizationProvider>
        </Grid>
        {/* <Grid container item xs={12} className="stepper-grid">
          <RipetizioniBox maxday={maxday} minDayValue={valueDataGiorno} />
        </Grid> */}
        {/* <Grid container item xs={12} className="stepper-grid">
          {!disabled &&
            (radioTipoPrenotazione ===
              radioButtonPrenotazioneStepper[0].value ||
              utentePrenotazione.length === 1) &&
              ospitiPrenotazioneInfo.length === 0 &&
              (
              <PannelloSuggerimentiPrenotazione label={"Suggerimenti"} />
            )}
        </Grid> */}
      </Grid>
      <PrenotazioniOspiti
        open={dialogOspitiState}
        handleClose={setDialogOspitiState}
        selectOptions={usersRows}
        selectedOption={utentePrenotazione}
      />
    </React.Fragment>
  );
}

/* trasforma un orario in una data statica più orario flessibile*/
function staticDateWithTimeValue(newValue) {
  let hours = new Date(newValue).getHours();
  let minutes = new Date(newValue).getMinutes();
  let date = new Date(null);
  return moment(new Date(date.setHours(hours, minutes))).format("HH:mm");
}
