import { Button, Grid, Tooltip } from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";
import { useDispatch, useSelector } from "react-redux";
import { ruoliApplicazione } from "../../../const/general/roles";
import { openEmptyDialogUser } from "../../../redux/reducers/dialogUser-reducer";
import { GetTaskSincronizzazioneUtenti } from "../../../const/urlAction";
import { useEffect, useState } from "react";
import handlerError from "../../../functions/handlerError";
import { notificationOpen } from "../../../redux/reducers/notification-reducer";
import axios from "axios";
import { ProgressBarDisponibilitaCircular, ProgressBarDisponibilitaLinear } from "../../Disponibilita/componentsViewDisponibilita/ProgressBarDisponibilita";
import useTaskAsyncRun from "../../../Hooks/TaskAsync/useTaskAsyncRun";

export const sincronizzazione = "sincronizzazione";
const taskMustBlockActivity = [1, 2];
const urlBase = process.env.REACT_APP_URL;

export default function SynchronizeButton({ task = null }) {
  const state = useSelector((state) => state.userDate);
  const taskState = useSelector((state) => state.taskAsync.utentiSincronizzazione);
  const dispatch = useDispatch();
const [oldTaskPercentage , setOldTaskPercentage] = useState(0);

//gestisce il rendering delle percentuali nella progressbar
  useEffect(() => {
    if(taskState?.taskObj?.taskPercentage  ) {
      if(taskState?.taskObj?.taskPercentage > oldTaskPercentage) setOldTaskPercentage(taskState?.taskObj?.taskPercentage)
    } else {
      setOldTaskPercentage(0)
    }
  },[taskState?.taskObj?.taskPercentage])

  return (
    state.roles.includes(ruoliApplicazione.admin) &&
     (taskState?.taskObj === null || !taskMustBlockActivity.includes(taskState.taskObj.taskType) ? (
      <>
        <Tooltip title="Sincronizzazione utenti">
          <div className="buttonBoxToolbar">
            <Button
              variant="outlined"
              startIcon={<SyncIcon />}
              size="small"
              sx={{ color: "#026D82" }}
              onClick={() => dispatch(openEmptyDialogUser(sincronizzazione))}
            >
              Sincronizzazione utenti
            </Button>
          </div>
        </Tooltip>
      </>
    ) : (
      <>
        <Tooltip title="Sincronizzazione utenti">
          <div className="buttonBoxToolbar">
            <Button
              variant="outlined"
              startIcon={<SyncIcon />}
              size="small"
              sx={{ color: "#026D82" }}
              disabled
            >
              Sincronizzazione in corso...
              <div className="flex-center-center">
                <ProgressBarDisponibilitaLinear
                  value={Math.max(taskState?.taskObj?.taskPercentage, oldTaskPercentage)}
                  style={{width:"105px"}}
                />
              </div>
            </Button>
          </div>
        </Tooltip>
      </>
    ))
  );
}
