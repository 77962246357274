
export const policiesAction = {
    visualizzazione: "Visualizzazione",
    esportazione:"Esportazione",
    inserimentoModifica:"InserimentoModifica",
    resetPassword:"ResetPassword",
    abilitaDisabilita:"AbilitaDisabilita",
}

export const policies = {
    gestioneRichiestaCredenziali:"Utenti.GestioneRichiestaCredenziali",
    utentiVisualizzazione:"Utenti.Visualizzazione",
    utentiEsportazione:"Utenti.Esportazione" ,
    utentiInserimentoModifica:"Utenti.InserimentoModifica" ,
    utentiEliminazioneRipristino:"Utenti.EliminazioneRipristino" ,
    utentiResetPassword:"Utenti.ResetPassword",
    utentiCancellazione:"Utenti.Cancellazione",
    
    edificiVisualizzazione: "Edifici.Visualizzazione",
    edificiInserimentoModifica: "Edifici.InserimentoModifica",
    edificiEliminazioneRipristino:"Edifici.EliminazioneRipristino" ,

    postazioniVisualizzazione : "Postazioni.Visualizzazione",
    postazioniInserimentoModifica : "Postazioni.InserimentoModifica",
    postazioniEliminazioneRipristino :"Postazioni.EliminazioneRipristino",
    postazioniDownloadQRCode :"Postazioni.CreaQrCode",

    zoneVisualizzazione: "Zone.Visualizzazione",
    zoneInserimentoModifica: "Zone.InserimentoModifica",
    zoneEliminazioneRipristino:"Zone.EliminazioneRipristino",

    stanzeVisualizzazione: "Stanze.Visualizzazione",
    stanzeInserimentoModifica: "Stanze.InserimentoModifica",
    stanzeEliminazioneRipristino:"Stanze.EliminazioneRipristino",

    prenotazionePostazionePerAltri:"Prenotazione.PrenotazionePerAltri",

    VisualizzazioneCompleta: "General.VisualizzazioneCompleta"
}