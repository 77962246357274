import { Divider, Grid, Popover, DialogTitle } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import "../PlanimetriaIcons.css";
import {
  aggiornaElementoPlaceholderMappa,
  creaElementoMappa,
} from "../../../functions/creaElementoMappa";
import { iconSelectedElement } from "../../../const/Planimetria/IconPlanimetria";
import Notification from "../../UI/Notification/Notification";
import ElementSubMenu from "../SubMenu/ElementSubmenu";
import { DISPONIBILE } from "../../../const/Planimetria/StatoPostazione";
import {
  aggiustamentiMenuContestualePlanimetriaPrenotazione,
  aggiustamentiPlanimetriaGestione,
  aggiustamentiPlanimetriaPrenotazione,
} from "../../../const/Planimetria/gestionePlanimetria";
import sfondoDefault from "../../../assests/sfondoPlanimetriaDefault.png";
import {
  savePostazioneCompletoInPrenotazioneStepper,
  saveStanzaCompletoInPrenotazioneStepper,
} from "../../../redux/reducers/infoPrenotazione-reducer";
import useNoPassiveEventListener from "../../../Hooks/useNoPassiveEventListener";
import useScrollGrabOnContainer from "../../../Hooks/useScrollGrabToContainer/useScrollGrabOnContainer";
import { addDoubleClickEvent } from "../../../functions/Utils/addDoubleClickEvent";
import {
  calcolaStato,
  handlerMobileZoom,
  handlerScale,
} from "../../../functions/handlerZoom";
import useMobileDetection from "../../../Hooks/useMobileDetection";
import {
  calcolaPercentualeDiMarginDaScaleImage,
  moltiplicaPercentualeScalling,
} from "../../../functions/calcolaScaleImageDelloZoom";
import ShowTips from "../../showTips/showTips";
import {
  initialValueScaleZoomDesktop,
  initialValueScaleZoomMobile,
} from "../../../const/Planimetria/valueScaleZoomPlanimetria";
import { containerClass } from "../../../const/Planimetria/costantiPlanimetria";
import handlerConfiguration from "../../../functions/general/handlerConfiguration";
import PartecipantiRiunione from "./PartecipantiRiunione/PartecipantiRiunione";
import {
  handlerUtentiPrenotazione,
} from "../../../redux/reducers/prenotazioneStepper-reducer";
import { selectUserInMultiUser } from "../../../redux/reducers/MultiUserInStepper-reducer";
import { notificationOpen } from "../../../redux/reducers/notification-reducer";
import AvatarImage from "../../Avatar/AvatarImage";

const grandezzaIcona = 24;
const PropStanza = "posto";
const msgAvvisoSelezioneUtente =
  "Per procedere con la prenotazione di un posto letto, è necessario selezionare un utente";

const componentName = "PlanimetriaPrenotazione";
export default function PlanimetriaPrenotazine({
  elementiDalDb = [],
  typePage = "",
  imageBackground,
  actionOnClickButton,
  dettaglioSfondoPlanimetria = {},
}) {
  const configurations = useSelector((state) => state.configurations);


  const {
    utentePrenotazione: utentiEPostazioneDaInserire,
    partecipantiRiunione,
    titoloRiunione,
    invioNotificaInvito,
  } = useSelector((state) => state.prenotazioneStepper);

  const infoPrenotazioneStepper = useSelector(
    (state) => state.prenotazioneStepper
  );

  const {
    larghezzaPlanimetriaDefault: PlanimetriaLarghezza,
    altezzaPlanimetriaDefault: PlanimetriaAltezza,
  } = handlerConfiguration(configurations);

  const isMobile = useMobileDetection();
  const refContainerDropzone = useRef(null);
  const dispatch = useDispatch();
  const refImage = useRef(null);
  const [selected, setSelected] = useState(-1);
  const [elementiRecuperati, setElementiRecuperati] = useState([]);
  const [dettaglioSalaRiunione, setDettaglioSalaRiunione] = useState(undefined);
  const [titoloRiunioneSala, setTitoloRiunioneSala] = useState("");
  const [invioNotificaInvitoState, setInvioNotificaInvitoState] = useState();
  const [ospitiDaInserireInSalaRiunione, setOspitiDaInserireInSalaRiunione] =
    useState([]);
  const [isOpenSubmenu, setIsOpenSubmenu] = useState(false);
  const userSelectedInStepper = useSelector(
    (state) => state.multiUserHandlerInStepper
  );
  const [scaleZoom, setScaleZoom] = useState(
    isMobile ? initialValueScaleZoomMobile : initialValueScaleZoomDesktop
  );
  const [infoPrenotazioniToSubmenu, setinfoPrenotazioniToSubmenu] = useState(
    []
  );
  const [infoPositionSubMenuAndId, setInfoPositionSubMenuAndId] = useState({
    id: 0,
    x: 0,
    y: 0,
    status: 0,
    descrizioneStatus: "Disponibile",
  });
  const [anchorEl, setAnchorEl] = React.useState(null);

  const {
    altezzaPlanimetria,
    denominazione,
    sigla,
    guidSfondoPlanimetria,
    larghezzaPlanimetria,
    idSfondoPlanimetria,
    isSalaRiunione,
  } = dettaglioSfondoPlanimetria;

  let deltaScale =
    handlerScale -
    (isMobile ? initialValueScaleZoomMobile : initialValueScaleZoomDesktop);

  useEffect(() => {
    setIsOpenSubmenu(false);
    let elementiDaMostrare = elementiDalDb.map((el) => {
      return {
        element: creaElementoMappa(el, true, userSelectedInStepper),
        ...el,
      };
    });

    let elementi = elementiDaMostrare;

    if (
      elementiDaMostrare.length > 0 &&
      elementiDaMostrare.every((x) => x.hasOwnProperty(PropStanza))
    ) {
      // se gli elementi non hanno la proprietà posto, non è una stanza ma una zona

      elementi = utentiEPostazioneDaInserire.some(
        (x) => x.posto != null || undefined
      )
        ? aggiornaStatoPostazioniPlanimetriaConPrenotazioniInCorso(
            utentiEPostazioneDaInserire,
            elementiDaMostrare,
            userSelectedInStepper
          )
        : elementiDaMostrare;

      // Gestisce il punto di vista dell'utente selezionato nella prenotazione multipla
      if (
        userSelectedInStepper.posto &&
        elementi[0]?.idStanza === userSelectedInStepper?.idStanza
      ) {
        setSelected({ index: userSelectedInStepper.posto - 1, status: 1 });
        dispatch(
          savePostazioneCompletoInPrenotazioneStepper({
            posto: userSelectedInStepper.posto,
          })
        ); //tiene aggiornata la postazione nelle info della stepper
      } else {
        setSelected(-1);
      }
    }
    setElementiRecuperati(elementi);
  }, [elementiDalDb, userSelectedInStepper]);

  function aggiornaStatoPostazioniPlanimetriaConPrenotazioniInCorso(
    utentiEPostazioneDaInserire = [],
    elementiRecuperati = [],
    userSelectedInStepper = null
  ) {
    let arr = utentiEPostazioneDaInserire.filter(
      (x) => x.posto != null || undefined
    );

    if (!arr.length) return null;

    let elementiDaModificare = arr.filter((x) => {
      let result = elementiRecuperati.filter((y) => x.posto === y.posto);
      if (result) {
        return x;
      }
    });

    if (
      !elementiDaModificare.length ||
      !elementiRecuperati.length ||
      !elementiRecuperati.every((x) => x.hasOwnProperty("posto"))
    )
      return null;

    let result = aggiornaElementoPlaceholderMappa(
      elementiRecuperati,
      elementiDaModificare,
      userSelectedInStepper,
      infoPrenotazioneStepper,
      utentiEPostazioneDaInserire
    );

    return result;
  }

  function openSubmenu(event, id, descrizioneStatus, status, selectedElement) {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);

    if (selectedElement !== undefined) {
      const { posX, posY, prenotazioni } = elementiRecuperati[selectedElement];
      setInfoPositionSubMenuAndId((prev) => {
        prev.id == id
          ? setIsOpenSubmenu(!isOpenSubmenu)
          : setIsOpenSubmenu(true);
        return {
          id,
          status,
          descrizioneStatus,
          x:
            event.screenX +
            refContainerDropzone?.current?.scrollLeft -
            refContainerDropzone.current.parentElement.parentElement
              .parentElement.parentElement.parentElement.parentElement
              .parentElement.offsetLeft -
            aggiustamentiPlanimetriaPrenotazione.aggiustamentoPosizioneLeftSuPlanimetriaPrenotazione +
            aggiustamentiMenuContestualePlanimetriaPrenotazione.aggiustamentoPosizioneLeftSuMenuContestualePlanimetriaPrenotazione,
          y:
            event.screenY -
            refContainerDropzone.current.parentElement.parentElement
              .parentElement.parentElement.parentElement.parentElement
              .parentElement.offsetTop +
            refContainerDropzone?.current?.scrollTop -
            aggiustamentiPlanimetriaPrenotazione.aggiustamentoPosizioneTopSuPlanimetriaPrenotazione,
        };
      });
      setinfoPrenotazioniToSubmenu(prenotazioni);
    }
  }

  function onClickIcon(event, index, el) {
    const {
      id,
      status,
      statusDescrizione,
      postazioniDisponibili,
      posto,
      idStanza,
    } = el;
    if (el.idZona != undefined) {
      dispatch(
        saveStanzaCompletoInPrenotazioneStepper({
          id: el.id,
          sigla: el.sigla,
          denominazione: el.denominazione,
          isSalaRiunione: el.isSalaRiunione,
        })
      );
    }


    let selectedElement = actionOnClickButton(
      event,
      id,
      index,
      status,
      postazioniDisponibili
    );
    
    if (status == DISPONIBILE) {
      if (Object.keys(userSelectedInStepper).length === 0 && infoPrenotazioneStepper.radioButtonPrenotazionePerAltri && infoPrenotazioneStepper.utentePrenotazione.length > 0) {
        // se non è selezionato nessun utente impedisce di selezionare la postazione e lancia una notifica all'utente.
        dispatch(
          notificationOpen({
            message: msgAvvisoSelezioneUtente,
            status: "warning",
          })
        );
        return;
      }

      setSelected({ index: selectedElement, status });
      dispatch(
        selectUserInMultiUser({
          ...userSelectedInStepper,
          posto: selectedElement + 1,
          idStanza: idStanza,
        })
      ); //mantiene coerenti i dati a livello di redux
      dispatch(savePostazioneCompletoInPrenotazioneStepper({ posto })); //viene usato nella Stepper per gestire le informazioni da mostrare nei pannelli riepilogativi
      const utenteConPostoAssociato = {
        ...userSelectedInStepper,
        idStanza,
        posto,
        id,
      };
      dispatch(handlerUtentiPrenotazione(utenteConPostoAssociato));
    } else {
      if (event)
        openSubmenu(event, id, statusDescrizione, status, selectedElement);
    }
  }

  function onTouchMobile(event, index, el) {
    event.preventDefault();
    event.stopPropagation();
    const { id, status, statusDescrizione, postazioniDisponibili, posto } = el;

    if (el.idZona != undefined) {
      dispatch(
        saveStanzaCompletoInPrenotazioneStepper({
          id: el.id,
          sigla: el.sigla,
          denominazione: el.denominazione,
          isSalaRiunione: el.isSalaRiunione,
        })
      );
    }

    let selectedElement = actionOnClickButton(
      event,
      id,
      index,
      status,
      postazioniDisponibili
    );
    if (status == DISPONIBILE) {
      setSelected({ index: selectedElement, status });
      dispatch(
        selectUserInMultiUser({
          ...userSelectedInStepper,
          posto: selectedElement + 1,
          idStanza: el.idStanza,
        })
      ); //mantiene coerenti i dati a livello di redux
      dispatch(savePostazioneCompletoInPrenotazioneStepper({ posto })); //viene usato nella Stepper durante la prenotazione
      const utenteConPostoAssociato = { ...userSelectedInStepper, posto, id };
      dispatch(handlerUtentiPrenotazione(utenteConPostoAssociato));
    } else {
      openSubmenu(undefined, id, statusDescrizione, status, selectedElement);
    }
  }
  //se è presente un solo elemento nella stanza o sala, lo auto-seleziona.
  useEffect(() => {
    if (
      utentiEPostazioneDaInserire.length <= 1 &&
      elementiRecuperati?.length === 1 &&
      selected === -1 &&
      elementiRecuperati[0].status === 1
    ) {
      //dispatch(resetPostazioniPrenotate())
      let el = elementiRecuperati[0];
      el.prenotazioni = [];
      el.status = 1;
      onClickIcon(null, 0, el);
    }
    if (dettaglioSfondoPlanimetria.isSalaRiunione) {
      const idPostazioneSalaRiunione = elementiDalDb[0].id;
      setOspitiDaInserireInSalaRiunione(
        partecipantiRiunione[idPostazioneSalaRiunione]
      );

      let keyIdPostazioneTitoloRiunione = Object.keys(titoloRiunione);

      let indexTitolo = keyIdPostazioneTitoloRiunione.map((x, index) => {
        if (x == idPostazioneSalaRiunione) {
          return index;
        }
      });

      let filteredIndexTitolo = indexTitolo.filter((x) => x !== undefined)[0];
      let idPostazioneSala = keyIdPostazioneTitoloRiunione[filteredIndexTitolo];
      let objTitolo = titoloRiunione[idPostazioneSala];
      if (objTitolo) setTitoloRiunioneSala(objTitolo);

      setDettaglioSalaRiunione(elementiDalDb[0]);
    }
  }, [elementiRecuperati, userSelectedInStepper, titoloRiunione]);

  function closeMenu(event) {
    event.preventDefault();
    event.stopPropagation();
    setIsOpenSubmenu(false);
  }

  function stopEvent(event) {
    event.stopPropagation();
  }

  useNoPassiveEventListener(refImage, "wheel", setScaleZoom);

  useScrollGrabOnContainer(refContainerDropzone, refImage);

  const { height, width, marginLeft, marginTop } =
    calcolaPercentualeDiMarginDaScaleImage(
      isMobile ? initialValueScaleZoomMobile : initialValueScaleZoomDesktop,
      handlerScale,
      idSfondoPlanimetria,
      {
        altezzaCustomImg: altezzaPlanimetria,
        larghezzaCustomImg: larghezzaPlanimetria,
      },
      {
        altezzaDefault: PlanimetriaAltezza,
        larghezzaDefault: PlanimetriaLarghezza,
      }
    );

  return (
    <React.Fragment>
      <Grid container className={true ? "" : "dontshow"}>
        <Grid item xs={12} sm={12}>
          <div
            style={{
              overflow: "auto",
              maxWidth: "100%",
              maxHeight: `calc( ${window.innerHeight}px - (${
                refContainerDropzone?.current?.offsetTop + 160
              }px)`,
            }}
            ref={refContainerDropzone}
          >
            {Object.keys(dettaglioSfondoPlanimetria).length !== 0 && (
              <div
                className={containerClass}
                ref={refImage}
                style={{
                  position: "relative",
                  marginLeft: `${
                    marginLeft *
                    (calcolaStato(scaleZoom) *
                      moltiplicaPercentualeScalling(deltaScale))
                  }px`,
                  marginTop: `${
                    marginTop *
                    (calcolaStato(scaleZoom) *
                      moltiplicaPercentualeScalling(deltaScale))
                  }px`,
                  transform: `scale(${scaleZoom})`,
                  backgroundImage: idSfondoPlanimetria
                    ? `url(${imageBackground})`
                    : `url(${sfondoDefault})`,
                  height: `${height}px`,
                  width: `${width}px`,
                  backgroundRepeat: "no-repeat",
                }}
                onClick={(e) =>
                  addDoubleClickEvent(
                    e,
                    { function: closeMenu },
                    {
                      function: handlerMobileZoom,
                      params: {
                        saveStateFunction: setScaleZoom,
                        refContainer: refContainerDropzone,
                      },
                    },
                    { ableToDesktop: false, isMobile, timer: 200 }
                  )
                }
              >
                {elementiRecuperati?.map((el, index) => (
                  <Draggable
                    onStart={() => false}
                    position={{ x: el.posX, y: el.posY }}
                    key={index}
                  >
                    <div
                      className={el.element.style}
                      style={{
                        position: "absolute",
                        width: `${grandezzaIcona}px`,
                        height: `${grandezzaIcona}px`,
                        displaY: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={(event) => onClickIcon(event, index, el)}
                      onTouchStart={(event) => onTouchMobile(event, index, el)}
                      onTouchStartCapture={(event) => stopEvent(event)}
                      key={index}
                    >
                      {selected.index === index && selected.status === DISPONIBILE
                          ? 
                          iconSelectedElement
                          :
                          el.prenotazioni?.[0]?.utentePrenotatoFullname != null & el.prenotazioni?.every(x => x.utentePrenotato === el.prenotazioni?.[0].utentePrenotato)
                            ?   
                            <AvatarImage size={38} bgColor={'#ad3a3a'} ImmagineAvatar={el.prenotazioni.length >= 1 ? el.prenotazioni?.[0].immagineAvatar : null} 
                            isUserLoggedImage={false} isCentered={false} isInputDisabled={true} name={el.prenotazioni?.[0].utentePrenotatoFullname}></AvatarImage>
                            :
                            el.element.icon
                      }
                    </div>
                  </Draggable>
                ))}
                <ElementSubMenu
                  open={isOpenSubmenu}
                  mostraTasti={false}
                  infoPrenotazioniToSubmenu={infoPrenotazioniToSubmenu}
                  infoPositionSubMenuAndId={infoPositionSubMenuAndId}
                  typeComponent={typePage}
                  anchorEl={anchorEl}
                />
              </div>
            )}
          </div>
          <Divider sx={{ marginTop: "2px" }} />
        </Grid>
        {isSalaRiunione && elementiDalDb[0].status === DISPONIBILE ? (
          <PartecipantiRiunione
            ospitiDaPrenotazioneStepper={ospitiDaInserireInSalaRiunione}
            salaRiunione={dettaglioSalaRiunione}
            titoloRiunione={titoloRiunioneSala}
          />
        ) : null}
      </Grid>
      <Notification />
      {isMobile && <ShowTips parent={componentName} />}
    </React.Fragment>
  );
}
