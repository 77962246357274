import React from "react";
import { RowPrenotazioneCompact, RowPrenotazioneConTimeline } from "./RowPrenotazione/RowPrenotazione";
import useMobileDetection from "../../../../Hooks/useMobileDetection";
import "../Prenotazioni.styles.css";
import { enumViewModePrenotazioni } from "../../../../const/prenotazioni/enumViewMode";

export default function ListaPrenotazioni({ prenotazioni, viewMode, viewOnlyMine, isOpenAdvancedSearch, advancedSearchMode}) {
  const isMobile = useMobileDetection(950);
  /**Conserva il valore della data inizio prenotazione durante iterazione delle prenotazioni*/
  let currentDateSlot = null;

  /** Data una prenotazione, ritorna un valore boolean che determina se essa 
    * è la prima prenotazione della fascia oraria (visualizzazione giornaliera) 
    * o la prima del giorno (visualizzazione mensile) 
    * per visualizzare nella timeline le etichette di ora/giorno solo sui bullet rilevanti */
  function determineIfSectionHeader(prenotazione) {
    let date = new Date(prenotazione.dataInizioPrenotazione);
    let result;

    if(!currentDateSlot) {
      currentDateSlot = date;
      return true;
    } 


    if(viewMode === enumViewModePrenotazioni.daily) {      
      if(date.getHours() !== currentDateSlot.getHours() || date.getMinutes() !== currentDateSlot.getMinutes()) {
        currentDateSlot = date;
        result = true;
      }
      else {
        result = false;
      }      
    }
    else { 
      // Visualizzazione Mensile: controllo per Data
      if (date.getDate() !== currentDateSlot.getDate()) {
        currentDateSlot = date;
        result = true;
      }
      else result = false;   
    }

    return result;       
  }

  return (<>
    {prenotazioni.length > 0 && (
      <div style={{ width: "100%"}} >{
        prenotazioni.map((prenotazione, index) => {
          return <div key={"prenotazione_key_" + index}>
            {isMobile 
              ? <RowPrenotazioneCompact 
              prenotazione={prenotazione} 
              viewMode={viewMode}     
              advancedSearchMode={advancedSearchMode}  
              viewOnlyMine={viewOnlyMine}/>
              : <RowPrenotazioneConTimeline
                isOpenAdvancedSearch= {isOpenAdvancedSearch}
                viewOnlyMine={viewOnlyMine}
                prenotazioni={prenotazioni}
                index={index}
                viewMode={viewMode}
                isSectionHeader={determineIfSectionHeader(prenotazione)} />}
          </div>
        })}
      </div>)}
  </>);

}