import { useState, useEffect, useLayoutEffect } from 'react';

/**
 * L'hook useViewportAdaptedHeight restituisce l'altezza adattata del viewport.
 * L'hook tiene conto anche dell'altezza degli elementi selezionati tramite un selettore.
 * @param {string} selector - Un selettore CSS per selezionare gli elementi di cui si vuole tenere conto nell'altezza totale. Es:".container"
 * @param {number} mainPaddingAndMargin - Un valore numerico per l'altezza totale dell'eventuale padding e margini dell'elemento principale del layout. 
 * @param {ref} ref - La referenza all'elemento di cui si vuole tenere conto nell'altezza totale. 
 * @param {boolean} isSideEffectCompleted - Un parametro booleano che indica se il side effect che condiziona il componente è completo o meno.
 * @return {string} L'altezza totale adattata del viewport, compresa l'altezza degli elementi selezionati e l'altezza del ref passato, con il suffisso "px" se necessario.
 */
export default function useViewportAdaptedHeight(selector = null, mainPaddingAndMargin = 0, ref = null, isSideEffectCompleted) {
  const [height, setHeight] = useState(null);

  // Utilizza useLayoutEffect perché l'altezza adattata del viewport deve essere calcolata prima che venga disegnata la UI
  useLayoutEffect(() => {
    function handleResize() {
      // Calcola l'altezza adattata del viewport in base all'altezza della finestra del browser
      // sottraendo l'altezza degli eventuali elementi selezionati tramite selettore
      let adaptedHeight = window.innerHeight;
      let elementsHeight = 0;
      let elementRefHeight = 0;
      
      // Se il selettore è stato fornito, seleziona tutti gli elementi corrispondenti e calcola la loro altezza
      if (selector) {
        const elements = document.querySelectorAll(selector);
        elements.forEach(element => {
          elementsHeight += element.clientHeight;
          const style = getComputedStyle(element);
          elementsHeight += parseInt(style.getPropertyValue('padding-top'), 10) + parseInt(style.getPropertyValue('padding-bottom'), 10);
          elementsHeight += parseInt(style.getPropertyValue('margin-top'), 10) + parseInt(style.getPropertyValue('margin-bottom'), 10);
        });
      }

      // Se la referenza è stata fornita, calcola l'altezza dell'elemento corrispondente
      if (ref && ref.current) {
        elementRefHeight += ref.current.clientHeight;
      }

      // Controlla se la somma delle altezze degli elementi supera l'altezza del viewport
      if (elementsHeight + elementRefHeight > window.innerHeight) {
        adaptedHeight = "100%";
      } else {
        adaptedHeight = `${window.innerHeight - elementsHeight - mainPaddingAndMargin}px`;
      }

      // Se il side effect che condiziona il componente non è ancora completo, impostiamo l'altezza adattata al 100% in modo da non condizionarne la grandezza.
      if(!isSideEffectCompleted) adaptedHeight = "100%";
      
      // Imposta l'altezza adattata calcolata nello stato
      setHeight(adaptedHeight);
    }

    // Aggiungi un event listener per ricalcolare l'altezza adattata del viewport
    // in caso di ridimensionamento della finestra del browser
    window.addEventListener('resize', handleResize);

    // Calcola l'altezza adattata del viewport iniziale
    handleResize();

    // Rimuovi l'event listener quando l'hook viene smontato per evitare memory leaks
    return () => window.removeEventListener('resize', handleResize);
  }, [selector, mainPaddingAndMargin, isSideEffectCompleted, ref]);

  // Restituisci l'altezza adattata del viewport con il suffisso "px" se necessario
  return height;
}

