import { openDialogWithBody, setPageType } from "../redux/reducers/DialogGeneral";

const typePage = "planimetria";

export default function openDialogFromPlanimetria(dispatch, type, body){
    
    /* dalla zona creo la stanza, dalla stanza creo la postazione*/ 
    switch(true) {
        case type === "zona":
             dispatch(setPageType("planimetriaZona"))
             dispatch(openDialogWithBody({dialogType:'add',body}))
          break;
        case type === "stanza":
            dispatch(setPageType("planimetriaStanza"))
            dispatch(openDialogWithBody({dialogType:'add',body}))
          break;
      } 
}