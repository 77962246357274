import TipsZoomMobile from "../../components/showTips/TipsComponents/TipsZoomMobile";


//l'anchor funziona solo sul "event.currentTarget", lo useRef non lo prende buono

export default function createTipsElement(parent,showTips, handleCloseTips) {
  let element = switchTipsElement(parent, showTips, handleCloseTips);
  return element;
}

function switchTipsElement(parent,showTips, handleCloseTips) {
  let element = null;
  let vertical;
  let horizontal;
  let nameTips;
  let pointToView

  switch (true) {
    case (parent === "PlanimetriaPrenotazione" && !showTips):
      nameTips = "tipsZoomMobile"
      element = <TipsZoomMobile handleCloseTips={handleCloseTips} nameTips={nameTips}/>;
      vertical = "center";
      horizontal = "center";
      break;
  }

  return { element, vertical, horizontal, nameTips, pointToView };
}
