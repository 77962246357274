export default function handlerMultiViewComponent(listaComponents = [], keyToRender, defaultPathToBack) {
    if(!keyToRender) keyToRender = defaultPathToBack
  
    let componentToShow = listaComponents.filter((x) => {
        return x.keyToIdentifyComponentToShow.trim().toLowerCase() === keyToRender.trim().toLowerCase();
});

if(componentToShow.length === 1) return componentToShow[0].component;

componentToShow.length > 1 
? console.error("Double key To Identify ComponentToShow in list of components") 
: console.error("List of components is empty");

return <></>

}
