import { recuperaOraEMinutiDaStringa } from "./recuperaOraEMinutiDaStringa";

export default function recuperaMaxTimeQrCodePrenotazione(dataFinePrenotazione, dataFinePrenotazioneRecuperata) {
    let maxTime
      if(dataFinePrenotazione !== null) {
        const orario = recuperaOraEMinutiDaStringa(dataFinePrenotazioneRecuperata)

        maxTime = new Date(0, 0, 0, orario.ora, orario.minuti)
      } else {
        maxTime = null
      }     

      return maxTime;
}