import React from "react";
import IconaPlanimetria from "../components/Planimetria/IconaPlanimetria";
import {
  statusToIcon,
  statusToIconRiepilogoPrenotazione,
  statusToIconStanzaSalaRiunione,
} from "../const/Planimetria/IconPlanimetria";
import { assegnaIconeInPlanimetriaGestione } from "./assegnaIconeInPlanimetriaGestione";

export const arrayElement = [];

const creaElementoMappa = (element = null, iconWithStyle = false, userPointOfView) => {
  let { icon, style } = {};

  //metodo di assegnazione icona di default per la creazione degli elementi
  const icons = assegnaIconeInPlanimetriaGestione(
    element.idStanza,
    element.isSalaRiunione
  );


  element.isSalaRiunione
    ? ({ icon, style } = statusToIconStanzaSalaRiunione(
        icons,
        element,
        iconWithStyle,
        userPointOfView
      ))
    : ({ icon, style } = statusToIcon(icons, element, iconWithStyle, userPointOfView));
  return { icon: <IconaPlanimetria icon={icon} />, style: style };
};

const creaElementoMappaRiepilogoPrenotazione = (
  icons,
  element = null,
  parent = "",
  utentePrincipale = undefined
) => {
  let { icon, style } = statusToIconRiepilogoPrenotazione(
    icons,
    element,
    utentePrincipale,
    parent
  );
  return { icon: <IconaPlanimetria icon={icon} />, style: style };
};

const aggiornaElementoPlaceholderMappa = (
  elementiOriginali = [],
  elementiDaModificare = [],
  userSelectedInStepper = null,
  infoPrenotazioneStepper,
  infoUtentiPrenotazione
) => {
  let elementiAggiornati = elementiDaModificare.map((el) => {

    if(elementiOriginali[0].idStanza !== el.idStanza) return null;

    let arr = elementiOriginali.map((x) => x.posto);
    let index = arr.indexOf(el.posto);
    let elDaModificare = elementiOriginali[index];
   
    if (elDaModificare.idStanza === userSelectedInStepper.idStanza && elDaModificare.posto === userSelectedInStepper.posto ) { 
      elDaModificare.element = creaElementoMappa(elDaModificare, true);
    } else {
      //aggiunge le prenotazioni non ancora inserite
      let utenteAssociato = infoUtentiPrenotazione.filter(
        (x) => x.idPosto === elDaModificare.id
      );
      elDaModificare.status = 2;
      elDaModificare.prenotazioni = [
        {
          dataFinePrenotazione: new Date(
            `1970-01-01T${infoPrenotazioneStepper.dataFine}`
          ),
          dataInizioPrenotazione: new Date(
            `1970-01-01T${infoPrenotazioneStepper.dataInizio}`
          ),
          utentePrenotatoFullname: utenteAssociato[0]?.nominativo,
          immagineAvatar: utenteAssociato[0]?.immagineAvatar
        },
      ];
      elDaModificare.element = creaElementoMappa(elDaModificare, true);
    }
    return elDaModificare;
  });


  elementiOriginali = elementiOriginali.map((x) => {
    let index = elementiAggiornati.indexOf(x.id);
    return index !== -1 ? elementiAggiornati[index] : x;
  });

  return elementiOriginali;
};

export {
  creaElementoMappa,
  creaElementoMappaRiepilogoPrenotazione,
  aggiornaElementoPlaceholderMappa,
};
