import { GridActionsCellItem } from "@mui/x-data-grid";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Tooltip } from "@mui/material";
import { useState } from "react";
import { dialogListSignIn } from "../../../../functions/dialog/dialogSignIn/confirm/createDialogConfirmActionInDialogSignIn";
import  createDialogConfirmActionInDialogSignIn  from "../../../../functions/dialog/dialogSignIn/confirm/createDialogConfirmActionInDialogSignIn";
import DialogConferma from "../../../UI/Dialog/DialogConferma";
import { reloadingDataTableUsers } from "../../../../redux/reducers/dataTableUsers-reducer";

export default function ButtonBoxRichiesteCredenziali({activeRow, rowInfo}) {
  const [isOpenDialogConfirm, setIsOpenDialogConfirm] = useState(false);
  const [dialog, setDialog] = useState("");
  const [noteValue, setNoteValue] = useState("");

  let objectToDialogConfirm = createDialogConfirmActionInDialogSignIn(
    dialog,
    { IdRichiesta:activeRow, Note:noteValue },
    rowInfo
  );

  function returnTextNote(value) {
    setNoteValue(value);
  }

  return (
    <>
      <GridActionsCellItem
        icon={
          <Tooltip title="Accetta">
            <CheckIcon sx={{ fontSize: 30 }} color="primary" />
          </Tooltip>
        }
        label="Accetta"
          onClick={() => {
            setIsOpenDialogConfirm(!isOpenDialogConfirm);
            setDialog(dialogListSignIn.approved);
        }}
      />
      <GridActionsCellItem
        icon={
          <Tooltip title="Rifiuta">
            <ClearIcon sx={{ fontSize: 30 }} color="primary" />
          </Tooltip>
        }
        label="Rifiuta"
        onClick={() => {
          setIsOpenDialogConfirm(!isOpenDialogConfirm);
          setDialog(dialogListSignIn.refuse);
      }}
      />
           <DialogConferma
        isOpenDialog={isOpenDialogConfirm}
        object={objectToDialogConfirm}
        returnTextNote={returnTextNote}
        handleState={setIsOpenDialogConfirm}
        reloadAfterAction={reloadingDataTableUsers()}
      />
    </>
  );
}
