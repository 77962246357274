import { Grid, Typography } from "@mui/material";
import MenuContextPlanner from "../componentsPlanner/MenuContextPlanner";
import { useState } from "react";
const timeContextMenuProtection = 300;

export default function PlannerTable({ dataPlanner, heightDefaultComponent }) {
    const [elClicked, setElClicked] = useState(null);
    const [timeStamp, setTimeStamp] = useState(0);

    function handlerMenuContext(value) {
        //impedisce la chiusura immediata del menucontestuale
        if (timeStamp + timeContextMenuProtection < Date.now() || !elClicked) {
          setTimeStamp(Date.now);
          setElClicked(value);
        }
      }

  return (
    <>
    <Grid item xs={9} sm={9} md={9} lg={10} xl={10}>
      <table
        style={{
          borderCollapse: "collapse",
          border: "none",
          width: "100%",
        }}
      >
        <tbody>
          <tr>
            {dataPlanner?.listaOre?.length > 0 &&
              dataPlanner?.listaOre.map((x, index) => {
                return (
                  <td
                    key={index}
                    className="background-weekend"
                    style={{
                      border: "0.5px solid #cbcbcb",
                      height: `${heightDefaultComponent}` + "px",
                      width: "37px",
                      position: "relative",
                      backgroundColor: x.isWeekendOrHoliday
                        ? "#ededed"
                        : "#ffffff",
                      padding: "0",
                    }}
                  >
                    <Grid
                      container
                      className="containerRow-planner"
                      sx={{
                        height: "100%",
                        width: "22px",
                        display: "block",
                        position: "absolute",
                        bottom: `${x.marginPlannerBar}%`,
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        id="riunione"
                        style={{
                          width: "22px",
                          height: `${x.percentageOreRiunione}%`,
                          backgroundColor: "#1D4890",
                        }}
                        onMouseEnter={(event) =>
                          handlerMenuContext({ event, el: x })
                        }
                      ></Grid>
                      <Grid
                        item
                        xs={12}
                        id="postazione"
                        style={{
                          width: "22px",
                          height: `${x.percentageOrePostazione}%`,
                          backgroundColor: "#416AB4",
                        }}
                        onMouseEnter={(event) =>
                          handlerMenuContext({ event, el: x })
                        }
                      ></Grid>
                    </Grid>
                  </td>
                );
              })}
          </tr>
          <>
            <tr>
              {dataPlanner?.listaOre?.length > 0 &&
                dataPlanner?.listaOre.map((x, index) => {
                  return (
                    <td
                      key={index}
                      style={{
                        border: "1px solid #cbcbcb",
                        height: "15px",
                        width: "37px",
                        backgroundColor: x.isWeekendOrHoliday
                          ? "#ededed"
                          : "#ffffff",
                        textAlign: "center",
                        fontSize: "12px",
                      }}
                    >
                      <Typography>{x.label}</Typography>
                    </td>
                  );
                })}
            </tr>
          </>
        </tbody>
      </table>
    </Grid>
          <MenuContextPlanner
          anchorEl={elClicked}
          returnElClicked={handlerMenuContext}
        />
    </>
  );
}
