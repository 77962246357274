import { Box, Grid, Tab, Tabs } from "@mui/material";
import { CalendarPicker, MonthPicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useState } from "react";
import { CalendarViewMode as viewMode } from "../../../../const/calendarViewMode";
import { useEffect } from "react";

import itLocale from "date-fns/locale/it";
import moment from "moment";

import "../../Prenotazioni/Prenotazioni.styles.css";
import { useSelector } from "react-redux";

export default function PrenotazioniCalendar({ selectedDate, setDateAndViewMode, saveViewMode }) {
    const [activeTab, setActiveTab] = useState(saveViewMode - 1);
    const [isToday, setIsToday] = useState(true);
    const configurations = useSelector((state) => state.configurations.configurazioniPrenotazione);

    const minDateCalendar = moment().add(-2, 'years');
    const maxDateCalendar = moment().add(configurations.maxGiorniRipetizione || 90, 'days');

    const yearInTab = moment(selectedDate).format("YYYY")

    function changeTab(event, value) {
        setActiveTab(value);
        setDateAndViewMode(selectedDate, value + 1)
    }

    function a11yProps(index) {/*props necessarie alle tab */
        return { id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}`, };
    }

    function resetToday() {
        setDateAndViewMode(new Date(), viewMode.daily + 1);
        setActiveTab(viewMode.daily );
    }

    //determina se data selezionata corrisponde alla odierna e aggiorna state
    useEffect(() => {
        let d1 = moment(selectedDate).dayOfYear();
        let d2 = moment(new Date()).dayOfYear();
        setIsToday(d1 === d2);
    }, [selectedDate]);


    return (
        <Grid container item direction="column" alignItems="center">
            <Grid>
                <Grid container item sx={{ borderBottom: 1, borderColor: 'divider' }} >

                    <Tabs value={activeTab} onChange={changeTab} aria-label="calendario" style={{ width: "100%" }}>
                        <Tab label={`MENSILE (${yearInTab})`} {...a11yProps(viewMode.monthly)} style={{ width: "50%" }} />
                        <Tab label="GIORNALIERA" {...a11yProps(viewMode.daily)} style={{ width: "50%" }} />
                    </Tabs>

                </Grid>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={itLocale}>
                    <Grid item >
                        {activeTab === viewMode.daily &&
                            <CalendarPicker style={{ minHeight: "fit-content" }}
                                minDate={new Date(minDateCalendar)}
                                maxDate={new Date(maxDateCalendar)}                                
                                date={selectedDate}
                                onChange={(date) => { setDateAndViewMode(date, viewMode.daily + 1) }}
                                onMonthChange={(date) => { setDateAndViewMode(date, viewMode.daily + 1)}}
                            />}

                        {activeTab === viewMode.monthly &&
                            <MonthPicker style={{ marginBottom: "20px" }}
                                date={selectedDate}
                                minDate={new Date(minDateCalendar)}
                                maxDate={new Date(maxDateCalendar)}  
                                onChange={(date) => { setDateAndViewMode(date, viewMode.monthly + 1)}}
                            />}
                    </Grid>
                </LocalizationProvider>
            </Grid>
        </Grid>);
}