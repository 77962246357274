import { createSlice } from '@reduxjs/toolkit';

const initialState = false;
//impedisce che un anomalia tra (Reducer e autocomplete) rinserisca i valori nelle autocomplete anche dopo il reset dello state del reducer.
const prenotazioniAdvancedMenuFilter = createSlice({
    name:'prenotazioniAdvancedMenuFilter',
    initialState,
    reducers: {
        active: (state) => {
           return state = true
         },
         disable: (state) => {
          return state = false
        }

    }
})

export const { active, disable } = prenotazioniAdvancedMenuFilter.actions;

const { reducer } = prenotazioniAdvancedMenuFilter;

export default reducer;