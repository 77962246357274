import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AutocompleteCustom } from "../../../UI/AutoComplete/AutoComplete";
import { BuildingHeader } from "../../../../const/DatatableData";
import CheckValidSortModel from "../../../../functions/CheckValidSortModel";
import {
  GetEdificiPerPrenotazione,
  getZonePrenotazione,
  getPostazionePrenotazione,
  addPrenotazioni,
} from "../../../../const/urlAction";
import axios from "axios";
import { useDispatch } from "react-redux";
import { notificationOpen } from "../../../../redux/reducers/notification-reducer";
import handlerError from "../../../../functions/handlerError";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  resetEdificioInPrenotazioneStepper,
  resetZonaInPrenotazioneStepper,
  saveEdificioCompletoInPrenotazioneStepper,
  saveEdificioInPrenotazioneStepper,
  saveZonaCompletoInPrenotazioneStepper,
  saveZonaInPrenotazioneStepper,
} from "../../../../redux/reducers/infoPrenotazione-reducer";
import {
  procediPrenotazione,
  resetPrenotazione,
  cambiaStatoTastoPrenotazione,
  resetPostazioniPrenotate,
  handlerOspitiPrenotazione,
} from "../../../../redux/reducers/prenotazioneStepper-reducer";
import SelectCustom from "../../../UI/Select/Select";
import {
  hideLoader,
  showLoader,
} from "../../../../redux/reducers/appLoader-reducer";
const typePage = "Homepage";
const urlBase = process.env.REACT_APP_URL;

export function AutocompleteBoxHome({ setStepNoAutoSkipped, framesIndex }) {
  const [invio, setInvio] = useState(true);
  const [edificio, setEdificio] = useState({});
  const [oldEdificioValue, oldSetEdificio] = useState({});
  const isSalaRiunione = useSelector((state) => state.filter.isSalaRiunione)
  const [edificioRows, setEdificioRows] = useState([]);
  const [zona, setZona] = useState({});
  const [zonaRows, setZonaRows] = useState([]);
  const [isDisabledZona, setIsDisabledZona] = useState(true);
  const [stanza, setStanza] = useState({});
  const [stanzaRows, setStanzaRows] = useState([]);
  const [isDisabledStanza, setIsDisabledStanza] = useState(true);
  const [postazione, setPostazione] = useState({});
  const [postazioneRows, setPostazioneRows] = useState([]);
  const [isDisabledPostazione, setIsDisabledPostazione] = useState(true);
  const dispatch = useDispatch();
  const state = useSelector((state) => state.prenotazioneStepper);
  const infoPrenotazioneStepper = useSelector(
    (state) => state.infoPrenotazione
  );
  const [sortModel, setSortModel] = useState([
    {
      field: BuildingHeader.initialFieldName,
      sort: BuildingHeader.initialFieldOrder,
    },
  ]);

  //i dati recuperati dal contesto(redux)
  const {
    prenotazione,
    ripetizioneGiorni,
    dataInizio,
    dataGiorno,
    dataFine,
    ripetizioneDataFine,
    utentePrenotazione,
    postazioneId,
    ospitiPrenotazioneInfo,
  } = state;

  //svuota Autocomplete a cascata
  useEffect(() => {
    if (isDisabledZona && edificio == null) {
      svuotoDaEdificio();
    } else if (isDisabledStanza && zona === null) {
      svuotoDaZona();
    }
  }, [isDisabledZona, isDisabledStanza, isDisabledPostazione]);

  function svuotoDaEdificio() {
    setZona({});
    setZonaRows([]);
    setIsDisabledZona(true);
    setStanza({});
    setStanzaRows([]);
    setIsDisabledStanza(true);
    setPostazione({});
    setPostazioneRows([]);
    setIsDisabledPostazione(true);
  }

  function svuotoDaZona() {
    setStanza({});
    setStanzaRows([]);
    setIsDisabledStanza(true);
    setPostazione({});
    setPostazioneRows([]);
    setIsDisabledPostazione(true);
  }

  //gestisce lo stato del tasto della prenotazione nella stepper
  useEffect(() => {
    if (zona === null) setZona({});
    else {
      Object.keys(zona).length === 0
        ? dispatch(cambiaStatoTastoPrenotazione(true))
        : dispatch(cambiaStatoTastoPrenotazione(false));
    }
  }, [zona]);

  //Chiamata di Edificio
  useEffect(() => {
    (async () => {
      dispatch(showLoader());
      //gestisce quali edifici vede chi sta effettuando la prenotazione nel momento in cui non sta prenotando per se stesso
      let IdUtentePrenotazione =
      utentePrenotazione.map((x) => x.utente) ?? [];

      const { action, method } = GetEdificiPerPrenotazione;
      let url = urlBase + action;

      try {
        const response = await axios({
          method: method,
          url,
          data: {IdUtentePrenotazione, filter:isSalaRiunione},
          headers: {
            "Content-Type": "application/json",
          },
        });
        setEdificioRows(response.data.result);
        if (response.data.result.length === 1) {
          dispatch(showLoader());
          setEdificio(response.data.result[0]); //se c'è un solo risultato inserisce quello automaticamente
        } else {
          dispatch(hideLoader());
          setStepNoAutoSkipped((old) => {
            //se c'è più di un record inserisce lo step in un array per la gestione del tasto indietro della stepper.
            if (old.indexOf(framesIndex) === -1) return [...old, framesIndex];
            return old;
          });
        }
      } catch (error) {
        console.error(error);
        dispatch(hideLoader());
        const notificationError = handlerError(error);
        dispatch(notificationOpen(notificationError));
      }
    })();
  }, []);

  //Chiamata di Zona
  useEffect(() => {
    if (JSON.stringify(edificio) !== "{}") {
      (async () => {
        setIsDisabledZona(true);

        if (edificio === null) svuotoDaEdificio();

        if (edificio !== null) {
          const { action, method } = getZonePrenotazione;
          let url = urlBase + action;

          try {
            const response = await axios({
              method: method,
              url,
              data: {idEdificio: edificio.id, filter:isSalaRiunione},
              headers: {
                "Content-Type": "application/json",
              },
            });
            setZonaRows(response.data.result);
            if (response.data.result.length === 1) {
              dispatch(showLoader());
              setZona(response.data.result[0]); //se c'è un solo risultato inserisce quello automaticamente
            } else {
              dispatch(hideLoader());
              setStepNoAutoSkipped((old) => {
                //se c'è più di un record inserisce lo step in un array per la gestione del tasto indietro della stepper.
                if (old.indexOf(framesIndex) === -1)
                  return [...old, framesIndex];
                return old;
              });
            }
          } catch (error) {
            console.error(error);
            dispatch(hideLoader());
            const notificationError = handlerError(error);
            dispatch(notificationOpen(notificationError));
          }
          setIsDisabledZona(false);
        }
      })();
    }
  }, [edificio]);

  const handleValueEdificio = (selectedValue) => {
    if (Object.keys(selectedValue).length === 0) return null;
    svuotoDaEdificio();
    setEdificio(selectedValue);
    dispatch(saveEdificioInPrenotazioneStepper(selectedValue?.id));
    dispatch(saveEdificioCompletoInPrenotazioneStepper(selectedValue));
  };

  const handleValueZona = (selectedValue) => {
    if (Object.keys(selectedValue).length === 0) return null;
    svuotoDaZona();
    setZona(selectedValue);

    if (
      infoPrenotazioneStepper.idZona !== selectedValue.id //questo impedisce di prenotare in ZONE differenti
    ) {
      dispatch(resetPostazioniPrenotate()); // se cambio edificio o zona, tutte le prenotazioni effettuate vengono annullate
    }

    dispatch(resetZonaInPrenotazioneStepper());
    dispatch(resetEdificioInPrenotazioneStepper());

    dispatch(saveZonaInPrenotazioneStepper(selectedValue?.id));
    dispatch(saveZonaCompletoInPrenotazioneStepper(selectedValue));
  };

  // si occupa di recuperare e inserire gli ospiti come prenotazioni nel context
  useEffect(() => {
    ospitiPrenotazioneInfo.map(x => {
      let ospitePrenotazione = {
        utente: x.utente.idUtente,
        nominativo: x.nominativo,
        idStanza: null,
        posto: null,
        immagineAvatar:null,  
        ospite:true
      };
      dispatch(handlerOspitiPrenotazione(ospitePrenotazione));
    })
  },[])

  return (
    <React.Fragment>
      <>
        <Grid
          container
          sx={{ marginTop: "0px" }}
          columnSpacing={51}
          rowSpacing={3}
        >
          <Grid item xs={12}>
            <SelectCustom
              optionsAutoComplete={edificioRows}
              field={["denominazione", "sigla"]}
              label="Edificio"
              handleValueReturned={handleValueEdificio}
              startValueAutoComplete={edificio}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectCustom
              optionsAutoComplete={zonaRows}
              field={["denominazione", "sigla"]}
              label="Zona"
              handleValueReturned={handleValueZona}
              startValueAutoComplete={zona}
              isDisabled={isDisabledZona}
            />
          </Grid>
        </Grid>
      </>
    </React.Fragment>
  );
}
