import { createSlice } from '@reduxjs/toolkit';

const initialState = false;

const dataTableBuildings = createSlice({
    name:'dataTableBuildings',
    initialState,
    reducers: {
        reloadingDataTableBuildings: (state) => {
            return !state;
         }
    }
})

export const { reloadingDataTableBuildings  } = dataTableBuildings.actions;

const { reducer } = dataTableBuildings;

export default reducer;