import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, TextareaAutosize, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { typoStyle } from "../../../const/Dialog/Disabilita/dialogDisabilitaText";
import { dialogButtonGeneral } from "../../../const/DialogData";
import { checkDisabilitazionePostazioni, DisablePostazione } from "../../../const/urlAction";
import { reloadingDataTableUsers } from "../../../redux/reducers/dataTableUsers-reducer";
import { closeDialog } from "../../../redux/reducers/DialogGeneral";
import { notificationOpen } from "../../../redux/reducers/notification-reducer";
import { DatapickerBoxDisabilita } from "../DisableDialog/DatapickerBoxDisabilita";
import { checkRangeDateDisabilitazione } from "../../../functions/checkRangeDate";
import { dialogButtonConfirm } from "../../../const/DialogData";
import { hideLoader, showLoader } from "../../../redux/reducers/appLoader-reducer";

const initialValues = {};

const validationSchema = yup.object({});


const urlBase = process.env.REACT_APP_URL;

export default function DisabilitaUnaPostazione({ isOpen = false, handlerBlur, rowInfo = false, change, closeForm, dialogType }) {
  const textArea = useRef(undefined);
  const [changedRow, setchangedRow] = useState(null);
  const backButtonState = useSelector((state) => state.dialogBackButton);
  const [valueDatapicker, setValueDatapicker] = useState({ DataOraInizio: undefined, DataOraFine: undefined });
  let [isDisabilitazione, setIsDisabilitazione] = useState(false);

  const dispatch = useDispatch();

  let isButtonDisabled = checkRangeDateDisabilitazione(valueDatapicker.DataOraInizio, valueDatapicker.DataOraFine)

  useEffect(() => {
    (async () => {
      setIsDisabilitazione(true);
      const { action, method } = checkDisabilitazionePostazioni
      let url = urlBase + action + rowInfo.id;
      try {
        const response = await axios({
          method: method,
          url,
        });
        if (response.data.ok) {
          setIsDisabilitazione(!response.data.result);
        }
      } catch (error) {
        console.error(error);
        dispatch(notificationOpen({ message: error.response.data.message, status: "error" }))
      }
    })();
  }, [isOpen]);

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      setchangedRow({
        IdEntita: rowInfo.id,
        DataOraInizioPrenotazione: valueDatapicker.DataOraInizio,
        DataOraFinePrenotazione: valueDatapicker.DataOraFine,
        Note: textArea.current.value.trim() || null
      });
    }
  });

  useEffect(() => {
    if (changedRow != null) {
      (async () => {
        dispatch(showLoader());
        const { action, method } = DisablePostazione;
        let url = urlBase + action;
        try {
          const response = await axios({
            method: method,
            url,
            data: changedRow,
            headers: {
              'Content-Type': 'application/json'
            }
          });

          dispatch(hideLoader());
          
          if (response.data.ok) {
            formik.resetForm(initialValues)
            dispatch(reloadingDataTableUsers());
            dispatch(closeDialog());
          }

          dispatch(notificationOpen({ message: response.data.message, status: "success" }))
        } catch (error) {
          console.error(error);
          dispatch(hideLoader());
          dispatch(notificationOpen({ message: error.response.data.message, status: "error" }))
        }
        
      })();
    }
  }, [changedRow]);

  function disableButton() { }


  function returnValueInizio(value) {
    setValueDatapicker({ ...valueDatapicker, DataOraInizio: value })
  }


  function returnValueFine(value) {
    setValueDatapicker({ ...valueDatapicker, DataOraFine: value })
  }

  return (
    <>
      <Dialog
        open={isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={'sm'}
      >
        <DialogTitle>Disabilita</DialogTitle>
        <DialogContent className="border-Content-dialog">
          <FormControl fullWidth>
            <Grid item sx={{ marginBottom: "5px" }} >
              <Typography sx={typoStyle}>Permette di disabilitare la <b>Postazione {rowInfo.posto}</b>, annullando tutte le prenotazioni in corso o programmate,
                contestualmente verrà inviata un'email di avviso ai responsabili dell'edificio e agli utenti coinvolti.</Typography>
            </Grid>
            <DatapickerBoxDisabilita disableButton={disableButton} valueInizio={returnValueInizio} valueFine={returnValueFine} />
            <Grid item sx={{ marginTop: "18px" }} >
              <label>
                <Typography sx={{ marginBottom: "2px" }}>Note</Typography>
              </label>
              <TextareaAutosize
                ref={textArea}
                minRows={6}
                maxRows={6}
                placeholder="(opzionale) Aggiungi una nota da inserire nell'email di notifica."
                style={{ width: "99%" }}
              />
            </Grid>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Grid container>
            <Grid item xs={12} lg={12} >
              <Box sx={{ float: "right" }}>
                <Button onClick={() => closeForm()} >
                  {dialogButtonGeneral.Cancel}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={() => formik.handleSubmit()}
                  disabled={isButtonDisabled}
                >
                  {dialogButtonConfirm.Confirm}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}
