import { createSlice } from '@reduxjs/toolkit';

const initialState = false;

const postazioniSlice = createSlice({
    name:'postazioni',
    initialState,
    reducers: {
        loadPostazioniState: (state) => { 
            return !state
         }
    }
})

export const { loadPostazioniState } = postazioniSlice.actions;

const { reducer } = postazioniSlice;

export default reducer;