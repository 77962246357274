import React, { useState, useEffect } from "react";
import axios from "axios";
import { dialogPageTypes } from "../../const/dialogPageTypes"
import { Grid, Divider, Typography, Button } from "@mui/material";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import TableToolbar from "../UI/TableToolbar/TableToolbar";
import { styled } from "@mui/material/styles";
import { CDCard, CDCardContent, CDCardHeader } from "../../const/CDCardStyles";
import CheckValidSortModel from "../../functions/CheckValidSortModel";
import { ZoneHeader, ZoneHeaderMobile } from "../../const/DatatableData";
import {
  getZonePaginated,
  exportUsers,
  getEdifici,
} from "../../const/urlAction";
import AdvancedFilterPanel from "./AdvancedFilterPanel";
import { useSelector, useDispatch } from "react-redux";
import ZoneDialog from "../Dialog/ZoneDialog/ZoneDialog";
import handlerError from "../../functions/handlerError";
import { notificationOpen } from "../../redux/reducers/notification-reducer";
import { loadEdificiListState } from "../../redux/reducers/edificiSlice-reducer";
import { loadDevicesState } from "../../redux/reducers/devices-reducer";
import Notification from "../UI/Notification/Notification";
import { formatDate } from "../../functions/formatDate";
import { useParams } from "react-router-dom";
import { removeDataGridHeader } from "../../functions/handlerDataGrid";
import usePathTextDetail from "../../Hooks/usePathTextDetail";
import { openEmptyDialog, setPageType } from "../../redux/reducers/DialogGeneral";
import useCheckDisabilitazioniSuPostazioni from "../../Hooks/useCheckDisabilitazioniSuPostazioni";
import { reloadingDataTableUsers } from "../../redux/reducers/dataTableUsers-reducer";
import {TitleDatable} from "../TitleDatable/TitleDataTable";

const typePage = "Zone";
const urlBase = process.env.REACT_APP_URL;
const rowHeight = 120;
//const columns = ZoneHeaderMobile.headers;

const ZoneDatatable = () => {

  const dispatch = useDispatch();
  const  params = useParams();
  const state = useSelector((state) => state.userDate.policies);
  const reloading = useSelector((state) => state.dataTableUsers);
  const  [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const viewState = useSelector((state) => state.viewState);
  const [searchFilter, setSearchFilter] = useState("");
  const [searchStatus, setSearchStatus] = useState(false); 
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rows, setRows] = useState([]);
  const [rowsCount, setRowsCount] = useState(rows.length);
  const [loading, setLoading] = useState(false);
  const [sortModel, setSortModel] = useState([]);

  const handleSearchFilter = (searchText) => {
    setSearchFilter(searchText);
    setSearchStatus(!searchStatus)
  };

  const isDisabilitazioniOnEntity = useCheckDisabilitazioniSuPostazioni()

  const StyledDataGrid = removeDataGridHeader(DataGrid, viewState)

  const path = usePathTextDetail()

  useEffect(() => {
    let isMounted = true;
    const { sortField, sortOrder } = CheckValidSortModel(
      sortModel,
      ZoneHeader.initialFieldName
    );

    (async () => {
      setLoading(true);
      const {idEdificio} = params;
      const { action, method } = getZonePaginated;
      let url = urlBase + action;
      const requestBody = {
        pageIndex: page,
        pageSize: pageSize,
        sortColumnName: sortField,
        sortDirection: sortOrder,
        applyPaginationAndSort: true,
        searchFilter: searchFilter,
        IdEdificio: idEdificio
      };

      try {
        const response = await axios({
          method: method,
          url,
          data: requestBody,
        });

        if(isMounted) {
          const newRows = response.data.result.result;
          const newRowsCount = response.data.result.totalFiltered;
          setRows(newRows);
          setRowsCount(newRowsCount);
          setLoading(false);

          if (!response.data.ok) {
            dispatch(
              notificationOpen({
                message: response.data.message,
                status: "success",
              })
              );
            }
          }
      } catch (error) {
        console.error(error);
        const notificationError = handlerError(error);
        dispatch(notificationOpen(notificationError));
        setLoading(false);
      }
    })();

    return () => {
      isMounted = false;
    }
  }, [page, pageSize, sortModel,searchStatus, searchFilter, reloading]);

  useEffect(() => {
    window.addEventListener('resize', handleResizeWindow);
    return () => {
        window.removeEventListener('resize', handleResizeWindow);
    };
});

const handleResizeWindow = () => {
  if(window.innerWidth > 600){
    setIsMobile(false);
  } 
  else {
    setIsMobile(true);
  } 
};

  function disableAction(disableAction) {
    dispatch(setPageType(dialogPageTypes.building))
    dispatch(openEmptyDialog(disableAction))
  }

  return (
    <>
      { isMobile || !isMobile && viewState ? (
        <>
          <Grid container>
            <Grid item component={CDCard}  xs={12} sm={12}>
              <CDCardHeader className="CDCardHeader-Padding" title={<><TitleDatable nameDataTable= "Reparti" pathDataTable={path} /></>} />
              <Divider />
              <CDCardContent>
                {/* <PathTextComponent /> */}
                {/* <AdvancedFilterPanel handleFilterData={handleFilterData} /> */}
                <Grid container item>
                  <StyledDataGrid
                    rows={rows}
                    rowCount={rowsCount}
                    getRowId={(row) => row.sigla}
                    columns={ZoneHeaderMobile.headers}
                    page={page}
                    pageSize={pageSize}
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    onSortModelChange={(newSortModel) =>
                      setSortModel(newSortModel)
                    }
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    disableSelectionOnClick
                    sortModel={sortModel}
                    sortingMode="server"
                    sortingOrder={["asc", "desc"]}
                    paginationMode="server"
                    loading={loading}
                    sx={{ border: 0 }}
                    disableColumnMenu
                    disableColumnFilter
                    disableColumnSelector
                    autoHeight
                    rowHeight={rowHeight}
                    autoWidth
                    components={{
                      Toolbar: () => (
                        <TableToolbar
                          typePage={typePage}
                          enableAdd={true}
                          enableSearch={true}
                          isSalaRiunione={false}
                          enableExport={true}
                          enableDisable={true}
                          showResetDisable={isDisabilitazioniOnEntity}
                          disableAction={disableAction}
                          handleAdd={() => {
                            dispatch(setPageType(dialogPageTypes.user))
                            dispatch(openEmptyDialog("add"));
                          }}
                          initialSearchText={searchFilter}
                          startSearch={handleSearchFilter}
                        />
                      ),
                      NoRowsOverlay: () => (
                        <GridOverlay>
                          <Typography>Nessun elemento</Typography>
                        </GridOverlay>
                      ),
                      NoResultsOverlay: () => (
                        <GridOverlay>
                          <Typography>Nessun elemento</Typography>
                        </GridOverlay>
                      ),
                    }}
                  />
                </Grid>
              </CDCardContent>
            </Grid>
          </Grid>
          <Notification />
          <ZoneDialog />
        </>
      ) : (
        <>
          <Grid container>
            <Grid item component={CDCard} xs={12} sm={12}>
            <CDCardHeader className="CDCardHeader-Padding" title={<><TitleDatable nameDataTable= "Reparti" pathDataTable={path} /></>} />
              <Divider />
              <CDCardContent>
                <Grid container item>
                  <StyledDataGrid
                    rows={rows}
                    rowCount={rowsCount}
                    getRowId={(row) => row.sigla}
                    columns={ZoneHeader.headers}
                    page={page}
                    pageSize={pageSize}
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    onSortModelChange={(newSortModel) =>
                      setSortModel(newSortModel)
                    }
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    disableSelectionOnClick
                    sortModel={sortModel}
                    sortingMode="server"
                    sortingOrder={["asc", "desc"]}
                    paginationMode="server"
                    loading={loading}
                    sx={{ border: 0 }}
                    disableColumnMenu
                    disableColumnFilter
                    disableColumnSelector
                    autoHeight
                    autoWidth
                    components={{
                      Toolbar: () => (
                        <TableToolbar
                          typePage={typePage}
                          enableAdd={true}
                          enableSearch={true}
                          enableExport={true}
                          isSalaRiunione={false}
                          enableDisable={true}
                          showResetDisable={isDisabilitazioniOnEntity}
                          disableAction={disableAction}
                          handleAdd={() => {
                            dispatch(setPageType(dialogPageTypes.zone))
                            dispatch(openEmptyDialog("add"));
                          }}
                          initialSearchText={searchFilter}
                          startSearch={handleSearchFilter}
                        />
                      ),
                      NoRowsOverlay: () => (
                        <GridOverlay>
                          <Typography>Nessun elemento</Typography>
                        </GridOverlay>
                      ),
                      NoResultsOverlay: () => (
                        <GridOverlay>
                          <Typography>Nessun elemento</Typography>
                        </GridOverlay>
                      ),
                    }}
                  />
                </Grid>
              </CDCardContent>
            </Grid>
          </Grid>
          <Notification />
          <ZoneDialog />
        </>
      )}
    </>
  );
};

export default ZoneDatatable;
