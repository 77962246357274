import { FormControl, FormControlLabel, FormGroup, FormLabel, Switch } from "@mui/material";

export default function ToogleCustom({toggleState = false, formLabel="default formLabel", handleChangeToggle = () => null, id="", label="default label"}) {
  
  
if(!id) console.error(`id nel Toogle ${label} non configurato`)

    return (
    <>
      <FormControl component="fieldset" variant="standard">
        <FormLabel component="legend">{formLabel}</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={toggleState}
                onChange={(event) => handleChangeToggle(event)}
                name={id}
              />
            }
          />
        </FormGroup>
      </FormControl>
    </>
  );
}
