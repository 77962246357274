import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch } from "react-redux";
import { GridActionsCellItem } from "@mui/x-data-grid";
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from 'react-redux';
import {policies as permessi} from "../../const/policies"
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { openDialogBuilding } from '../../redux/reducers/DialogBuilding-reducer';
import {multiDialogAction} from '../../const/Dialog/MultiDialogAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';


const ButtonBoxBuilding = ({ rowId, rowInfo }) => {
  const state = useSelector((state) => state.userDate);
  const { policies } = state;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const idEdificio = rowInfo.id

  const handleDialogToEdit = ({type, rowInfo}) => {
    dispatch(openDialogBuilding({type, rowInfo}))
  };

  return (
    <>
      {policies.indexOf(permessi.edificiInserimentoModifica) != -1 && <GridActionsCellItem
        icon={
          <Tooltip title="Modifica" >
            <EditIcon color="primary"  style = {{ fontSize : 28}} />
          </Tooltip>
        }
        label="Modifica"
        onClick={() => handleDialogToEdit({type:multiDialogAction.edit, rowInfo})}
      />}

      {policies.indexOf(permessi.zoneVisualizzazione) != -1 &&<GridActionsCellItem
        icon={
          <Tooltip title="Gestisci Reparto" >
            <FontAwesomeIcon icon={faLayerGroup}
            style={{ color: "#026D82", fontSize:"22px"}}
             />
          </Tooltip>
        }
        label="Stanze"
        onClick={() => navigate("" + idEdificio)}
      />}

      {policies.indexOf(permessi.edificiEliminazioneRipristino) != -1 && <GridActionsCellItem
        icon={
          <Tooltip title={rowInfo.dispositivoAbilitato ? "Abilita" : "Elimina"}>
           { rowInfo.dispositivoAbilitato ? <DoDisturbIcon color="primary" /> : <DeleteIcon color = "primary" style = {{ fontSize : 28}} />}
          </Tooltip> 
        }
        label="Cancella"
        onClick={() => handleDialogToEdit({type:multiDialogAction.delete, rowInfo})}
      />}
      </>
  );
};

export default ButtonBoxBuilding;