import "../panoramica.css";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

export default function InputSearchDisponibilita({
  setSearchFilter = () => null
}) {
 
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setSearchFilter(searchText);
    }, 500);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [searchText, setSearchFilter]);

  const handleOnChange = (value) => {
    setSearchText(value);
  };

  return (
    <>
    <input className="searchInputDisponibilita" placeholder="Filtra per reparto..." onChange={(event) => handleOnChange(event?.target?.value)}></input>
    </>
  );
}
