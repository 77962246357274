import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const edificiSlice = createSlice({
    name:'edificiList',
    initialState,
    reducers: {
        loadEdificiListState: (state, payload) => { 
            return payload.payload
         }
    }
})

export const { loadEdificiListState } = edificiSlice.actions;

const { reducer } = edificiSlice;

export default reducer;