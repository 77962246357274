import { enumViewModePrenotazioni } from "../../const/prenotazioni/enumViewMode";

export default function resetSelectedDate(
  selectedDate,
  prenotazioniView = 0,
  lastMonthSelectedInDaily,
  lastDaySelectedInlastMonthSelectedDaily,
  lastViewSelected,
  returnLastViewSelected = () => null,
  returnLastMonthSelected = () => null,
  returnLastDaySelectedInlastMonthSelectedDaily = () => null
) {
  if (!selectedDate) selectedDate = new Date();
  let endSelectedDate = new Date(selectedDate);

  if (prenotazioniView === enumViewModePrenotazioni.daily) {
    
    
    returnLastViewSelected(prenotazioniView);
    returnLastMonthSelected(selectedDate.getMonth());
    selectedDate.setHours(0, 0, 0, 0);
    selectedDate.setDate(
      lastViewSelected === enumViewModePrenotazioni.daily
      ? selectedDate.getDate()
      : lastDaySelectedInlastMonthSelectedDaily.getDate()
      );
      endSelectedDate = new Date(selectedDate);
    endSelectedDate.setFullYear(selectedDate.getFullYear());
    endSelectedDate.setMonth(selectedDate.getMonth());
    endSelectedDate.setDate(selectedDate.getDate());
    endSelectedDate.setHours(23, 59, 59, 999);
  } else {
    returnLastViewSelected(prenotazioniView);
    returnLastDaySelectedInlastMonthSelectedDaily(new Date(selectedDate));
    selectedDate.setDate(1);
    selectedDate.setHours(0, 0, 0, 0);
    endSelectedDate.setFullYear(selectedDate.getFullYear());
    endSelectedDate.setMonth(selectedDate.getMonth() + 1);
    endSelectedDate.setDate(0);
    endSelectedDate.setHours(23, 59, 59, 999);
  }

  return { selectedDate, endSelectedDate };
}
