import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const devicesSlice = createSlice({
    name:'devices',
    initialState,
    reducers: {
        loadDevicesState: (state, payload) => { 
            return payload.payload
         }
    }
})

export const { loadDevicesState } = devicesSlice.actions;

const { reducer } = devicesSlice;

export default reducer;