import { createSlice } from '@reduxjs/toolkit';


const initialState = {indirizzo:"", coordinate:""};

const addressEdificio = createSlice({
    name: 'addressEdificio',
    initialState,
    reducers: {
        setIndirizzo: (state,action) => {
            return { ...state, indirizzo: action.payload.indirizzo,  };
        },
        setCoordinate: (state, action) => {
            return { ...state, coordinate: action.payload.coordinate };
        },
    }
});

export const { setIndirizzo, setCoordinate } = addressEdificio.actions;


const { reducer } = addressEdificio;

export default reducer;