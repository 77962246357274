import React, { useEffect, useState } from 'react';
import CalcPercenteProgressBar from "../../../functions/disponibilita/calcPercenteProgressBar";
const apiKey = process.env.REACT_APP_MAPS_APIKEY;

const MapsStruttureJs = ({ markerData, percentStatusConfiguration, setElementClickedMaps = () => null, setChangeClickMaps = () => null }) => {

  useEffect(() => {

    if (markerData) {
      const markers = markerData.map(({ id, denominazione, coordinate, totPostazioniDisponibili, totPostazioniOccupate  }) => {
        let { valueProgressBar, colorProgressbar } = CalcPercenteProgressBar(
          percentStatusConfiguration,
          totPostazioniOccupate,
          totPostazioniDisponibili
        );

        switch (colorProgressbar) {
          case "customError":
            colorProgressbar = "red";
            break;
          case "customWarning":
            colorProgressbar = "orange";
            break;
          case "customSuccess":
            colorProgressbar = "green";
            break;
        }

        const [lat, lng] = (coordinate ?
          coordinate.split(",") :
          ['', '']
        )
        return { id, position: { lat: parseFloat(lat), lng: parseFloat(lng) }, title: denominazione, color: colorProgressbar };
      });



      // const initMap = () => {
      //   const map = new window.google.maps.Map(document.getElementById('map'), {
      //       center: { lat: 41.853119, lng: 12.57199 }, 
      //       zoom: 10,
      //       mapTypeControl: false, 
      //       streetViewControl: false 
      //   });

      //   const customIcon = (opts) => Object.assign({
      //     path: 'm68.51,199.3c-1.94-3.32-3.76-6.49-5.62-9.64-9.41-15.88-19.72-31.17-29.96-46.51-8.16-12.23-16.29-24.47-22.85-37.66-4.46-8.98-8-18.28-9.44-28.28-1.34-9.26-.55-18.36,1.97-27.32,3.74-13.32,10.92-24.49,21.42-33.47C35.26,6.8,48.32,1.39,63.03.23c14.24-1.12,27.68,1.81,40.02,9.12,15.88,9.4,26.36,23.05,31.49,40.77,2.38,8.21,3.05,16.62,2.14,25.11-.88,8.21-3.32,16.02-6.6,23.58-5.2,12-12.02,23.09-19.14,34.02-10.59,16.25-21.28,32.45-31.8,48.75-3.5,5.42-6.61,11.08-9.9,16.63-.19.32-.41.62-.73,1.1Zm.17-92.99c20.23.04,37.59-16.52,37.61-37.5.02-21.41-16.74-38.09-38.02-38.09-20.67,0-37.58,17.17-37.55,38.14.03,20.57,17.21,37.52,37.96,37.45Z',
      //     fillColor: opts.fillColor,
      //     fillOpacity: 1,
      //     strokeColor: 'white',
      //     strokeWeight: 1,
      //     scale: 0.2,                    
      //   }, opts);

      //   markers.forEach(markerInfo => {

      //     const marker = new window.google.maps.Marker({
      //       position: markerInfo.position,
      //       map,
      //       title: markerInfo.title,
      //       icon: customIcon({fillColor: '#026D82'}),              
      //     });

      //     marker.addListener('click', () => {
      //       handleMarkerClick(markerInfo.id);
      //     });
      //   });
      // };

      async function initMap() {
        // Request needed libraries.
        const { Map } = await window.google?.maps?.importLibrary("maps");
        const { AdvancedMarkerElement, PinElement } = await window.google?.maps?.importLibrary("marker");

        const map = new Map(document.getElementById("map"), {
          center: markers[0].position,
          zoom: 10,
          mapId: "4504f8b37365c3d0",
        });

        markers.forEach(markerInfo => {

          let pinContent;

          if (markerInfo.color === 'green') {
            pinContent = new PinElement({
              background: "#adddb8",
              borderColor: "#28a745",
              glyphColor: "#28a745",
            });
            // pinContent = new PinElement({
            //   background: "#28a745",
            //   borderColor: "#adddb8",
            //   glyphColor: "white",
            // });
          }
          else if (markerInfo.color === 'orange') {
            pinContent = new PinElement({
              background: "#ffe7a0",
              borderColor: "#ffc107",
              glyphColor: "#ffc107",
            });
          }
          else {
            pinContent = new PinElement({
              background: "#f1b2b8",
              borderColor: "#dc3545",
              glyphColor: "#dc3545",
            });
          }

          // const pinContent = new PinElement({
          //   background: "#FBBC04",
          //   borderColor: "#137333",
          //   glyphColor: "white",
          // });

          const marker = new AdvancedMarkerElement({
            map,
            position: markerInfo.position,
            content: pinContent.element,
            title: markerInfo.title,
          });

          marker.addListener('click', () => {
            handleMarkerClick(markerInfo);
          });
        });
      }

      const handleMarkerClick = (markerInfo) => {
        const elementClicked = markerData.find(oggetto => oggetto.id === markerInfo.id)
        setElementClickedMaps(elementClicked)
        // Gestisci il click sul marker con l'ID specifico
        // console.log('Marker clicked:', markerInfo);
        // console.log(elementClicked)
        // setElementClickedMaps(null)
        setChangeClickMaps(click => !click)
        // console.log(elementClicked)
        // console.log(elementClicked)

        // Puoi eseguire azioni aggiuntive in base all'ID del marker cliccato
      };
      const isGoogleMapsScriptLoaded = !!document.querySelector(`script[src="https://maps.googleapis.com/maps/api/js?key=${apiKey}"]`);
      const loadGoogleMapsScript = () => {
          if(!isGoogleMapsScriptLoaded){
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=` + apiKey;
        script.defer = true;
        script.async = true;
        document.head.appendChild(script);
      }
      if(window.google){
        initMap()
      }
      };

      loadGoogleMapsScript();
    }
    return () => {
      
      // const isGoogleMapsScriptLoaded = !!document.querySelector(`script[src="https://maps.googleapis.com/maps/api/js?key=${apiKey}"]`);
      // isGoogleMapsScriptLoaded?.remove()
    }
  }, [markerData]);
  

  return <div id="map" style={{ height: '400px', width: '100%' }}></div>;
};

export default MapsStruttureJs;