export default function userLoggedToPrenotazioneObj(user, avatar) {
  return {
    nominativo: user.fullName,
    idStanza: null,
    idPosto: null,
    posto: null,
    immagineAvatar: avatar,
    ospite: false,
    utente: null,
  };
}
