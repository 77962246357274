import { Button } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dialogButtonConfirm } from "../../../const/DialogData";
import { restorePostazione } from "../../../const/urlAction";
import { reloadingDataTableUsers } from "../../../redux/reducers/dataTableUsers-reducer";
import { closeDialog } from "../../../redux/reducers/DialogGeneral";
import { notificationOpen } from "../../../redux/reducers/notification-reducer";
import { showLoader, hideLoader } from "../../../redux/reducers/appLoader-reducer";

const urlBase = process.env.REACT_APP_URL;
export default function ButtonRipristinaUnaPostazione({ rowInfo, isButtonDisabled = false }) {
  const [changedRow, setchangedRow] = useState();
  const backButtonState = useSelector((state) => state.dialogBackButton);
  const dispatch = useDispatch();

  const { id } = rowInfo

  useEffect(() => {
    if (changedRow != null) {
      (async () => {
        dispatch(showLoader());
        const { action, method } = restorePostazione
        let url = urlBase + action;
        try {
          const response = await axios({
            method: method,
            url,
            data: changedRow,
            headers: {
              'Content-Type': 'application/json'
            }
          });
          dispatch(hideLoader());
          if (response.data.ok) {
            dispatch(reloadingDataTableUsers());
            dispatch(closeDialog());
            dispatch(notificationOpen({ message: response.data.message, status: "success" }))
          }
        } catch (error) {
          console.error(error);
          dispatch(hideLoader());
          dispatch(notificationOpen({ message: error.response.data.message, status: "error" }))
        }
      })();
    }
  }, [changedRow]);

  function RiattivaPostazione() {
    setchangedRow({
      IdEntita: id,
      DataOraInizioPrenotazione: null,
      DataOraFinePrenotazione: null,
      RestorePostazioni: true
    })
  }

  return (
    <React.Fragment>
      <Button
        variant="contained"
        disabled={isButtonDisabled}
        onClick={() => { RiattivaPostazione() }}>
        {dialogButtonConfirm.Confirm}
      </Button>
    </React.Fragment>
  )
}