import { createSlice } from '@reduxjs/toolkit';
import { OpenAndCloseNotification } from './notification-reducer'
const initialState = {};

const userSlice = createSlice({
    name:'typeCall',
    initialState,
    reducers: {
        saveUser: (state, payload) => {
            return payload.payload
         },
         logoutUser: () => {
             localStorage.removeItem('deskbooking');
             return {}
         }
    }
})


export const refreshToken = (state, navigate) => (dispatch) => { 
if(state != null) {
        const tokenNotification = {message: "il token sta per scadere", status: "warning"}
        const timerAvviso = 300000 

        let now = Date.now();
        let expireDate = Date.parse(state.scadenzaToken);

        clearTimeout(state.timerToken)
        clearTimeout(state.timerTokenNotification)

        const timerTokenNotification = (expireDate - now > timerAvviso) 
        ? setTimeout(() => dispatch(OpenAndCloseNotification(tokenNotification)), expireDate - ( now + timerAvviso))
        : undefined

        const timerToken = setTimeout(() => { dispatch(clearTokenTimeoutAndLogout(state,navigate)) }, expireDate - now)
        let response = {...state, timerTokenNotification, timerToken}

        dispatch(saveUser(response))
    }
}

export const clearTokenTimeoutAndLogout = (state, navigate) => (dispatch) => {

    clearTimeout(state.timerToken)
    clearTimeout(state.timerTokenNotification)
    dispatch(logoutUser())
    navigate("/")
}


export const { saveUser, logoutUser } = userSlice.actions;

const { reducer } = userSlice;

export default reducer
