import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import setIconToPathDisponibilita from "../../../../functions/disponibilita/setIconToPath";

export default function PathViewDisponibilitaSlice({ el }) {
  const { index, element } = el;
  let Icon = setIconToPathDisponibilita(index)
  return (
    <>
    <Grid item className="flex-center-center pathElement">
      {Icon}
      <Typography className="textPath">{element.sigla}</Typography>
    </Grid>
  </>
  );
}
