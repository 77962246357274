
export const accountListUserRadioButtonInHistoryDatatable = [{ label: "Solo le mie", value: "true" }, { label: "Tutte", value: "false" }];
export const accountListViewRadioButtonInHistoryDatatable = [{ label: "Prenotazioni", value: "false" }, { label: "Disabilitazioni", value: "true" }];

/**Opzioni ordinamento per data in ricerca avanzata prenotazioni*/
export const dateSortOptionsAdvancedSearch = [{ label: "Meno recente", value: true }, { label: "Più recente", value: false }];

export const radioButtonPrenotazioneStepper = [{ label: "Per me", value: 'false' }, { label: "Per altri utenti", value: 'true' }, { label: "Solo per ospiti", value: "ospiti" }];
export const radioButtonPrenotazioneStepperOnlyMe = [{ label: "Per me", value: 'false' }, { label: "Solo per ospiti", value: "ospiti" }];
export const radioButtonPrenotazioneStepperOnlyForMe = [{ label: "Per me", value: 'false' }];

export const labelArrayDialogUserAddEdit = ["Credenziali di dominio","Email e Password"]
export const labelArrayDialogUserSincronizzazione = ["Credenziali di dominio","Email e Password"]
export const labelArrayDialogUserSignIn = ["Credenziali di dominio","Email e Password"]

export const labelPlannerOrdineAlfabetico = [{ label: "A-Z", value: "Alfabetico" }, { label: "Ore totali", value: "NumOre" }];
