import {
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  dialogEditUser,
} from "../../../../../const/DialogData";
import {useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserById,
} from "../../../../../const/urlAction";
import axios from "axios";
import { notificationOpen } from "../../../../../redux/reducers/notification-reducer";
import RadioButtonWithTabs from "../../../../UI/RadiobuttonCustom.js/RadioButtonWithTabs/RadioButtonWithTabs";
import { ListTabsDialogoUserEdit } from "../../../../../const/Tabs/Dialog/DialogUser/DialogEdit/ListTabsDialogUserEdit";
import { labelArrayDialogUserAddEdit } from "../../../../../const/RadioButton/RadioButtonListRadio";
import MessageNoProvider from "../../../../UI/MessageNoProvider/MessageNoProvider";
import setTabsToShowAddEdit from "../../../../../functions/dialog/dialogUser/addEdit/setTabsToShowAddEdit";

const urlBase = process.env.REACT_APP_URL;
export default function DialogEditUser({
  isOpen,
  rowInfo,
}) {
  const [isRenderingDialog, setIsRenderingDialog] = useState(false);
  const [infoUser, setInfoUser] = useState({});
  const [tab, setTab] = useState({});
  const authConfiguration = useSelector((state) => state.configurations.configurazioniAutenticazione);
  const dispatch = useDispatch();

  useEffect(() => {
    if(Object.keys(infoUser).length > 0) {
      setTab(setTabsToShowAddEdit(
        labelArrayDialogUserAddEdit,
        infoUser.hasPassword,
        infoUser.username
        ))
      }
  }, [infoUser]);

  // recupera i valori partendo da rowInfo
  useEffect(() => {
    if (isOpen) {
      (async () => {
        setIsRenderingDialog(true);

        const { id } = rowInfo;
        const { action, method } = getUserById;
        let url = urlBase + action + id;

        try {
          const response = await axios({
            method: method,
            url,
          });

          var utenteR = response.data.result.utenteResponsabileUtenti.map(
            (item) => item.idUtenteResponsabile
          )[0];

          if (response.data.ok) {
            setInfoUser(response.data.result);
          }
        } catch (error) {
          console.error(error);
          if (error.response) {
            dispatch(
              notificationOpen({
                message: `${error.response.data.message}`,
                status: "error",
              })
            );
          } else {
            dispatch(
              notificationOpen({
                message: "Operazione fallita: Server non raggiungibile.",
                status: "error",
              })
            );
          }
        }
        setIsRenderingDialog(false);
      })();
    }
  }, [isOpen]);

  function returnValue(value) {
    setInfoUser(value);
  }

  return (
    <>
      {!isRenderingDialog && (
        <Dialog
          open={isOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth={"sm"}
        >
          <DialogContent className="border-Content-dialog">
            <DialogTitle sx={{ paddingLeft: "0" }}>
              {dialogEditUser.DialogTitle}
            </DialogTitle>
            <RadioButtonWithTabs
              GroupName={"Tipo di autenticazione"}
              tabPanel={ListTabsDialogoUserEdit(
                infoUser,
                authConfiguration,
                returnValue
              )}
              labelList={labelArrayDialogUserAddEdit}
              startValueReset={tab}
              noTabsComponent={<MessageNoProvider />}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
