import { useEffect, useLayoutEffect, useState } from "react";
import { useSelector, useDispatch, dispatch } from "react-redux";
import "./Planner.styles.css";
import {
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Slider,
} from "@mui/material";
import { CDCard, CDCardContent, CDCardHeader } from "../../const/CDCardStyles";
import Notification from "../UI/Notification/Notification";
import { Box } from "@mui/system";
import moment from "moment";
import { hideLoader, showLoader } from "../../redux/reducers/appLoader-reducer";
import { SliderPlanner } from "./componentsPlanner/SliderPlanner";
import { labelPlannerOrdineAlfabetico } from "../../const/RadioButton/RadioButtonListRadio";
import RadioButtonCustom from "../UI/RadiobuttonCustom.js/RadioButtonCustom";
import MenuContextPlanner from "./componentsPlanner/MenuContextPlanner";
import MemoRowPlanner from "./RowPlanner/RowPlanner";
import MemoUserTablePlanner from "./componentsPlanner/UserTablePlanner";
import { useCallback } from "react";
import { useRef } from "react";

const defaultRadioButton = "Alfabetico";
const startDate = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  1,
  0,
  0,
  0
);
export default function PlannerComponent() {
  const [month, setMonth] = useState(startDate.getMonth());
  const [year, setYear] = useState(startDate.getFullYear());
  const [prova, setProva] = useState(true);
  const [radioButtonOrdine, setRadioButtonOrdine] =
    useState(defaultRadioButton);
  const actualYear = startDate.getFullYear();
  const containerRef = useRef(null);

  const handleYear = (event) => {
    setYear(event.target.value);
  };

  const handlePlannerOrdinePlanner = (event) => {
    setRadioButtonOrdine(event.target.value);
  };

  return (
    <>
      <Grid container>
        <Grid item component={CDCard} xs={12} sm={12}>
          <CDCardHeader
            className="CDCardHeader-Padding"
            title={<span> Planner </span>}
          />
          <Divider />
          <CDCardHeader
            id="header"
            sx={{
              backgroundColor: "white",
              maxHeight: "7rem",
              margin: "10px 0 0 0",
            }}
            className="CDCardHeader-Padding"
            title={
              <>
                <Grid container item>
                <Grid className="flex-center-center" item xs={1} medium={1} md={1} lesslg={1} lg={1} xl={1}>
                  <Select
                    sx={{
                      width: "6rem",
                      height: "2rem",
                      position: "relative",
                      bottom: "0.9rem",
                    }}
                    value={year}
                    onChange={handleYear}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value={actualYear - 2}>
                      {actualYear - 2 + ""}
                    </MenuItem>
                    <MenuItem value={actualYear - 1}>
                      {actualYear - 1 + ""}
                    </MenuItem>
                    <MenuItem value={actualYear}>{actualYear + ""}</MenuItem>
                    <MenuItem value={actualYear + 1}>
                      {actualYear + 1 + ""}
                    </MenuItem>
                    <MenuItem value={actualYear + 2}>
                      {actualYear + 2 + ""}
                    </MenuItem>
                  </Select>
                  </Grid >
                  <Grid item xs={9} medium={8} md={7} lesslg={6} lg={5} xl={4}>
                    <Box sx={{ height: "0.5rem", display: "inline-block" }}>
                      <SliderPlanner returnSliderValue={setMonth} />
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Box sx={{ height: "0.5rem", display: "inline-block" }}>
                      <FormLabel
                        sx={{
                          width: "30rem",
                          position: "relative",
                          left: "8rem",
                          top: "0.3rem",
                        }}
                        id="demo-radio-buttons-group-label"
                      >
                        Ordinamento:
                      </FormLabel>
                      <RadioGroup
                        sx={{
                          width: "30rem",
                          position: "relative",
                          left: "8rem",
                          top: "0.3rem",
                        }}
                        row
                        aria-label="recoveryEnabled"
                        name="row-radio-group-enabled"
                        onChange={handlePlannerOrdinePlanner}
                        defaultValue={defaultRadioButton}
                      >
                        {labelPlannerOrdineAlfabetico.length > 0 &&
                          labelPlannerOrdineAlfabetico.map((x, index) => {
                            return (
                              <FormControlLabel
                                key={index}
                                value={x.value}
                                control={<Radio />}
                                label={x.label}
                              />
                            );
                          })}
                      </RadioGroup>
                    </Box>
                  </Grid>
                </Grid>
              </>
            }
          />
          <Divider />
          <CDCardContent
            className="no-padding-MuiCardContent-root"
            style={{ width: "100%", padding:"10px 0 0 0", margin:"10px 0" }}
          >
            <Grid ref={containerRef} container direction="row">
              <div  style={{ width: "100%", height: "10px 0" }}>
                <MemoUserTablePlanner
                  year={year}
                  month={month}
                  order={radioButtonOrdine}
                  heightComponent={window.innerHeight -  containerRef?.current?.offsetTop - 16}
                />
              </div>
            </Grid>
          </CDCardContent>
        </Grid>
        <Notification />
      </Grid>
    </>
  );
}
