import { Grid, Typography } from "@mui/material";

export default function MessageNoProvider() {
  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className="flex-center-center"
        >
          <Typography className="error-msg-login">
            Nessun provider di autenticazione configurato.<br />
            Contattare l'amministratore di sistema.
            <br />{" "}
          </Typography>
        </Grid>
        {/* <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className="flex-center-center"
        >
          <Typography className="error-msg-login">
            Contattare l'amministratore di sistema.
          </Typography>
        </Grid> */}
      </Grid>
    </>
  );
}
