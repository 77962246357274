import axios from "axios";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { notificationOpen } from "../redux/reducers/notification-reducer";
import handlerError from "../functions/handlerError";


const urlBase = process.env.REACT_APP_URL;
export default function useGenerateQrCode(body, setLoading, urlAction) {
  const dispatch = useDispatch();

  useEffect(() => {
    let isMounted = true;
    (async () => {
      if (body) {
        setLoading(true);
        const { action, method } = urlAction;
        let url = urlBase + action;
        try {
          const response = await axios({
            method: method,
            url,
            data: body,
            responseType: 'blob',
            headers: {
              'Content-Type': 'application/json'
            } 
          });

          const urlDownload = window.URL.createObjectURL(response.data);

          let a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = urlDownload;
            a.download = response.headers.filenameqrcode;
            a.click();
            window.URL.revokeObjectURL(url);
            setLoading(false);

        } catch (error) {
          console.error(error);
          const errorMsg = JSON.parse(await error?.response?.data.text());
          const notificationError = handlerError(errorMsg.message);
          dispatch(notificationOpen(notificationError));
        }
      }
    })();
    return () => {
      isMounted = false;
      setLoading(false);
    };
  }, [body]);

}
