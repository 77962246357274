import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {Provider} from 'react-redux';
//import AdapterDateFns from '@mui/lab/AdapterDateFns';
//import LocalizationProvider from '@mui/lab/LocalizationProvider';
import itLocale from 'date-fns/locale/it';
import { itIT } from '@mui/material/locale';
import store  from './store/index.js';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import '@fortawesome/fontawesome-free/css/all.min.css';


const mdTheme = createTheme(
  {
    palette: {
      primary: {
        main: '#026D82',
      },
      customWarning: {
        main: "#ffc107"
      },
      warning: {
        main: "#ffc107"
      },
      customSuccess: {
        main: "#28a745"
      },
      success: {
        main: "#28a745"
      },
      customError: {
        main: "#dc3545"
      },
      error: {
        main: "#dc3545"
      },
      EmptyColor: {
        main: "#f5f5f5"
      },
    },
    typography: {
      fontFamily: 'Segoe UI'
    },
    breakpoints: {
      values: {
        xs: 0,
        lesssm:400,
        sm: 600,
        lessmd:750,
        medium:910,
        md: 990,
        lesslg:1000,
        lg: 1200,
        lessxl:1350,
        xl: 1536,
      }
    },
    components: {
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontWeight: 'bold',
            color: '#026D82',
            marginBottom: '10px',
            backgroundColor: '#C9E2DC',
          },
        },
      },
    },
  },
  itIT
);


ReactDOM.render(
   <ScopedCssBaseline>
  <Provider store={store}>
      <ThemeProvider theme={mdTheme}>
        <BrowserRouter> 
          <App />
        </BrowserRouter>
      </ThemeProvider>
  </Provider>
    </ScopedCssBaseline>,
  document.getElementById('root')
);

reportWebVitals();
