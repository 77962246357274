import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { dialogButtonGeneral } from "../../../const/DialogData";
import { useFormik } from "formik";
import axios from "axios";
import * as yup from "yup";
import { DatapickerBoxDisabilita } from "./DatapickerBoxDisabilita";
import { useDispatch } from "react-redux";
import { closeDialog } from "../../../redux/reducers/DialogGeneral";
import { notificationOpen } from "../../../redux/reducers/notification-reducer";
import { disableAndRestorePostazioniDaEdificio } from "../../../const/urlAction";
import { useParams } from "react-router-dom";
import { urlDisabilitaEntita } from "../../../functions/UrlParams";
import ButtonBoxRiattivaTuttePostazioni from "./ButtonBoxRiattivaTuttePostazioni";
import {
  testoDisabilita,
  testoRiabilita,
} from "../../../const/Dialog/Disabilita/dialogDisabilitaText";
import { reloadingDataTableUsers } from "../../../redux/reducers/dataTableUsers-reducer";
import { checkRangeDateDisabilitazione } from "../../../functions/checkRangeDate";
import { dialogButtonConfirm } from "../../../const/DialogData";
import SpinnerCustom from "../../UI/spinner/spinner";
import { hideLoader, showLoader } from "../../../redux/reducers/appLoader-reducer";

const initialValues = {};

const validationSchema = yup.object({});

const urlBase = process.env.REACT_APP_URL;

export function BoxDialogDisabilita({
  isOpen = false,
  handlerBlur,
  rowInfo = false,
  change,
  closeForm,
  dialogType,
}) {
  const dispatch = useDispatch();
  const textArea = useRef(undefined);
  const [changedRow, setchangedRow] = useState(null);
  const [valueDatapicker, setValueDatapicker] = useState({
    DataOraInizio: undefined,
    DataOraFine: undefined,
  });
  const params = useParams();

  const { entita, action, method } = urlDisabilitaEntita(params);

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      setchangedRow({
        IdEntita: entita,
        DataOraInizioPrenotazione: valueDatapicker.DataOraInizio,
        DataOraFinePrenotazione: valueDatapicker.DataOraFine,
        Note: textArea.current.value.trim() || null,
      });
    },
  });

  useEffect(() => {
    if (changedRow != null) {
      (async () => {
        dispatch(showLoader());
        let url = urlBase + action;
        try {
          const response = await axios({
            method: method,
            url,
            data: changedRow,
            headers: {
              "Content-Type": "application/json",
            },
          });

          if (response.data.ok) {
            formik.resetForm(initialValues);
            dispatch(reloadingDataTableUsers());
            dispatch(closeDialog());            
          }
          dispatch(hideLoader());
          dispatch(
            notificationOpen({
              message: response.data.message,
              status: "success",
            })
          );
        } catch (error) {
          console.error(error);
          dispatch(hideLoader());
          dispatch(
            notificationOpen({
              message: error.response.data.message,
              status: "error",
            })
          );
        }        
      })();
    }
  }, [changedRow]);

  function disableButton() {}

  function returnValueInizio(value) {
    setValueDatapicker({ ...valueDatapicker, DataOraInizio: value });
  }

  function returnValueFine(value) {
    setValueDatapicker({ ...valueDatapicker, DataOraFine: value });
  }

  let isButtonDisabled = checkRangeDateDisabilitazione(
    valueDatapicker.DataOraInizio,
    valueDatapicker.DataOraFine
  );

useEffect(() => {
  setValueDatapicker({
    DataOraInizio: undefined,
    DataOraFine: undefined,
  })
},[isOpen])

  return (
    <React.Fragment>
      {dialogType === "disableRangeData" ? (
        <>
          <Dialog
            open={isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth={"sm"}
          >
            <DialogTitle>Disabilita</DialogTitle>
            <DialogContent className="border-Content-dialog">
              <FormControl fullWidth>
                <Grid item sx={{ marginBottom: "5px" }}>
                  {testoDisabilita}
                </Grid>
                <DatapickerBoxDisabilita
                  disableButton={disableButton}
                  valueInizio={returnValueInizio}
                  valueFine={returnValueFine}
                />
                <Grid item sx={{ marginTop: "18px" }}>
                  <label>
                    <Typography sx={{ marginBottom: "2px" }}>Note</Typography>
                  </label>
                  <TextareaAutosize
                    ref={textArea}
                    minRows={6}
                    maxRows={6}
                    placeholder="(opzionale) Aggiungi una nota da inserire nell'email di notifica."
                    style={{ width: "99%" }}
                  />
                </Grid>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => closeForm()}>
                {dialogButtonGeneral.Cancel}
              </Button>
              <Button
                type="submit"
                variant="contained"
                disabled={isButtonDisabled}
                onClick={() => formik.handleSubmit()}
              >
                {dialogButtonConfirm.Confirm}
              </Button>
            </DialogActions>            
          </Dialog>
        </>
      ) : (
        <>
          <Dialog
            open={isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth={"sm"}
          >
            <DialogTitle>Riabilita</DialogTitle>
            <DialogContent className="border-Content-dialog">
              <FormControl fullWidth>{testoRiabilita}</FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => closeForm()}>
                {dialogButtonGeneral.Cancel}
              </Button>
              <ButtonBoxRiattivaTuttePostazioni
                buttonText={dialogButtonConfirm.Confirm}
              />
            </DialogActions>
          </Dialog>
        </>
      )}
    </React.Fragment>
  );
}
