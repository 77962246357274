import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { Grid, Typography } from "@mui/material";
import "../index.css";
import {
  DISABILITATA,
  DISPONIBILE,
  OCCUPATA,
  RISERVATA,
} from "../const/Planimetria/StatoPostazione";
import ResponseQrCode from "../components/Dashboard/QrCode/ComponentQrCode/GeneralComponentQrCode/ResponseQrCode";
import { coloreDisabilitata, coloreDisponibile, coloreOccupato, coloreRiservata } from "../const/general/colorApp";

const grandezzaLogo = "150px"
const grandezzaScritte = "38px"

// export const coloreDisponibile = "#28a745"
// export const coloreOccupato = "#dc3545"
// const coloreDisabilitata = "#979797"
// const coloreRiservata = "#979797"

export default function CreateStatusPostazioneScannedQrCode(
  statoAlMomentoDelloScan,
  postazione
) {
  let icon;
  let text;


  //   #28a745
  // #fd7e14
  const {isSalaRiunione} = postazione;

  let tipologiaPrenotazione = isSalaRiunione ? "Servizio" : "Postazione";

  switch (true) {
    case statoAlMomentoDelloScan === DISPONIBILE:
      icon = (
        <CheckCircleIcon style={{ fontSize: grandezzaLogo , color: coloreDisponibile }} />
      );
      text = (
        <ResponseQrCode text1={tipologiaPrenotazione} text2="disponibile" color={coloreDisponibile} fontSize={grandezzaScritte} className="flex-center-center" />
      );
      break;
    case statoAlMomentoDelloScan === OCCUPATA:
      icon = <CancelIcon style={{ fontSize: grandezzaLogo, color: coloreOccupato }} />;
      text = (
        <ResponseQrCode text1={tipologiaPrenotazione} text2="occupata" color={coloreOccupato} fontSize={grandezzaScritte}className="flex-center-center" />
      );
      break;
    case statoAlMomentoDelloScan === DISABILITATA:
      icon = (
        <DoNotDisturbIcon style={{ fontSize: grandezzaLogo, color: coloreDisabilitata }} />
      );
      text = (
        <ResponseQrCode text1={tipologiaPrenotazione} text2="non disponibile" color={coloreDisabilitata} fontSize={grandezzaScritte} className="flex-center-center" />
      );
      break;
    case statoAlMomentoDelloScan === RISERVATA:
      icon = (
        <DoNotDisturbIcon style={{ fontSize: grandezzaLogo, color: coloreRiservata }} />
      );
      text = (
        <ResponseQrCode text1={tipologiaPrenotazione} text2="non disponibile" color={coloreRiservata} fontSize={grandezzaScritte} className="flex-center-center" />
      );
      break;
  }

  return (
    <>
      <Grid item className="flex-center-center" xs={12}>
        {icon}
      </Grid>
      <Grid item className="flex-center-center" xs={12}>
        {text}
      </Grid>
    </>
  );
}
