import { configureStore } from '@reduxjs/toolkit';
import dialogDispositivoReducer from '../redux/reducers/dialogDispositivo-reducer';
import sidebarReducer from '../redux/reducers/sidebar-reducer';
import reloadingDataTableUsers from '../redux/reducers/dataTableUsers-reducer';

import  notificationReducer  from '../redux/reducers/notification-reducer';
//import typeCallReducer from '../redux/reducers/typeCallSlice-reducer';
import userReducer from '../redux/reducers/user-reducer';
import dialogCambioPasswordReducer from '../redux/reducers/dialogCambioPassword-reducer';
import dialogUserReducer from "../redux/reducers/dialogUser-reducer";
import dialogStanzaReducer from "../redux/reducers/dialogStanze-reducer";
import rolesReducer from "../redux/reducers/userRolesSlice-reducer";
import edificiReducer from "../redux/reducers/edificiSlice-reducer";
import dataTableUsersReducer from "../redux/reducers/dataTableUsers-reducer";
import dataTableBuildingsReducer from "../redux/reducers/dataTableBuildings-reducer";
import devicesReducer from "../redux/reducers/devices-reducer";
import filterInitialReducer from "../redux/reducers/filterInitial-reducer";
import changeViewReducer from "../redux/reducers/changeView-reducer";
import usersReducer from '../redux/reducers/usersSlice-reducer'
import prenotazioneStepperReducer from '../redux/reducers/prenotazioneStepper-reducer'
import dialogGeneralReducer from '../redux/reducers/DialogGeneral'
import dataTablePostazioniReducer from "../redux/reducers/postazioniSlice-reducer";
import dataTablePrenotazioniReducer from "../redux/reducers/prenotazioneTable-reducer";
import dialogRiepilogoReducer from '../redux/reducers/DialogRiepilogo-reducer';
import dialogRemoveReducer from '../redux/reducers/DialogRemovePrenotazione-reducer';
import dialogPostazionefromReducer from '../redux/reducers/dialogPostazione-reducers';
import infoPrenotazioneReducer from '../redux/reducers/infoPrenotazione-reducer';
import filterParametersTuttePrenotazioniReducer from '../redux/reducers/filterParametersTuttePrenotazioni';
import dialogBackButtonReducer from '../redux/reducers/dialogBackButton-reducer';
import handlerBackToDefaultReducer from '../redux/reducers/handlerBackToDefaultView-reducer';
import dialogBuildingReducer from '../redux/reducers/DialogBuilding-reducer';
import configurationReducer from '../redux/reducers/configuration';
import taskAsyncReducer from '../redux/reducers/TaskAsync';
import appLoaderReducer from '../redux/reducers/appLoader-reducer';
import forceAppToReloadReducer from '../redux/reducers/forceAppToReload';
import prenotazioniAdvancedMenuFilterReducer from '../redux/reducers/prenotazioniAdvancedMenuFilter-reducer'
import multiUserHandlerReducer from '../redux/reducers/MultiUserInStepper-reducer'
import planimetriaReducer from '../redux/reducers/planimetria-reducer' 
import RiepilogoDialogAfterPrenotazioneReducer from '../redux/reducers/RiepilogoDialogAfterPrenotazione-reducer'
import addressEdificioReducer from '../redux/reducers/addressEdificio-reducer'
const store = configureStore({
    reducer: {
        sidebar: sidebarReducer,
        dialogDispositivo: dialogDispositivoReducer,
        dataTableUsers: reloadingDataTableUsers,
        notification: notificationReducer,
        userDate: userReducer,
        dialogCambioPassword: dialogCambioPasswordReducer,
        dialogUser: dialogUserReducer,
        dialogStanza: dialogStanzaReducer,
        userRoles: rolesReducer,
        edificiList: edificiReducer,
        dataTableUsers: dataTableUsersReducer,
        devices: devicesReducer,
        filter: filterInitialReducer,
        viewState: changeViewReducer,
        users : usersReducer,
        dataTableBuildings: dataTableBuildingsReducer,
        prenotazioneStepper: prenotazioneStepperReducer,
        dialogGeneral: dialogGeneralReducer,
        dialogRiepilogo : dialogRiepilogoReducer,
        dialogRemove : dialogRemoveReducer,
        dataTablePostazioni: dataTablePostazioniReducer,
        dataTablePrenotazioni: dataTablePrenotazioniReducer,
        dialogPostazione: dialogPostazionefromReducer,
        infoPrenotazione: infoPrenotazioneReducer,
        filterParametersTuttePrenotazioni: filterParametersTuttePrenotazioniReducer,
        dialogBackButton: dialogBackButtonReducer,
        handlerBackToDefault: handlerBackToDefaultReducer,
        dialogBuilding: dialogBuildingReducer,
        configurations: configurationReducer,
        taskAsync: taskAsyncReducer,
        appLoader: appLoaderReducer,
        forceAppToReload : forceAppToReloadReducer,
        prenotazioniAdvancedMenuFilter : prenotazioniAdvancedMenuFilterReducer,
        multiUserHandlerInStepper: multiUserHandlerReducer,
        planimetria: planimetriaReducer,
        addressEdificio: addressEdificioReducer,
        RiepilogoDialogAfterPrenotazione: RiepilogoDialogAfterPrenotazioneReducer,
    }
});


export default store; 