import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { getCallTypes } from "../../const/urlAction";
import { Grid, Button, Card, CardContent, IconButton, CardActions, Collapse, Typography } from "@mui/material";
import { FormControl, FormLabel, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './AdvancedFilterPanel.module.css';
import { changeFontSizeRadioButton } from "../../functions/handlerDataGrid";
import { useSelector } from "react-redux";
import useMobileDetection from "../../Hooks/useMobileDetection";


const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    })
  }));


  export default function AdvancedFilterPanel({ isFetching, handleFilterData, startValueRadioButton }) {
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const [radioUserEnabled, setRadioRecoveryEnabled] = useState(startValueRadioButton);
  
  const handleUserChange = (event) => {
    setRadioRecoveryEnabled(event.target.value);
  };

 function actionApplica() {
    handleFilterData(radioUserEnabled); 
    setExpanded(false)
  }

  return (
    <>
        <Grid container item >
          <Grid item component={Card} xs={12} sm={12} sx={{boxShadow: 0}}>
            <CardActions sx={{justifyContent: 'flex-end', padding:"0 0 8px 0 !important"}} disableSpacing>
                <Typography variant="button">{expanded ? 'Riduci' : 'Espandi'} Filtri </Typography>
                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                    >
                    <ExpandMoreIcon />
                </ExpandMore>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>
                  <Grid container columnSpacing={3} rowSpacing={3}>
                      <Grid item xs={12} sm={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Mostra</FormLabel>
                            <RadioGroup aria-label="recoveryEnabled" name="row-radio-group-enabled" onChange={handleUserChange} value={radioUserEnabled} row>
                                <FormControlLabel value={true} control={<Radio />} label="Solo abilitati" />
                                <FormControlLabel value={false} control={<Radio />} label="Solo non abilitati" />
                                <FormControlLabel value={"null"} control={<Radio />} label="Tutti" />
                            </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid container item>
                        <Grid item xs={12} className={styles.buttonGrid}>
                          <Button variant="contained" disabled={isFetching} className={styles.buttonStyle} onClick={() => {
                           actionApplica();
                            }}>Applica</Button>
                        </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Collapse>
          </Grid>
        </Grid>
    </>
  );
}


export function AdvancedFilterScissor({expanded, isFetching, handleFilterData, returnExpandedState, labelCustom = null }) {

  const handleExpandClick = () => {
    returnExpandedState(!expanded);
  };

  const [radioUserEnabled, setRadioRecoveryEnabled] = useState(true);
  

  const handleUserChange = (event) => {
    setRadioRecoveryEnabled(event.target.value);
  };

 function actionApplica() {
    handleFilterData(radioUserEnabled); 
    returnExpandedState(false)
  }

  return (
    <>
        <Grid container item >
          <Grid item component={Card} xs={12} sm={12} sx={{boxShadow: 0}}>
            <CardActions sx={{justifyContent: 'flex-end', padding:"0 0 8px 0"}} disableSpacing>
                {!labelCustom ? <Typography variant="button">Esporta e Filtra </Typography> : <Typography className="etichettaFiltroCustom">{labelCustom}</Typography>}
                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                    >
                    <ExpandMoreIcon />
                </ExpandMore>
            </CardActions>
          </Grid>
        </Grid>
    </>
  );
}


export function MenuCollapse({expanded, handleFilterData, isFetching, actionApplica, startValueRadioButton, returnExpandedStateAfterConfirm}) {

  const [radioUserEnabled, setRadioRecoveryEnabled] = useState(startValueRadioButton);

  const isMobile = useMobileDetection()

  const FormControlLabelCustom = changeFontSizeRadioButton(FormControlLabel,isMobile);
  
  const handleUserChange = (event) => {
    setRadioRecoveryEnabled(event.target.value);
  };

 function actionApplica() {
    handleFilterData(radioUserEnabled);
    returnExpandedStateAfterConfirm(false) 
 }
 
  return(
    <>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent >
                  <Grid container columnSpacing={3} rowSpacing={3}>
                      <Grid item xs={12} sm={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Mostra</FormLabel>
                            <RadioGroup aria-label="recoveryEnabled" name="row-radio-group-enabled" onChange={handleUserChange}  value={radioUserEnabled} row>
                            <FormControlLabel value={true} control={<Radio />} label="Solo abilitati" />
                            <FormControlLabel value={false} control={<Radio />} label="Solo non abilitati" />
                            <FormControlLabel value={"null"} control={<Radio />} label="Tutti" />                         </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid container item>
                        <Grid item xs={12} className={styles.buttonGrid}>
                          <Button variant="contained" disabled={isFetching} className={styles.buttonStyle} onClick={() => {
                           actionApplica();
                            }}>Applica</Button>
                        </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Collapse>
    </>
  )
}


