import { DialogContent, Grid, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import TextWarningQrCodeScanned from "../../TextWarningQrCodeScanned";
import itLocale from "date-fns/locale/it";
import { DISPONIBILE } from "../../../../../../const/Planimetria/StatoPostazione";
import GeneralCustomDateTimePicker from "../../../../../UI/TimePicker/GeneralCustomDateTimePicker";

export default function BodyComponentPostazioneOccupata({statoAlMomentoDelloScan,postazioneOccupata, setValueDataFine = () => null,valueDataFine,dataFinePrenotazione, hasPrenotazioniAttiveNelGiorno}) {


    const handleChangeOrarioFine = (newValue) => {
        if (newValue !== null) {
          setValueDataFine(newValue);
        } else {
          setValueDataFine(dataFinePrenotazione);
        }
      };
    

  return (
    <>
      {postazioneOccupata && (
        <>
          <Grid
            container
            className="flex-center-center"
            sx={{ marginTop: "15px" }}
          >
            <Typography sx={{ fontSize: "23px", fontStyle: "italic" }}>
              {postazioneOccupata.nominativo}
            </Typography>
          </Grid>
          <Grid container className="flex-center-center">
            <Typography sx={{ fontSize: "23px", fontStyle: "italic" }}>
              {moment(postazioneOccupata.orarioInizioPrenotazione).format(
                "HH:mm"
              )}{" "}
              -{" "}
              {moment(postazioneOccupata.orarioFinePrenotazione).format(
                "HH:mm"
              )}
            </Typography>
          </Grid>
        </>
      )}
      {!postazioneOccupata && (
        <Grid container className="flex-center-center">
          <Grid item>
            <DialogContent sx={{ minHeight: "100px", paddingBottom: "0px" }}>
              {!(statoAlMomentoDelloScan != 1) && (
                <>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={itLocale}
                  >
                    <Grid item xs={12} sm={12}>
                      <GeneralCustomDateTimePicker
                        TypeTimePicker={1}
                        label="Ora fine prenotazione"
                        valueStart={valueDataFine}
                        handleChange={handleChangeOrarioFine}
                      />
                    </Grid>
                  </LocalizationProvider>
                </>
              )}
            </DialogContent>
          </Grid>
        </Grid>
      )}
      {hasPrenotazioniAttiveNelGiorno &&
        statoAlMomentoDelloScan === DISPONIBILE && <TextWarningQrCodeScanned />}
    </>
  );
}
