import moment from 'moment';
import 'moment/locale/it';

const formatDate = (value) => {
  let date = new Date(value);
  let formattedDate = date.toLocaleDateString('it-IT').replaceAll('/', '-');

  return formattedDate;

};

const formatDateExport = (value) => {
  let date = new Date(value);
  let formattedDate = moment(date).format("YY/MM/DD");


  return formattedDate;

};

const formatDateRegularNoTime = (value) => {
  let date = new Date(value);
  let formattedDate = moment(date).format("DD/MM/YY");

  return formattedDate;

};

const formatDateTime = (value) => {
  let date = new Date(value);
  let formattedDate = date.toLocaleDateString('it-IT') + " " + date.toLocaleTimeString('it-IT');
  return formattedDate;

};

const formatDateTimeToView = (value) => {
  let date = new Date(value);
  let formattedDate = moment(value).format("DD/MM/YYYY HH:mm");
  return formattedDate;

};


/**restituisce fascia oraria in formato "09" */
export function DailyHourSlot(dateString) {
  return moment(dateString).format('HH:mm');
}

/**restituisce orario in formato "12:00" */
export function getTime(dateString) { return moment(dateString).format("HH:mm") };


/**restituisce numero giorno del mese*/
export function getDayofMonth(dateString) { return moment(dateString).format("M") };

/**restituisce giorno della settimana in formato "Gio"*/
export function getDayOfWeek(dateString) {
  moment.locale("it");
  let day = moment(dateString).format('ddd');
  //rendi prima lettera maiuscola
  return day.charAt(0).toUpperCase() + day.slice(1);
}

/**restituisce data in formato: Gio 15 */
export function DayofWeekandMonth(dateString) {
  moment.locale("it");
  let datestr = moment(dateString).format('ddd D');
  //rendi prima lettera maiuscola
  return datestr.charAt(0).toUpperCase() + datestr.slice(1);
}

/**restituisce data giornaliera in formato: 15 */
export function dayofMonth(dateString) {
  moment.locale("it");
  let datestr = moment(dateString).format('DD');
  //rendi prima lettera maiuscola
  return datestr;
}

export function month(dateString) {
  moment.locale("it");
  let datestr = moment(dateString).format('MMM');
  //rendi prima lettera maiuscola
  return datestr.charAt(0).toUpperCase() + datestr.slice(1);
}

/**restituisce data in formato: Gio 15 */
export function DayofWeekandMonthVisibile(dateString) {
  moment.locale("it");
  let datestr = moment(dateString).format('D MMM');
  return datestr.charAt(0).toUpperCase() + datestr.slice(1);
  /* 
  //rendi prima lettera maiuscola
let replaced;
  if (datestr.length === 10) {replaced = datestr.replace(datestr.substring(6,8), datestr.substring(6,8).toUpperCase());}
  if (datestr.length === 9) {replaced = datestr.replace(datestr.substring(5,7), datestr.substring(5,7).toUpperCase());}
 let formattedStr = replaced.charAt(0).toUpperCase() + replaced.slice(1);
  return formattedStr; */

}

export { formatDate, formatDateTime, formatDateTimeToView, formatDateExport, formatDateRegularNoTime };