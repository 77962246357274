import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  dialogAddBuilding,
  dialogButtonGeneral,
} from "../../../../../const/DialogData";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Fragment } from "react";
import { useFormik } from "formik";
import { initialValuesBuildingAdd } from "../../../../../const/formik/Building/initialValuesBuilding";
import { useEffect } from "react";
import { validationSchemaBuildingAdd } from "../../../../../const/formik/Building/validationSchemaBuilding";
import { useState } from "react";
import { addBuilding } from "../../../../../const/urlAction";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { notificationOpen } from "../../../../../redux/reducers/notification-reducer";
import { reloadingDataTableBuildings } from "../../../../../redux/reducers/dataTableBuildings-reducer";
import {
  hideLoader,
  showLoader,
} from "../../../../../redux/reducers/appLoader-reducer";
import AddressField from "./addressForm/AddressField"
import AutocompleteAddressField from "./addressForm/AutocompleteAddressField";

const urlBase = process.env.REACT_APP_URL;
const apiKey = process.env.REACT_APP_MAPS_APIKEY;


export default function DialogViewBuildingAdd({
  isOpen = false,
  returnIsOpen,
  handlerBlur,
  users,
  selectedUsers,
  handleChangeUserSelect = () => null,
}) {
  const [changedRow, setchangedRow] = useState();
  const dispatch = useDispatch();
  const initialValues = initialValuesBuildingAdd;

  const state = useSelector((state) => state.addressEdificio);
  const { coordinate, indirizzo } = state;

  const appLoadingState = useSelector((state) => state.appLoader.loading);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchemaBuildingAdd,
    enableReinitialize: true,
    onSubmit: (values) => {
      setchangedRow({
        Denominazione: values.Denominazione,
        Sigla: values.Sigla,
        UtentiResponsabiliPrenotazioni: selectedUsers,
        Indirizzo: indirizzo,
        Coordinate: coordinate
      });
    },
  });

  const closeDialog = (initialValues) => {
    formik.resetForm(initialValues);
    returnIsOpen(false);
  };

  useEffect(() => {
    if (changedRow != null) {
      (async () => {
        let listIdRole, formData, action, method, url;

        if (selectedUsers === null || selectedUsers === undefined) {
          formData = { ...changedRow, UtentiResponsabiliPrenotazioni: null };
        } else {
          formData = {
            ...changedRow,
            UtentiResponsabiliPrenotazioni: selectedUsers,
          };
        }

        action = addBuilding.action;
        method = addBuilding.method;

        url = urlBase + action;
        dispatch(showLoader());
        try {
          const response = await axios({
            method: method,
            url,
            data: formData,
          });

          if (response.data.ok) {
            formik.resetForm(initialValues);
            dispatch(reloadingDataTableBuildings());
            closeDialog();
          }
          dispatch(
            notificationOpen({
              message: response.data.message,
              status: "success",
            })
          );
        } catch (error) {
          console.error(error);

          if (error?.response?.data?.errors != undefined) {
            let keys = Object.keys(error?.response?.data?.errors);
            keys.map((key) => {
              formik.setFieldError(key, error?.response?.data?.errors[key]);
            });
          }
          if (error.response.data.message == "Edificio già presente.") {
            dispatch(
              notificationOpen({
                message: "Edificio già presente!",
                status: "error",
              })
            );
          }
          if (error.response.data.message == "Selezionare un Responsabile!") {
            dispatch(
              notificationOpen({
                message: `${error.response.data.message}`,
                status: "error",
              })
            );
          }

          if (error.response.data.message == "Formato non valido") {
            dispatch(
              notificationOpen({
                message: "Formato coordinate non valido",
                status: "error",
              })
            );
          }
        }
        dispatch(hideLoader());
      })();
    }
  }, [changedRow]);

  return (
    <>
      <Dialog
        open={isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"sm"}
        onBlur={(e) => handlerBlur(e)}
      >
        <DialogTitle>{dialogAddBuilding.DialogTitle}</DialogTitle>
        <DialogContent className="border-Content-dialog">
          <FormControl fullWidth>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                inputProps={{
                  maxLength: 3,
                }}
                id="Sigla"
                label="Sigla"
                type="text"
                fullWidth
                variant="standard"
                // value={formik?.values?.Sigla}
                onChange={formik.handleChange("Sigla")}
                error={formik.touched.Sigla && Boolean(formik.errors.Sigla)}
                helperText={formik.touched.Sigla && formik.errors.Sigla}
              />
            </Grid>
            <Grid container rowSpacing={1} columnSpacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  margin="dense"
                  id="Denominazione"
                  label="Denominazione"
                  type="text"
                  fullWidth
                  variant="standard"
                  // value={formik?.values?.Denominazione}
                  onChange={formik.handleChange("Denominazione")}
                  error={
                    formik.touched.Denominazione &&
                    Boolean(formik.errors.Denominazione)
                  }
                  helperText={
                    formik.touched.Denominazione && formik.errors.Denominazione
                  }
                />
              </Grid>

              {apiKey ? 
                <AutocompleteAddressField formik={formik}/>
              :
                <AddressField formik={formik}/>
              }
              <Grid item xs={12} sm={12}>
                <Autocomplete
                  multiple
                  id="UtentiResponsabiliPrenotazioni"
                  size="small"
                  options={users} //tutti ma sotto forma di json selectedUsers idUtente Nominativo
                  value={selectedUsers}
                  disableCloseOnSelect
                  limitTags={1}
                  isOptionEqualToValue={(option, value) =>
                    option.idUtente === value.idUtente
                  }
                  getOptionLabel={(option) => option.nominativo || ""}
                  onChange={handleChangeUserSelect}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.nominativo}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Responsabili edificio"
                      variant="standard"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <Fragment>{params.InputProps.endAdornment}</Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <br></br>
              <br></br>
            </Grid>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeDialog(initialValues)}>
            {dialogButtonGeneral.Cancel}
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={appLoadingState}
            onClick={() => formik.handleSubmit()}
          >
            {dialogButtonGeneral.Confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
