import { Grid, Typography } from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { checkInPrenotazione } from "../../../../../../const/urlAction";
import handlerError from "../../../../../../functions/handlerError";
import { hideLoader, showLoader } from "../../../../../../redux/reducers/appLoader-reducer";
import { notificationOpen } from "../../../../../../redux/reducers/notification-reducer";
import { loadprenotazioniTableState } from "../../../../../../redux/reducers/prenotazioneTable-reducer";

const urlBase = process.env.REACT_APP_URL;
export default function BodyComponentPostazioneCheckin({
  prenotazioneCheckIn,
  checkInDependency = 0,
  returnComponentState = () => {},
}) {
  const dispatch = useDispatch();

  useEffect(() => {
      (async () => {
        dispatch(showLoader());
        const { action, method } = checkInPrenotazione;
        let url = urlBase + action;
        try {
          const response = await axios({
            method: method,
            url,
            data: { id: prenotazioneCheckIn.id, checkInTypology: 1 },
          });
          if (response.data.ok) {
            returnComponentState(false);
            dispatch(
              notificationOpen({
                message: response.data.message,
                status: "success",
              })
            );
          }
        } catch (error) {
          console.error(error);
          const notificationError = handlerError(error);
          dispatch(notificationOpen(notificationError));
        }
        dispatch(loadprenotazioniTableState());
        dispatch(hideLoader());
      })();
  }, []);

  return (
    <>
    </>
  );
}
