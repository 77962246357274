import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import EditIcon from '@mui/icons-material/Edit';
import LoopOutlinedIcon from '@mui/icons-material/LoopOutlined';
import { useDispatch } from "react-redux";
import { GridActionsCellItem } from "@mui/x-data-grid";
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from 'react-redux';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import {policies as permessi} from "../../const/policies"
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { useNavigate } from 'react-router-dom';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import DeleteIcon from '@mui/icons-material/Delete';
import { dialogPageTypes } from "../../const/dialogPageTypes"
import { openDialog, setPageType } from '../../redux/reducers/DialogGeneral';
import { faDoorOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ButtonBoxZone = ({ rowId, rowInfo }) => {
  const state = useSelector((state) => state.userDate);
  const { policies } = state;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDialogToEdit = ({dialogType, rowInfo}) => {
    dispatch(setPageType(dialogPageTypes.zone))
    dispatch(openDialog({dialogType, rowInfo}))
  };
  const idZona = rowInfo.id
  return (
    <>
      {policies.indexOf(permessi.zoneInserimentoModifica) != -1 && <GridActionsCellItem
        icon={
          <Tooltip title="Modifica" >
            <EditIcon
            sx={{fontSize: 30 }}
            color="primary" />
          </Tooltip>
        }
        label="Modifica"
        onClick={() => handleDialogToEdit({dialogType:"edit", rowInfo})}
      />}
         {policies.indexOf(permessi.stanzeVisualizzazione) != -1 &&<GridActionsCellItem
        icon={
          <Tooltip title="Gestisci Stanze" >
            <FontAwesomeIcon icon={faDoorOpen}
            style={{ color: "#026D82", fontSize:"23px"}}
             />
          </Tooltip>
        }
        label="Stanze"
        onClick={() => navigate("" + idZona)}
      />}
      {policies.indexOf(permessi.utentiVisualizzazione) != -1 &&<GridActionsCellItem
        icon={
          <Tooltip title={!rowInfo.dataDel ? "Cancella" : "Abilita"}>
           { rowInfo.dataDel ? <DoDisturbIcon sx={{fontSize: 30 }} color="primary" /> : <DeleteIcon sx={{fontSize: 30 }} color="primary" />}
          </Tooltip>
        }
        label="Cancella"
        onClick={() => handleDialogToEdit({dialogType:"delete", rowInfo})}
      />}
      </>
  );
};

export default ButtonBoxZone;
