import { createSlice } from '@reduxjs/toolkit';

const initialState = {planimetriaGestioneReload : false};

const planimetriaSlice = createSlice({
    name:'planimetriaPrenotazioneSlice',
    initialState,
    reducers: {
        reloadPlanimetriaPrenotazione: (state) => { 
            state.planimetriaGestioneReload = !state.planimetriaGestioneReload
         }
    }
})

export const { reloadPlanimetriaPrenotazione } = planimetriaSlice.actions;

const { reducer } = planimetriaSlice;

export default reducer;