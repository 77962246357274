import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  DialogActions,
  Button,
  makeStyles,
  Tabs,
  Tab,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import handlerError from "../../../functions/handlerError";
import { notificationOpen } from "../../../redux/reducers/notification-reducer";
import Notification from "../../UI/Notification/Notification";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { getDettaglioPrenotazioneById } from "../../../const/urlAction";
import { dialogButtonRiepilogo } from "../../../const/DialogData";
import {
  closeDialogRiepilogo,
  openDialogRiepilogo,
} from "../../../redux/reducers/DialogRiepilogo-reducer";
import { useFormik } from "formik";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import { blue } from "@mui/material/colors";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import moment from "moment";
import { openDialogRemove } from "../../../redux/reducers/DialogRemovePrenotazione-reducer";
import DialogRemovePrenotazione from "../DialogRemove/DialogRemovePrenotazione";
import { maxValueText } from "../../../const/DialogRiepilogoText";
import useMobileDetection from "../../../Hooks/useMobileDetection";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import { Box } from "@mui/system";
import PlanimetriaMostraRiepilogoPrenotazione from "../../Planimetria/PlanimetriaMostraRiepilogoPrenotazione/PlanimetriaMostraRiepilogoPrenotazione";
import createDialogConfirmActionInDialogRiepilogo, {
  dialogList,
} from "../../../functions/createDialogConfirmActionInDialogRiepilogo";
import "../../../index";
import CustomMainTabsComponent from "../../UI/tabs/CustomMainTabsComponent";
import ListTabsDialogoRiepilogo from "../../../const/Tabs/Dialog/DialogRiepilogo/ListTabsDialogoRiepilogo"
import { hideLoader, showLoader } from "../../../redux/reducers/appLoader-reducer";
const urlBase = process.env.REACT_APP_URL;

const tabsWithWidthLarge = [1];

export function DialogRiepilogo({ isOpenRiepilogo = false, dialogType, id }) {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [dialog, setDialog] = useState("");
  const [noteValue, setNoteValue] = useState("");
  const [lastTabsSelected, setLastTabsSelected] = useState(0);
  const [isOpenRemove, setIsOpenRemove] = useState(false);
  const [isSalaRiunione, setIsSalaRiunione] = useState(false);
  
  const [sizePlanimetria, setSizePlanimetria] = useState({});
  const backButtonState = useSelector((state) => state.dialogBackButton);

  const isMobile = useMobileDetection();

  useEffect(() => {
    if (isOpenRiepilogo) {
      (async () => {

        const { action, method } = getDettaglioPrenotazioneById;
        let url = urlBase + action + id;

        try {
          const response = await axios({
            method: method,
            url,
          });

          if (response.data.ok) {
            setRows(response.data.result);
            setIsSalaRiunione(response.data.res)
          } else {
            dispatch(
              notificationOpen({
                message: response.data.message,
                status: "success",
              })
            );
          }
        } catch (error) {
          console.error(error);
          const notificationError = handlerError(error);
          dispatch(notificationOpen(notificationError));
        }
      })();
    }
    return () => {
      isOpenRiepilogo = false;
      setRows([]);
    };
  }, [isOpenRiepilogo]);

  function returnSizePlanimetria(size) {
    if (size != null || size != undefined) setSizePlanimetria(size);
  }

  let objectToDialogConfirm = createDialogConfirmActionInDialogRiepilogo(
    dialog,
    { id, NotaCancellazione:noteValue }
  );

  function returnTextNote(value) {
    setNoteValue(value);
  }

  useEffect(() => {
    dispatch(closeDialogRiepilogo());
  },[backButtonState])


  return (
    <>
      { rows.length === 0 
      ? (<></>) 
      : (
        <Dialog
          fullScreen={isMobile}
          open={isOpenRiepilogo}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={
            tabsWithWidthLarge.indexOf(lastTabsSelected) != -1 
              ? {
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "100%",
                      maxWidth: Math.max(sizePlanimetria.larghezzaPlanimetria,500)
                    },
                    "& .MuiTabs-flexContainer": {
                      justifyContent: "center",
                    },
                  },
                }
              : {
                  "& .MuiTabs-flexContainer": {
                    justifyContent: "center",
                  },
                }
          }
        >
          <>
            <Grid container columnSpacing={30}>
              <Grid item></Grid>
              <Grid item sm={12} md={12} xs={12}>
                {rows.disabilitato ? (
                  <DialogTitle>Dettaglio disabilitazione</DialogTitle>
                ) : (
                  <DialogTitle>Dettaglio prenotazione</DialogTitle>
                )}
                <CustomMainTabsComponent
                  tabPanel={ListTabsDialogoRiepilogo(
                    id,
                    returnSizePlanimetria,
                    rows
                  ).filter(n => n)}
                  lastTabsSelected={lastTabsSelected}
                  setLastTabsSelected={setLastTabsSelected}
                />
                <DialogActions className="border-Action-dialog">
                  <Grid container item lg={12} md={12} sm={12} xs={12}>
                    <Grid item lg={2} md={2} sm={2} xs={2}>
                      <Button onClick={() => dispatch(closeDialogRiepilogo())}>
                        {dialogButtonRiepilogo.Indietro}
                      </Button>
                    </Grid>
                    <Grid
                      container
                      item
                      lg={10}
                      md={10}
                      sm={10}
                      xs={10}
                      sx={{ display: "flex", justifyContent: "right" }}
                    >
                      <Grid sx={{ textAlign: "right" }}>
                        {rows.checkOutIsAvailable && (
                          <Box>
                            <Button
                              className="button-paddingLeft"
                              variant="contained"
                              onClick={() => {
                                setIsOpenRemove(!isOpenRemove);
                                setDialog(dialogList.checkOutPrenotazione);
                              }}
                            >
                              Checkout
                            </Button>
                          </Box>
                        )}
                      </Grid>
                      <Grid sx={{ textAlign: "right" }}>
                        {rows.checkInIsAvailable && (
                          <Box>
                            <Button
                              className="button-paddingLeft"
                              variant="contained"
                              onClick={() => {
                                setIsOpenRemove(!isOpenRemove);
                                setDialog(dialogList.checkInPrenotazione);
                              }}
                            >
                              Check-in
                            </Button>
                          </Box>
                        )}
                      </Grid>
                      <Grid sx={{ textAlign: "right" }}>
                        {rows.isDeletable && (
                          <Box>
                            <Button
                              className="button-paddingLeft"
                              variant="contained"
                              onClick={() => {
                                setIsOpenRemove(!isOpenRemove);
                                setDialog(dialogList.deletePrenotazione);
                              }}
                            >
                              {dialogButtonRiepilogo.Elimina}
                            </Button>
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </DialogActions>
              </Grid>
            </Grid>
          </>
        </Dialog>
      )}
      <DialogRemovePrenotazione
        isOpenRemove={isOpenRemove}
        dialogType={dialogType}
        id={id}
        object={objectToDialogConfirm}
        returnTextNote={returnTextNote}
        handleState={setIsOpenRemove}
      />
    </>
  );
}
