import { useEffect } from "react";
import DialogViewSincronizzazioneUser from "../../../../../components/Dialog/UserDialog/componentUserDialog/viewUserDialog/sincronizzazioneUtentiView/DialogViewSincronizzazioneUser";
import { UserDialogTextActiveDirectory, UserDialogTextLocalDatabase } from "../../../../../components/UI/Form/AvvisoFormMessage";
import { labelArrayDialogUserSincronizzazione } from "../../../../RadioButton/RadioButtonListRadio";


export function ListTabsDialogoUserSincronizzazione(authConfiguration, action, setAction = () => null) {
  let arrayTabs = []

  if(authConfiguration?.enableActiveDirectoryAuth) arrayTabs.push({ componentTab: <DialogViewSincronizzazioneUser setAction={setAction} selectField={true} pannelPills={true} testoSpiegazioneUso={UserDialogTextActiveDirectory} action={action} />, label: labelArrayDialogUserSincronizzazione[0]})
  if(authConfiguration?.enableDatabaseAuth) arrayTabs.push({ componentTab: <DialogViewSincronizzazioneUser  setAction={setAction} toggleAdmin={true} testoSpiegazioneUso={UserDialogTextLocalDatabase}action={action}/>, label: labelArrayDialogUserSincronizzazione[1] })

  return arrayTabs;
  }