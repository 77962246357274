import { actionOnStepper } from "../const/Stepper/actionOnStepper";
import { resetStanzaInPrenotazioneStepper, resetStatePrenotazioneStepper, resetZonaInPrenotazioneStepper, resetPostazioneInPrenotazioneStepper, resetEdificioInPrenotazioneStepper  } from "../redux/reducers/infoPrenotazione-reducer";
import { procediPrenotazione, resetPostazioniPrenotate } from "../redux/reducers/prenotazioneStepper-reducer";


export default function HandleFrameStepper( dispatch, numFrame,setLastActionOnStepper) {
// in base al frames della stepper associa un dispatch per gestire le informazioni alla struttura della stepper
let ActionTodispatch
switch (true) {
    case numFrame === 3:
        setLastActionOnStepper(actionOnStepper.indietro)
        dispatch(procediPrenotazione(false))
        dispatch(resetStanzaInPrenotazioneStepper())
         //dispatch(resetPostazioniPrenotate())
        break;
        case numFrame === 2:
            // il reset delle informazioni riguardanti zona e edificio è stato spostato dentro "AutocompleteBoxHome"
        // dispatch(resetZonaInPrenotazioneStepper())
        //  dispatch(resetEdificioInPrenotazioneStepper())
        break;
        case numFrame === 0:
        break;
    default:
        break;
}
return ActionTodispatch
}

