import { CircularProgress, LinearProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";

export function ProgressBarDisponibilitaLinear({colorProgressBar,value, style={}}) {

  return (
    <Box  className="flex-center-center" sx={{ paddingRight:"5px"}}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress sx={style} color={colorProgressBar} variant="determinate" value={value} />
      </Box>
      <Box sx={{ minWidth: 35 }}> 
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}


export function ProgressBarDisponibilitaCircular({colorProgressBar,value, styleBox = { position: 'relative', display: 'inline-flex', bottom:"8px"}, sizeCircle = 40, styleTypography = {} }) {

let empty = value === 0; 

    return (
      <>
      <Box sx={styleBox}>
        <CircularProgress size={sizeCircle}  sx={{zIndex:"2"}}color={empty ? "EmptyColor" : colorProgressBar} variant="determinate" value={empty ? 100 : value}  />
        <CircularProgress size={sizeCircle} sx={{position:"absolute"}} color={ "EmptyColor" } variant="determinate" value={100}  />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography sx={styleTypography} variant="caption" component="div" color="text.secondary">
            {value}%
          </Typography>
        </Box>
      </Box>
          </>
    );
  }
