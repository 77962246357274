export default function setTabsToShowAddEdit(listTabs, hasPassword, username) {
  let isEmail = username.indexOf("@") === -1 ? false : true;

  
let tabSelected;
  switch (true) {
    case hasPassword && isEmail:
      tabSelected = listTabs[1];
      break;
    case !hasPassword && !isEmail:
      tabSelected = listTabs[0];
      break;
    case !hasPassword && isEmail:
      tabSelected = listTabs[2];
      break;
    default:
      break;
  }

  return tabSelected;
}
