import {
  Button,
  Grid,
  Avatar,
  IconButton,
} from "@mui/material";
import React,{ useEffect, useState, useRef } from "react";
import { notificationOpen } from "../../redux/reducers/notification-reducer";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faUser } from "@fortawesome/free-solid-svg-icons";

const urlBase = process.env.REACT_APP_URL;

export default function AvatarImage({size ,name, isInputDisabled, ImmagineAvatar, isCentered, bgColor, isUserLoggedImage, styleContainer}) {
  const dispatch = useDispatch();

  const inputRef = useRef();
  const [isChangedAvatar, setIsChangedAvatar] = useState();
  const [avatarImage, setAvatarImage] = useState(null);
  const [changedRow, setchangedRow] = useState();

  function handleChangeFile(event) {
    if (event?.currentTarget?.files[0]?.type?.startsWith("image")) {
      setchangedRow({
        Image: event.currentTarget.files[0],
      });
    } else {
      dispatch(
        notificationOpen({
          message: "Il tipo di file non è consentito. Caricare un Immagine.",
          status: "error",
        })
      );
    }
  }

  //GET PER RECUPERO AVATAR
  useEffect(() => {
    if (isUserLoggedImage){
      (async () => {
        let url = urlBase + "Account/GetAvatar";

        try {
          const response = await axios({
            method: "get",
            url,
          });

          if (response.data) {
            setAvatarImage(response.data.result);
          }
        } catch (error) {
          setAvatarImage(null);
          console.error(error);
        }
      })();
   }
    else{
      setAvatarImage(ImmagineAvatar)
    }
    return () => {setAvatarImage(null)}
  }, [ImmagineAvatar, isChangedAvatar]);

  

  //POST PER ADD/EDIT IMMAGINE AVATAR
  useEffect(() => {
    if (changedRow != null) {
      (async () => {
        let formData = new FormData();

        Object.keys(changedRow).map((key) => {
          formData.append(`${key}`, changedRow[key]);
        });
        let url = urlBase + "Account/AddAvatar";
        try {
          const response = await axios({
            method: "post",
            url,
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          if (response.data.ok) {
            setIsChangedAvatar(!isChangedAvatar);
          }
          dispatch(
            notificationOpen({
              message: response.data.message,
              status: "success",
            })
          );
        } catch (error) {
          console.error(error);
        }
      })();
    }
  }, [changedRow]);

  const DeleteAvatar = async (event) => {
    inputRef.current.value = "";
    let url = urlBase + "Account/DeleteAvatar";
    try {
      const response = await axios({
        method: "post",
        url,
      });

      if (response.data) {
        setAvatarImage(null);
      }
      dispatch(
        notificationOpen({
          message: response.data.message,
          status: "success",
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
    {isCentered ?
      <Grid item xs={12} sm={12} className="avatarContainer" sx={styleContainer}>
        <input
          accept="image/*"
          className="hiddenInput"
          id="icon-button-file"
          type="file"
          onChange={(event) => handleChangeFile(event)}
          disabled={isInputDisabled}
          ref={inputRef}
        />
        <label htmlFor="icon-button-file">
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
          >
            <Avatar
              sx={{ bgcolor: bgColor, width: size, height: size, fontSize:size/2  }}
              src={"data:image/*;base64," + (ImmagineAvatar ? ImmagineAvatar : avatarImage)}
            >
              {/* per ottenere la prima e l'ultima iniziale usare questo */}
              {/* (name).match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase() */}
              {name ? (name).split(" ").map((n)=>n[0]).join("").slice(0,2).toUpperCase() : <DoNotDisturbIcon  sx={{fontSize: size ? size/1.4 : 30 }} />}
              </Avatar>
          </IconButton>
        </label>
      </Grid>
    :
      <Avatar
        sx={{ bgcolor: bgColor, width: size, height: size, fontSize:size/2}}
        style={styleContainer}
        src={"data:image/*;base64," +  avatarImage}
        >
        {name ? (name).split(" ").map((n)=>n[0]).join("").slice(0,2).toUpperCase() : <DoNotDisturbIcon  sx={{fontSize: size ? size/1.4 : 30 }} />}
      </Avatar>
    }
      {avatarImage && !isInputDisabled && (
        <Grid item xs={12} sm={12} className="avatarContainer">
          <Button
            variant="contained"
            size="small"
            onClick={(event) => DeleteAvatar(event)}
            startIcon={<FontAwesomeIcon icon={faTrashCan} />}
          >
            Cancella
          </Button>
        </Grid>
      )}
    </>
  );
}


export const AvatarImageMemoized = React.memo((AvatarImage))