import { Grid, Typography } from "@mui/material";

export default function ResponseQrCode({text1= "no text",text2= "no text", color="black", fontSize="20px", className }) {


    return (
        <>
        <Grid container className={className}>
        <Grid item xs={12} sm={12} className={className}>
          <Typography sx={{ color: color, fontSize: fontSize }}>
            {text1}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} className={className}>
          <Typography sx={{ color:color, fontSize: fontSize }}>
            {text2}
          </Typography>
        </Grid>
      </Grid>
      </>
    )
}