import { closeDialogWithBackButton } from "../../redux/reducers/dialogBackButton-reducer";
import { closeSidebar } from "../../redux/reducers/sidebar-reducer";

export default function GeneralBackButton(param, dispatch) {
    const {sidebarState,anchorElement, handleCloseMenu} = param;
    
    /*
    * per permette al backbutton di chiudere una dialog bisogna inserire nel component, uno useSelector che recupera lo slice "dialogBackButton",
    e passare alla useEffect il metodo che gestisce la chiusura della dialog interessata.
    */ 
    let dialog = document.querySelector(".MuiDialog-root")
    if(dialog) return dispatch(closeDialogWithBackButton())

    if(anchorElement) return handleCloseMenu()
    if(sidebarState) return dispatch(closeSidebar())

    
}