import UsersDatatable from "../../components/UsersDatatable/UsersDatatable";

const UsersPanel = () => {

    
    return (
        <>
        <UsersDatatable></UsersDatatable>
        </>
    )
}

export default UsersPanel;