import { createSlice } from '@reduxjs/toolkit';

const initialState = {count:0 , type:"", rowInfo:-1};

const dialogBuildingSlice = createSlice({
    name:'dialogBuildingSlice',
    initialState,
    reducers: {
         openDialogBuilding:(state,payload) => {
             return {...state, count:state.count + 1, type:payload.payload.type, rowInfo:payload.payload.rowInfo}
         },
        closeDialogBuilding:(state) => {
            return state = initialState
        },
    }
});

export const { openDialogBuilding,closeDialogBuilding} = dialogBuildingSlice.actions;

const { reducer } = dialogBuildingSlice;

export default reducer;