import { TextareaAutosize, Typography } from "@mui/material";

export default function CustomNote({
  returnValue,
  min = 4,
  max = 4,
  placeholder = "(opzionale) Aggiungi una nota da inserire nell'email di notifica.",
  style = { width: "99%" },
}) {

  return (
    <>
      <label>
        <Typography sx={{ marginBottom: "2px" }}>Note</Typography>
      </label>
      <TextareaAutosize
        onChange={ev => returnValue(ev.target.value)}
        minRows={min}
        maxRows={max}
        placeholder={placeholder}
        style={style}
        autoFocus
      />
    </>
  );
}
