
import { Grid } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBed, faDoorOpen, faHospital, faLayerGroup } from '@fortawesome/free-solid-svg-icons';

export default function IconResum({informazioniPostazione = {}}) {
const {edificio, zona, stanza, posto, isSalaRiunione} = informazioniPostazione;
  return (
    <>
      <Grid container className="flex-center-center">
        <Grid item  className="flex-center-center icon-margin">
          <FontAwesomeIcon icon={faHospital} color="primary"/> {edificio}
        </Grid>
        <Grid item  className="flex-center-center icon-margin">
          <FontAwesomeIcon icon={faLayerGroup} color="primary" /> {zona}
        </Grid>
        <Grid item className="flex-center-center icon-margin">
          <FontAwesomeIcon icon={faDoorOpen} color="primary"sx={{marginRight:"2px"}}/> {stanza}
        </Grid>
{!isSalaRiunione && <Grid item  className="flex-center-center icon-margin">
        <FontAwesomeIcon icon={faBed} sx={{marginRight:"4px"}}/> {posto}
        </Grid>}
      </Grid>
    </>
  );
}
