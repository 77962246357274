import { createSlice } from '@reduxjs/toolkit';

const initialState = {
DataFine: null,
DataInizio: null,
Edificio: null,
MostraDisabilitazioni : false,
Postazione: null,
RadioButtonUser: true,
Stanza:null,
Zona: null,
utente: null,
};

const filterParametersTuttePrenotazioni = createSlice({
    name:'filterParametersTuttePrenotazioni',
    initialState,
    reducers: {
         saveCompletedFilterParametersTuttePrenotazioni: (state,payload) => {
            return {...state,
                 DataOraFine:payload.payload.DataFine,
                 DataOraInizio:payload.payload.DataInizio,
                 Edificio:payload.payload.Edificio,
                 RadioButtonUser:payload.payload.RadioButtonUser,
                 MostraDisabilitazioni: payload.payload.MostraDisabilitazioni,
                 Postazione: payload.payload.Postazione,
                 Stanza:payload.payload.Stanza,
                 Zona:payload.payload.Zona,
                 utente:payload.payload.utente,
            };
         },
    }
});

export const { saveCompletedFilterParametersTuttePrenotazioni } = filterParametersTuttePrenotazioni.actions;

const { reducer } = filterParametersTuttePrenotazioni;

export default reducer;  