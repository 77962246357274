import { Grid, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import useBackGroundPlanimetria from "../../Hooks/useBackgroundPlanimetria";

export function ShowImgPlanimetria({ idZona = undefined, idStanza = undefined, nomeImgPlanimetria= "planimetria" }) {
  const params = useParams();

  let paramsWithIdEntityFromRow = { ...params, idZona, idStanza };

  let imageBackground = useBackGroundPlanimetria(paramsWithIdEntityFromRow);
  return (
    <React.Fragment>
      <Grid container item>
      <Grid item>
      <label className="labelCustom marginToplabel">
        <Typography> Anteprima planimetria </Typography>
      </label>
      </Grid>
      <Grid xs={12} item>
      <img
        src={imageBackground}
        alt={nomeImgPlanimetria}
        width="auto"
        height="150"
      />
      </Grid>
      </Grid>
    </React.Fragment>
  );
}
