import { AvatarGroup, Grid, Typography } from "@mui/material";
import PannelloDialog from "../../../UI/Pannello/PannelloDialog";
import PannelloConElementiCliccabili from "./PannelliStepper/PannelloConElementiCliccabili";
import { useEffect, useState, Fragment } from "react";
import AvatarImage from "../../../Avatar/AvatarImage";
import { selectUserInMultiUser } from "../../../../../src/redux/reducers/MultiUserInStepper-reducer";
import { reloadPlanimetriaPrenotazione } from "../../../../../src/redux/reducers/planimetria-reducer";
import { useDispatch, useSelector } from "react-redux";
import normalizzaNominativoOspite from "../../../../functions/Ospite/normalizzaNominativoOspite";

export default function LabelPrenotazionePerUtente({
  utentiDaMostrare = [],
  activeStep,
}) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (utentiDaMostrare.length === 1) {
      dispatch(selectUserInMultiUser(utentiDaMostrare[0]));
      dispatch(reloadPlanimetriaPrenotazione());
    }
  }, [utentiDaMostrare]);

  return (
    <>
      {activeStep === 3 && utentiDaMostrare.length > 1 ?      
      <>
      <Grid container>
        <Grid
          item
          xs={12}
          className="flex-center-center"
          sx={{ paddingBottom: "4px" }}
        >
          <PannelloConElementiCliccabili utenti={utentiDaMostrare}/>
       </Grid>
      </Grid>
      </>
      :
      <>
      <Grid container>
       <Grid
         item
         xs={12}
         className="flex-center-center"
         sx={{ paddingBottom: "4px" }}
       >
        <div style={{display:"flex", justifyContent:"center", width:"100%"}} className="boxElementi-Planimetria">
         <span style={{alignSelf:"center", marginRight:"10px"}}>{utentiDaMostrare.length > 1 ? "Prenotazione multipla" : "Prenotazione per " + utentiDaMostrare[0].nominativo} </span> 
          <AvatarGroup max={3} sx={{
            '& .MuiAvatar-root': { width: 35, height: 35, fontSize: 15 },
          }}
          style={{alignSelf:"center"}}>
            {utentiDaMostrare.map((item, key)=> {
            let nominativo = normalizzaNominativoOspite(item.nominativo)
              return (
                <AvatarImage
                key={key} 
                isInputDisabled={true} 
                isCentered={false}
                isUserLoggedImage={false} 
                ImmagineAvatar={item.immagineAvatar} 
                name={nominativo}
                />)
              }
              )}
          </AvatarGroup>
        </div>
       </Grid>
     </Grid>
     </>
      }
    </>
  );
}
