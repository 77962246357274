import PostazioniDatatable from "../../components/PostazioniDatatable/PostazioniDatatable";
const PostazioniPanel = () => {

return (
    <>
    <PostazioniDatatable></PostazioniDatatable>
    </>
)

}

export default PostazioniPanel