import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { stanzaIcon } from "../../../../const/Planimetria/IconPlanimetria";
import { GetStanzeByIdZonaConFiltro } from "../../../../const/urlAction";
import handlerError from "../../../../functions/handlerError";
import useBackGroundPlanimetria from "../../../../Hooks/useBackgroundPlanimetria";
import { hideLoader, showLoader } from "../../../../redux/reducers/appLoader-reducer";
import { saveStanzaCompletoInPrenotazioneStepper, saveStanzaInPrenotazioneStepper } from "../../../../redux/reducers/infoPrenotazione-reducer";
import { notificationOpen } from "../../../../redux/reducers/notification-reducer";
import PlanimetriaPrenotazine from "../../../Planimetria/PlanimetriaPrenotazione/PlanimetriaPrenotazione";

const urlBase = process.env.REACT_APP_URL;
let imageBackground = undefined;

export const StanzaPlanimetriaBoxHome = ({ setStepNoAutoSkipped, framesIndex}) => {
  const dispatch = useDispatch();
  const [dettaglioZona, setDettaglioZona] = useState({});
  const [elementiDalDb, setElementiDalDb] = useState([]);
  const isSalaRiunione = useSelector((state) => state.filter.isSalaRiunione);

  const state = useSelector((state) => state.infoPrenotazione);

  useEffect(() => {
    (async () => {
      if (state.idZona) {
        const { method, action } = GetStanzeByIdZonaConFiltro;

        let url = urlBase + action;
        try {
          const response = await axios({
            method: method,
            url,
            data: { idZona:state.idZona, filter:isSalaRiunione},
            headers: {
              "Content-Type": "application/json",
            },
          });
          setDettaglioZona(response.data.result.dettaglio);
          setElementiDalDb(response.data.result.listaEntita);
          if(response.data.result.listaEntita.length === 1) {
            dispatch(showLoader());
            const { id, sigla, denominazione, isSalaRiunione, postazioniDisponibili } = response.data.result.listaEntita[0]
            actionOnClickIcon(null, id, null, null, postazioniDisponibili)
            dispatch(
              saveStanzaCompletoInPrenotazioneStepper({
                 id,
                sigla,
                denominazione,
                isSalaRiunione,
              })
            );
          } else {
            dispatch(hideLoader());
            setStepNoAutoSkipped((old) => {
              if(old.indexOf(framesIndex) === -1) return [...old,framesIndex]
              return old;
           }) 
          }
        } catch (error) {
          console.error(error);
          dispatch(hideLoader());
          const notificationError = handlerError(error);
          dispatch(notificationOpen(notificationError));
        }
      }
    })();
  }, []);

  function actionOnClickIcon(event, id, index, status, PostazioniDisponibili) {
    if (PostazioniDisponibili === true) {
      dispatch(saveStanzaInPrenotazioneStepper(id));
      dispatch(saveStanzaInPrenotazioneStepper(id));
    } else {
      dispatch(
        notificationOpen({
          message:
            "La stanza selezionata non contiene postazioni. Selezionare un'altra stanza.",
          status: "warning",
        })
      );
      dispatch(hideLoader());
    }
  }

  imageBackground = useBackGroundPlanimetria(state);

  function handleAutoCompleteSelected(selectedValue) {
    if (selectedValue !== null && Object.keys(selectedValue).length > 0) {
      actionOnClickIcon(null, selectedValue.id);
    }
  }

  return (
    <>
      {imageBackground !== "" && (
        <PlanimetriaPrenotazine
          dettaglioSfondoPlanimetria={dettaglioZona}
          elementiDalDb={elementiDalDb}
          imageBackground={imageBackground}
          IconsToCreate={stanzaIcon}
          actionOnClickButton={actionOnClickIcon}
        />
      )}
      {/* <div style={{paddingTop:"10px"}}>
      <AutocompleteCustom label="Stanza" field={["denominazione"]} checkField="id" optionsAutoComplete={elementiDalDb} handleValueReturned={handleAutoCompleteSelected}/>
      </div> */}
    </>
  );
};
