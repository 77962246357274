import {
  Avatar,
  Button,
  Divider,
  Grid,
  Paper,
  Skeleton,
  Typography,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import "../Planner.styles.css";
import "./RowPlanner.styles.css";
import { useDispatch } from "react-redux";
import { StackedBarChart } from "../../Charts/StackedBarChart";
import { Chart as ChartJS } from "chart.js/auto";
import { Chart } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { Box, fontWeight } from "@mui/system";
import { blue } from "@mui/material/colors";
import calcPercentageOnPlannerBar from "../../../functions/planner/calcPercentageOnPlannerBar";
import React, { Fragment, useEffect, useState } from "react";
import calcMarginPlannerBar from "../../../functions/planner/calcMarginPlannerBar";
import { Key, Translate } from "@mui/icons-material";
import MenuContextPlanner from "../componentsPlanner/MenuContextPlanner";
import convertToHoursAndMinutes from "../../../functions/Utils/convertToHoursAndMinutes";
import { v4 as uuidv4 } from "uuid";
import { useRef } from "react";
import { itemSizeReactWindow } from "../componentsPlanner/UserTablePlanner";
import AvatarImage, { AvatarImageMemoized } from "../../Avatar/AvatarImage";
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import PlannerTable from "./TableRowPlanner";


/**Component della card con dati di prenotazione (formato esteso per desktop)*/
let maxOreOnAllPlanner = 0;
let numberOfSlice = 2; //riunione,postazione

export const heightDefaultComponent = 65;

export const typePlanner = { postazione: 1, riunione: 0 };

const listaOrePostazione = [];
const labels = [];
const listaOreSalaRiunione = [];
let skeletonWidth = 0;

function RowPlanner({
  datiUtenteSingolo,
  anno,
  mese,
  holidays = [],
  style,
  isScrolling = false,
}) {
  const state = useSelector((state) => state.userDate);

  const [listaOre, setListaOre] = useState([]);

  const [listaOreWithInfo, setListaOreWithInfo] = useState([]);
  const [dataPlanner, setDataPlanner] = useState({});


  const containerRef = useRef(null);

  const configuration = useSelector(
    (state) => state.configurations.configurazioniGlobali
  );
  const { plannerDomenicaFeriale, plannerSabatoFeriale } = configuration;

  useEffect(() => {
    let listaOre = [];
    for (var key in datiUtenteSingolo.prenotazioniMese) {
      if (datiUtenteSingolo.prenotazioniMese.hasOwnProperty(key)) {
        var date = new Date(anno, mese, key);
        var holidaysDate = holidays.map((x) => new Date(x)) ?? [];
        var weekendOrHoliday =
          (plannerDomenicaFeriale && date.getDay() === 0) ||
          (plannerSabatoFeriale && date.getDay() === 6) ||
          holidaysDate.some((x) => x.getTime() === date.getTime());
        var orePostazione = convertToHoursAndMinutes(
          datiUtenteSingolo.prenotazioniMese[key].orePostazione
        );
        var oreRiunione = convertToHoursAndMinutes(
          datiUtenteSingolo.prenotazioniMese[key].oreSalaRiunione
        );

        let maxOreOnData = Math.max(
          datiUtenteSingolo.prenotazioniMese[key].orePostazione ?? 0,
          datiUtenteSingolo.prenotazioniMese[key].oreSalaRiunione ?? 0
        );
        maxOreOnAllPlanner = Math.max(maxOreOnAllPlanner, maxOreOnData);

        listaOre.push({
          label: key,
          data: date,
          isWeekendOrHoliday: weekendOrHoliday,
          allTimeInMinutesPostazione:
            datiUtenteSingolo.prenotazioniMese[key].orePostazione,
          allTimeInMinutesRiunione:
            datiUtenteSingolo.prenotazioniMese[key].oreSalaRiunione,
          orePostazione: {
            ore: orePostazione?.hours,
            minuti: orePostazione?.minutes,
          },
          oreRiunione: {
            ore: oreRiunione?.hours,
            minuti: oreRiunione?.minutes,
          },
          percentageOrePostazione: 0,
          percentageOreRiunione: 0,
        });
      }
    }

    setListaOre(listaOre);
  }, [datiUtenteSingolo]);

  useEffect(() => {
    setDataPlanner({
      labels: labels,
      datasets: [
        {
          barThickness: 20,
          stack: "ore",
          label: "Ore impiegate in postazioni",
          data: listaOrePostazione,
          backgroundColor: "#416AB4",
        },
        {
          barThickness: 20,
          stack: "ore",
          label: "Ore impiegate in sale riunioni",
          data: listaOreSalaRiunione,
          backgroundColor: "#1D4890",
        },
      ],
      listaOre: listaOre,
    });
  }, [listaOreWithInfo]);


  useEffect(() => {
    setListaOreWithInfo(
      listaOre.map((x) => {
        let percentageOrePos = calcPercentageOnPlannerBar(
          x.allTimeInMinutesPostazione,
          maxOreOnAllPlanner,
          numberOfSlice
        );
        let percentageOreRiun = calcPercentageOnPlannerBar(
          x.allTimeInMinutesRiunione,
          maxOreOnAllPlanner,
          numberOfSlice
        );
        x.marginPlannerBar = calcMarginPlannerBar(
          percentageOrePos,
          percentageOreRiun
        );
        x.percentageOrePostazione = percentageOrePos;
        x.percentageOreRiunione = percentageOreRiun;
        return x;
      })
    );
  }, [listaOre]);


  return  (
    <>
      <Paper
        key={uuidv4()}
        className="row-planner no-box-shadow "
        style={style}
      >
        <Grid container item sx={{ height: "92px"  }} className="box-shadow-light">
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            lg={2}
            xl={2}
            className="flex-center-center planner-card-left-section "

          >
            <div style={{margin:"10px"}}>
              <AvatarImageMemoized name={datiUtenteSingolo.nome + " " +datiUtenteSingolo.cognome} isInputDisabled={true} 
              ImmagineAvatar={datiUtenteSingolo.immagineAvatar} isCentered={false} isUserLoggedImage={false} />
            </div>
            <Typography
              sx={{
                color: "#026D82",
                fontWeight: 650,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span style={{ fontSize: "15px" }}>
                {datiUtenteSingolo.cognome} {datiUtenteSingolo.nome}
              </span>
            </Typography>
            <Divider></Divider>
          </Grid>
          <PlannerTable dataPlanner={dataPlanner} heightDefaultComponent={heightDefaultComponent} />
        </Grid>
      </Paper>
    </>
  );
}

export default React.memo(RowPlanner);
