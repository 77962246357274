import { Typography as Typo } from "@mui/material";

export const typoStyle= {fontSize:"12px", color:"#026D82"}
export const testoDisabilita = <div><Typo sx={typoStyle}>Permette di disabilitare tutte le postazioni relative a questo livello,
                                           annullando tutte le prenotazioni in corso o programmate.
                                           Contestualmente, verrà inviata un'email di avviso ai responsabili dell'edificio e agli utenti coinvolti.</Typo></div>;
                                           
export const testoRiabilita = <Typo>Vuoi riabilitare tutte le postazioni relative a questo livello?</Typo>;

export const testoDisabilitaOne = (posto) =>{
    return <Typo sx={typoStyle}>Permette di disabilitare la <b>Postazione {posto}</b>, annullando tutte le prenotazioni in corso o programmate.
    Contestualmente, verrà inviata un'email di avviso ai responsabili dell'edificio e agli utenti coinvolti.</Typo>
}
    
export const testoRiabilitaOne = (posto) => {

    return <>Vuoi riabilitare la <b>Postazione {posto}</b>?</>;
} 



