
import { Typography } from "@mui/material";
import { CalendarViewMode } from "../../../const/calendarViewMode";
import { enumViewModePrenotazioni } from "../../../const/prenotazioni/enumViewMode";
import {
  DailyHourSlot,
  DayofWeekandMonth,
  DayofWeekandMonthVisibile,
} from "../../../functions/formatDate";

import "./BookingTimeline.styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBed, faBriefcaseMedical } from "@fortawesome/free-solid-svg-icons";

/**renderizza un segmento della timeline per la visualizzazione della lista prenotazioni modalità desktop nella dashboard*/
export function BookingTimelineSegment({
  prenotazioni,
  index,
  showDateLabel,
  viewMode,
  isOpenAdvancedSearch = false,
}) {
  /**stampa la fascia oraria del giorno o il giorno del mese
   * a seconda della modalità visualizzazione giornaliera o mensile*/
  function printDayOrHour() {

    if (viewMode === enumViewModePrenotazioni.AdvancedFilter) {
      return DayofWeekandMonthVisibile(
        prenotazioni[index].dataInizioPrenotazione
      );
    } else if (viewMode === enumViewModePrenotazioni.daily) {
      return DailyHourSlot(prenotazioni[index].dataInizioPrenotazione);
    } else if (viewMode === enumViewModePrenotazioni.Month) {
      return DayofWeekandMonth(prenotazioni[index].dataInizioPrenotazione);
    }
  }

  return (
    <div className="timeline-container">
      <div className="bullet-date-label">
        <Typography sx={{ fontWeight: "800" }}>
          {/*fascia ora/giorno su tutti i bullet in mod.giornaliera, solo su primo del giorno mod.mensile*/}
          {viewMode === CalendarViewMode.daily || showDateLabel
            ? printDayOrHour()
            : ""}
        </Typography>
      </div>

      <BookingTimelineBullet
        icon={
          <TimelineIcon
            isRoom={prenotazioni[index].isSalaRiunione}
            isDisabilitazione={prenotazioni[index].disabilitato}
          />
        }
        position={getRelativePosition(index, prenotazioni)}
      />
    </div>
  );
}

/**renderizza un bullet point con icona impostabile
 * nb: icon deve essere un component es: \<FontAwesomeIcon icon={faBed} /\>,
 * position è la posizione relativa dell'oggetto in lista (vedi: TimelinePosition) */
export function BookingTimelineBullet({ icon, position }) {
  return (
    <div className="timeline-bullet-segment ">
      {position !== TimelinePosition.first &&
      position !== TimelinePosition.only ? (
        <div className="timeline-connector timeline-connector-top"></div>
      ) : (
        <div className="timeline-connector-hide"></div>
      )}

      {icon || <div className="default-timeline-bullet"></div>}

      {position !== TimelinePosition.last &&
      position !== TimelinePosition.only ? (
        <div className="timeline-connector timeline-connector-bottom"></div>
      ) : (
        <div className="timeline-connector-hide"></div>
      )}
    </div>
  );
}

/**renderizza connettore con icona sala/postazione nella timeline verticale delle prenotazioni */
export function BookingTimelineConnector() {
  return <div className="timeline-connector"></div>;
}

/**dato un indice ed un array di elementi, restituisce la posizione relativa dell'indice nell'array (first=1,last=2,only=3 o default=0)*/
export function getRelativePosition(index, array) {
  switch (true) {
    case array.length === 1:
      return TimelinePosition.only; //3
    case index === 0:
      return TimelinePosition.first; //1
    case index === array.length - 1:
      return TimelinePosition.last; //2
    default:
      return TimelinePosition.default; //0
  }
}

/**lista di posizioni relative di un elemento nell'array di appartenenza ( first=1,last=2,only=3 o default=0 )*/
export class TimelinePosition {
  static default = 0;
  static first = 1;
  static last = 2;
  static only = 3; //unico elemento in lista
}

/**renderizza icona corrispondente a sala o postazione*/
export function TimelineIcon({ isRoom, isDisabilitazione = false }) {
  return (
    <>
      {isRoom ? (
        <div
          className={
            isDisabilitazione
              ? "timeline-icon-demo tl-icon-fill-disabled flex-center-center"
              : "timeline-icon-demo tl-icon-light flex-center-center"
          }
        >
          <FontAwesomeIcon icon={faBriefcaseMedical} style={{fontSize:"18px"}}  />
        </div>
      ) : (
        <div
          className={
            isDisabilitazione
              ? "timeline-icon-demo-bed tl-icon-fill-disabled flex-center-center"
              : "timeline-icon-demo-bed tl-icon-fill flex-center-center"
          }
        >
          <FontAwesomeIcon icon={faBed} style={{fontSize:"11px"}} />
        </div>
      )}
    </>
  );
}
