import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { dialogSignInUser } from "../../../const/DialogData";
import { labelArrayDialogUserSignIn } from "../../../const/RadioButton/RadioButtonListRadio";
import { ListTabsDialogUserSignIn } from "../../../const/Tabs/Dialog/DialogUser/DialogUserSignIn/ListTabsDialogUserSignIn";
import AvvisoFormMessage from "../../UI/Form/AvvisoFormMessage";
import MessageNoProvider from "../../UI/MessageNoProvider/MessageNoProvider";
import RadioButtonWithTabs from "../../UI/RadiobuttonCustom.js/RadioButtonWithTabs/RadioButtonWithTabs";

export default function DialogSignIn({isOpen = false, returnIsOpen = () => null}) {
  const dialogState = useSelector((state) => state.dialogUser);

  useEffect(() => {
    returnIsOpen(dialogState.isOpen)
  },[dialogState])
  
  return (
    <>
      <Dialog
        open={isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"sm"}
      >
          <DialogContent className="border-Content-dialog">
            <DialogTitle sx={{ paddingLeft: "0" }}>
              {dialogSignInUser.DialogTitle}
            </DialogTitle>
            <AvvisoFormMessage msg={"A seguito della richiesta verrà inviata un email con un link di conferma."} />
            <RadioButtonWithTabs
              GroupName={"Tipo di autenticazione"}
              tabPanel={ListTabsDialogUserSignIn()}
              labelList={labelArrayDialogUserSignIn}
              startValueReset={labelArrayDialogUserSignIn[0]}
              noTabsComponent={<MessageNoProvider />}
            />
          </DialogContent>
      </Dialog>
    </>
  );
}
