import { Typography } from "@mui/material"
import { Box } from "@mui/system"
import React from "react"
import { gestioneLunghezzaFrasi } from "../../../../functions/gestioneLunghezzaFrasi"


export default function InfoZona({infoElement}) {




    return(
        <React.Fragment>
            <Box sx={{paddingBottom:"5px"}}>
            <Typography sx={{display:"flex", alignItems:"center", justifyContent:"center"}}><b>{infoElement.sigla}</b></Typography>
            <Typography sx={{display:"flex", alignItems:"center", justifyContent:"center"}}><i>{gestioneLunghezzaFrasi(infoElement.denominazione)}</i></Typography>
            </Box>
        </React.Fragment>
    )
}