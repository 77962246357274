import axios from "axios";
import React, { useState, useEffect } from "react";

const urlBase = process.env.REACT_APP_URL;

export default function useGetAvatarUserLogged() {
  const [avatarImage, setAvatarImage] = useState(null);
  useEffect(() => {
      (async () => {
        let url = urlBase + "Account/GetAvatar";

        try {
          const response = await axios({
            method: "get",
            url,
          });

          if (response.data) {
            setAvatarImage(response.data.result);
          }
        } catch (error) {
          setAvatarImage(null);
          console.error(error);
        }
      })();
    return () => {
      setAvatarImage(null);
    };
  }, []);

  return avatarImage;
}
