//scarta tutta la configurazione in un obj con tutte le proprieta
export default function handlerConfiguration(configurations) {
    let result ;
  let keys = Object.keys(configurations);

   result = keys.map((key) => {
    return configurations[key];
  });


  if(result && typeof result[0] === typeof {}) {
    let array = {}
    result.map((obj, index) => {
        let keys = Object.keys(obj)
 keys.map((key) => {
            array = {...array,[key]:obj[key]}
        })
    })
    result = array
  }

  return result;
}



function prova(obj) {
    let keys = Object.keys(obj);

    let result = keys.map((key) => {
       return obj[key];
     });

     return result;
}