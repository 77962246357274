import { roRO } from "@mui/material/locale";
import { createSlice } from "@reduxjs/toolkit";
import {
  idSalaRiunione,
  ospiti,
} from "../../components/Planimetria/PlanimetriaPrenotazione/PartecipantiRiunione/PartecipantiRiunione";
import { object } from "yup";
import { useDispatch } from "react-redux";
import { radioButtonPrenotazioneStepper } from "../../const/RadioButton/RadioButtonListRadio";

const now = new Date(Date.now());

export const initialState = {
  dataGiorno: new Date(now.setDate(now.getDate() + 1)).toString(),
  dataInizio: "09:00",
  dataFine: "18:00",
  ripetizioneGiorni: [],
  ripetizioneDataFine: new Date(
    new Date().setDate(new Date().getDate() + 1)
  ).toString(),
  ripetizioneStatus: false,
  prenotazione: false,
  utentePrenotazione: [],
  statoTastoInvio: true,
  postazioneId: 0,
  radioButtonPrenotazionePerAltri: radioButtonPrenotazioneStepper[0].value,
  initialStateConfigured: false,
  partecipantiRiunione: {},
  titoloRiunione: {},
  invioNotificaInvito: {},
  ospitiPrenotazioneInfo: [],
};

const prenotazioneStepperSlice = createSlice({
  name: "prenotazioneStepper",
  initialState,
  reducers: {
    returnDataGiorno: (state, payload) => {
      return { ...state, dataGiorno: payload.payload };
    },
    returnDateInizio: (state, payload) => {
      return { ...state, dataInizio: payload.payload };
    },
    returnDateFine: (state, payload) => {
      return { ...state, dataFine: payload.payload };
    },
    returnRipetizioneStatus: (state, payload) => {
      return { ...state, ripetizioneStatus: payload.payload };
    },
    returnRipetizioneGiorni: (state, payload) => {
      return { ...state, ripetizioneGiorni: payload.payload };
    },
    returnPostazionePrenotata: (state, payload) => {
      return { ...state, postazioneId: payload.payload };
    },
    resetPostazionePrenotata: (state, payload) => {
      state.utentePrenotazione.map((x) => {
        if (
          x.utente === payload.payload.utente &&
          payload.payload.nominativo === x.nominativo &&
          x.ospite === payload.payload.ospite
        ) {
          x.posto = null;
          x.idStanza = null;
          x.idPosto = 0;
        }
        return x;
      });
    },
    returnRipetizioneDataFine: (state, payload) => {
      return { ...state, ripetizioneDataFine: payload.payload };
    },
    returnUtentePrenotazione: (state, payload) => {
      //si usa per la multiplaPrenotazione
      state.utentePrenotazione = payload.payload;
    },
    resetPostazioniPrenotate: (state) => {
      state.utentePrenotazione.map(
        (x) => ((x.posto = null), (x.idPosto = null))
      );
    },
    resetUtentePrenotazione: (state) => {
      state.utentePrenotazione = [];
    },
    deleteUtenteFromPrenotazione: (state, payload) => {
      const filteredUtentePrenotazione = state.utentePrenotazione.filter(
        (x) => {
          const isFiltered =
            x.nominativo !== payload.payload.nominativo ||
            x.ospite !== payload.payload.ospite ||
            x.utente !== payload.payload.utente.idUtente;
          return isFiltered;
        }
      );
      state.utentePrenotazione = filteredUtentePrenotazione;
      return state;
    },
    procediPrenotazione: (state, payload) => {
      return { ...state, prenotazione: payload.payload };
    },
    changeInitialStateConfigured: (state) => {
      return { ...state, initialStateConfigured: true };
    },
    cambiaStatoTastoPrenotazione: (state, payload) => {
      return { ...state, statoTastoInvio: payload.payload };
    },
    cambiaRadioButtonPrenotazionePerAltri: (state, payload) => {
      return { ...state, radioButtonPrenotazionePerAltri: payload.payload };
    },
    resetPrenotazione: () => {
      return initialState;
    },
    setPartecipantiRiunione: (state, payload) => {
      let id = payload.payload[idSalaRiunione];
      let ospitiSalaRiunione = payload.payload[ospiti];
      let newObj = {
        ...state.partecipantiRiunione,
        [`${id}`]: ospitiSalaRiunione,
      };
      state.partecipantiRiunione = newObj;
      return state;
    },
    setInvioNotificaInvito: (state, payload) => {
      const { idPosto, invioNotificaInvito } = payload.payload;
      return {
        ...state,
        invioNotificaInvito: {
          ...state.invioNotificaInvito,
          [idPosto]: invioNotificaInvito,
        },
      };
    },
    setTitoloRiunione: (state, payload) => {
      let newObj = {
        ...state.titoloRiunione,
        [payload.payload.idPosto]: payload.payload.titoloRiunione,
      };
      state.titoloRiunione = newObj;

      return state;
    },

    setOspitiPrenotazioneInfo: (state, payload) => {
      const { ospitiPrenotazioneInfo } = state;
      const { nominativo, utente } = payload.payload;

      // Controllo se nominativo(payload.payload) è un'email' e se esiste già un ospite con lo stesso valore
      if (
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(nominativo) &&
        ospitiPrenotazioneInfo.some(
          (ospite) => ospite.nominativo === nominativo
        )
      ) {
        return state;
      }
      // Controllo se la coppia nominativo e utente associato esiste già
      if (
        ospitiPrenotazioneInfo.some(
          (ospite) =>
            ospite.utente.idUtente === utente.idUtente &&
            ospite.nominativo === nominativo
        )
      ) {
        return state;
      }
      
      let newArr = [...state.ospitiPrenotazioneInfo, payload.payload];
      state.ospitiPrenotazioneInfo = newArr;

      return state;
    },
    deleteOspitiPrenotazioneInfo: (state, payload) => {
      const { nominativo, utente } = payload.payload;
      let filteredArray = state.ospitiPrenotazioneInfo.filter(
        (ospite) => {
          return ospite.nominativo !== nominativo ||
          ospite.utente.idUtente !== utente.idUtente}
      );
      state.ospitiPrenotazioneInfo = filteredArray;
      return state;
    },
  },
});

//da mettere alla chiusura
export const ControlloRipetizioneStatus = (payload) => (dispatch) => {
  if (!payload) {
    dispatch(returnRipetizioneGiorni([]));
    dispatch(
      returnRipetizioneDataFine(
        new Date(new Date().setDate(new Date().getDate() + 1)).toString()
      )
    );
  }
  dispatch(returnRipetizioneStatus(payload));
};

export const resetPrenotazioneWithDefaultConfiguration =
  (configuration) => (dispatch) => {
    const { orarioInizioDefault, orarioFineDefault } = configuration;
    dispatch(resetPrenotazione());
    dispatch(returnDateInizio(orarioInizioDefault));
    dispatch(returnDateFine(orarioFineDefault));
  };

//gestisce l'array di utenti da prenotare nella Stepper di prenotazione
export const handlerUtentiPrenotazione = (payload) => (dispatch, getState) => {
  let arrUtenti = getState().prenotazioneStepper.utentePrenotazione;

  const indexUtente = arrUtenti.findIndex(function (utente) {
    return (
      utente.utente === payload.utente &&
      utente.nominativo === payload.nominativo &&
      utente.ospite === payload.ospite
    );
  });

  arrUtenti = arrUtenti.map((x, index) => {
    if (index === indexUtente) {
      return {
        ...x,
        posto: payload.posto,
        idStanza: payload.idStanza,
        idPosto: payload.id,
        ospite: payload.ospite,
      };
    }
    return x;
  });

  dispatch(returnUtentePrenotazione(arrUtenti));
};

export const handlerSelectUtenti = (payload) => (dispatch, getState) => {
  let arrUtenti = getState().prenotazioneStepper.utentePrenotazione;
  let arrUtentiUpdated = payload.map((x) => {
    let el = arrUtenti.find(
      (obj) =>
        obj.utente === x.utente &&
        obj.nominativo === x.nominativo &&
        obj.ospite === x.ospite
    );
    if (el) {
      return {
        ...x,
        posto: el.posto,
        idPosto: el.idPosto,
        idStanza: el.idStanza,
      };
    }
    return x;
  });
  dispatch(returnUtentePrenotazione(arrUtentiUpdated));
};

// gestisce la sincronizzazione tra "utentePrenotazione" e "ospitiPrenotazioneInfo".
export const deleteOspitiFromPlanimetriaStepper =
  (payload) => (dispatch, getState) => {
    dispatch(deleteUtenteFromPrenotazione(payload));
    let utentePrenotazione = getState().prenotazioneStepper.utentePrenotazione;

    let hasUtentePrenotazione = utentePrenotazione.some((x) => {
      return (
        x.nominativo === payload.nominativo &&
        x.ospite === payload.ospite &&
        x.utente === payload.utente
      );
    });

    if (!hasUtentePrenotazione) {
      dispatch(
        deleteOspitiPrenotazioneInfo({
          nominativo: payload.nominativo,
          utente: payload.utente
        })
      );
    }
  };

//gestisce l'inserimento degli ospiti come prenotazioni
export const handlerOspitiPrenotazione = () => (dispatch, getState) => {
  const ospiti = getState().prenotazioneStepper.ospitiPrenotazioneInfo;

  let ospitiPrenotazione = ospiti.map((x) => {
    return {
      utente: x.utente.idUtente,
      nominativo: x.nominativo,
      idStanza: null,
      posto: null,
      immagineAvatar: null,
      ospite: true,
    };
  });

  const arrUtenti = getState().prenotazioneStepper.utentePrenotazione;

  const arrUtentiFiltered = ospitiPrenotazione.filter((x) => {
    return !arrUtenti.some(
      (y) =>
        y.nominativo === x.nominativo &&
        y.ospite === x.ospite &&
        y.utente === x.utente
    );
  });
  const arrUtentiUpdate = [...arrUtenti, ...arrUtentiFiltered];
  dispatch(returnUtentePrenotazione(arrUtentiUpdate));
};


export const gestisciDatiPrenotazioneDisponibilita = ( payload, state) => (dispatch) => {  
  dispatch(returnDataGiorno(payload.dataGiorno.toString()))
  dispatch(returnDateInizio(payload.dataInizio))
  dispatch(returnDateFine(payload.dataFine))
    // console.log(payload)
}


export const {
  returnDataGiorno,
  returnDateInizio,
  returnDateFine,
  returnRipetizioneStatus,
  returnRipetizioneGiorni,
  returnRipetizioneDataFine,
  procediPrenotazione,
  returnUtentePrenotazione,
  resetPrenotazione,
  changeInitialStateConfigured,
  returnPostazionePrenotata,
  deleteUtenteFromPrenotazione,
  resetPostazionePrenotata,
  cambiaStatoTastoPrenotazione,
  cambiaRadioButtonPrenotazionePerAltri,
  setPartecipantiRiunione,
  setInvioNotificaInvito,
  setTitoloRiunione,
  resetUtentePrenotazione,
  resetPostazioniPrenotate,
  setOspitiPrenotazioneInfo,
  deleteOspitiPrenotazioneInfo,
} = prenotazioneStepperSlice.actions;

const { reducer } = prenotazioneStepperSlice;

export default reducer;
