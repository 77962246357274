import moment from "moment";
import { initialState } from "../../components/MenuCollapse/reducerMenuCollapse/filterHistoryDatatable-reducer";
import { enumViewModePrenotazioni } from "../../const/prenotazioni/enumViewMode";

const sortField = "DataOraInizioPrenotazione"; //la proprietà deve essere nell'entità prenotazione sul be.
export const SortDirection = {DESC: 0,ASC: 1}

export default function createBodyRequestPrenotazioni(
  selectedDate,
  endSelectedDate,
  filtriFromAdvancedFilter,
  prenotazioniViewMode,
  dataTableParams,
) {
  let body;

  let isCalendarView = prenotazioniViewMode < enumViewModePrenotazioni.AdvancedFilter;


  if(isCalendarView) {
    body = {
      pageIndex: dataTableParams.pageIndex,
      pageSize: dataTableParams.pageSize,
      sortColumnName: sortField,
      sortDirection:  SortDirection.ASC,
      applyPaginationAndSort: true,
      DataInizio: selectedDate,
      DataFine: endSelectedDate,
      MostraSoloPrenotazioniPersonali: true,
      MostraDisabilitazioni: false,
      PrenotazioniPassate: true,      
    };
  }
  else {
    
    if(!filtriFromAdvancedFilter) return null;

    body = {
      pageIndex: dataTableParams.pageIndex,
      pageSize: dataTableParams.pageSize,
      sortColumnName: sortField,       
      sortDirection: filtriFromAdvancedFilter.OrderByDateAsc ? SortDirection.ASC : SortDirection.DESC,          
      applyPaginationAndSort: true,
      DataInizio: filtriFromAdvancedFilter.DataInizio ? filtriFromAdvancedFilter.DataInizio && moment(filtriFromAdvancedFilter.DataInizio).format("YYYY-MM-DDTHH:mm:ss") : null,
      DataFine: filtriFromAdvancedFilter.DataFine ? filtriFromAdvancedFilter.DataFine && moment(filtriFromAdvancedFilter.DataFine).format("YYYY-MM-DDTHH:mm:ss") : null,
      // DataInizio: filtriFromAdvancedFilter.DataOraInizio ? filtriFromAdvancedFilter.DataOraInizio && moment(filtriFromAdvancedFilter.DataOraInizio).format("YYYY-MM-DDTHH:mm:ss") : null,
      // DataFine: filtriFromAdvancedFilter.DataOraFine ? filtriFromAdvancedFilter.DataOraFine && moment(filtriFromAdvancedFilter.DataOraFine).format("YYYY-MM-DDTHH:mm:ss") : null,
      idUtente: filtriFromAdvancedFilter.utente?.idUtente || null,
      IdPostazione: filtriFromAdvancedFilter.Postazione?.id,
      MostraSoloPrenotazioniPersonali: filtriFromAdvancedFilter.RadioButtonUser,
      IdStanza: filtriFromAdvancedFilter.Stanza?.id,
      IdZona: filtriFromAdvancedFilter.Zona?.id,
      IdEdificio: filtriFromAdvancedFilter.Edificio?.id,
      MostraDisabilitazioni: filtriFromAdvancedFilter.MostraDisabilitazioni,
      PrenotazioniPassate: filtriFromAdvancedFilter.PrenotazioniPassate,
    };
  }

  return body;
}