import DialogViewEditUserLocalDatabase from "../../../../../components/Dialog/UserDialog/componentUserDialog/viewUserDialog/EditUserView/DialogViewEditUserLocalDatabase";
import DialogViewEditUserActiveDirectory from "../../../../../components/Dialog/UserDialog/componentUserDialog/viewUserDialog/EditUserView/DialogViewEditUserActiveDirectory";
import { labelArrayDialogUserAddEdit } from "../../../../RadioButton/RadioButtonListRadio";
import { useSelector } from "react-redux";


export function ListTabsDialogoUserEdit(infoUser,authConfiguration, returnValue) {
  let arrayTabs = []

  if(authConfiguration.enableActiveDirectoryAuth) arrayTabs.push({ componentTab: <DialogViewEditUserActiveDirectory infoUser={infoUser} returnValue={returnValue}/>, label: labelArrayDialogUserAddEdit[0]})
  if(authConfiguration.enableDatabaseAuth) arrayTabs.push({ componentTab: <DialogViewEditUserLocalDatabase infoUser={infoUser} returnValue={returnValue} />, label: labelArrayDialogUserAddEdit[1] })

  return arrayTabs;
  }