import { dialogPageTypes } from "../../../const/dialogPageTypes";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect, useState, Fragment } from "react";
import {
  addBuilding,
  editAccount,
  editBuilding,
  deleteBuilding,
  getBuilding,
  getUsersFiltered,
  getUsers,
} from "../../../const/urlAction";
import axios from "axios";
import * as yup from "yup";
import {
  dialogAddUser,
  dialogEditBuilding,
  dialogButtonGeneral,
  dialogButtonConfirm,
  changeStatusBuildingTextDialog,
  changeStatusBuildingTitleDialog,
  dialogAddBuilding,
} from "../../../const/DialogData";
import { notificationOpen } from "../../../redux/reducers/notification-reducer";
import { reloadingDataTableBuildings } from "../../../redux/reducers/dataTableBuildings-reducer";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
//import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import handlerError from "../../../functions/handlerError";
import { loadUsersState } from "../../../redux/reducers/usersSlice-reducer";
import handlerMultiViewComponent from "../../../functions/Utils/handlerMultiViewComponent";
import { listOfDialogBuildViewComponent } from "../../../const/Dialog/Building/listOfDialogBuildViewComponent";

const urlBase = process.env.REACT_APP_URL;

export default function BuildingDialogRefactored({
  clicked,
  rowInfo,
}) {
  const [users, setUsers] = useState([]);
  const [stateDialog, setStateDialog] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const backButtonState = useSelector((state) => state.dialogBackButton);
  const dispatch = useDispatch();

  useEffect(() => {
    if (clicked.count > 0) setStateDialog(true);
  }, [clicked.count]);


  const handleChangeUserSelect = (event, value) => {
    setSelectedUsers(value);
  };

  //Gestisce il cambio di Datatable con la Dialog Aperta, la chiude e il dialogPageType impedisce di far partire la chiamata
  useEffect(() => {
    if (stateDialog) setStateDialog(false)
  }, [window.onhashchange]);

  //si occupa di gestire la chiusura della dialog attraverso il backButton
  useEffect(() => {
    if (stateDialog) setStateDialog(false)
  }, [backButtonState]);

  //recupera la lista degli utenti
  useEffect(() => {
    let isMounted = true;
    (async () => {
      const { action, method } = getUsersFiltered;
      let url = urlBase + action;

      try {
        const response = await axios({
          method: method,
          url,
        });
        if (isMounted) {
          setUsers(response.data.result);
        }
      } catch (error) {
        console.error(error);
        const notificationError = handlerError(error);
        dispatch(notificationOpen(notificationError));
      }
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    setSelectedUsers([]);
  }, [stateDialog]);

  const handlerBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setStateDialog(false)
    }
  };

  let PlaceHolderViewDialogBuild = handlerMultiViewComponent(
    listOfDialogBuildViewComponent,
    clicked.type
  );

  return (
    <>
      <PlaceHolderViewDialogBuild
        rowInfo={rowInfo}
        handlerBlur={handlerBlur}
        users={users}
        handleChangeUserSelect={handleChangeUserSelect}
        selectedUsers={selectedUsers}
        returnIsOpen={setStateDialog}
        isOpen={stateDialog}
      />
    </>
  );
}
