import React, { useState, useEffect } from "react";
import axios from "axios";
import { dialogPageTypes } from "../../const/dialogPageTypes";
import { Grid, Button, Divider, Typography, Tooltip } from "@mui/material";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import TableToolbar from "../UI/TableToolbar/TableToolbar";
import { styled } from "@mui/material/styles";
import { CDCard, CDCardContent, CDCardHeader } from "../../const/CDCardStyles";
import CheckValidSortModel from "../../functions/CheckValidSortModel";
import { UsersHeader, UsersHeaderMobile } from "../../const/DatatableData";
import {
  getUsersPaginated,
  exportUsers,
  getUserRoles,
} from "../../const/urlAction";
import AdvancedFilterPanel, {
  AdvancedFilterScissor,
  MenuCollapse,
} from "./AdvancedFilterPanel";
import { useSelector, useDispatch } from "react-redux";
import {
  openEmptyDialogUser,
  setPageType,
} from "../../redux/reducers/dialogUser-reducer";
import UserDialog from "../Dialog/UserDialog/UserDialog";
import handlerError from "../../functions/handlerError";
import { notificationOpen } from "../../redux/reducers/notification-reducer";
import { loadUserRolesState } from "../../redux/reducers/userRolesSlice-reducer";
import Notification from "../UI/Notification/Notification";
import { getUsers } from "../../const/urlAction";
import { loadUsersState } from "../../redux/reducers/usersSlice-reducer";
import { removeDataGridHeader } from "../../functions/handlerDataGrid";
import AddIcon from "@mui/icons-material/Add";
import { TitleDatable } from "../TitleDatable/TitleDataTable";
import SynchronizeButton from "./customButton/SynchronizeButton";
import useTaskAsyncRun from "../../Hooks/TaskAsync/useTaskAsyncRun";

const rowHeight = 120;
const mobileLimitWidth = 1000;
const typePage = "Utenti";
const urlBase = process.env.REACT_APP_URL;

const UsersDatatable = () => {
  const dispatch = useDispatch();
  const viewState = useSelector((state) => state.viewState);
  const user = useSelector((state) => state.userDate);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const state = useSelector((state) => state.userDate.policies);
  const reloading = useSelector((state) => state.dataTableUsers);
  const [searchFilter, setSearchFilter] = useState("");
  const [searchStatus, setSearchStatus] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rows, setRows] = useState([]);
  const [expandedState, setExpandedState] = useState(false);
  const [rowsCount, setRowsCount] = useState(rows.length);
  const [loading, setLoading] = useState(false);
  const [sortModel, setSortModel] = useState([
    {
      field: UsersHeader.initialFieldName,
      sort: UsersHeader.initialFieldOrder,
    },
  ]);
  const [filterParameters, setFilterParameters] = useState(true);

  const StyledDataGrid = removeDataGridHeader(DataGrid, viewState);

  const handleFilterData = (radioUserEnabled) => {
    setFilterParameters(JSON.parse(radioUserEnabled));
  };

  const handleSearchFilter = (searchText) => {
    setSearchFilter(searchText);
    setSearchStatus(!searchStatus);
  };

  const handleResizeWindow = () => {
    if (window.innerWidth > 600) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  });

  useEffect(() => {
    let isMounted = true;
    const { sortField, sortOrder } = CheckValidSortModel(
      sortModel,
      UsersHeader.initialFieldName
    );

    (async () => {
      setLoading(true);

      const { action, method } = getUsersPaginated;
      let url = urlBase + action;

      const requestBody = {
        pageIndex: page,
        pageSize: pageSize,
        sortColumnName: sortField,
        sortDirection: sortOrder,
        applyPaginationAndSort: true,
        searchFilter: searchFilter,
        radioUserEnabled: filterParameters,
      };

      try {
        const response = await axios({
          method: method,
          url,
          data: requestBody,
        });
        if (isMounted) {
          const newRows = response.data.result.result;
          const newRowsCount = response.data.result.totalFiltered;
          setRows(newRows);
          setRowsCount(newRowsCount);
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        const notificationError = handlerError(error);
        dispatch(notificationOpen(notificationError));
        setLoading(false);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [
    page,
    pageSize,
    sortModel,
    searchStatus,
    searchFilter,
    reloading,
    filterParameters,
  ]);

  useEffect(() => {
    (async () => {
      const { action, method } = getUsers;
      let url = urlBase + action;
      const requestBody = {};
      try {
        const response = await axios({
          method: method,
          url,
          data: requestBody,
        });

        if (response.data.ok) {
          dispatch(loadUsersState(response.data.result));
        }
      } catch (error) {
        console.error(error);
        const notificationError = handlerError(error);
        dispatch(notificationOpen(notificationError));
      }
    })();
  }, []);

  useEffect(() => {
    let isMounted = true;
    (async () => {
      const { action, method } = getUserRoles;
      let url = urlBase + action;
      const requestBody = {};
      try {
        const response = await axios({
          method: method,
          url,
          data: requestBody,
        });

        if (response.data.ok && isMounted) {
          dispatch(loadUserRolesState(response.data.result));
        }
      } catch (error) {
        console.error(error);
        const notificationError = handlerError(error);
        dispatch(notificationOpen(notificationError));
      }
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  function returnExpandedState(state) {
    setExpandedState(state);
  }

  useEffect(() => {
    setExpandedState(false);
  }, [isMobile]);

  return (
    <>
      {isMobile || (!isMobile && viewState) ? (
        <>
          <Grid container variant="No-Padding">
            <Grid item component={CDCard} xs={12} sm={12}>
              <CDCardHeader
                className="CDCardHeader-Padding"
                title={
                  <>
                    <TitleDatable nameDataTable="Utenti" />
                  </>
                }
              />
              <Divider />
              <CDCardContent>
                <Grid container>
                  {/* <Grid item xs={6} lg={6} className={expandedStateFilter ? "tastoTop" : "center-spacebetween" }> */}
                  <Grid item xs={12} lg={6} className="tastoTop">
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <AdvancedFilterScissor
                      returnExpandedState={returnExpandedState}
                      expanded={expandedState}
                      labelCustom={"Espandi Filtri"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <MenuCollapse
                    startValueRadioButton={filterParameters}
                      expanded={expandedState}
                      handleFilterData={handleFilterData}
                      returnExpandedStateAfterConfirm={returnExpandedState}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <StyledDataGrid
                    rows={rows}
                    rowCount={rowsCount}
                    getRowId={(row) => row.username}
                    columns={UsersHeaderMobile.headers}
                    page={page}
                    pageSize={pageSize}
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    onSortModelChange={(newSortModel) =>
                      setSortModel(newSortModel)
                    }
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    disableSelectionOnClick
                    sortModel={sortModel}
                    sortingMode="server"
                    sortingOrder={["asc", "desc"]}
                    paginationMode="server"
                    loading={loading}
                    sx={{ border: 0 }}
                    disableColumnMenu
                    disableColumnFilter
                    disableColumnSelector
                    autoHeight
                    rowHeight={rowHeight}
                    autoWidth
                    components={{
                      Toolbar: () => (
                        <TableToolbar
                          typePage={typePage}
                          enableAdd={true}
                          enableSearch={true}
                          isSalaRiunione={false}
                          enableExport={true}
                          handleAdd={() => {
                            dispatch(setPageType(dialogPageTypes.user));
                            dispatch(openEmptyDialogUser("add"));
                          }}
                          initialSearchText={searchFilter}
                          startSearch={handleSearchFilter}
                        />
                      ),
                      NoRowsOverlay: () => (
                        <GridOverlay>
                          <Typography>Nessun elemento</Typography>
                        </GridOverlay>
                      ),
                      NoResultsOverlay: () => (
                        <GridOverlay>
                          <Typography>Nessun elemento</Typography>
                        </GridOverlay>
                      ),
                    }}
                  />
                </Grid>
              </CDCardContent>
            </Grid>
          </Grid>
          <Notification />
          <UserDialog />
        </>
      ) : (
        <>
          <Grid container>
            <Grid item component={CDCard} xs={12} sm={12}>
              <CDCardHeader
                className="CDCardHeader-Padding"
                title={
                  <>
                    <TitleDatable nameDataTable="Elenco Utenti" />
                  </>
                }
              />
              <Divider />
              <CDCardContent>
                <AdvancedFilterPanel handleFilterData={handleFilterData} startValueRadioButton={filterParameters}/>
                <Divider sx={{ mb: 2 }} />
                <Grid container item>
                  <StyledDataGrid
                    rows={rows}
                    rowCount={rowsCount}
                    getRowId={(row) => row.username}
                    columns={UsersHeader.headers}
                    page={page}
                    pageSize={pageSize}
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    onSortModelChange={(newSortModel) =>
                      setSortModel(newSortModel)
                    }
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    disableSelectionOnClick
                    sortModel={sortModel}
                    sortingMode="server"
                    sortingOrder={["asc", "desc"]}
                    paginationMode="server"
                    loading={loading}
                    sx={{ border: 0 }}
                    disableColumnMenu
                    disableColumnFilter
                    disableColumnSelector
                    autoHeight
                    autoWidth
                    components={{
                      Toolbar: () => (
                        <TableToolbar
                          typePage={typePage}
                          enableAdd={true}
                          customButton={<SynchronizeButton />}
                          isSalaRiunione={false}
                          enableSearch={true}
                          enableExport={true}
                          handleAdd={() => {
                            dispatch(setPageType(dialogPageTypes.user));
                            dispatch(openEmptyDialogUser("add"));
                          }}
                          initialSearchText={searchFilter}
                          startSearch={handleSearchFilter}
                        />
                      ),
                      NoRowsOverlay: () => (
                        <GridOverlay>
                          <Typography>Nessun elemento</Typography>
                        </GridOverlay>
                      ),
                      NoResultsOverlay: () => (
                        <GridOverlay>
                          <Typography>Nessun elemento</Typography>
                        </GridOverlay>
                      ),
                    }}
                  />
                </Grid>
              </CDCardContent>
            </Grid>
          </Grid>
          <Notification />
          <UserDialog />
          <GenericServiceComponent />
        </>
      )}
    </>
  );
};

export default UsersDatatable;

function GenericServiceComponent() {
  /* questo componente è stato aggiunto per permette al tasto "SynchronizeButton" di conoscere lo stato del proprio task asincrono in funzione nel BE.
    la Toolbar di Mui non permette di usare SideEffect al suo interno(posizione in cui il componente button viene istanziato),
     e il DataTable viene renderizzato ogni volta che l'hook useTaskAsyncRun() gestisce un nuovo stato.
     Per queste ragioni è stato creato questo componente nascosto con l'unica funzione di gestire il sideEffect attraverso un customHook. */
  useTaskAsyncRun();

  return null;
}
