import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const configuration = createSlice({
    name:'configuration',
    initialState,
    reducers: {
        saveConfiguration: (state,payload) => { 
            return state = payload.payload;
         }
    }
})

export const { saveConfiguration } = configuration.actions;

const { reducer } = configuration;

export default reducer;