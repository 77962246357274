import * as yup from "yup";

export const validationSchemaUserEditToAuthActiveDirectory = yup.object({
    username: yup.string().required("Campo Obbligatorio."),
    firstName: yup.string().required("Campo Obbligatorio."),
    lastName: yup.string().required("Campo Obbligatorio."),
    email: yup.string().required("Campo Obbligatorio."),
  });

  export const validationSchemaUserEditToAuthDatabase = yup.object({
    username: yup.string().required("Campo Obbligatorio."),
    firstName: yup.string().required("Campo Obbligatorio."),
    lastName: yup.string().required("Campo Obbligatorio."),
  });

 export const validationSchemaUserAddToAuthDatabase = yup.object({
    username: yup.string().required("Campo Obbligatorio."),
    firstName: yup.string().required("Campo Obbligatorio."),
    lastName: yup.string().required("Campo Obbligatorio."),
    password: yup.string().required("Campo Obbligatorio."),
    confirmPassword: yup.string().required("Campo Obbligatorio."),
  });

  export const validationSchemaUserAddToAuthActiveDirectory = yup.object({
    username: yup.string().required("Campo Obbligatorio."),
    firstName: yup.string().required("Campo Obbligatorio."),
    lastName: yup.string().required("Campo Obbligatorio."),
    email: yup.string().required("Campo Obbligatorio."),
  });
  
  export const validationSchemaUserSignInToAuthActiveDirectory = yup.object({
    username: yup.string().required("Campo Obbligatorio."),
    firstName: yup.string().required("Campo Obbligatorio."),
    lastName: yup.string().required("Campo Obbligatorio."),
  });

  export const validationSchemaUserSignInToAuthDatabase = yup.object({
    email: yup.string().required("Campo Obbligatorio."),
    firstName: yup.string().required("Campo Obbligatorio."),
    lastName: yup.string().required("Campo Obbligatorio."),
  });

export const validationSchemaUserReset = yup.object({
    password: yup.string().required("Campo Obbligatorio."),
    confirmPassword: yup.string().required("Campo Obbligatorio."),
  });


