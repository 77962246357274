import DialogViewBuildingAdd from "../../../components/Dialog/BuildingDialog/componentBuildingDialog/viewBuildingDialog/DialogViewBuildingAdd";
import DialogViewBuildingDelete from "../../../components/Dialog/BuildingDialog/componentBuildingDialog/viewBuildingDialog/DialogViewBuildingDelete";
import DialogViewBuildingEdit from "../../../components/Dialog/BuildingDialog/componentBuildingDialog/viewBuildingDialog/DialogViewBuildingEdit";


export const listOfDialogBuildViewComponent = 
[
    {component:DialogViewBuildingDelete, keyToIdentifyComponentToShow:"delete"},
    {component:DialogViewBuildingEdit,keyToIdentifyComponentToShow:"edit"},
    {component:DialogViewBuildingAdd,keyToIdentifyComponentToShow:"add"},
]
