import { Slider } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { useState } from "react";
import useMobileDetection from "../../../../Hooks/useMobileDetection";
import {
  marksSliderDisponibilita,
  minDistanceSliderDisponibilita,
} from "../../../../const/Disponibilita/marksSliderDIsponibilità";

function valuetext(value) {
  return `${value}:00`;
}

function valuetextArr(arr) {
  return arr.map((time) => {
    return `${time}:00`;
  });
}

let time;
let firstRender = true;
export default function SliderDisponibiltaGiorno({ returnRangeTime }) {
  const [value, setValue] = useState([9, 18]);

  const handleChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (activeThumb === 0) {
      setValue([
        Math.min(newValue[0], value[1] - minDistanceSliderDisponibilita),
        value[1],
      ]);
    } else {
      setValue([
        value[0],
        Math.max(newValue[1], value[0] + minDistanceSliderDisponibilita),
      ]);
    }
  };

  useEffect(() => {
    if (firstRender) {
      returnRangeTime(valuetextArr(value))
      return firstRender = false
    };
    if (time) clearTimeout(time);
    time = setTimeout(function () {
      returnRangeTime(valuetextArr(value));
    }, 500);
  }, [value]);


  const isMobile = useMobileDetection();

  return (
    <Box sx={{ padding: "0 25px" }}>
      <Slider
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        valueLabelFormat={valuetext}
        min={0}
        max={24}
        marks={isMobile ? marksSliderDisponibilita : marksSliderDisponibilita}
        disableSwap
      />
    </Box>
  );
}
