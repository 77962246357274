import { Button, DialogActions, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useRef, useState } from "react";
import { useEffect } from "react";
import { QrReader } from "react-qr-reader";
import {
  dialogButtonQrCode,
  dialogButtonRiepilogo,
} from "../../../../const/DialogData";
import adaptiveCssFromRef from "../../../../Hooks/Adaptive/useAdaptiveCssFromRef";
import TitleScannedQrCode from "./GeneralComponentQrCode/titleScannedQrCode";

const qrCodeScanDelay = process.env.REACT_APP_QR_CODE_SCAN_DELAY;

export default function ScannerQrCode({
  returnValue,
  returnComponentState,
  isScanActive = true,
}) {
  const [data, setData] = useState(undefined);
  const [adaptiveHeightComponent, setAdaptiveHeightComponent] = useState(0);
  const buttonsBox = useRef(undefined);

  useEffect(() => {
    returnValue(data);
  }, [data]);


  useEffect(()  => {
    setAdaptiveHeightComponent(adaptiveCssFromRef(buttonsBox))
  },[buttonsBox])

  return (
    <>
     <div style={{ minHeight:`${adaptiveHeightComponent}px` }}>
      <TitleScannedQrCode title="Scansiona posto letto" />
      <QrReader
        scanDelay={qrCodeScanDelay}
        constraints={{ facingMode: "environment" }}
        onResult={(result, error) => {
          if (result !== undefined) {
            var qrCode = result.text;
              if (isScanActive) setData(qrCode);
          }
        }}
        className="qrCode-CoreElement"
      />
      <Grid container>
        <Grid item xs={12} sm={12}>
          <Box sx={{ padding: "0 10px 0 5px" }}>
            <ol>
              <Typography className="space-Between-Typografy">
                <li>
                  Inquadrare il QR code con la fotocamera posteriore del vostro
                  dispositivo.
                </li>
              </Typography>
              <Typography className="space-Between-Typografy">
                <li>
                  Attendere la notifica di scansione del QR code e l'apertura
                  della prenotazione del posto letto.
                </li>
              </Typography>
              <Typography className="space-Between-Typografy">
                <li>Scegliere l'orario della prenotazione.</li>
              </Typography>
              <Typography className="space-Between-Typografy">
                <li>
                  Confermare la prenotazione e attendere la risposta del
                  sistema.
                </li>
              </Typography>
            </ol>
          </Box>
        </Grid>
      </Grid>
      </div>
      <Grid item container  ref={buttonsBox}>
        <Grid item>
          <DialogActions>
            <Button onClick={() => returnComponentState(false)}>
              {dialogButtonQrCode.Annulla}
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </>
  );
}
