import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import { getCallTypes } from "../../const/urlAction";
import { Grid, Button, Card, CardContent, IconButton, CardActions, Collapse, Typography } from "@mui/material";
import { FormControl, FormLabel, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './AdvancedFilterPanel.module.css';


const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));


function AdvancedFilterPanel({ isFetching, handleFilterData }) {

  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const [radioUserEnabled, setRadioRecoveryEnabled] = useState("null");
  

  const handleUserChange = (event) => {
    setRadioRecoveryEnabled(event.target.value);
  };


  return (
    <>
        <Grid container item>
          <Grid item component={Card} xs={12} sm={12} sx={{boxShadow: 0}}>
            <CardActions sx={{justifyContent: 'flex-end'}} disableSpacing>
                <Typography variant="button">{expanded ? 'Riduci' : 'Espandi'} Filtri </Typography>
                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                    >
                    <ExpandMoreIcon />
                </ExpandMore>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>
                  <Grid container columnSpacing={3} rowSpacing={3}>
                      <Grid item xs={12} sm={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Abilitato</FormLabel>
                            <RadioGroup row aria-label="recoveryEnabled" name="row-radio-group-enabled" onChange={handleUserChange} defaultValue="null">
                                <FormControlLabel value="null" control={<Radio />} label="Tutti" />
                                <FormControlLabel value="true" control={<Radio />} label="Solo Abilitati" />
                                <FormControlLabel value="false" control={<Radio />} label="Solo Non Abilitati" />
                            </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid container item>
                        <Grid item xs={12} className={styles.buttonGrid}>
                          <Button variant="contained" disabled={isFetching} className={styles.buttonStyle} onClick={() => {
                            handleFilterData(radioUserEnabled);
                            }}>Applica</Button>
                        </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Collapse>
          </Grid>
        </Grid>
    </>
  );
}

export default AdvancedFilterPanel;
