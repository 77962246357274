import React, { useState, useEffect } from "react";
import axios from "axios";
import { dialogPageTypes } from "../../const/dialogPageTypes"
import { Grid, Divider, Typography } from "@mui/material";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import TableToolbar from "../UI/TableToolbar/TableToolbar";
import { styled } from "@mui/material/styles";
import { CDCard, CDCardContent, CDCardHeader } from "../../const/CDCardStyles";
import CheckValidSortModel from "../../functions/CheckValidSortModel";
import { PostazioniHeader, PostazioniHeaderMobile } from "../../const/DatatableData";
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import {
  getPostazioniDataTableByIdStanza,
  exportUsers,
  getUsersAssociati,
} from "../../const/urlAction";
import { useSelector, useDispatch } from "react-redux";
import PostazioniDialog from "../Dialog/PostazioniDialog";
import handlerError from "../../functions/handlerError";
import { notificationOpen } from "../../redux/reducers/notification-reducer";
import Notification from "../UI/Notification/Notification";
import { useParams } from "react-router-dom";

//import PathTextComponent from "../pathTextComponent/PathTextComponent";
import { loadUsersState } from "../../redux/reducers/usersSlice-reducer";
import PlanimetriaGestione from "../Planimetria/PlanimetriaGestione/PlanimetriaGestione";
import { removeDataGridHeader } from "../../functions/handlerDataGrid";
import usePathTextDetail from "../../Hooks/usePathTextDetail";
import { openEmptyDialog, setPageType } from "../../redux/reducers/DialogGeneral";
import useCheckDisabilitazioniSuPostazioni from "../../Hooks/useCheckDisabilitazioniSuPostazioni";
import { TitleDatable } from "../TitleDatable/TitleDataTable";


const typePage = "Posti letto";
const urlBase = process.env.REACT_APP_URL;

const PostazioniDatatable = () => {
    
  const dispatch = useDispatch();
  const  params = useParams();
  const  [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const viewState = useSelector((state) => state.viewState);
  const state = useSelector((state) => state.userDate.policies);
  const reloading = useSelector((state) => state.dataTablePostazioni);
  const [searchFilter, setSearchFilter] = useState("");
  const [searchStatus, setSearchStatus] = useState(false); 
  const [dettaglioStanza, setDettaglioStanza] = useState({})
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rows, setRows] = useState([]);
  const [rowsCount, setRowsCount] = useState(rows.length);
  const [loading, setLoading] = useState(false);
  const [isMapActive, setIsMapActive] = useState(false);
  const [sortModel, setSortModel] = useState([
    {
      field: PostazioniHeader.initialFieldName,
      sort: PostazioniHeader.initialFieldOrder,
    },
  ]);


  const handleSearchFilter = (searchText) => {
    setSearchFilter(searchText);
    setSearchStatus(!searchStatus)
  };
  
  const StyledDataGrid = removeDataGridHeader(DataGrid, viewState)

  const path = usePathTextDetail()

  const isDisabilitazioniOnEntity = useCheckDisabilitazioniSuPostazioni()

  useEffect(() => {
    window.addEventListener('resize', handleResizeWindow);
    return () => {
        window.removeEventListener('resize', handleResizeWindow);
    };
});



const handleResizeWindow = () => {
  if(window.innerWidth > 600){
    setIsMobile(false);
  } 
  else {
    setIsMobile(true);
  } 
};

  useEffect(() => {
    const { sortField, sortOrder } = CheckValidSortModel(
      sortModel,
      PostazioniHeader.initialFieldName
    );
if(!isMapActive) {
    (async () => {
      let isMounted = true;
      setLoading(true);
    const {idStanza} = params;
      const { action, method } = getPostazioniDataTableByIdStanza;
      let url = urlBase + action ;

      const requestBody = {
        pageIndex: page,
        pageSize: pageSize,
        sortColumnName: sortField,
        sortDirection: sortOrder,
        applyPaginationAndSort: true,
        searchFilter: searchFilter,
        IdStanza: idStanza
      };

      try {
        const response = await axios({
          method: method,
          url,
          data: requestBody,
        });

        if(isMounted) {
          setDettaglioStanza(response.data.result.dettaglio)
          setRows(response.data.result.result);
          setRowsCount(response.data.result.totalFiltered);
          setLoading(false);
        }

        if (!response.data.ok) {
          dispatch(
            notificationOpen({
              message: response.data.message,
              status: "success",
            })
          );
        }
      } catch (error) {
        console.error(error);
        const notificationError = handlerError(error);
        dispatch(notificationOpen(notificationError));
        setLoading(false);
      }
      
      return() => {
        isMounted = false;
      }
    })();
   }
  }, [page, pageSize, sortModel,searchStatus, searchFilter, reloading, isMapActive]);


  function changeViewToMap() {
    setIsMapActive(!isMapActive)
    return !isMapActive
  }

  function disableAction(disableAction) {
    dispatch(setPageType(dialogPageTypes.building))
    dispatch(openEmptyDialog(disableAction))
  }


  return (
    <>
      { isMobile || !isMobile && viewState ? (
        <>
          <Grid container>
            <Grid item component={CDCard} xs={12} sm={12}>
            <CDCardHeader className="CDCardHeader-Padding" title={<><TitleDatable nameDataTable="Posti letto" pathDataTable={path} /></>} />
              <Divider />
              <CDCardContent>
                {/* <PathTextComponent /> */}
                {/* <AdvancedFilterPanel handleFilterData={handleFilterData} /> */}
                <Grid container item>
                  <StyledDataGrid
                    rows={rows}
                    rowCount={rowsCount}
                    getRowId={(row) => `${row.id}-${row.posto}`}
                    columns={PostazioniHeaderMobile.headers}
                    page={page}
                    pageSize={pageSize}
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    onSortModelChange={(newSortModel) =>
                      setSortModel(newSortModel)
                    }
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    disableSelectionOnClick
                    sortModel={sortModel}
                    sortingMode="server"
                    sortingOrder={["asc", "desc"]}
                    paginationMode="server"
                    loading={loading}
                    sx={{ border: 0 }}
                    disableColumnMenu
                    disableColumnFilter
                    disableColumnSelector
                    autoHeight
                    rowHeight={120}
                    autoWidth
                    components={{
                      Toolbar: () => (
                        <TableToolbar
                          typePage={typePage}
                          enableAdd={true}
                          enableSearch={true}
                          enableExport={true}
                          isSalaRiunione={dettaglioStanza.isSalaRiunione}
                          enableDisable={true}
                          showResetDisable={isDisabilitazioniOnEntity}
                          disableAction={disableAction}
                          handleAdd={() => {
                            dispatch(setPageType(dialogPageTypes.postazioni))
                            dispatch(openEmptyDialog('add'));
                          }}
                          handleEdit={() => {
                            dispatch(setPageType(dialogPageTypes.postazioni))
                            dispatch(openEmptyDialog('add'));
                          }}
                          initialSearchText={searchFilter}
                          startSearch={handleSearchFilter}
                        />
                      ),
                      NoRowsOverlay: () => (
                        <GridOverlay>
                          <Typography>Nessun elemento</Typography>
                        </GridOverlay>
                      ),
                      NoResultsOverlay: () => (
                        <GridOverlay>
                          <Typography>Nessun elemento</Typography>
                        </GridOverlay>
                      ),
                    }}
                  />
                </Grid>
              </CDCardContent>
            </Grid>
          </Grid>
          <Notification />
         <PostazioniDialog /> 
        </>
      ) : (
        <>
          <Grid container>
            <Grid item component={CDCard} xs={12} sm={12}>
            <CDCardHeader className="CDCardHeader-Padding" title={<><TitleDatable nameDataTable="Posti letto" pathDataTable={path} /></>} />
              <Divider />
              <CDCardContent>
              {/* <PathTextComponent /> */}
                {/* <AdvancedFilterPanel handleFilterData={handleFilterData} />
                <Divider sx={{ mb: 2 }} /> */}
                <Grid container item>
                { isMapActive ? (
                    <>
                      <TableToolbar
                          typePage={"planimetriaGestione"}
                          map={true}
                          mobile={false}
                          changeViewToMap={changeViewToMap}
                        />
                    <PlanimetriaGestione dettaglio={dettaglioStanza}  />
                    </>
                  ) : (
                  <StyledDataGrid
                    rows={rows}
                    rowCount={rowsCount}
                    getRowId={(row) => `${row.id}-${row.posto}`}
                    columns={PostazioniHeader.headers}
                    page={page}
                    pageSize={pageSize}
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    onSortModelChange={(newSortModel) =>
                      setSortModel(newSortModel)
                    }
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    disableSelectionOnClick
                    sortModel={sortModel}
                    sortingMode="server"
                    sortingOrder={["asc", "desc"]}
                    paginationMode="server"
                    loading={loading}
                    sx={{ border: 0 }}
                    disableColumnMenu
                    disableColumnFilter
                    disableColumnSelector
                    autoHeight
                    autoWidth
                    components={{
                      Toolbar: () => (
                        <TableToolbar
                          typePage={typePage} 
                          enableAdd={true}
                          enableSearch={true}
                          enableExport={true}
                          map={true}
                          isSalaRiunione={dettaglioStanza.isSalaRiunione}
                          enableDisable={true}
                          showResetDisable={isDisabilitazioniOnEntity}
                          disableAction={disableAction}
                          changeViewToMap={changeViewToMap}
                          handleAdd={() => {
                            dispatch(setPageType(dialogPageTypes.postazioni))
                            dispatch(openEmptyDialog('add'));
                          }}
                          handleEdit={() => {
                            dispatch(setPageType(dialogPageTypes.postazioni))
                            dispatch(openEmptyDialog('add'));
                          }}
                          initialSearchText={searchFilter}
                          startSearch={handleSearchFilter}
                        />
                      ),
                      NoRowsOverlay: () => (
                        <GridOverlay>
                          <Typography>Nessun elemento</Typography>
                        </GridOverlay>
                      ),
                      NoResultsOverlay: () => (
                        <GridOverlay>
                          <Typography>Nessun elemento</Typography>
                        </GridOverlay>
                      ),
                    }}
                  />
                  )}
                </Grid>
              </CDCardContent>
            </Grid>
          </Grid>
          <Notification />
          <PostazioniDialog />
        </>
      )}
    </>
  );
};

export default PostazioniDatatable;