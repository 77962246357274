import { createSlice } from '@reduxjs/toolkit';

const initialState = {  isSalaRiunione: false};

const filterSlice = createSlice({
    name:'filterSlice',
    initialState,
    reducers: {
        setIsSalaRiunione: (state, action) => {
            state.isSalaRiunione = action.payload;
          }
    }
})

export const { setIsSalaRiunione } = filterSlice.actions;

const { reducer } = filterSlice;

export default reducer;