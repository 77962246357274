import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const prenotazioniTableSlice = createSlice({
    name:'prenotazioniTable',
    initialState,
    reducers: {
        loadprenotazioniTableState: (state) => { 
            return !state
         }
    }
})

export const { loadprenotazioniTableState } = prenotazioniTableSlice.actions;

const { reducer } = prenotazioniTableSlice;

export default reducer;