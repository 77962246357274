import { styled } from "@mui/material/styles";
import { noHeaderStyle, NormalSize, smallSize, withHeaderStyle } from "../const/MUI/muiDataGridHeader";

export function removeDataGridHeader(muiComponent,viewState) {

const style = viewState ? noHeaderStyle : withHeaderStyle

const StyledComponent = styled(muiComponent)(() => (style));

return StyledComponent

}

export function changeFontSizeRadioButton(muiComponent,viewState) {

    const style = viewState ?   smallSize : NormalSize
    
    const StyledComponent = styled(muiComponent)(() => (style));
    
    return StyledComponent
    
    }