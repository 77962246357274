import { createSlice } from '@reduxjs/toolkit';

const initialState = {isOpen:false , dialogType:"", rowInfo:-1};

const dispositivoSlice = createSlice({
    name:'dialogDispositivo',
    initialState,
    reducers: {
        openEmptyDialog: (state, payload) => { 
            return {...state, isOpen: true, dialogType:payload.payload};
         },

         openDialogDispositivi:(state,payload) => {
             return {...state, isOpen:true, dialogType:payload.payload.dialogType, rowInfo:payload.payload.rowInfo}
         },
        closeDialog: (state) => { 
            return {...state, isOpen: false};
         }
    }
})

export const { openDialogDispositivi, closeDialog, openEmptyDialog  } = dispositivoSlice.actions;

const { reducer } = dispositivoSlice;

export default reducer;