import { createSlice } from '@reduxjs/toolkit';

const initialState = false;

const sidebarSlice = createSlice({
    name:'sidebar',
    initialState,
    reducers: {
        changeSidebarState: (state,payload) => {
            return !state;
         },
         closeSidebar: (state) => { 
            return state = false;
         },
    }
})

export const { changeSidebarState, closeSidebar } = sidebarSlice.actions;

const { reducer } = sidebarSlice;

export default reducer;