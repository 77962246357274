import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useEffect, useState } from "react";

export default function RadioButtonCustom({
  GroupName = null,
  tabNumber = [],
  startValueReset = "default",
  labelList = [{value:"test",label:"test"}],
  handleChange,
}) {

   const [value, setValue] = useState(startValueReset);
   const [labelListObject, setLabelListObject] = useState([]);

  function handleRadioButtonChange (event) {
    handleChange(event.target.value)
    setValue(event.target.value)
  }
 
useEffect(() => {
    setValue(startValueReset)
},[])

useEffect(() => {
  if(typeof(labelList[0]) !== typeof({})) {
    labelList = labelList.map(x => { return {value:x, label:x}})
    setLabelListObject(labelList)
  } else {
    setLabelListObject(labelList)
  }
},[labelList])


  return (
    <>
      <FormControl component="fieldset">
        {GroupName && (
          <FormLabel component="legend">
            <b>{GroupName}</b>
          </FormLabel>
        )}
        <RadioGroup
          row
          aria-label="recoveryEnabled"
          name="row-radio-group-enabled"
          onChange={handleRadioButtonChange}
          value={value}
          defaultValue="true"
        >
          {labelListObject.length > 0 && tabNumber.length > 1 &&
          labelListObject.map((x, index) => {
            return (
              <FormControlLabel
                key={index}
                value={x.value}
                control={<Radio />}
                label={x.label}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </>
  );
}
