import { closeDialog } from "../../redux/reducers/DialogGeneral";
import { resetStatePrenotazioneStepper } from "../../redux/reducers/infoPrenotazione-reducer";

export default function stepperBackButton(param, dispatch) {
        if (param) {
           const { activeStep, handleBack } = param;
          if (activeStep === 0) {
                dispatch(resetStatePrenotazioneStepper())
                dispatch(closeDialog());
            }
          else {
            handleBack()
          }
        }
      }