import { Grid, Paper, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import CalcPercenteProgressBar from "../../../functions/disponibilita/calcPercenteProgressBar";
import useMobileDetection from "../../../Hooks/useMobileDetection";
import {
  ProgressBarDisponibilitaLinear,
  ProgressBarDisponibilitaCircular,
} from "./ProgressBarDisponibilita";

export default function ViewRowDisponibilita({
  element,
  returnIdElementClicked,
  lengthArrElementPath,
  percentStatusConfiguration,
}) {

  const {
    id,
    denominazione,
    sigla,
    totPostazioniOccupate,
    totPostazioniDisponibili,
    totPostazioni,
  } = element;

  function insertElement(el) {
    if (lengthArrElementPath < 3 && totPostazioni > 0)
      returnIdElementClicked(el);
  }

  let { valueProgressBar, colorProgressbar } = CalcPercenteProgressBar(
    percentStatusConfiguration,
    totPostazioniOccupate,
    totPostazioniDisponibili
  );

  const isMobile = useMobileDetection();

  return (
    <>
      <Paper
        className="rowDisponibilita box-shadow-light"
        onClick={() => {
          insertElement(element);
        }}
        sx={
          lengthArrElementPath < 3 && totPostazioni > 0
            ? { cursor: "pointer" }
            : {}
        }

        
      >
        <Grid container sx={{ padding: "0" }}>
          <Grid item xs={2} sm={2} md={2} lg={1}>
            <Paper className="cardDisponibilitaSigla flex-center-center no-box-shadow">
              <Typography sx={{ color: "#026D82" }}>
                <b>{sigla.toUpperCase()}</b>
              </Typography>
            </Paper>
          </Grid>
          <Grid
            container
            item
            xs={8}
            sm={8}
            md={8}
            lg={9}
            sx={{ padding: "8px 8px" }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography className="textDisponibilta font-w500">
                {denominazione}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography className="textDisponibilta">
                {totPostazioni > 0 ? (
                  <i>
                    {totPostazioniOccupate + "/" + totPostazioniDisponibili}
                  </i>
                ) : (
                  <i>Nessun posto letto censito</i>
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            xs={2}
            sm={2}
            md={2}
            lg={2}
            sx={{
              float: "right",
              paddingTop: "21px !important",
            }}
          >
            {totPostazioni > 0 &&
              (isMobile ? (
                <ProgressBarDisponibilitaCircular
                  colorProgressBar={colorProgressbar}
                  value={valueProgressBar}
                />
              ) : (
                <ProgressBarDisponibilitaLinear
                  colorProgressBar={colorProgressbar}
                  value={valueProgressBar}
                />
              ))}
          </Grid>
        </Grid>

        
      </Paper>
    </>
  );
}
