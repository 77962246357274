import { Popover } from "@mui/material";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import createTipsElement from "../../functions/showTips/createTipsElement";
import {tipsInizialValueLocalStorage} from "../../const/Tips/tipsInizialValueLocalStorage";

export default function ShowTips({ parent }) {
  const [isOpen, setIsOpen] = useState(true);
  let showTips
  let result = localStorage.getItem("deskbookingTipsZoom");
  showTips = JSON.parse(result)

  if (!showTips) {
    localStorage.setItem("deskbookingTipsZoom",false);
    result = localStorage.getItem("deskbookingTipsZoom");
    showTips = JSON.parse(result)
  }
  
  function handleCloseTips() {
    setIsOpen(false);
    localStorage.setItem("deskbookingTipsZoom",true)
  }
  
  const { element } = createTipsElement(
    parent,
    showTips,
    handleCloseTips
  );

const {width, height } = window.screen;
  return (
    <React.Fragment>
      {element !== null && (
        <Popover
        anchorReference="anchorPosition"
        anchorPosition={{ top: height / 3, left: width / 2 }}
          open={isOpen}
        >
          {element}
        </Popover>
      )}
    </React.Fragment>
  );
}
