import { Grid, Typography, Avatar } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import React from "react";
import "../../PlanimetriaIcons.css";
import "../../../../index.css";
import { maxValueText } from "../../../../const/DialogRiepilogoText";
import CheckIfShowDayOnSubMenu from "../../../../functions/MostraMappa.js/InfoSubMenu/CheckIfShowDayOnSubMenu";
import { useEffect } from "react";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { red } from "@mui/material/colors";
import AvatarImage from "../../../Avatar/AvatarImage";

export default function ShowSubMenuInfo({ info, rangeData, dataSelected }) {
  const { prenotazioni, posto } = info;

  //ORDINAMENTO PRENOTAZIONI
  prenotazioni?.sort(
    (a, b) =>
      Date.parse(a.dataInizioPrenotazione) -
      Date.parse(b.dataInizioPrenotazione)
  );

  let prenotazioniOrdinatoPerUtenteEData = prenotazioni.reduce(function (
    r,
    prenotazioni
  ) {
    r[prenotazioni.utentePrenotato] = r[prenotazioni.utentePrenotato] || [];
    r[prenotazioni.utentePrenotato].push(prenotazioni);
    return r;
  },
  new Array());

  let listaUtenti = Object.keys(prenotazioniOrdinatoPerUtenteEData);

  return (
    <>
      <Grid
        item
        xs={12}
        sx={{
          backgroundColor: "#026D82",
          color: "#F9F9FA",
          paddingLeft: "5px",
        }}
        className="flex-center-left"
      >
        {posto && (
          <Typography className="header-submenu-planimetriaInfo">
            <b>{"Postazione " + posto}</b>
          </Typography>
        )}
      </Grid>
      <Grid
        container
        item
        xs={12}
        className="flex-center-center containerSubmenu"
      >
        {listaUtenti.length > 0 ? (
          listaUtenti.map((key, index) => {
            let arrayInformazioni = prenotazioniOrdinatoPerUtenteEData[key];
            return arrayInformazioni.map((x, i) => {
              const {
                utentePrenotatoFullname,
                dataInizioPrenotazione,
                dataFinePrenotazione,
                isMaxValue,
                immagineAvatar,
                ospite,
                nominativoOspite,
              } = x;

              const isShowDay = CheckIfShowDayOnSubMenu(
                dataSelected,
                dataInizioPrenotazione,
                dataFinePrenotazione
              );
              // return i == 0 ? (
              return (
                <React.Fragment key={i}>
                  <Grid container className="submenu-separatore">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginX: "8px",
                        paddingLeft: "3px",
                        paddingRight: "3px",
                      }}
                    >
                      {ospite ? (
                        <AvatarImage
                          name={nominativoOspite}
                          isInputDisabled={true}
                          isCentered={false}
                          isUserLoggedImage={false}
                        ></AvatarImage>
                      ) : (
                        <AvatarImage
                          name={utentePrenotatoFullname}
                          isInputDisabled={true}
                          ImmagineAvatar={immagineAvatar}
                          isCentered={false}
                          isUserLoggedImage={false}
                        ></AvatarImage>
                      )}
                    </div>
                    <Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        sx={{
                          display: "Flex",
                          alignItems: "center",
                          justifyContent: "left",
                          paddingLeft: "5px",
                        }}
                      >
                        <Typography
                          sx={{ paddingTop: "5px", paddingBottom: "0px" }}
                        >
                          <b>
                            {ospite
                            // ? <>{nominativoOspite}<Typography sx={{display:"inline", color:"grey"}}> {" (" + utentePrenotatoFullname + ")"} </Typography></>
                              ? <>{nominativoOspite + " (" + utentePrenotatoFullname + ")"}</>
                              : utentePrenotatoFullname
                              ? utentePrenotatoFullname
                              : "Non disponibile"}
                          </b>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        sx={{
                          display: "Flex",
                          alignItems: "center",
                          justifyContent: "left",
                          paddingBottom: info.isSalaRiunione ? "0px" : "5px",
                          paddingLeft: "5px",
                        }}
                      >
                        <span>{isShowDay}</span>
                      </Grid>
                      {info.isSalaRiunione && (
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "Flex",
                            alignItems: "center",
                            justifyContent: "left",
                            paddingBottom: "5px",
                            paddingLeft: "5px",
                            fontSize: 14,
                            fontWeight: 100,
                          }}
                        >
                          {info.prenotazioni[0].titoloRiunione}
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </React.Fragment>
              );
            });
          })
        ) : (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              display: "Flex",
              alignItems: "center",
              justifyContent: "left",
              paddingTop: "5px",
              paddingBottom: "5px",
              paddingLeft: "5px",
            }}
          >
            <Typography className="header-submenu-planimetriaInfo">
              {" "}
              <i>Disponibile</i>
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
}
