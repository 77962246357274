import React from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { Grid, LinearProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import LogoWithTitle from "../../../assests/logo.png";
import { useSelector } from "react-redux";
import useMobileDetection from "../../../Hooks/useMobileDetection";

//position={{ top: "33%", left: "49%" }}
export default function SpinnerCustom({ state = false, positionTop = "200px" }) {

  const isMobile = useMobileDetection();

const appLoadingState = useSelector((state) => state.appLoader.loading);

let spinnerWidth = isMobile ? "200px" : "300px" 

  return (
    <React.Fragment>
      {appLoadingState &&
        <div className="modal custom-spinner">
          <Grid container sx={{ display: "flex", justifyContent: "center", paddingTop: positionTop }} >
            {/* <Grid className="flex-center-center" item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress sx={{ width: "100px !important", height: "100px !important"}}/>
            </Grid> */}

            <Grid className="flex-center-center" item xs={12} md={12} lg={12} >              
              <Box sx={{marginBottom:"10px"}}>
                <img src={LogoWithTitle} alt="Logo" width={spinnerWidth} />            
              </Box>
            </Grid>

            <Grid className="flex-center-center" item xs={12} md={12} lg={12} >
              {/* <LinearProgress sx={{ width:"200px" }} /> */}
              <LinearProgress sx={{ width:spinnerWidth }} />
            </Grid>

            {/* <Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Typography color="white" fontSize={25} sx={{ textAlign: "center" }} >
                Elaborazione della richiesta in corso...
              </Typography>
            </Grid> */}
          </Grid>
        </div>
      }
    </React.Fragment>
  )
}