import {
  Divider,
  FormLabel,
  Grid,
  InputLabel,
  Paper,
  Typography,
} from "@mui/material";
import "./panoramica.css";
import React from "react";
import { useState } from "react";
import itLocale from "date-fns/locale/it";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  CDCard,
  CDCardContent,
  CDCardHeader,
} from "../../const/CDCardStyles";
import { TitleDatable } from "../TitleDatable/TitleDataTable";
import Notification from "../UI/Notification/Notification";
import SpinnerCustom from "../UI/spinner/spinner";
import { DialogRiepilogo } from "../Dialog/DialogRiepilogo/DialogRiepilogo";
import { CalendarPicker, LocalizationProvider } from "@mui/x-date-pickers";
import CardViewDisponibilita from "./componentsViewDisponibilita/CardViewDisponibilita";
import { useEffect } from "react";
import SliderDisponibiltaGiorno from "./componentsViewDisponibilita/sliderDisponibilita/SliderDisponibilitaGiorno";
import DialogMostraMappaDisponibilita from "./componentsViewDisponibilita/DialogMostraMappaDisponibilita/DialogMostraMappaDisponibilita";
import useMobileDetection from "../../Hooks/useMobileDetection";
import useViewportAdaptedHeight from "../../Hooks/Adaptive/useViewportAdaptedHeight";
import { useRef } from "react";
import MapsStrutture from "./componentsViewDisponibilita/MapsStrutture";
import MapsStruttureJs from "./componentsViewDisponibilita/MapsStruttureJs";
import DialogStepper from "../Dashboard/Stepper/stepperComponent/DialogStepper";
import { useSelector, useDispatch } from "react-redux";
import { gestisciDatiPrenotazioneDisponibilita, resetPrenotazione } from "../../redux/reducers/prenotazioneStepper-reducer";
import { saveStanzaInPrenotazioneStepper , resetStatePrenotazioneStepper} from "../../redux/reducers/infoPrenotazione-reducer";



const initialStatePrenotazione = {
  dataGiorno: new Date(Date.now()),
  dataInizio: "09:00",
  dataFine: "18:00",
  postazioneId: 0,
}

const now = initialStatePrenotazione.dataGiorno;
export default function Disponibilita({ changeViewComponent }) {
  const [date, setDate] = useState(initialStatePrenotazione.dataGiorno);
  const [isSideEffectCompleted, setIsSideEffectCompleted] = useState(false);
  const refCard = useRef(null)
  const [returnRangeTime, setReturnRangeTime] = useState([initialStatePrenotazione.dataInizio, initialStatePrenotazione.dataFine]);
  const [monthChanged, setMonthChanged] = useState(0);
  const [
    elementToDialogMostraMappaDisponibilita,
    setElementToDialogMostraMappaDisponibilita,
  ] = useState([]);
  const [elementClickedMaps, setElementClickedMaps] = useState();
  const [changeClickMaps, setChangeClickMaps] = useState();


  const [activeBackStepPath, setActiveBackStepPath] = useState(false);
  const dispatch = useDispatch();
  const [
    handlerStateDialogMappaDisponibilita,
    setHandlerStateDialogMappaDisponibilita,
  ] = useState(null);
  const [datiPrenotazioneDisponibilita, setDatiPrenotazioneDisponibilita] = useState({});
  function setDateClicked(info) {
    setDate(info);
    // setDatiPrenotazioneDisponibilita({...datiPrenotazioneDisponibilita,  data: {info}})
    // console.log(datiPrenotazioneDisponibilita)
  }
  const { isOpen, lastOpen } = useSelector((state) => state.dialogGeneral);
  const [isSalaRiunioni] = useState(sessionStorage.getItem('isSalaRiunioni') === "true")

  // const [prenotazioneModalOpen, setPrenotazioneModalOpen] = useState(false);
  // const { isOpen, lastOpen } = useSelector((state) => state.dialogGeneral);

function setRangeTime(info) {
  setReturnRangeTime(info)
  // setDatiPrenotazioneDisponibilita({...datiPrenotazioneDisponibilita,  ora: {info}})
  // console.log(datiPrenotazioneDisponibilita)
}


useEffect(() => {
  //oggetto che viene passato a redux per valorizzare dialog
  let statePrenotazioneDisponibilita = {};
  statePrenotazioneDisponibilita.dataGiorno = date
  if(returnRangeTime){
    statePrenotazioneDisponibilita.dataInizio = returnRangeTime[0]
    statePrenotazioneDisponibilita.dataFine = returnRangeTime[1]
  }
  dispatch(gestisciDatiPrenotazioneDisponibilita(statePrenotazioneDisponibilita))
  // dispatch(saveStanzaInPrenotazioneStepper(374))
  if(!isOpen){
    setActiveBackStepPath(old => !old);
  }
  return () => {
    dispatch(resetPrenotazione())
    // dispatch(resetStatePrenotazioneStepper())
    // dispatch(resetPrenotazione())
  }
}, [isOpen])


  const isMobile = useMobileDetection();

  useEffect(() => {
    let elements = document.querySelector(".MuiPickersDay-today");
    elements.classList.add("selectedElement");
  }, []);
  
  //gestisce la persistenza dell'highlights nel datepicker
  useEffect(() => {
    if (date !== now && monthChanged === 0) {
      let elements = document.querySelector(".MuiPickersDay-today");
      elements.classList.remove("selectedElement");
    }
    let el = document.querySelector(".MuiPickersDay-root:focus");
    if (el) el.classList.add("selectedElement");
    return () => {
      if (el) el.classList.remove("selectedElement");
    };
  }, [date, monthChanged]);
  let adaptHeight = useViewportAdaptedHeight(".adaptHeight", 8, refCard, isSideEffectCompleted)

  const [struttureData, setStruttureData] = useState(0);

  const HandleElementPrenotazioni= (childData) =>{
    if(childData?.listDeskBookingElement && childData?.listDeskBookingElement[0]?.subElementType === 1){
      setStruttureData(childData)
    }
  }
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        columnSpacing={1}
        rowSpacing={1}
      >
        <Grid item xs={12} >
          <>
            <Grid container variant="No-Padding" >
              <Grid item component={CDCard} xs={12} sm={12}>
                <CDCardHeader
                  className="CDCardHeader-Padding adaptHeight"
                  title={
                    <>
                      <TitleDatable
                        nameDataTable={isSalaRiunioni ?"Disponibilità servizi" : "Disponibilità posti letto"}
                        changeView={changeViewComponent}
                      />
                    </>
                  }
                />
                <Divider />
                <CDCardContent className="no-padding-MuiCardContent-root"
                id="mainElement" 
                ref={refCard}
                sx={{height:adaptHeight}}>
                  <>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        lessmd={6}
                        md={6}
                        lesslg={5}
                        lg={4}
                        lessxl={3}
                        xl={3}
                        style={isMobile ?{}:{paddingRight:10}}
                      >
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={itLocale}
                        >
                          <CalendarPicker
                                                          minDate={new Date(new Date().setFullYear(new Date().getFullYear() +- 2))}
                                                          maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 10))}
                            onMonthChange={() =>
                              setMonthChanged(monthChanged + 1)
                            }
                            onChange={(info) => setDateClicked(info)}
                            defaultValue={new Date(new Date()+1)}
                          />
                        </LocalizationProvider>
                        <label>
                          {isMobile ? (
                            <Typography sx={{paddingLeft:"16px"}}>
                              <b> Fascia oraria</b>
                            </Typography>
                          ) : (
                            <Typography>
                              <b> Fascia oraria</b>
                            </Typography>
                          )}
                        </label>
                        <SliderDisponibiltaGiorno
                          returnRangeTime={(info) => setRangeTime(info)}
                        />
                        <MapsStruttureJs
                        markerData={struttureData?.listDeskBookingElement} percentStatusConfiguration={struttureData?.percentStatusConfiguration} setElementClickedMaps={setElementClickedMaps} setChangeClickMaps={setChangeClickMaps}></MapsStruttureJs>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        lessmd={6}
                        md={6}
                        lesslg={7}
                        lg={8}
                        lessxl={9}
                        xl={9}
                      >
                        <Grid container sx={{ paddingTop: "10px" }}>
                          <CardViewDisponibilita
                            // setPrenotazioneModalOpen={setPrenotazioneModalOpen}
                            sendData={HandleElementPrenotazioni}
                            isSideEffectCompleted={setIsSideEffectCompleted}
                            dateSelected={date}
                            timeSelected={returnRangeTime}
                            returnElementToDialogMostraMappaDisponibilita={
                              setElementToDialogMostraMappaDisponibilita
                            }
                            activeBackStepPath={activeBackStepPath}
                            handlerStateDialogMappaDisponibilita={
                              handlerStateDialogMappaDisponibilita
                            }
                            elementClickedMaps={elementClickedMaps}
                            changeClickMaps={changeClickMaps}
                            setElementClickedMaps={setElementClickedMaps}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                </CDCardContent>
              </Grid>
            </Grid>
            <Notification />
          </>
        </Grid>
      </Grid>
      <DialogStepper isOpen={isOpen} saltaAllUltimoStep={true}/>
      <DialogMostraMappaDisponibilita
        rows={elementToDialogMostraMappaDisponibilita}
        dataSelected={date}
        rangeTime={returnRangeTime}
        activeBackStepPath={setActiveBackStepPath}
        returnHandlerStateDialogMappaDisponibilita={
          setHandlerStateDialogMappaDisponibilita
        }
      />
    </>
  );
}
