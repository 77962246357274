import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const multiUserHandler = createSlice({
    name:'multiUserHandler',
    initialState,
    reducers: {
        selectUserInMultiUser: (state, payload) => { 
            return payload.payload;
         },
         resetUserInMultiUser: (state, payload) => { 
            return initialState;
         },
    }
})

export const { selectUserInMultiUser, resetUserInMultiUser } = multiUserHandler.actions;

const { reducer } = multiUserHandler;

export default reducer;