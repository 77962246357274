export function gestioneLunghezzaFrasi(frase, MaxLength) {
  MaxLength = 15;
  
  frase =
    frase.length > MaxLength
      ? frase.substring(0, MaxLength).concat("...")
      : frase;

  return frase;
}
