export const marksSliderDisponibilita = [
    {
      value: 0,
      label: '00:00',
    },
    {
      value: 3,
      label: '03:00',
    },
    {
      value: 6,
      label: '06:00',
    },
    {
      value: 9,
      label: '09:00',
    },
    {
      value: 12,
      label: '12:00',
    },
    {
      value: 15,
      label: '15:00',
    },
    {
      value: 18,
      label: '18:00',
    },
    {
      value: 21,
      label: '21:00',
    },
    {
      value: 24,
      label: '24:00',
    },
  ];

  export const minDistanceSliderDisponibilita = 1;