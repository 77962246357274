import {
  Backdrop,
  Button,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { typePlanner } from "../RowPlanner/RowPlanner";
import MenuText from "./MenuText";

export default function MenuContextPlanner({
  anchorEl,
  returnElClicked = () => null
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState(null);

  useEffect(() => {
    if (anchorEl?.event) {
      setIsOpen(true);
      setContextMenuPosition({
        //gestisce la posizione del Context menu
        mouseX: anchorEl?.event.clientX + 2,
        mouseY: anchorEl?.event.clientY - 6,
      });
    } else {
      setIsOpen(false);
    }
  }, [anchorEl]);


  return (
    <>
      {contextMenuPosition && anchorEl?.el && (
        <Menu
          hideBackdrop={true}
          open={isOpen}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenuPosition !== null
              ? {
                  top: contextMenuPosition.mouseY,
                  left: contextMenuPosition.mouseX,
                }
              : undefined
          }
        >
          <MenuText 
          returnElClicked={returnElClicked}
          el={anchorEl?.el} />
        </Menu>
      )}
    </>
  );
}
