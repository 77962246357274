import { Box } from "@mui/system";
import { useState } from "react";
import { TabPanelComponentToShow } from "../../tabs/TabPanelComponentToShow";
import RadioButtonCustom from "../RadioButtonCustom";
import { useEffect } from "react";

export default function RadioButtonWithTabs({
  GroupName,
  startValueReset,
  labelList,
  tabPanel = [],
  lastTabsSelected,
  setLastTabsSelected,
  saveLastTabs = false,
  noTabsComponent = <>Nessuna Tab selezionata</>
}) {
    const [valueTabSelected, setValueTabSelected] = useState(lastTabsSelected || startValueReset);
    const handleChange = (newValue) => {
        setValueTabSelected(newValue)
        if (saveLastTabs) setLastTabsSelected(newValue);
      };

      useEffect(() => {
        if(lastTabsSelected) {
          setValueTabSelected(lastTabsSelected)
        } else if(tabPanel.length === 1) {
          setValueTabSelected(tabPanel[0].label)
        } else {
          setValueTabSelected(startValueReset)
        }
      },[])

  return (
    <>
    <Box>
    <RadioButtonCustom
      GroupName={GroupName}
      startValueReset={startValueReset}
      labelList={labelList}
      handleChange={handleChange}
      tabNumber={tabPanel}
    />
    </Box>
    {
    tabPanel.length > 0 ? 
    tabPanel.map((tab, index) => {
      if (tab.label == valueTabSelected) {
        return (
          <TabPanelComponentToShow
            key={index}
            componentTab={tab.componentTab}
            index={index}
            withPadding={false}
          />
        );
      }
    })
  : noTabsComponent
  }
  </>
);
}
