
const checkRangeDate = (startDate, endDate) => {
  let isCorrectRangeData = true;
  let errorMsg = [];
  let now =  Date.now();

  if (startDate > endDate) {
    isCorrectRangeData = false;
    errorMsg.push("la data inizio periodo è superiore alla data di fine periodo");
  }

  if (endDate.Date > now.Date) {
    isCorrectRangeData = false;
    errorMsg.push("la data fine periodo è superiore alla data attuale");
  }

  if (startDate.Date > now.Date) {
    isCorrectRangeData = false;
    errorMsg.push("la data inizio periodo è superiore alla data attuale");
  }

  return { errorMsg, isCorrectRangeData, startDate, endDate };
};

const checkRangeDatePrenotazioniFilter = (startDate, endDate) => {
  let isCorrectRangeData = false;

if (typeof startDate == 'string') startDate = new Date(startDate);
if (typeof endDate == 'string') endDate = new Date(endDate);

  if(startDate === null && endDate === null || startDate === null || endDate === null){
    isCorrectRangeData = true;
  }
 if(startDate < endDate && startDate !== null && startDate.getTime() === startDate.getTime() && endDate !== null && endDate.getTime() === endDate.getTime()){
    isCorrectRangeData = true;
  }
  return {isCorrectRangeData, startDate, endDate };
};

const checkRangeDateDisabilitazione = (startDate, endDate) => {
  let now =  Date.now();

      if(startDate != null && startDate < now){
        return true;
      }
      else if(endDate != null && endDate < startDate || endDate != null && endDate < now){
        return true;
      }
  return false;
};

export { checkRangeDate, checkRangeDatePrenotazioniFilter, checkRangeDateDisabilitazione };
