export default function convertPositionToInt(number) {

    var numberParsed = Number.parseInt(number)

    if (Number.isNaN(numberParsed)) {
        return 0;
      }

    return numberParsed
    
}