import { createSlice } from '@reduxjs/toolkit';

const initialState = { utentiSincronizzazione:{taskObj:null , stateTask:false}};

const taskAsync = createSlice({
    name:'taskAsync',
    initialState,
    reducers: {
        utentiSincronizzazioneTaskOn: (state) => {
            let utentiSincronizzazione = {...state.utentiSincronizzazione,stateTask:!state.utentiSincronizzazione.stateTask}
            return {...initialState, utentiSincronizzazione};
         },
         utentiSincronizzazioneSetTask: (state,payload) => {
            let utentiSincronizzazione = {...state.utentiSincronizzazione,taskObj:payload.payload}
            return {...initialState, utentiSincronizzazione};
         },
    }
})

export const { utentiSincronizzazioneSetTask, utentiSincronizzazioneTaskOn } = taskAsync.actions;

const { reducer } = taskAsync;

export default reducer;