import { useEffect } from "react";
import { containerClass } from "../../const/Planimetria/costantiPlanimetria";

export default function useScrollGrabOnContainer(refContainer, refElementToGrab) {
  let pos = { top: 0, left: 0, x: 0, y: 0 };

  let moveMouseHandler= (e) => mouseMoveHandler(e)

    useEffect(() => {
    if (refElementToGrab.current !== null && refElementToGrab.current !== undefined && refContainer.current !== null && refContainer.current !== undefined)
    createScrollGrabOnContainer(refElementToGrab);
  }, [refElementToGrab.current, refContainer.current]);



//crea l'evento che gestirà gli eventi di grab
function createScrollGrabOnContainer(refElementToGrab) {
  refElementToGrab.current.addEventListener("mousedown", (e) => mouseDownHandler(e));

}

  //Metodo di default per la gestione del Grab
  function mouseDownHandler(e) {
    let classList ;
    //si assicura che l'evento coinvolga solo l'elemento con la classe associata alla costante containerClass
    if(e?.path != null) {
      classList = e?.path[0].classList?.value
    } else if(e.composedPath()) {
      classList = e.composedPath()[0]?.classList[0]
    }
if(classList && classList.includes(containerClass)) {
  pos = {
          // scroll corrente
          left: refContainer.current.scrollLeft,
          top: refContainer.current.scrollTop,
          // posizione mouse
          x: e.clientX,
          y: e.clientY,
        }
        
        refContainer.current.style.cursor = 'grabbing';

        refElementToGrab.current.addEventListener('mousemove', moveMouseHandler);
        refElementToGrab.current.addEventListener('mouseup', (e) => mouseUpHandler(e));
      }
  };


  function mouseMoveHandler(e) {
    // variazione di posizione
    const dx = e.clientX - pos.x;
    const dy = e.clientY - pos.y;

    // sposta la visuale
    refContainer.current.scrollTop = pos.top - dy;
    refContainer.current.scrollLeft = pos.left - dx;
  };

  const mouseUpHandler = function () {
    refElementToGrab.current.removeEventListener('mousemove', moveMouseHandler);
    refElementToGrab.current.removeEventListener('mouseup', mouseUpHandler);

    refContainer.current.style.cursor = 'grab';
    refContainer.current.style.removeProperty('grab');
}

}








