import { Grid, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";

export default function TextOrganizationalUnits({
  returnValueFromText = [],
  eventButton = undefined,
}) {
  const [ouPills, setOuPills] = useState("");
  const [valueFromText, setValueFromText] = useState("");
  const [allOUpills, setAllOuPills] = useState([]);
  const textField = useRef(undefined);

  function handleChange(e) {
    if (e?.key === "Enter") {
      setOuPills(e.target.value.toUpperCase());
      e.target.value = "";
      textField.current.focus();
    } else if (eventButton?.type === "click") {
    }
  }

  //gestisce il click del button
  useEffect(() => {
    if (eventButton !== undefined) {
      if(valueFromText) {
        setOuPills(valueFromText?.target?.value.toUpperCase());
      textField.current.focus();
      valueFromText.target.value = "";
    }
  }
  }, [eventButton]);

  useEffect(() => {
    if (ouPills.trim() != "" && !allOUpills.includes(ouPills.toUpperCase()))
      setAllOuPills((old) => [...old, ouPills]);
  }, [ouPills]);

  useEffect(() => {
    returnValueFromText(allOUpills);
  }, [allOUpills]);

    //permette di inserire,cancellare e rinserire un elemento.  
    useEffect(() => {
      setOuPills("")
    },[ouPills])

  //Permette di identificare quale tasto è stato premuto.
  useEffect(() => {
    window.addEventListener("keydown", handleChange);

    return () => {
      window.removeEventListener("keydown", handleChange);
    };
  }, []);

  return (
    <>
      <TextField
        ref={textField}
        margin="dense"
        id="OrganizationalUnits"
        label="Unità organizzative"
        fullWidth
        variant="standard"
        onChange={(e) => setValueFromText(e)}
      />
    </>
  );
}
