import { TextField } from "@mui/material";
import {
  DatePicker,
  DesktopDatePicker,
  DesktopTimePicker,
  MobileDatePicker,
  MobileDateTimePicker,
  DateTimePicker,
  DesktopDateTimePicker,
  MobileTimePicker,
  TimePicker,
} from "@mui/x-date-pickers";
import React, { useState } from "react";

export default function GeneralCustomDateTimePicker({
  TypeTimePicker = 1,
  label = "",
  openDialogOnInput = true,
  valueStart,
  minutesStep=5,
  placeholder= "hh/mm",
  handleChange = () => null,
  showToolbar = false,
  minDate = null,
  maxDate = null,
  disabled = false
}) {
  const [open, setOpen] = useState(false);

  let funcOnClick = openDialogOnInput ? () => { !disabled && setOpen(true) }
                                      : () => null;
                                      
  let Component = importTypePicker(TypeTimePicker);

function close() {
  setOpen(false)
}


  return (
    <Component
      label={label}
      value={valueStart}
      open={open}
      onOpen={() => {disabled ? setOpen(false) : setOpen(true)}}
      onClose={() => close()}
      minDate={minDate}
      maxDate={maxDate}
      minutesStep={minutesStep}
      onChange={disabled ? () => null : handleChange}
      cancelText={React.createElement("span", null, "indietro")}
      showToolbar={showToolbar}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          onClick={funcOnClick}
          id="fromDate"
          variant="standard"
          size="small"
          inputProps={{
            ...params.inputProps,
            placeholder: placeholder,
          }}
          fullWidth
        />
      )}
    />
  );
}

function importTypePicker(type) {
  switch (true) {
    case type === 1:
      return TimePicker;
      break;
    case type === 2:
      return MobileTimePicker;
      break;
    case type === 3:
      return DesktopTimePicker;
      break;
    case type === 4:
      return DatePicker;
      break;
    case type === 5:
      return DesktopDatePicker;
      break;
    case type === 6:
      return MobileDatePicker;
      break;
    case type === 7:
      return DateTimePicker;
      break;
    case type === 8:
      return MobileDateTimePicker;
      break;
    case type === 9:
      return MobileDateTimePicker;
      break;
    case type === 10:
      return DesktopDateTimePicker;
      break;
    default:
      console.error("CustomTimePicker - Type picker missed ");
      break;
  }
}
