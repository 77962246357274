import { Card } from "@mui/material";
import React from "react";

export default function BoxIconaToolbar({icon}) {
 
    return (
      <React.Fragment>
    <Card style={{display:"flex", alignItems:"center", boxShadow:"0px 0px 3px 0px rgba(0,0,0,0.2)", justifyContent:"center", backgroundColor:"white", borderRadius:"50%", height: "38px", width: "38px"}}>
      {icon}
    </Card>
      </React.Fragment>
    )
}