import { enumCheckInStatus } from "../../const/prenotazioni/enumCheckInStatus";

export default function createStatusRowSide(status) {

    switch (status) {
        case enumCheckInStatus.future:
            return {color:"transparent"}
        case enumCheckInStatus.waiting:
          return {color:"#ffc107"};
        case enumCheckInStatus.completed:
            return {color:"#28a745"};
        case enumCheckInStatus.notChecked:
            return {color:"#dc3545"};
      }
}