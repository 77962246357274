import React from "react";
import { Grid, Typography } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import "../../index.css";
import { useSelector } from "react-redux";
import { useState } from "react";
import AvatarImage from "../Avatar/AvatarImage";


export default function CardInfoUtente({returnCardInfoDialogState}) {
  const state = useSelector((state) => state.userDate);
  const [isOpenDialogDettaglioUtente, setIsOpenDialogDettaglioUtente] = useState(false);
  const {
    email,
    fullName,
    roles,
  } = state;

  function returnDialogState() {
    returnCardInfoDialogState()
  }

  return (
    <>
      <Grid container className="cardInfoUtente" onClick={() => {returnDialogState()}} >
        <Grid container item sx={{ paddingBottom: "5px" }} className="cardInfoUtente-Click">
          <Grid item lg={12} md={12} xs={12} className="card-icon">
            <AvatarImage size={30} name={fullName} isInputDisabled={true} isCentered={true} isUserLoggedImage={true}/>
            {/* <AccountCircleIcon className="fontCard-Icon" sx={{color:"#646464"}} /> */}
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
            className="card-text-Primary textCard-margin"
          >
            <Typography className="fontCard-Principal">{fullName}</Typography>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
            className="card-text-Primary textCard-margin"
          >
            <Typography className="fontCard-Principal">{email}</Typography>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
            className="card-text-Primary textCard-margin"
          >
            <Typography className="fontCard-Principal">
              {roles.join(", ")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
