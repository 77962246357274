import { useEffect, useRef, useState } from "react";
import {
  Button,
  IconButton,
  Tooltip,
  TextField,
  Box,
  Stack,
  Grid,
} from "@mui/material";
import { GridToolbarContainer, gridClasses } from "@mui/x-data-grid";
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/Download";
import AddIcon from "@mui/icons-material/Add";
import { styled, alpha } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { policies, policiesAction } from "../../../const/policies";
import { checkPolicy } from "../../../functions/checkPolicy";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import { changeViewState } from "../../../redux/reducers/changeView-reducer";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MobileScreenShareIcon from "@mui/icons-material/MobileScreenShare";
import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";
import EditIcon from "@mui/icons-material/Edit";
import MapIcon from "@mui/icons-material/Map";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import useBackPathFromUrl from "../../../Hooks/useBackfromUrlPath";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import useCheckDisabilitazioniSuPostazioni from "../../../Hooks/useCheckDisabilitazioniSuPostazioni";
import OverrideMaxWidth from "../../../functions/overrideMaxWidth";
let buttonViewMap = false;

const paddingSideMobile = 33;
const paddingSideDesktop = 130;

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: "auto",
  color: "#026D82",
}));

const TableToolbar = ({
  startSearch,
  customButton = undefined,
  initialSearchText,
  enableSearch,
  enableBackOnToolbar = false,
  isSalaRiunione = true,
  showResetDisable,
  downloadXLS,
  enableExport,
  handleAdd,
  enableAdd,
  typePage,
  enableDisable,
  disableAction,
  mobile = true,
  map = false,
  changeViewToMap,
}) => {
  const statePolicy = useSelector((state) => state.userDate.policies);
  const dispatch = useDispatch();
  const userDate = useSelector((state) => state.userDate);
  const [searchText, setSearchText] = useState(initialSearchText);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const [delta, setDelta] = useState(
    window.innerWidth > 600 ? paddingSideDesktop : paddingSideMobile
  );
  const [width, setWidth] = useState(window.innerWidth);
  const viewState = useSelector((state) => state.viewState);
  const navigate = useNavigate();
  const location = useLocation();

  const { policies: statePolicies, responsabileEdificio } = userDate;

  const handleResizeWindow = () => {
    setWidth(window.innerWidth);
    if (window.innerWidth > 600) {
      setDelta(paddingSideDesktop);
      setIsMobile(false);
    } else {
      setDelta(paddingSideMobile);
      setIsMobile(true);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  });

  function changeViewMap() {
    buttonViewMap = changeViewToMap();
  }

  useEffect(() => {
    if (map) {
      //da un problema sul render se si toglie mobile
      buttonViewMap =
        typePage === "planimetriaGestione" || (mobile && buttonViewMap)
          ? true
          : false;
      if (mobile && buttonViewMap) {
        changeViewMap();
      }
    } else {
      buttonViewMap = false;
    }
  }, []);

  const path = useBackPathFromUrl();

  let string = OverrideMaxWidth(width, delta);


  return (
    //<GridToolbarContainer className={gridClasses.toolbarContainer}>
    <Grid container rowSpacing={2}>
      <Grid
        item
        xs={12}
        sm={12}
        md={9}
        lg={9}
        sx={{ overflow: "auto", maxWidth: string }}
      >
        <Stack direction="row">
          {path != "" && enableBackOnToolbar && (
            <div className="buttonBoxToolbar">
              <Tooltip title="Indietro">
                <Button
                  variant="outlined"
                  startIcon={<ArrowBackIcon />}
                  size="small"
                  sx={{ color: "#026D82" }}
                  onClick={() => navigate(path)}
                >
                  Indietro
                </Button>
              </Tooltip>
            </div>
          )}
          {/* {enableExport &&
            checkPolicy(statePolicy, policiesAction.esportazione, typePage) && (
              <div className="buttonBoxToolbar">
                <Tooltip title="Download file Excel">
                  <Button
                    variant="outlined"
                    startIcon={<DownloadIcon />}
                    size="small"
                    sx={{ color: "#026D82" }}
                    onClick={downloadXLS}
                  >
                    Esporta
                  </Button>
                </Tooltip>
              </div>
            )} */}
          {!isMobile && mobile && (
            <div className="buttonBoxToolbar">
              <Tooltip
                title={viewState ? "Modalità Desktop" : "Modalità Mobile"}
              >
                <Button
                  variant="outlined"
                  startIcon={
                    viewState ? (
                      <DesktopWindowsIcon fontSize="large" color="primary" />
                    ) : (
                      <MobileScreenShareIcon fontSize="large" color="primary" />
                    )
                  }
                  size="small"
                  sx={{ color: "#026D82" }}
                  onClick={() => dispatch(changeViewState())}
                >
                  {viewState ? "Desktop" : "Mobile"}
                </Button>
              </Tooltip>
            </div>
          )}
          {map && !viewState && (
            <Tooltip title="Planimetria">
              <div className="buttonBoxToolbar">
                <Button
                  variant="outlined"
                  startIcon={<MapIcon fontSize="large" color="primary" />}
                  size="small"
                  sx={{ color: "#026D82" }}
                  onClick={() => changeViewMap()}
                >
                  {buttonViewMap ? "Elenco" : "Planimetria"}
                </Button>
              </div>
            </Tooltip>
          )}
          {!isSalaRiunione &&
            enableAdd &&
            checkPolicy(
              statePolicy,
              policiesAction.inserimentoModifica,
              typePage
            ) && (
              <Tooltip title="Aggiungi">
                <div className="buttonBoxToolbar">
                  <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    size="small"
                    sx={{ color: "#026D82" }}
                    onClick={handleAdd}
                  >
                    Aggiungi
                  </Button>
                </div>
              </Tooltip>
            )}
            {customButton && customButton}
          {enableDisable &&
            (checkPolicy(
              statePolicy,
              policiesAction.abilitaDisabilita,
              typePage
            ) ||
              responsabileEdificio.length > 0) && (
              <Tooltip title="Disabilita">
                <div className="buttonBoxToolbar">
                  <Button
                    variant="outlined"
                    startIcon={<DoDisturbIcon />}
                    size="small"
                    sx={{ color: "#026D82" }}
                    onClick={() => disableAction("disableRangeData")}
                  >
                    Disabilita
                  </Button>
                </div>
              </Tooltip>
            )}
          {showResetDisable &&
            enableDisable &&
            (checkPolicy(
              statePolicy,
              policiesAction.abilitaDisabilita,
              typePage
            ) ||
              responsabileEdificio.length > 0) && (
              <Tooltip title="Riabilita">
                <div
                  style={{ marginRight: "15px" }}
                  className="buttonBoxToolbar"
                >
                  <Button
                    variant="outlined"
                    startIcon={<RotateRightIcon />}
                    size="small"
                    sx={{ color: "#026D82" }}
                    onClick={() => disableAction("ripristinaAll")}
                  >
                    Riabilita
                  </Button>
                </div>
              </Tooltip>
            )}
        </Stack>
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        {enableSearch && (
          <Search>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <TextField
                id="search-input"
                placeholder="Cerca..."
                inputProps={{ "aria-label": "search" }}
                value={searchText}
                variant="standard"
                fullWidth
                onChange={(event) => {
                  setSearchText(event.target.value);
                }}
                onBlur={() => {
                  startSearch(searchText);
                }}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    startSearch(searchText);
                  }
                }}
              />
              <Tooltip title="Cerca">
                <Button
                  variant="outlined"
                  sx={{
                    borderRadius: "500px",
                    padding: "0",
                    minHeight: "35px",
                    minWidth: "35px",
                  }}
                  onClick={() => {
                    startSearch(searchText);
                  }}
                >
                  <SearchIcon sx={{ mr: 0, my: 0 }} />
                </Button>
              </Tooltip>
            </Box>
          </Search>
        )}
      </Grid>
    </Grid>
    //</GridToolbarContainer>
  );
};

export default TableToolbar;
